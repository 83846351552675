import React, {useEffect} from "react";
import { Link , Route, Redirect} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SketchPicker } from 'react-color';

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function Tag(serviceListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [tagDetails, settagDetails] = React.useState({
      name: "",
      shortcode: "",
      is_deleted: "",
      color: "",
      business_id: JSON.parse(localStorage.getItem('selected_business_id')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [yesNo, setyesNo] = React.useState([
      { value: true , name: 'Yes'  },
      { value: false , name: 'No'  },
    ]);
    const [nameErr, setnameErr] = React.useState();
    // const [shortcodeErr, setshortcodeErr] = React.useState();
    const [isDeletedErr, setisDeletedErr] = React.useState();
    const [colorErr, setcolorErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
    }, [flag, open, openBulk]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelCreateTagOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTagClose = () => {
      setOpen(false);
      settagDetails({
        name: "",
        shortcode: "",
        is_deleted: "",
        color: "",
        business_id: JSON.parse(localStorage.getItem('selected_business_id')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setnameErr();
      // setshortcodeErr();
      setisDeletedErr();
      setcolorErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTagClear = async() => {
      await settagDetails({
        name: "",
        shortcode: "",
        is_deleted: "",
        color: "",
        business_id: JSON.parse(localStorage.getItem('selected_business_id')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      setnameErr();
      // setshortcodeErr();
      setisDeletedErr();
      setcolorErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTagMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let nameErr = '';
      // let shortcodeErr = '';
      let isDeletedErr = '';
      let colorErr = '';

      if(!tagDetails.name)  {
          nameErr = 'Please Enter Name';
      }
      // if(!tagDetails.shortcode)  {
      //     shortcodeErr = 'Please Enter Short Code';
      // }

      if( tagDetails.is_deleted == "" && tagDetails.is_deleted !== false && tagDetails.is_deleted !== true )  {
          isDeletedErr = 'Please Enter File Type';
      }

      if (!tagDetails.color){
        colorErr = 'Please Select Color';
      }

      if( nameErr || isDeletedErr || colorErr ){
        setnameErr(nameErr);
        // setshortcodeErr(shortcodeErr);
        setisDeletedErr(isDeletedErr);
        setcolorErr(colorErr);

        return false;
      }

      return true;

    }

    const handelSubmitCreateTag = () => {
      console.log("tagDetails",tagDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(tagDetails)
          };
          return fetch( process.env.REACT_APP_CREATE_TAG , requestOptions)
              .then((Response) => Response.json())
              .then(tagCreated => {
                  setShowProcedure(false);
                  console.log("tagCreated on same page",tagCreated.success);
                  console.log("tagCreated on same page",tagCreated);
                  if(tagCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Tag Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    settagDetails({
                      name: "",
                      shortcode: "",
                      is_deleted: "",
                      color: "",
                      business_id: JSON.parse(localStorage.getItem('selected_business_id')),
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setnameErr();
                    // setshortcodeErr();
                    setisDeletedErr();
                    setcolorErr();
                  }
                  if(tagCreated.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: tagCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    .then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
      }
    }

    const handleChangeColor = (color, event) => {
      console.log("color, event",color, event)
      settagDetails({...tagDetails, color:color.hex })
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'45%'}}>Create Tag</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateTagMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateTagClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody} style={{position:'absolute'}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={tagDetails.name}
              onChange={(e) => settagDetails({...tagDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(tagDetails.name)?(<div></div>):(nameErr)}</div>

            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="shortcode"
              label="Short Code for Tag"
              name="shortcode"
              autoComplete="shortcode"
              value={tagDetails.shortcode}
              onChange={(e) => settagDetails({...tagDetails, shortcode:e.target.value }) }
            />
            <div className={classes.validation}>{(tagDetails.shortcode)?(<div></div>):(shortcodeErr)}</div> */}

            <FormControl className={classes.formControl}>
              <InputLabel id="color">Color *</InputLabel>
              <div style={{marginTop: "10%"}}>
              <SketchPicker 
                color={ tagDetails.color }
                onChangeComplete={ (color, event) => handleChangeColor(color, event) }
              />
              </div>
            </FormControl>
            <div className={classes.validation}>{(tagDetails.color)?(<div></div>):(colorErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_deleted">Is Deleted*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Deleted*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_deleted"
                  aria-label="is_deleted"
                  name="is_deleted"
                  defaultValue={JSON.stringify(tagDetails.is_deleted)}
                  onChange={ (e) => settagDetails({...tagDetails, is_deleted: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_deleted"
                id="is_deleted"
                value={tagDetails.is_deleted}
                onChange={(e) => settagDetails({...tagDetails, is_deleted:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(tagDetails.is_deleted == "" && tagDetails.is_deleted !== false && tagDetails.is_deleted !== true)?(isDeletedErr):(<div></div>)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateTag}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateTagClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelTagBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelTagBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelTagBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelTagBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitTagBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_TAG , requestOptions)
            .then((Response) => Response.json())
            .then( tagBulk => {
                setShowProcedure(false);
                console.log("tagBulk on same page",tagBulk.success);
                  console.log("tagBulk on same page",tagBulk);
                  if(tagBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Tag Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                   
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(tagBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: tagBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }             
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'48%'}}>Tag Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelTagBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelTagBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcel(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitTagBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelTagBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <ReceiptIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Tags List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>                  
                  <Link to="TagList" style={{ textDecoration: 'none', color:'black' }}>
                    Tag List
                  </Link>
                </Grid>
                <Grid item xs={12} onClick={handelCreateTagOpen} className={classes.userAddUpdateDelete}>
                    Create Tag
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateTagClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelTagBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Tags
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelTagBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
  )
}
