import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";


const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 840,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function QuestionLinks(questionListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [questionLinkDetails, setquestionLinkDetails] = React.useState({
        next_question_id: "",
        question_option_id: "",
        boolean_question_id: "",
        boolean_answer: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [selectedQueType, setselectedQueType] = React.useState();
    const [OptionDetails, setOptionDetails] = React.useState([]);
    const [trueFalse, settrueFalse] = React.useState([
      { value: true , name: 'True'  },
      { value: false , name: 'False'  },
    ]);

    const [nextQuestionErr, setnextQuestionErr] = React.useState();
    const [queOptionIdErr, setqueOptionIdErr] = React.useState();
    const [booleanQueErr, setbooleanQueErr] = React.useState();
    const [booleanAnsErr, setbooleanAnsErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      // dispatch(userActions.checkSessionValidity());
    }, [flag, open, openBulk]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    // useEffect(() => {
    //     // you will get updated finalData here, each time it changes
    //     console.log(selectedQueType);
    //     // you can trigger your function from here
    // },[selectedQueType]);

    const handelCreateQuestionLinkOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateQuestionLinkClose = () => {
      setOpen(false);
      setquestionLinkDetails({
        next_question_id: "",
        question_option_id: "",
        boolean_question_id: "",
        boolean_answer: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setnextQuestionErr();
      setqueOptionIdErr();
      setbooleanQueErr();
      setbooleanAnsErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateQuestionLinkClear = async() => {
      await setquestionLinkDetails({
        next_question_id: "",
        question_option_id: "",
        boolean_question_id: "",
        boolean_answer: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      setnextQuestionErr();
      setqueOptionIdErr();
      setbooleanQueErr();
      setbooleanAnsErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateQuestionLinkMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let nextQuestionErr = '';
      let queOptionIdErr = '';
      let booleanQueErr = '';
      let booleanAnsErr = '';

      if(!questionLinkDetails.next_question_id)  {
          nextQuestionErr = 'Please Select Next Question';
      }
      if(selectedQueType == "BOOLEAN"){
        if( questionLinkDetails.boolean_answer == "" && questionLinkDetails.boolean_answer !== false && questionLinkDetails.boolean_answer !== true)  {
            booleanAnsErr = 'Please Select Answer';
        }
        if(!questionLinkDetails.boolean_question_id)  {
            booleanQueErr = 'Please Select Boolean Question';
        }
      }

      if(selectedQueType == "SINGLE-ANSWER" || selectedQueType == "MULTIPLE-ANSWER"){
        if(questionLinkDetails.question_option_id == "")  {
            queOptionIdErr = 'Please Select Question Option';
        }
      }

      if(nextQuestionErr || queOptionIdErr || booleanQueErr || booleanAnsErr){
        setnextQuestionErr(nextQuestionErr);
        setqueOptionIdErr(queOptionIdErr);
        setbooleanQueErr(booleanQueErr);
        setbooleanAnsErr(booleanAnsErr);

        return false;
      }

      return true;

    }

    const handelSubmitCreateQuestionLink = () => {
      console.log("questionLinkDetails",questionLinkDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(questionLinkDetails)
          };
          return fetch( process.env.REACT_APP_ADD_QUESTION_LINKS , requestOptions)
              .then((Response) => Response.json())
              .then(questionLinkCreated => {
                  setShowProcedure(false);
                  console.log("questionLinkCreated on same page",questionLinkCreated.success);
                  console.log("questionLinkCreated on same page",questionLinkCreated);
                  if(questionLinkCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Question Link Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                   
                    setquestionLinkDetails({
                        next_question_id: "",
                        question_option_id: "",
                        boolean_question_id: "",
                        boolean_answer: "",
                        device_token: JSON.parse(localStorage.getItem('device_token')),
                        session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setnextQuestionErr();
                    setqueOptionIdErr();
                    setbooleanQueErr();
                    setbooleanAnsErr();
                    setselectedQueType();
                    setOptionDetails();
                  }
                  if(questionLinkCreated.success == false || questionLinkCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: questionLinkCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
      }
    }

    const handleChangeNextQue = (e) => {
      console.log("e",e.target.value)
      // console.log("e target",e.target)
      // console.log("e value2",e.target.value2)
      setquestionLinkDetails({...questionLinkDetails, next_question_id:e.target.value })
      questionListDropdown.questionListDropdown.map( async(item) =>{
        if( item.id == e.target.value){
          console.log("id is matched",item)
          console.log("qtype",item.qtype)
          return(
            await setselectedQueType(item.qtype),
            await setOptionDetails(item.options)
          )
          
        }
      })
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'35%'}}>Create Question Link</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateQuestionLinkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateQuestionLinkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody}>
         { console.log('questionListDropdown',questionListDropdown)}
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="next_question_id">Next Question*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Next Question*</Grid>
              <Select
                native
                labelId="next_question_id"
                id="next_question_id"
                value={questionLinkDetails.next_question_id}
                // onChange={(e) =>setquestionLinkDetails({...questionLinkDetails, next_question_id:e.target.value }) }
                 onChange={handleChangeNextQue}
              >
                {/* <MenuItem value={''}>Select Question</MenuItem>
                {questionListDropdown.questionListDropdown.filter(item => item.qtype !== "TEXT-ANSWER").map(item =>
                  <MenuItem value={item.id} value2={item.qtype}>{item.question}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Question</option>
                  {questionListDropdown.questionListDropdown.filter(item => item.qtype !== "TEXT-ANSWER").map(item =>
                    <option value={item.id}>{item.question}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(questionLinkDetails.next_question_id)?(<div></div>):(nextQuestionErr)}</div>

            {
              ( selectedQueType == "BOOLEAN" ) ? 
              (
                // <div>selected question type is boolean</div>
                <div>   
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="boolean_question_id">Boolean Question*</InputLabel> */}
                    <Grid style={{marginTop:"2%", color:"grey"}}>Boolean Question*</Grid>
                    <Select
                      native
                      labelId="boolean_question_id"
                      id="boolean_question_id"
                      value={questionLinkDetails.boolean_question_id}
                      onChange={(e) => setquestionLinkDetails({...questionLinkDetails, boolean_question_id:e.target.value }) }
                    >
                      {/* <MenuItem value={''}>Select Question</MenuItem>
                        {questionListDropdown.questionListDropdown.filter(item => item.qtype == "BOOLEAN").map(item =>
                          <MenuItem value={item.id} value2={item.qtype}>{item.question}</MenuItem>
                        )} */}
                        <option value="" style={{color:'grey'}}>Select Question</option>
                          {questionListDropdown.questionListDropdown.filter(item => item.qtype == "BOOLEAN").map(item =>
                            <option value={item.id}>{item.question}</option>
                        )}

                    </Select>
                  </FormControl>
                  <div className={classes.validation}>{(questionLinkDetails.boolean_question_id)?(<div></div>):(booleanQueErr)}</div>

                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="boolean_answer">Boolean Answer*</InputLabel> */}
                    <Grid style={{marginTop:"2%", color:"grey"}}>Boolean Answer*</Grid>
                    <Grid container item xs={12} style={{marginTop:"1%"}}>
                      <RadioGroup
                        row
                        id ="boolean_answer"
                        aria-label="boolean_answer"
                        name="boolean_answer"
                        defaultValue={JSON.stringify(questionLinkDetails.boolean_answer)}
                        onChange={ (e) => setquestionLinkDetails({...questionLinkDetails, boolean_answer: JSON.parse(e.target.value) })}
                      >                  
                        <div>
                          <FormControlLabel
                            value="true"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "Yes"
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            value="false"
                            classes={{ label: classes.label }}
                            control={<Radio size="small" color="primary" />}
                            label= "No"
                          />
                        </div>
                      </RadioGroup>
                    </Grid>
                    {/* <Select
                      labelId="boolean_answer"
                      id="boolean_answer"
                      value={questionLinkDetails.boolean_answer}
                      onChange={(e) => setquestionLinkDetails({...questionLinkDetails, boolean_answer:e.target.value }) }
                    >
                      {trueFalse.map(item =>
                        <MenuItem value={item.value}>{item.name}</MenuItem>
                      )}
                    </Select> */}
                  </FormControl>
                  <div className={classes.validation}>{( questionLinkDetails.boolean_answer == "" && questionLinkDetails.boolean_answer !== false  && questionLinkDetails.boolean_answer !== true)?(booleanAnsErr):(<div></div>)}</div>
                  
                </div>
              ): 
              (
                ( selectedQueType == "SINGLE-ANSWER" || selectedQueType == "MULTIPLE-ANSWER" ) ? 
                (
                  // <div>selected question type is SINGLE-ANSWER</div>
                  <div>
                    <FormControl className={classes.formControl}>
                      {/* <InputLabel id="question_option_id">Question Option Id*</InputLabel> */}
                      <Grid style={{marginTop:"2%", color:"grey"}}>Question Option Id*</Grid>
                      <Select
                        native
                        labelId="question_option_id"
                        id="question_option_id"
                        value={questionLinkDetails.question_option_id}
                        onChange={(e) => setquestionLinkDetails({...questionLinkDetails, question_option_id:e.target.value }) }
                      >
                        {/* <MenuItem value={''}>Select Option</MenuItem>
                        {OptionDetails && OptionDetails.map(item =>
                          <MenuItem value={item.option_id}>{item.option}</MenuItem>
                        )} */}
                        <option value="" style={{color:'grey'}}>Select Option</option>
                          {OptionDetails && OptionDetails.map(item =>
                            <option value={item.option_id}>{item.option}</option>
                        )}
                      </Select>
                    </FormControl>
                    <div className={classes.validation}>{(questionLinkDetails.question_option_id == "")?(queOptionIdErr):(<div></div>)}</div>
                  </div>
                ):
                (<div></div>)
              )
            }
            
            

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateQuestionLink}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateQuestionLinkClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelQuestionLinkBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelQuestionLinkBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelQuestionLinkBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelQuestionLinkBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitQuestionLinkBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_QUESTION_LINKS , requestOptions)
            .then((Response) => Response.json())
            .then( questionLinkBulk => {
                setShowProcedure(false);
                console.log("questionLinkBulk on same page",questionLinkBulk.success);
                  console.log("questionLinkBulk on same page",questionLinkBulk);
                  if(questionLinkBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Question Link Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                   
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(questionLinkBulk.success == false){                  
                    Swal.fire({
                      icon: 'error',
                      text: questionLinkBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'35%'}}>Question Link Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelQuestionLinkBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelQuestionLinkBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcel(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitQuestionLinkBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelQuestionLinkBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Question Links List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="QuestionLinksList" style={{ textDecoration: 'none', color:'black' }}>
                    Question Links List
                  </Link>
                </Grid>
                <Grid item xs={12} onClick={handelCreateQuestionLinkOpen} className={classes.userAddUpdateDelete}>
                    Create Question Link
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateQuestionLinkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelQuestionLinkBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Question Links
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelQuestionLinkBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
            </Grid>
        </Paper>
    </Grid>
  )
}
