import * as updatecaseConstant from '../constants/updatecase.constants';

const initialState = []

export function updateCase(state = initialState, action) {
  switch (action.type) {

    case updatecaseConstant.SELECTED_CASE_DETAILS:
      return Object.assign({}, state, {
        selectedCaseDetails: action.selectedCaseDetails
      });

    case updatecaseConstant.NEW_CASE_DETAILS:
      return Object.assign({}, state, {
        newCaseDetails: action.newCaseDetails
      });

    case updatecaseConstant.SELF_ASSESMENT:
      return Object.assign({}, state, {
        selfAssesment: action.selfAssesment
      });
    
    case updatecaseConstant.SUPER_CATEGORY:
      return Object.assign({}, state, {
        superCategory: action.superCategory
      });

    case updatecaseConstant.SUB_CATEGORY:
      return Object.assign({}, state, {
        subCategory: action.subCategory
      });
    
    case updatecaseConstant.SUB_CATEGORY_FETCHED:
      return Object.assign({}, state, {
        subCategoryFetched: action.subCategoryFetched
      });

    case updatecaseConstant.SELECTED_CATEGORY_IS_CATEGORY_FLAG:
      return Object.assign({}, state, {
        selectedCategoryFlag: action.selectedCategoryFlag
      });

    case updatecaseConstant.LAST_CATEGORY:
      return Object.assign({}, state, {
        lastCategory: action.lastCategory
      });

    case updatecaseConstant.SSE_INFO:
      return Object.assign({}, state, {
        sseInfo: action.sseInfo
      });

    case updatecaseConstant.CREATE_CASE_DETAILS:
      return Object.assign({}, state, {
        createCaseDetails: action.createCaseDetails
      });

    case updatecaseConstant.QSN_LIST:
      return Object.assign({}, state, {
        qsnList: action.qsnList
      });

    case updatecaseConstant.QSN_LIST_LEVEL_TWO:
      return Object.assign({}, state, {
        qsnListLevelTwo: action.qsnListLevelTwo
      });

    case updatecaseConstant.QSN_LIST_LEVEL_THREE:
      return Object.assign({}, state, {
        qsnListLevelThree: action.qsnListLevelThree
      });

    case updatecaseConstant.NEW_CASE_CREATED_CASE_ID:
      return Object.assign({}, state, {
        newCaseCreatedCaseId: action.newCaseCreatedCaseId
      });

    case updatecaseConstant.YEAR_ASS:
      return Object.assign({}, state, {
        yearAss: action.yearAss
      });

    case updatecaseConstant.UPDATE_CASE_RESET_ACTION:
      return initialState;

      // return Object.assign({}, state, {
      //   initialState
      // });

      // return Object.assign({}, state, {
      //   selectedCaseDetails: [],
      //   newCaseDetails: [],
      //   selfAssesment: "",
      //   superCategory: "",
      //   subCategory: "",
      //   subCategoryFetched: "",
      //   selectedCategoryFlag: "",
      //   lastCategory: "",
      //   sseInfo: [],
      //   createCaseDetails: [],
      //   qsnList: [],
      //   qsnListLevelTwo: [],
      //   qsnListLevelThree: [],
      //   newCaseCreatedCaseId: [],
      // });

      // return  {selectedCaseDetails: [],
      // newCaseDetails: [],
      // selfAssesment: "",
      // superCategory: "",
      // subCategory: "",
      // subCategoryFetched: "",
      // selectedCategoryFlag: "",
      // lastCategory: "",
      // sseInfo: [],
      // createCaseDetails: [],
      // qsnList: [],
      // qsnListLevelTwo: [],
      // qsnListLevelThree: [],
      // newCaseCreatedCaseId: [],}

      // return state

      // return Object.assign({}, state, {
      //   state
      // });

    default:
      return state
  }
}