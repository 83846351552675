import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import BackupIcon from "@material-ui/icons/Backup";
import TaxManagementClientTable from "../TaxManagementClientTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import FilterViewClient from "../FilterViewClient";
import Drawer from "@material-ui/core/Drawer";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link, Redirect} from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useHistory } from 'react-router-dom'

import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStyles from '../ThemeStyles';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(ClientPendingZipData, comparator) {
    const stabilizedThis = ClientPendingZipData && ClientPendingZipData.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


  const headCells = [
    { id: 'file_id', numeric: false, label: 'Id', },
    { id: 'client_file_type', numeric: false, label: 'File Type', },
    { id: 'client_zip_file', numeric: false, label: 'File Name', },
    { id: 'file_state', numeric: false, label: 'File Status', },
    { id: 'total_items', numeric: false, label: 'Total Items', },
    { id: 'total_success', numeric: false, label: 'Total Success', },
    { id: 'total_failed', numeric: false, label: 'Total Fail', },
  ];

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                // style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
                style={{fontWeight: 'bold', fontSize: 17, }}
              >
                {headCell.label}
              </TableCell>
            )
            :(
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'center' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              // style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              style={{fontWeight: 'bold', fontSize: 17,}}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    }
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

export default function Uploaded(props) {
    const [ device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [ session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [businessRoles, setBusinessRoles] = React.useState([]);
    const [roleDetails, setRoleDetails] = React.useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [ClientPendingZipData, setClientPendingZipData] = React.useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, ClientPendingZipData && ClientPendingZipData.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setShowProcedure(true);
        dispatch(userActions.checkSessionValidity());

        // console.log("in get data of uploded uploaded,process.env.REACT_APP_GET_CLIENT_ZIP_FILES",
        // process.env.REACT_APP_GET_CLIENT_ZIP_FILES, process.env.REACT_APP_CLIENT_ZIP_FILES, 
        // process.env.REACT_APP_GET_CASE_STEP_TODO_LIST, process.env.REACT_APP_INVITE_CLIENT_EXTRACTED,
        // process.env.REACT_APP_GET_USERS_PERMISSION )
        
        getData();

   }, []);

   const getData = () => {
    console.log("in get data of uploded uploaded,process.env.REACT_APP_GET_CLIENT_ZIP_FILES",
    process.env.REACT_APP_GET_CLIENT_ZIP_FILES )

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          // 'file_state': 'pending', 
          'file_state': 'all', 
          'business_id': business_id, 
          'device_token': device_token, 'session_id': session_id
        })
    };
    return fetch( process.env.REACT_APP_GET_CLIENT_ZIP_FILES , requestOptions)
    // return fetch('https://api.samai.ai/users/getclientzipfiles/', requestOptions)
    .then((Response) => Response.json())
    .then(checkResponseClientZipDetails => {
        setShowProcedure(false);
        console.log("checkResponseClientZipDetails====",checkResponseClientZipDetails)
        setClientPendingZipData(checkResponseClientZipDetails.data.clientzips)
        
    })
    // .catch(err => {
    //     setShowProcedure(false);
    //     Swal.fire({
    //       icon: 'error',
    //       // text: "Something went wrong",
    //       text: "Server Error (s3). Please try again.",
    //       confirmButtonColor: '#d33',
    //       confirmButtonText: 'OK'
    //     })
    // });
   }

    return (
        // console.log("ClientPendingZipData in return",ClientPendingZipData),
        <div >
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
            </modal>: null}
            <div>
            <Grid container>
                <Grid item xs={12}  className={classes.pageHeaderContainer}>
                    <Grid item xs={8} className={classes.pageHeaderLabel}>
                        {/* UPLAODED */}
                      Status of uploaded files
                    </Grid>

                    <Grid style={{marginTop:'2%'}}>
                    <TableContainer className={classes.tableWrapper} component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={ClientPendingZipData && ClientPendingZipData.length}
                            />
                            <TableBody>
                                {stableSort(ClientPendingZipData && ClientPendingZipData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => {
                                // console.log("row in table map",item)
                                    return (
                                    <TableRow 
                                        key={item.tag_id}
                                        hover={true} classes={{hover: classes.hover}}
                                        tabIndex={-1}
                                        align="center"
                                    >
                                        <TableCell width="5%" align="center" >{item.file_id}</TableCell>
                                        <TableCell width="5%" align="center" >{item.client_file_type}</TableCell>
                                        {/* <TableCell width="10%" align="center" >{item.client_zip_file}</TableCell>  */}
                                        <TableCell width="20%" align="center" >{item.client_zip_file.substring(item.client_zip_file.lastIndexOf('/')+1)}</TableCell> 
                                        <TableCell width="10%" align="center" >{item.file_state}</TableCell>
                                        <TableCell width="10%" align="center" >{item.total_items}</TableCell>
                                        <TableCell width="10%" align="center" >{item.total_success}</TableCell>
                                        <TableCell width="10%" align="center" >{item.total_failed}</TableCell>

                                        
                                    </TableRow>
                                    );
                                })}
                                    {/* {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                    )} */}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: ClientPendingZipData && ClientPendingZipData.length }]}
                                        colSpan={8}
                                        count={ClientPendingZipData && ClientPendingZipData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                    </TableRow>
                                </TableFooter>
                        </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {/* UPLAODED */}
            </Grid>
            </div>
        </div>
    )
}