import React, {useEffect} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../../actions";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import Button from "@material-ui/core/Button";
import { Link, Redirect} from 'react-router-dom';

import TableHead from '@material-ui/core/TableHead';
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(searchListMessages, comparator) {
  const stabilizedThis = searchListMessages && searchListMessages.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'message', numeric: false, label: 'Message', },
  { id: 'user', numeric: false, label: 'User', },
  { id: 'case_id', numeric: false, label: 'Case ID', },
  { id: 'case_name', numeric: false, label: 'Case Name', },
  { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            align="center"
            style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))} */}
        {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
           )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  rootrp: {
    flexGrow: 1,
    // backgroundColor: 'white',
  },
  cursorPointer: {
    cursor: "pointer"
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  table: {
    minWidth: 500, 
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  hover: {
    cursor: "pointer",
  },
}));

function SearchViewMessages({ intl, handleChange }) {
  const classes = useStyles();
  const [searchListMessages, setsearchListMessages] = React.useState([]);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id , setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [RedirectToTaxManagementCaseDetails, setRedirectToTaxManagementCaseDetails] = React.useState(false);
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
  rowsPerPage - Math.min(rowsPerPage, searchListMessages && searchListMessages.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    let search_string =JSON.parse(localStorage.getItem('searchStringIs'));
    // let search_string = search_string_array.name
    console.log("search_string in search result",search_string)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'search_str': search_string, 'business_id': business_id, 
      'device_token': device_token, 'session_id': session_id,
      'sort_by': '', 'priority': '', 'tags': [], 'assigned_users': [], "client_type": "ALL" })
    };

    fetch( process.env.REACT_APP_SEARCH_CASES , requestOptions)
      .then((Response) => Response.json())
      .then( async(SearchDetails) => {
        console.log("SearchDetails in search",SearchDetails.data);
        console.log("SearchDetails in search search_results",SearchDetails.data.search_results);
        if(SearchDetails.data.search_results)
        {
          setsearchListMessages(SearchDetails.data.search_results.messages);
        }
        if(SearchDetails.success == false){
          setsearchListMessages([])
          Swal.fire({
            icon: 'error',
            text: SearchDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, []);

  const handleClickShowCaseDetailsForFilter = async(item) => {
    console.log("message clicked is",item);
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("SearchResult"));
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
  }

  const handleRowClickOfTable = async(item) => {
    console.log("show case clicked is",item);
    await localStorage.setItem('navigated_toshow_case_from', JSON.stringify("SearchResult"));
    await localStorage.setItem('selected_case_for_details', JSON.stringify(item));
    // if( item.case_curr_state == "CONFIRMED"){
      await setRedirectToTaxManagementCaseDetails(true);
    // }
    // else{
    //   Swal.fire({
    //     // icon: 'error',
    //     text: "Case is not Confirmed yet",
    //     confirmButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   })
    // }
  }

  if(RedirectToTaxManagementCaseDetails == true){
    return <Redirect to={`/TaxManagementCaseDetails`} />
  }
  else{
    return (
      console.log("searchListMessages in case details tab",searchListMessages),
      <div>
        <TableContainer className={classes.tableWrapper} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={searchListMessages && searchListMessages.length}
            />
            <TableBody>
              {stableSort(searchListMessages && searchListMessages, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => {
                console.log("row in table map",item)
                return (
                  <TableRow 
                    key={item.document_type_id}
                    hover={true} classes={{hover: classes.hover}}
                    tabIndex={-1}
                    align="center"
                  >
                    <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.message}</TableCell>
                    <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.user}</TableCell> 
                    <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.case_id}</TableCell> 
                    <TableCell onClick={ () => handleRowClickOfTable(item)} width="10%" align="center">{item.case_name}</TableCell>
                    {
                      // ( item.case_curr_state == "CONFIRMED")?
                      ( item.case_curr_state == "IN-PROGRESS" || item.case_curr_state == "PENDING" || 
                      item.case_curr_state == "PAYMENT-PENDING" || item.case_curr_state == "COMPLETED")?
                      (
                        <TableCell width="5%" align="center" >
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            href="/TaxManagementCaseDetails"
                            onClick={() => handleClickShowCaseDetailsForFilter(item)}
                          >
                            Show Case
                          </Button>
                        </TableCell>
                      )
                      :(
                          <TableCell width="5%" align="center" ></TableCell>
                      )
                    }   
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[100, 50, 25, 10, { label: 'All', value: searchListMessages && searchListMessages.length }]}
                  colSpan={8}
                  count={searchListMessages && searchListMessages.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default injectIntl(SearchViewMessages);
