import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import BackupIcon from "@material-ui/icons/Backup";
import TaxManagementClientTable from "../TaxManagementClientTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage, injectIntl } from "react-intl";
import FilterViewClient from "../FilterViewClient";
import Drawer from "@material-ui/core/Drawer";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link, Redirect} from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';

import Uploaded from "./Uploaded";
import Processed from "./Processed";
import Failed from "./Failed";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useHistory } from 'react-router-dom'

import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStyles from '../ThemeStyles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function TaxManagementClientBulkDashboard({props, intl}) {
    const [ device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [ session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
    const [checked, setChecked] = React.useState(false);
    const [businessRoles, setBusinessRoles] = React.useState([]);
    const [roleDetails, setRoleDetails] = React.useState([]);
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    // const [ClientPendingZipData, setClientPendingZipData] = React.useState([]);
    // const [flag, setflag] = React.useState(false);

    const [Open, setOpen] = React.useState(false);
    const [JsonUpload, setJsonUpload] = React.useState(false);
    const [ZipUpload, setZipUpload] = React.useState(false);
    const [ExcelUpload, setExcelUpload] = React.useState(false);
    const [DocumentName, setDocumentName] = React.useState();
    const [DocumentFile, setDocumentFile] = React.useState();
    const [FileErr, setFileErr] = React.useState("");

    // tab
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        // setflag(true)
        setShowProcedure(true);
        dispatch(userActions.checkSessionValidity());
        setShowProcedure(false);
        
        // const requestOptions = {
        //     method: 'POST',
        //     headers: authHeader(),
        //     body: JSON.stringify({
        //       'file_state': 'pending', 'business_id': business_id, 
        //       'device_token': device_token, 'session_id': session_id
        //     })
        // }
        // return fetch( process.env.REACT_APP_GET_CLIENT_ZIP_FILES , requestOptions)
        // .then((Response) => Response.json())
        // .then(checkResponseClientZipDetails => {
        //     setShowProcedure(false);
        //     console.log("checkResponseClientZipDetails====",checkResponseClientZipDetails)
        //     setClientPendingZipData(checkResponseClientZipDetails.data.clientzips)
            
        // })
        // .catch(err => {
        //     setShowProcedure(false);
        //     Swal.fire({
        //       icon: 'error',
        //       // text: "Something went wrong",
        //       text: "Server Error (s3). Please try again.",
        //       confirmButtonColor: '#d33',
        //       confirmButtonText: 'OK'
        //     })
        // })
    }, []);

    const uploadFileOpen = (item) => {
        console.log("in uploadFileOpen", item)
        
        if ( item === "JSON" ){
            setJsonUpload(true);
            setOpen(true);
        }
        if ( item === "ZIP" ){
            setZipUpload(true);
            setOpen(true);
        }
        if( item === "EXCEL") {
            setExcelUpload(true);
            setOpen(true);
        }
        
    }

    const uploadFileClose = () => {
        setOpen(false);
        setJsonUpload(false);
        setZipUpload(false);
        setExcelUpload(false);
        setDocumentName();
        setDocumentFile();
        setFileErr("")
    }

    const onUploadFile = (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        console.log("uri",event.target.files.uri)
        setDocumentName(event.target.files[0].name);
        setDocumentFile(event.target.files[0])
    }

    const validate = () => {
        let FileErr = '';

        if( !DocumentFile ){
            FileErr = "Please Select File To Upload";
        }

        if( FileErr ){
            setFileErr(FileErr);
    
            return false;
          }
          return true;
    }

    const handelSubmitUploadFile = () => {
        const isValid = validate();
        if(isValid){
            setValue(1)
            setOpen(false);
            console.log('in handelSubmitUploadFile',DocumentFile);
            setShowProcedure(true)
            
            // set this after response id true
            // setJsonUpload(false);
            // setZipUpload(false);
            // setFileErr("")

            const requestOptionsSecond = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                  'doc_type': 'client_zip', 'doc_name': DocumentFile.name, 'doc_extra_info': {'business_id': business_id},
                  'device_token': device_token, 'session_id': session_id
                })
            }
            return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
            .then((Response) => Response.json())
            .then(checkResponseOfS3Upload => {
                console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)
                if (checkResponseOfS3Upload.success === true) {
                    console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)

                    const formData = new FormData();
                    Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
                    formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
                    });
                    formData.append("file", DocumentFile);

                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
                    xhr.send(formData);

                    xhr.onload = function () {
                        if (this.status === 204) {
                            console.log("-----SUCCESSFULLY UPLOADED");

                            console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)

                            let user = JSON.parse(localStorage.getItem('user'));
                            // let formdata = new FormData();
                            // formdata.append('zip_file', checkResponseOfS3Upload.data.public_url.url);
                            // formdata.append('business_id', business_id);
                            // formdata.append('device_token', device_token);
                            // formdata.append('session_id', session_id);
                            const requestOptions = {
                                method: 'POST',
                                headers: {'Authorization': 'Token ' + user.key},
                                // headers: authHeader(),
                                // body: formdata,
                                body: JSON.stringify({
                                    'zip_file': checkResponseOfS3Upload.data.public_url.url, 'business_id': business_id,
                                    'file_key': checkResponseOfS3Upload.data.public_url.key,
                                    'device_token': device_token, 'session_id': session_id
                                })
                            };
                            return fetch( process.env.REACT_APP_CLIENT_ZIP_FILES , requestOptions)
                            .then((Response) => Response.json())
                            .then( IdProofSaved => {
                                setValue(0)
                                setShowProcedure(false);
                                console.log("IdProofSaved ",IdProofSaved.success);
                                console.log("IdProofSaved ",IdProofSaved);
                                if(IdProofSaved.success == true){
                                    Swal.fire({
                                        icon: 'success',
                                        text: "File Saved Succesfully",
                                        confirmButtonColor: 'primary',
                                        confirmButtonText: 'OK',
                                        timer: 5000,
                                    })
                                    setJsonUpload(false);
                                    setZipUpload(false);
                                    setExcelUpload(false);
                                    setFileErr("");
                                    setDocumentFile();
                                    // setflag(false);
                                    
                                }
                                if(IdProofSaved.success == false){
                                    setShowProcedure(false);
                                    setOpen(true);
                                    Swal.fire({
                                      icon: 'error',
                                      text: IdProofSaved.errors,
                                      confirmButtonColor: '#d33',
                                      confirmButtonText: 'OK',
                                      timer: 5000,
                                    })
                                }  
                            })
                            .catch(err => {
                                setShowProcedure(false);
                                Swal.fire({
                                  icon: 'error',
                                  // text: "Something went wrong",
                                  text: "Server Error. Please try again.",
                                  confirmButtonColor: '#d33',
                                  confirmButtonText: 'OK'
                                })
                            });

                        }
                        else {
                            console.log("---------FAILED UPLOAD");
                            setShowProcedure(false);
                            Swal.fire({
                              icon: 'error',
                              // text: "Something went wrong",
                              text: "Upload failed to s3",
                              confirmButtonColor: '#d33',
                              confirmButtonText: 'OK'
                            })
                        }
                    }

                }
                else {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error (s3). Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                }
            })
            .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error (s3). Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
            })

        }
    }

    const body = (
        <div className={classes.paperModal}>
            {console.log("in body ")}
            <div className={classes.addNewBusinessHeaderBackgroud}> 
            <Grid container item xs={12}>
                <Grid  item xs={11}>
                    {
                        ( JsonUpload == true )?
                        (
                            <h2 style={{marginLeft:'38%'}}>UPLOAD JSON</h2>
                        ):
                        (
                            ( ZipUpload == true )?
                            (
                                <h2 style={{marginLeft:'40%'}}>UPLOAD ZIP</h2>
                            ):
                            (
                                // <Grid>Upload JSON</Grid>
                                ( ExcelUpload == true )?
                                (
                                    <h2 style={{marginLeft:'40%'}}>UPLOAD Excel</h2>
                                ):
                                ( <Grid></Grid>)
                            )
                        )
                    }
                </Grid>
                <Grid item xs={1} style={{marginTop:'1%',}}>
                    <CloseIcon onClick={()=>uploadFileClose()} style={{fontSize: 20, cursor:'pointer'}}/>
                </Grid>
            </Grid>
                
            </div>
            <div className={classes.modalBody}>
                <div style={{padding: 10     }}>
                    <input type='file' 
                        // accept="*"
                        // accept={( JsonUpload == true ? ".json" : ".zip")}
                        accept={( JsonUpload == true ? ".json" : ZipUpload == true ? ".zip" : ExcelUpload == true ? ".xlsx,.xls" : null)}
                        id="myBulk"
                        onChange={ (e) => onUploadFile(e)} 
                    />
                    <div className={classes.validationMaxTopMargin}>{(DocumentFile)?(<div></div>):(FileErr)}</div>
                </div>

                <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handelSubmitUploadFile}
                    >
                        UPLOAD
                    </Button>
                    <div style={{marginLeft:'2%'}}></div>
                    <Button
                        type="submit"
                        variant="contained"
                        // color="grey"
                        className={classes.submit}
                        onClick={uploadFileClose}
                    >
                        Cancel
                    </Button>
                </div>
            </div> 
        </div>
    )

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
            </modal>: null}
            <div className={classes.wrapper}>
            <Grid container>
                {/* header and buttons */}
                <Grid>
                    <Button href="/client" className={classes.backButton}>
                        <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                    </Button>
                </Grid>
                <br/><br/>
                <Grid item xs={12} container  className={classes.pageHeaderContainer}>
                    <Grid item xs={6} className={classes.pageHeaderLabel}>
                        Bulk Dashboard 
                    </Grid>

                    {/* <Grid item xs={2}>
                        <div className={classes.buttonContainer}> */}
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonStyleBulkDashboard}
                                startIcon={<BackupIcon />}
                                onClick={() => uploadFileOpen('JSON')}
                            >
                                Upload JSON
                            </Button>
                        {/* </div>
                    </Grid>

                    <Grid item xs={2}> */}
                        {/* <div className={classes.buttonContainer}> */}
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonStyleBulkDashboard}
                                startIcon={<BackupIcon />}
                                onClick={() => uploadFileOpen('ZIP')}
                            >
                                Upload ZIP
                            </Button>
                        {/* </div>
                    </Grid> */}
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyleBulkDashboard}
                        startIcon={<BackupIcon />}
                        onClick={() => uploadFileOpen('EXCEL')}
                    >
                        Upload EXCEL
                    </Button>
                </Grid>

                {/* for tabs */}
                <Grid container>
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.customPaperStyle}>
                            <div className={classes.casesDetailSubMenu}>
                                <Tabs
                                    value={value}
                                    onChange={handleChangeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab
                                        className={classes.tabCustomStyle}
                                        label={intl.formatMessage({ id: "All" })}
                                    />                
                                    <Tab
                                        className={classes.tabCustomStyle}
                                        label={intl.formatMessage({ id: "Succeeded" })}
                                    />
                                    <Tab
                                        className={classes.tabCustomStyle}
                                        label={intl.formatMessage({ id: "Failed" })}
                                    />
                                </Tabs>
                            </div>
                            <TabPanel value={value} index={0}>
                                {/* <Uploaded ClientPendingZipData={ClientPendingZipData}/> */}
                                <Uploaded />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Processed />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Failed />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Grid>

            </Grid>
            </div>
            <Modal
                open={Open}
                onClose={uploadFileClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    )
}

export default injectIntl(TaxManagementClientBulkDashboard);
