import * as newcaseConstant from '../constants/newcase.constants';


export function addNewCase(newCaseDetails) {
    return {
        type: newcaseConstant.NEW_CASE_DETAILS,
        newCaseDetails
    };
}

export function addSelfAssesment(selfAssesment) {
    return {
        type: newcaseConstant.SELF_ASSESMENT,
        selfAssesment
    };
}

export function addSuperCategory(superCategory) {
    return {
        type: newcaseConstant.SUPER_CATEGORY,
        superCategory
    };
}

export function addSubCategory(subCategory) {
    return {
        type: newcaseConstant.SUB_CATEGORY,
        subCategory
    };
}

export function addSubCategoryFetched(subCategoryFetched) {
    return {
        type: newcaseConstant.SUB_CATEGORY_FETCHED,
        subCategoryFetched
    };
}

export function addSelectedCategoryFlag(selectedCategoryFlag) {
    return {
        type: newcaseConstant.SELECTED_CATEGORY_IS_CATEGORY_FLAG,
        selectedCategoryFlag
    };
}

export function addLastCategory(lastCategory) {
    return {
        type: newcaseConstant.LAST_CATEGORY,
        lastCategory
    };
}

export function addsseInfo(sseInfo) {
    return {
        type: newcaseConstant.SSE_INFO,
        sseInfo
    };
}

export function addcreateCaseDetails(createCaseDetails) {
    return {
        type: newcaseConstant.CREATE_CASE_DETAILS,
        createCaseDetails
    };
}

export function addqsnList(qsnList) {
    return {
        type: newcaseConstant.QSN_LIST,
        qsnList
    };
}

export function addqsnListLevelTwo(qsnListLevelTwo) {
    return {
        type: newcaseConstant.QSN_LIST_LEVEL_TWO,
        qsnListLevelTwo
    };
}

export function addqsnListLevelThree(qsnListLevelThree) {
    return {
        type: newcaseConstant.QSN_LIST_LEVEL_THREE,
        qsnListLevelThree
    };
}

export function addnewCaseCreateCaseId(newCaseCreatedCaseId) {
    return {
        type: newcaseConstant.NEW_CASE_CREATED_CASE_ID,
        newCaseCreatedCaseId
    };
}

export function addYearAss(yearAss) {
    return {
        type: newcaseConstant.YEAR_ASS,
        yearAss
    };
}

export function addasseNameOrContactSelected(asseNameOrContactSelected) {
    return {
        type: newcaseConstant.ASSENAME_OR_OTHER_CONTACT_SELECTED,
        asseNameOrContactSelected
    };
}

export function addnewContactSelected(newContactSelected) {
    return {
        type: newcaseConstant.ADD_NEW_CONTACT_SELECTED,
        newContactSelected
    };
}


export function newCaseActionReset() {
    return {
      type: newcaseConstant.NEW_CASE_ACTION_RESET,
    }
}
