import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import Grid from "@material-ui/core/Grid";
import AdjustIcon from "@material-ui/icons/Adjust";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { FormattedMessage } from "react-intl";
import GroupIcon from "@material-ui/icons/Group";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useSelector , useDispatch} from 'react-redux';
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { Button } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { userActions } from "../../actions";
import { Link, Redirect} from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '25px',
    marginBottom: '25px'
  },
  stepContainer: {
    textAlign: "left",
    color: "#4D47DD",
  },
  rootDescription: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "500px",
    marginTop: "5px",
    marginBottom: "25px",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflow:'scroll',
  },
  paperModalStepTodoList: {
    position: 'absolute',
    width: 550,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    cursor: 'pointer',
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
    cursor: 'pointer',
  },
  casesetPriorityInfo: {
    marginTop: "5%",
  },
  setPriorityButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "#4D47DD",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  verticalAlignTop: {
    verticalAlign: "top",
  },
  calenderNewStyle: {
    border: '0px',
    width: '50%',
    cursor:'pointer',
  },
  calenderNewStyleWithoutCursor: {
    border: '0px',
    width: '50%',
  },
  formControl: {
    // margin: theme.spacing(1),
    // marginTop: '4%',
    // width: '100%',
    minWidth: 170,
  },
  CollapseLableHeader: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    // lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "1%",
  },

}));

export default function TaxManagementCasesOverView({fromCaseOverviewCompleteCase}) {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [flag, setflag] = React.useState(false);
  const [caseForDetailsFromAsync, setcaseForDetailsFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_details')));
  const [caseDetailsSteps, setcaseDetailsSteps] = React.useState([]);
  const [caseStepDetailsInOrder, setcaseStepDetailsInOrder] = React.useState([]);
  const [CaseStepInformation, setCaseStepInformation] = React.useState([]);
  const [CurrentIndexForSteps, setCurrentIndexForSteps] = React.useState();
  const [toDoListDetailsFromApi, settoDoListDetailsFromApi] = React.useState([]);
  const [toDoListInformationFromApi, settoDoListInformationFromApi] = React.useState([]);
  const [StepStatesDetails, setStepStatesDetails] = React.useState([]); 
  const [open, setopen] = React.useState(false);
  const [SelectedStepId, setSelectedStepId] = React.useState();
  const [caseStatusIs, setcaseStatusIs] = React.useState("Not Started");
  const [SelectedStepAllDetails, setSelectedStepAllDetails] = React.useState();
  const [OpenAddTodo, setOpenAddTodo] = React.useState(false);
  const [createNewToDoDetails, setcreateNewToDoDetails] = React.useState({
    todolist_id: "",
    item: "",
    is_active: true,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [itemErr, setitemErr] = React.useState("");

  const [openAddUser, setopenAddUser] = React.useState(false);
  const [StepSelectedToAddUsers, setStepSelectedToAddUsers] = React.useState([]);
  const [allUsersList, setallUsersList] = React.useState([]);

  const [dueDate, setdueDate] = React.useState(new Date());
  const [minDate, setminDate] = React.useState(new Date());

  const [RedirectTtoCaseDetailsPage , setRedirectTtoCaseDetailsPage] = React.useState(false);

  const [roleOfUser, setroleOfUser] =  React.useState(JSON.parse(localStorage.getItem('role_of_user')));
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)


  React.useEffect(() => {
    setflag(true);
    // dispatch(userActions.checkSessionValidity());
    // readItemFromStorage();
    // to fetch case details of steps
    let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let process_id =  caseForDetailsFromAsync.process_id;

    const requestOptionsUsers = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptionsUsers)
      .then((Response) => Response.json())
      .then( async(allUsersDetails) => {
        // setShowProcedure(false);
        console.log("allUsersDetails",allUsersDetails);
        if(allUsersDetails.success == true)
        {
          setallUsersList(allUsersDetails.data.business_team);
        }
        else if(allUsersDetails.success == false){
          setallUsersList([]);
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

    // new api to fetch steps 
    let case_id =  caseForDetailsFromAsync.case_id;
    const requestOptionsCaseSteps = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': case_id,
        'device_token': device_token, 'session_id': session_id })
    };
    console.log("requestOptionsCaseSteps",requestOptionsCaseSteps)
    fetch( process.env.REACT_APP_GET_CASE_STEPS , requestOptionsCaseSteps)
      .then((Response) => Response.json())
      .then( async(caseStepsNewDetails) => {
        // setShowProcedure(false);
        console.log("caseStepsNewDetails",caseStepsNewDetails);
        if(caseStepsNewDetails.success == true)
        {
          console.log("caseStepsNewDetails on show case details true",caseStepsNewDetails.data);
          if(caseStepsNewDetails.data.steps_in_order){
            setcaseStepDetailsInOrder(caseStepsNewDetails.data.steps_in_order);
          }
          else if(!caseStepsNewDetails.data.steps_in_order){
            setcaseStepDetailsInOrder([]);
          }
          
          if(caseStepsNewDetails.data.case_step_info){
            setCaseStepInformation(caseStepsNewDetails.data.case_step_info);

            caseStepsNewDetails.data.case_step_info.map((item,index)=>{
              // if( item.step_info.is_start == true && item.step_info.is_end == false){
              //   console.log("this is for current index", item, index)
              //   setCurrentIndexForSteps(index)
              // }
              if( item.is_current == true){
                console.log("this is for current index", item, index)
                setCurrentIndexForSteps(index)
              }
            })
          }
          else if(!caseStepsNewDetails.data.case_step_info){
            setCaseStepInformation([]);
          }
        }
        else if(caseStepsNewDetails.success == false){
          setcaseStepDetailsInOrder([]);
          setCaseStepInformation([]);
          Swal.fire({
            icon: 'error',
            text: caseStepsNewDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

  }, [flag, ShowProcedure, open, OpenAddTodo ]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);
  //   }
  // };

  const StepClickedFunction = (item, i) => {
    // setShowProcedure(true);
    settoDoListDetailsFromApi([])
    settoDoListInformationFromApi([])
    dispatch(userActions.checkSessionValidity());
    console.log("Step is clicked, item, i.is_completed",item, i.is_completed)
    console.log("Step is clicked i ",i)

    if( !i.task_details.case_step_todolist_info){
      Swal.fire({
        icon: 'error',
        text: "Business configuration done incorrectly - task details are empty.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK',
        timer: 9000,
      })
    }

    else if(i.is_current == false){
      if(i.is_completed == false){
        Swal.fire({
          // icon: 'error',
          text: "Step is not active.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
          timer: 9000,
        })
      }
      else if(i.is_completed === true){
        // console.log("okikkkkkkkkkkkkkkk")
        setSelectedStepId(item)
        setSelectedStepAllDetails(i)
        setcreateNewToDoDetails({ ...createNewToDoDetails, todolist_id:item })
        // setcreateNewToDoDetails({ ...createNewToDoDetails, todolist_id:"6" })

        // to set seleceted step state name
        setcaseStatusIs(i.current_state.state_name)

        // to fetch todo details of selected step
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));
        let step_id = item;

        let GetToDoListDetails = ({
          case_id: caseForDetailsFromAsync.case_id,
          step_id: step_id,
          // step_id: "6",
          device_token: device_token,
          session_id: session_id,
        })
        console.log("GetToDoListDetails",GetToDoListDetails)

        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          // body: JSON.stringify({"step_id": "6", "device_token": device_token,"session_id": session_id  })
          body: JSON.stringify(GetToDoListDetails)
        };
        fetch( process.env.REACT_APP_GET_CASE_STEP_TODO_LIST , requestOptions)
          .then((Response) => Response.json())
          .then( async(toDoListDetails) => {
            // setShowProcedure(false);
            console.log("toDoListDetails",toDoListDetails);
            if(toDoListDetails.success == true)
            {
              console.log("toDoListDetails",toDoListDetails.data.case_step_todolist);
              console.log("toDoListDetails",toDoListDetails.data.case_step_todolist_info);
              settoDoListDetailsFromApi(toDoListDetails.data.case_step_todolist);
              settoDoListInformationFromApi(toDoListDetails.data.case_step_todolist_info);
            }
            else if(toDoListDetails.success == false){
              settoDoListDetailsFromApi([]);
              settoDoListInformationFromApi([]);
              Swal.fire({
                icon: 'error',
                text: toDoListDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 9000,
              })
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });

          // to get step states
          fetch( process.env.REACT_APP_GET_CASE_STEP_STATES , requestOptions)
          .then((Response) => Response.json())
          .then( async(StepStatesDetails) => {
            // setShowProcedure(false);
            console.log("StepStatesDetails",StepStatesDetails);
            if(StepStatesDetails.success == true)
            {
              setStepStatesDetails(StepStatesDetails.data.step_states)
            }
            else if(StepStatesDetails.success == false){
              setStepStatesDetails([])
            }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });


        handelShowStepTodoOpen();
      }
    }

    // else if(i.is_current == true || i.is_completed == true){
    else if(i.is_current === true){
      setSelectedStepId(item)
      setSelectedStepAllDetails(i)
      setcreateNewToDoDetails({ ...createNewToDoDetails, todolist_id:item })
      // setcreateNewToDoDetails({ ...createNewToDoDetails, todolist_id:"6" })

      // to set seleceted step state name
      setcaseStatusIs(i.current_state.state_name)

      // to fetch todo details of selected step
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));
      let step_id = item;

      let GetToDoListDetails = ({
        case_id: caseForDetailsFromAsync.case_id,
        step_id: step_id,
        // step_id: "6",
        device_token: device_token,
        session_id: session_id,
      })
      console.log("GetToDoListDetails",GetToDoListDetails)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // body: JSON.stringify({"step_id": "6", "device_token": device_token,"session_id": session_id  })
        body: JSON.stringify(GetToDoListDetails)
      };
      fetch( process.env.REACT_APP_GET_CASE_STEP_TODO_LIST , requestOptions)
        .then((Response) => Response.json())
        .then( async(toDoListDetails) => {
          // setShowProcedure(false);
          console.log("=========================toDoListDetails",toDoListDetails);
          if(toDoListDetails.success == true)
          {
            console.log("toDoListDetails",toDoListDetails.data.case_step_todolist);
            console.log("toDoListDetails",toDoListDetails.data.case_step_todolist_info);
            settoDoListDetailsFromApi(toDoListDetails.data.case_step_todolist);
            settoDoListInformationFromApi(toDoListDetails.data.case_step_todolist_info);
          }
          else if(toDoListDetails.success == false){
            settoDoListDetailsFromApi([]);
            settoDoListInformationFromApi([]);
            Swal.fire({
              icon: 'error',
              text: toDoListDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 9000,
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

        // to get step states
        fetch( process.env.REACT_APP_GET_CASE_STEP_STATES , requestOptions)
        .then((Response) => Response.json())
        .then( async(StepStatesDetails) => {
          // setShowProcedure(false);
          console.log("StepStatesDetails",StepStatesDetails);
          if(StepStatesDetails.success == true)
          {
            setStepStatesDetails(StepStatesDetails.data.step_states)
          }
          else if(StepStatesDetails.success == false){
            setStepStatesDetails([])
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });


      handelShowStepTodoOpen();
    }

    
  }

  // show step todo all functions
  const handelShowStepTodoOpen = () =>{
    console.log("handelShowStepTodoOpen")
    setopen(true)
    dispatch(userActions.checkSessionValidity());
    settoDoListDetailsFromApi([])
    settoDoListInformationFromApi([])
  }

  const handelShowStepTodoClose = () =>{
    setopen(false);
    setflag(false);
    dispatch(userActions.checkSessionValidity());
    settoDoListDetailsFromApi([])
    settoDoListInformationFromApi([])
  }

  const handleDeleteToDoListItem = (item) => {
    dispatch(userActions.checkSessionValidity());
    setShowProcedure(true);
    setopen(false);
    console.log("Item is", item);
    let deleteToDoItemDetails = ({
      todolist_id: item.todolist_id,
      todo_id: item.item_id,
      item: item.item,
      is_active: false,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    console.log("deleteToDoItemDetails is", deleteToDoItemDetails);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: classes.buttonSwalDelete,
          cancelButton: classes.buttonSwalCancel,
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {
      
      if (result.value) {
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(deleteToDoItemDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_USER_TODO , requestOptions)
          .then((Response) => Response.json())
          .then( toDoDetailsAfterDelete => {
            setShowProcedure(false);
            setopen(false);
            console.log("toDoDetailsAfterDelete",toDoDetailsAfterDelete.success);
            console.log("toDoDetailsAfterDelete",toDoDetailsAfterDelete);
            if(toDoDetailsAfterDelete.success == true){
              Swal.fire({
                icon: 'success',
                text: "User ToDo Deleted Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setopen(true);
            }
            if(toDoDetailsAfterDelete.success == false ){
              Swal.fire({
                icon: 'error',
                text: toDoDetailsAfterDelete.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
              setopen(true);
            } 
          refreshToDoList();            
        })
        .catch(err => {
          setShowProcedure(false);
          setopen(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
              
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) 
      {
        setShowProcedure(false);
        setopen(true);
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }

  const toggleToDoListCheckBox = async(value, item) => {
    console.log("toggleToDoListCheckBox value, item",value, item)
    // toDoListDetailsFromApi
    setopen(false);
    dispatch(userActions.checkSessionValidity());
     
    // settoDoListDetailsFromApi(
    //   toDoListDetailsFromApi.map((f) => {
    //       if (f.item_id === item.item_id) 
    //       {
    //           f.is_checked = value
    //       }
    //       return f;
    //   })
    // );
    
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));

    let caseToDoUpdate = ({
      case_id: caseForDetailsFromAsync.case_id,
      item_id: item.item_id,
      is_checked: value,
      item_type: item.item_type,
      device_token: device_token,
      session_id: session_id,
    })
    console.log("caseToDoUpdate",caseToDoUpdate)

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      setopen(true);
      setflag(true);
      if (result.value) {
        // setShowProcedure(true);
        const requestOptionscaseToDoUpdate = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(caseToDoUpdate)
        };
        fetch( process.env.REACT_APP_UPDATE_CASE_STEP_TODOLIST_ITEM , requestOptionscaseToDoUpdate)
          .then((Response) => Response.json())
          .then( async(caseToDoUpdate) => {
            // setShowProcedure(false);
            console.log("caseToDoUpdate",caseToDoUpdate);
            if(caseToDoUpdate.success == true)
            {
              console.log(caseToDoUpdate.data)
              setflag(false);
              refreshToDoList()
              // settoDoListDetailsFromApi(
              //     toDoListDetailsFromApi.map((f) => {
              //         if (f.item_id === item.item_id) 
              //         {
              //             f.is_checked = value
              //         }
              //         return f;
              //     })
              //   );
            }
            else if(caseToDoUpdate.success == false){
              console.log(caseToDoUpdate.data)
              setflag(false);
              // Swal.fire({
              //   icon: 'error',
              //   text: caseDetails.errors,
              //   confirmButtonColor: '#d33',
              //   confirmButtonText: 'OK',
              //   timer: 9000,
              // })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
              
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) 
      {
        // setopen(true);
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })

    
  }

  const changeStateOfSelectedStep = (state_name) => {
    dispatch(userActions.checkSessionValidity());
    console.log("state_name",state_name);

    if ( state_name == "COMPLETED" ){
      let checkCompulsaory = "";
      console.log("checkCompulsaory before",checkCompulsaory)
      toDoListDetailsFromApi.map((f) => {
        console.log("f is",f)
        if(f.is_compulsory == true){
          if( f.is_checked == false){
            checkCompulsaory = "true";
          }
        }
      })
      console.log("checkCompulsaory after",checkCompulsaory)
      
      if(checkCompulsaory == "true"){
        Swal.fire({
          icon: 'error',
          text: "Check All Compulsory Tasks",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
        })
      }
      else{
        // setopen(false);
      var state_id = ""
      StepStatesDetails.map((f)=>{
        if(f.state_name == state_name){
          console.log("this is new state",f)
          state_id = f.step_state_id
        }
      })
      console.log("selected steps state id is", state_id)

      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      let stateChangeObj = ({
        case_id: caseForDetailsFromAsync.case_id,
        step_state_id: state_id,
        device_token: device_token,
        session_id: session_id,
      })
      console.log("stateChangeObj",stateChangeObj)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(stateChangeObj)
      };
      return fetch( process.env.REACT_APP_UPDATE_CASE_STEP_STATE , requestOptions)
        .then((Response) => Response.json())
        .then(caseStepStateUpdated => {
          setShowProcedure(false);
          setflag(false);
          console.log("caseStepStateUpdated",caseStepStateUpdated);

          if(caseStepStateUpdated.success == true){
            // Swal.fire({
            //   icon: 'success',
            //   text: "State Updated Succesfully",
            //   confirmButtonColor: 'primary',
            //   confirmButtonText: 'OK',
            //   timer: 5000,
            // }).then(function (result) {
            //   console.log("result of swal",result)
              setopen(true);
              setcaseStatusIs(state_name)
              Swal.fire({
                icon: 'success',
                text: "Step state updated succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              }).then(function (result) {
                console.log("result of swal",result)
                setopen(false);
              })
            // })

            // 17 may
            fromCaseOverviewCompleteCase()
            const requestOptionsCaseDetails = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify({'case_id': caseForDetailsFromAsync.case_id,
                'device_token': device_token, 'session_id': session_id })
            };
            fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
              .then((Response) => Response.json())
              .then( async(caseDetails) => {
                console.log("caseDetailson on confirm case details",caseDetails);
                if(caseDetails.success == true)
                {
                  console.log("caseDetailson on confirm case details true",caseDetails.data.case_details);
                  
                  await localStorage.setItem('selected_case_for_details', JSON.stringify(caseDetails.data.case_details));
                  await setcaseForDetailsFromAsync(caseDetails.data.case_details)
                  setflag(true);
                  // setRedirectTtoCaseDetailsPage(true)
                }
                else if(caseDetails.success == false){
                  await localStorage.setItem('selected_case_for_details', []);
                  await setcaseForDetailsFromAsync([])
                  setflag(true);
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }); 
            
          }
          if(caseStepStateUpdated.success == false ){
            setopen(false)
            Swal.fire({
              icon: 'error',
              text: caseStepStateUpdated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            }).then(function (result) {
              console.log("result of swal",result)
              setopen(true);
            })
          } 
        // setflag(false);            
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
      }

    }
    else{
      // setopen(false);
      var state_id = ""
      StepStatesDetails.map((f)=>{
        if(f.state_name == state_name){
          console.log("this is new state",f)
          state_id = f.step_state_id
        }
      })
      console.log("selected steps state id is", state_id)

      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      let stateChangeObj = ({
        case_id: caseForDetailsFromAsync.case_id,
        step_state_id: state_id,
        device_token: device_token,
        session_id: session_id,
      })
      console.log("stateChangeObj",stateChangeObj)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(stateChangeObj)
      };
      return fetch( process.env.REACT_APP_UPDATE_CASE_STEP_STATE , requestOptions)
        .then((Response) => Response.json())
        .then(caseStepStateUpdated => {
          setShowProcedure(false);
          setflag(false);
          console.log("caseStepStateUpdated",caseStepStateUpdated);

          if(caseStepStateUpdated.success == true){
            // Swal.fire({
            //   icon: 'success',
            //   text: "State Updated Succesfully",
            //   confirmButtonColor: 'primary',
            //   confirmButtonText: 'OK',
            //   timer: 5000,
            // }).then(function (result) {
            //   console.log("result of swal",result)
              setopen(true);
              setcaseStatusIs(state_name)
              Swal.fire({
                icon: 'success',
                text: "Step state updated succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              }).then(function (result) {
                console.log("result of swal",result)
                setopen(false);
              })
            // })
            
          }
          if(caseStepStateUpdated.success == false ){
            setopen(false)
            Swal.fire({
              icon: 'error',
              text: caseStepStateUpdated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            }).then(function (result) {
              console.log("result of swal",result)
              setopen(true);
            })
          } 
        // setflag(false);            
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const body = (
    <div className={classes.paperModalStepTodoList}>
      {
        ( roleOfUser == "client")?
        (
          <div>
            <div className={classes.addNewBusinessHeaderBackgroud}> 
              <Grid container >
                <Grid container item xs={11} style={{justifyContent: 'center',}}>
                  <h2>Step ToDo List</h2>               
                </Grid>
                <Grid item xs={1} style={{marginTop: '4%',}}>
                  <Grid item xs={1}  className={classes.moreVertIcon}>
                    <CloseIcon onClick={handelShowStepTodoClose} style={{color:'black', cursor: "pointer", }}/>
                  </Grid>
                </Grid>
              </Grid>  
            </div>
            
            <div className={classes.modalBody} style={{position:'absolute'}}>
              {/* to show other info */}
              <div>
                <Grid container item xs={12} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>
                  {SelectedStepAllDetails && SelectedStepAllDetails.step_info.name}
                </Grid>
                <Grid container item xs={12} className={classes.casesetPriorityInfo} style={{marginTop:"1%"}}>
                  <Grid container style={{marginTop:"1%"}}>
                    <Grid container item xs={8}>
                      <Grid item xs={8} style={{fontSize: 15, fontWeight: 'bold'}}>
                        {/* State: {SelectedStepAllDetails && SelectedStepAllDetails.current_state.state_name} */}
                        State: {caseStatusIs}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item xs={12} >
                        {/* <FormControl className={classes.formControl}>
                          <Select
                            style={{marginTop:'-5 %'}}
                            displayEmpty
                            className={classes.selectEmpty}
                            id="caseStatusIs"
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={caseStatusIs}
                            // onChange={(e) => setcaseStatusIs(e.target.value ) }
                            onChange={(e) => changeStateOfSelectedStep(e.target.value) }
                          >
                            <MenuItem value={''}>Select State</MenuItem>
                            {StepStatesDetails.map(item =>
                              <MenuItem value={item.state_name}>{item.state_name}</MenuItem>
                            )}
                          </Select>
                        </FormControl> */}
                      </Grid>
                    </Grid> 
                  </Grid>
                </Grid>

                <Grid item xs={4} style={{marginTop:"1%"}}>
                  <AccessTimeIcon
                    fontSize="small"
                    style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                  ></AccessTimeIcon>
                  <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                  verticalAlign: "top", fontSize: "14px" , color:'#6e6d6b', marginLeft:'7%'}} >
                    {/* No due date */}
                    {SelectedStepAllDetails && SelectedStepAllDetails.due_date }
                  </div>
                </Grid>
                <Grid item xs={12} style={{marginTop:"1%"}}>
                  <GroupIcon
                    fontSize="small"
                    style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                  ></GroupIcon>
                    <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block",
                    verticalAlign: "top", fontSize: "14px" }} >
                        {/* Unassigned */}
                        <Grid item xs={12} style={{ marginTop:'1%' }}>
                          {SelectedStepAllDetails && SelectedStepAllDetails.assigned_users.map((au, aui) =>(
                            // <AccountCircleIcon style={{ fontSize:40 }}/>
                                  // console.log("a assigned_users",au),
                            <Grid item xs={12} style={{marginLeft:'5%', color:'#6e6d6b'}}>{aui + 1}.{au.name}</Grid>
                          )
                          )}
                        </Grid>
                    </div>
                </Grid>
                <Grid container style={{marginTop:"1%"}}>
                  <Grid container item xs={10}>
                    <Grid item xs={11} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>Tasks</Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid style={{ fontSize: 15, color:'black'}}>
                      {toDoListInformationFromApi && toDoListInformationFromApi.completed} / 
                      {toDoListInformationFromApi && toDoListInformationFromApi.total_tasks}
                    </Grid>
                  </Grid> 
                </Grid>
              </div>
              <hr/>
              {/* to show state list */}
              <Grid item xs={12}>
                <ul style={{listStyle: "none", marginLeft: "-8%"}}>
                  {toDoListDetailsFromApi && toDoListDetailsFromApi.map((item, index) =>
                    <li key={item.item_id}>
                      <Grid container style={{marginTop:"1%"}}>
                        <Grid container item xs={1} style={{marginTop:"2.5%"}}>
                        {
                          ( item.is_checked == true)?
                          (
                            <input
                              type="checkbox"
                              checked={item.is_checked}
                              disabled
                            />
                          ):
                          (
                            <input
                              type="checkbox"
                              checked={item.is_checked}
                              // onChange={e => toggleToDoListCheckBox(e.target.checked, item)}
                            />
                          )
                        }
                        
                        </Grid>
                        <Grid container item xs={10}>
                          <Grid item xs={11} style={{ fontSize: 15, color:'black', padding: 10 }}>
                            {/* {item.item} */}
                            {item.item}&nbsp;{ item.is_compulsory == true ? "*" : ""}
                          </Grid>
                        </Grid>

                        <Grid item xs={1}>
                            {
                              ( item.item_type == "user")?
                              (
                                <Grid item xs={1} style={{marginTop: "20%"}} className={classes.moreVertIcon}>
                                  {/* <DeleteIcon 
                                    onClick={() => handleDeleteToDoListItem(item)} 
                                    style={{color:'black', cursor: "pointer", }}/> */}
                                </Grid>
                              ):
                              (<div></div>)
                            }
                        </Grid>
                        <Grid style={{marginLeft:"10%", marginTop:"-2%"}}>
                          {
                            ( item.is_checked == true)?
                            (
                              <Grid style={{fontSize:10}}>(Checked By: {item.user_checked})</Grid>
                            ):
                            (<Grid></Grid>)
                          }
                        </Grid>
                      </Grid>  
                      {/* <hr/>  */}

                    </li>
                  )}
                </ul>
                {/* <Button
                  className={classes.addButtonStyle}
                  startIcon={<AddIcon />}
                  onClick={() => handleAddNewToDoModalOpen()}
                >
                  Add New ToDo
                </Button> */}
              </Grid>
            </div>
            </div>
        )
        :(
          // 17 may
          ( caseForDetailsFromAsync.case_curr_state == "CLOSED" || caseForDetailsFromAsync.case_curr_state == "COMPLETED")?
          (
            <div>
            <div className={classes.addNewBusinessHeaderBackgroud}> 
              <Grid container >
                <Grid container item xs={11} style={{justifyContent: 'center',}}>
                  <h2>Step ToDo List</h2>               
                </Grid>
                <Grid item xs={1} style={{marginTop: '4%',}}>
                  <Grid item xs={1}  className={classes.moreVertIcon}>
                    <CloseIcon onClick={handelShowStepTodoClose} style={{color:'black', cursor: "pointer", }}/>
                  </Grid>
                </Grid>
              </Grid>  
            </div>
            
            <div className={classes.modalBody} style={{position:'absolute'}}>
              {/* to show other info */}
              <div>
                <Grid container item xs={12} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>
                  {SelectedStepAllDetails && SelectedStepAllDetails.step_info.name}
                </Grid>
                <Grid container item xs={12} className={classes.casesetPriorityInfo} style={{marginTop:"1%"}}>
                  <Grid container style={{marginTop:"1%"}}>
                    <Grid container item xs={8}>
                      <Grid item xs={8} style={{fontSize: 15, fontWeight: 'bold'}}>
                        {/* State: {SelectedStepAllDetails && SelectedStepAllDetails.current_state.state_name} */}
                        State: {caseStatusIs}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item xs={12} >
                        {/* <FormControl className={classes.formControl}>
                          <Select
                            style={{marginTop:'-5 %'}}
                            displayEmpty
                            className={classes.selectEmpty}
                            id="caseStatusIs"
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={caseStatusIs}
                            // onChange={(e) => setcaseStatusIs(e.target.value ) }
                            onChange={(e) => changeStateOfSelectedStep(e.target.value) }
                          >
                            <MenuItem value={''}>Select State</MenuItem>
                            {StepStatesDetails.map(item =>
                              <MenuItem value={item.state_name}>{item.state_name}</MenuItem>
                            )}
                          </Select>
                        </FormControl> */}
                      </Grid>
                    </Grid> 
                  </Grid>
                </Grid>

                <Grid item xs={4} style={{marginTop:"1%"}}>
                  <AccessTimeIcon
                    fontSize="small"
                    style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                  ></AccessTimeIcon>
                  <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                  verticalAlign: "top", fontSize: "14px" , color:'#6e6d6b', marginLeft:'7%'}} >
                    {/* No due date */}
                    {SelectedStepAllDetails && SelectedStepAllDetails.due_date }
                  </div>
                </Grid>
                <Grid item xs={12} style={{marginTop:"1%"}}>
                  <GroupIcon
                    fontSize="small"
                    style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                  ></GroupIcon>
                    <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block",
                    verticalAlign: "top", fontSize: "14px" }} >
                        {/* Unassigned */}
                        <Grid item xs={12} style={{ marginTop:'1%' }}>
                          {SelectedStepAllDetails && SelectedStepAllDetails.assigned_users.map((au, aui) =>(
                            // <AccountCircleIcon style={{ fontSize:40 }}/>
                                  // console.log("a assigned_users",au),
                            <Grid item xs={12} style={{marginLeft:'5%', color:'#6e6d6b'}}>{aui + 1}.{au.name}</Grid>
                          )
                          )}
                        </Grid>
                    </div>
                </Grid>
                <Grid container style={{marginTop:"1%"}}>
                  <Grid container item xs={10}>
                    <Grid item xs={11} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>Tasks</Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid style={{ fontSize: 15, color:'black'}}>
                      {toDoListInformationFromApi && toDoListInformationFromApi.completed} / 
                      {toDoListInformationFromApi && toDoListInformationFromApi.total_tasks}
                    </Grid>
                  </Grid> 
                </Grid>
              </div>
              <hr/>
              {/* to show state list */}
              <Grid item xs={12}>
                <ul style={{listStyle: "none", marginLeft: "-8%"}}>
                  {toDoListDetailsFromApi && toDoListDetailsFromApi.map((item, index) =>
                    <li key={item.item_id}>
                      <Grid container style={{marginTop:"1%"}}>
                        <Grid container item xs={1} style={{marginTop:"2.5%"}}>
                        {
                          ( item.is_checked == true)?
                          (
                            <input
                              type="checkbox"
                              checked={item.is_checked}
                              disabled
                            />
                          ):
                          (
                            <input
                              type="checkbox"
                              checked={item.is_checked}
                              // onChange={e => toggleToDoListCheckBox(e.target.checked, item)}
                            />
                          )
                        }
                        
                        </Grid>
                        <Grid container item xs={10}>
                          <Grid item xs={11} style={{ fontSize: 15, color:'black', padding: 10 }}>
                            {/* {item.item} */}
                            {item.item}&nbsp;{ item.is_compulsory == true ? "*" : ""}
                          </Grid>
                        </Grid>

                        <Grid item xs={1}>
                            {
                              ( item.item_type == "user")?
                              (
                                <Grid item xs={1} style={{marginTop: "20%"}} className={classes.moreVertIcon}>
                                  {/* <DeleteIcon 
                                    onClick={() => handleDeleteToDoListItem(item)} 
                                    style={{color:'black', cursor: "pointer", }}/> */}
                                </Grid>
                              ):
                              (<div></div>)
                            }
                        </Grid>
                        <Grid style={{marginLeft:"10%", marginTop:"-2%"}}>
                          {
                            ( item.is_checked == true)?
                            (
                              <Grid style={{fontSize:10}}>(Checked By: {item.user_checked})</Grid>
                            ):
                            (<Grid></Grid>)
                          }
                        </Grid>
                      </Grid>  
                      {/* <hr/>  */}

                    </li>
                  )}
                </ul>
                {/* <Button
                  className={classes.addButtonStyle}
                  startIcon={<AddIcon />}
                  onClick={() => handleAddNewToDoModalOpen()}
                >
                  Add New ToDo
                </Button> */}
              </Grid>
            </div>
            </div>
          ):
          (
            ( SelectedStepAllDetails && SelectedStepAllDetails.is_completed == false )?
            (
              <div>
              <div className={classes.addNewBusinessHeaderBackgroud}> 
              <Grid container >
                <Grid container item xs={11} style={{justifyContent: 'center',}}>
                  <h2>Step ToDo List</h2>               
                </Grid>
                <Grid item xs={1} style={{marginTop: '4%',}}>
                  <Grid item xs={1}  className={classes.moreVertIcon}>
                    <CloseIcon onClick={handelShowStepTodoClose} style={{color:'black', cursor: "pointer", }}/>
                  </Grid>
                </Grid>
              </Grid>  
            </div>
            
            <div className={classes.modalBody} style={{position:'absolute'}}>
              {/* to show other info */}
              <div>
                <Grid container item xs={12} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>
                  {SelectedStepAllDetails && SelectedStepAllDetails.step_info.name}
                </Grid>
                <Grid container item xs={12} className={classes.casesetPriorityInfo} style={{marginTop:"1%"}}>
                  <Grid container style={{marginTop:"1%"}}>
                    <Grid container item xs={8}>
                      <Grid item xs={8} style={{fontSize: 15, fontWeight: 'bold'}}>
                        {/* State: {SelectedStepAllDetails && SelectedStepAllDetails.current_state.state_name} */}
                        State: {caseStatusIs}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid item xs={12} >
                        <FormControl className={classes.formControl}>
                          <Select
                            style={{marginTop:'-5 %'}}
                            displayEmpty
                            className={classes.selectEmpty}
                            id="caseStatusIs"
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={caseStatusIs}
                            // onChange={(e) => setcaseStatusIs(e.target.value ) }
                            onChange={(e) => changeStateOfSelectedStep(e.target.value) }
                          >
                            <MenuItem value={''}>Select State</MenuItem>
                            {StepStatesDetails.map(item =>
                              <MenuItem value={item.state_name}>{item.state_name}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid> 
                  </Grid>
                </Grid>
  
                <Grid item xs={4} style={{marginTop:"1%"}}>
                  <AccessTimeIcon
                    fontSize="small"
                    style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                  ></AccessTimeIcon>
                  <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                  verticalAlign: "top", fontSize: "14px" , color:'#6e6d6b', marginLeft:'7%'}} >
                    {/* No due date */}
                    {SelectedStepAllDetails && SelectedStepAllDetails.due_date }
                  </div>
                </Grid>
                <Grid item xs={12} style={{marginTop:"1%"}}>
                  <GroupIcon
                    fontSize="small"
                    style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                  ></GroupIcon>
                    <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block",
                    verticalAlign: "top", fontSize: "14px" }} >
                        {/* Unassigned */}
                        <Grid item xs={12} style={{ marginTop:'1%' }}>
                          {SelectedStepAllDetails && SelectedStepAllDetails.assigned_users.map((au, aui) =>(
                            // <AccountCircleIcon style={{ fontSize:40 }}/>
                                  // console.log("a assigned_users",au),
                            <Grid item xs={12} style={{marginLeft:'5%', color:'#6e6d6b'}}>{aui + 1}.{au.name}</Grid>
                          )
                          )}
                        </Grid>
                    </div>
                </Grid>
                <Grid container style={{marginTop:"1%"}}>
                  <Grid container item xs={2}>
                    <Grid item xs={10} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>Tasks: </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid style={{ fontSize: 15, color:'black'}}>
                      {toDoListInformationFromApi && toDoListInformationFromApi.completed} / 
                      &nbsp;{toDoListInformationFromApi && toDoListInformationFromApi.total_tasks}
                    </Grid>
                  </Grid> 
                  <Grid container item xs={8} justify="flex-end">
                    {/* <Grid style={{ fontSize: 15, fontWeight:'bold', color:'blue', alignSelf:'end' }}>Activity </Grid> */}
                    {/* <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.CollapseLableHeader}>Activity</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid>
                          to set activity here
                        </Grid>
                      </AccordionDetails>
                    </Accordion> */}
                  </Grid>
                </Grid>

                <Grid style={{padding:5}}>
                  <Accordion >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.CollapseLableHeader}>Activity</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid>
                        to set activity here for states
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>

              </div>
              <hr/>
              {/* to show state list */}
              <Grid item xs={12}>
                <ul style={{listStyle: "none", marginLeft: "-8%"}}>
                  {toDoListDetailsFromApi && toDoListDetailsFromApi.map((item, index) =>
                    
                    <li key={item.item_id}>
                      <Grid container style={{marginTop:"1%"}}>
                      {console.log("toDoListDetailsFromApi todolist item",item)}
                        <Grid container item xs={1} >
                        {
                          ( item.is_checked == true)?
                          (
                            <input
                              type="checkbox"
                              checked={item.is_checked}
                              disabled
                            />
                          ):
                          (
                            <input
                              type="checkbox"
                              checked={item.is_checked}
                              onChange={e => toggleToDoListCheckBox(e.target.checked, item)}
                            />
                          )
                        }
                        
                        </Grid>
                        <Grid container item xs={10}>
                          <Grid item xs={11} style={{ fontSize: 15, color:'black', padding: 10 }}>
                            {item.item}&nbsp;{ item.is_compulsory == true ? "*" : ""} 
                            {/* {<Grid>{item.user_checked}</Grid>} */}
                        </Grid>
                        </Grid>
  
                        <Grid item xs={1}>
                            {
                              ( item.item_type == "user")?
                              (
                                <Grid item xs={1} style={{marginTop: "20%"}} className={classes.moreVertIcon}>
                                  <DeleteIcon onClick={() => handleDeleteToDoListItem(item)} style={{color:'black', cursor: "pointer", }}/>
                                </Grid>
                              ):
                              (<div></div>)
                            }
                        </Grid>
                      </Grid>  
                      <Grid style={{marginLeft:"10%", marginTop:"-2%"}}>
                        {
                          ( item.is_checked == true)?
                          (
                            <Grid style={{fontSize:10}}>(Checked By: {item.user_checked})</Grid>
                          ):
                          (<Grid></Grid>)
                        }
                      </Grid>
                      {/* <hr/>  */}
  
                    </li>
                  )}
                </ul>
                <Button
                  className={classes.addButtonStyle}
                  startIcon={<AddIcon />}
                  onClick={() => handleAddNewToDoModalOpen()}
                >
                  Add New ToDo
                </Button>
              </Grid>
  
            </div>
            </div>
            ):
            (
              <div>
              <div className={classes.addNewBusinessHeaderBackgroud}> 
                <Grid container >
                  <Grid container item xs={11} style={{justifyContent: 'center',}}>
                    <h2>Step ToDo List</h2>               
                  </Grid>
                  <Grid item xs={1} style={{marginTop: '4%',}}>
                    <Grid item xs={1}  className={classes.moreVertIcon}>
                      <CloseIcon onClick={handelShowStepTodoClose} style={{color:'black', cursor: "pointer", }}/>
                    </Grid>
                  </Grid>
                </Grid>  
              </div>
              
              <div className={classes.modalBody} style={{position:'absolute'}}>
                {/* to show other info */}
                <div>
                  <Grid container item xs={12} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>
                    {SelectedStepAllDetails && SelectedStepAllDetails.step_info.name}
                  </Grid>
                  <Grid container item xs={12} className={classes.casesetPriorityInfo} style={{marginTop:"1%"}}>
                    <Grid container style={{marginTop:"1%"}}>
                      <Grid container item xs={8}>
                        <Grid item xs={8} style={{fontSize: 15, fontWeight: 'bold'}}>
                          {/* State: {SelectedStepAllDetails && SelectedStepAllDetails.current_state.state_name} */}
                          State: {caseStatusIs}
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid item xs={12} >
                          {/* <FormControl className={classes.formControl}>
                            <Select
                              style={{marginTop:'-5 %'}}
                              displayEmpty
                              className={classes.selectEmpty}
                              id="caseStatusIs"
                              inputProps={{ 'aria-label': 'Without label' }}
                              value={caseStatusIs}
                              // onChange={(e) => setcaseStatusIs(e.target.value ) }
                              onChange={(e) => changeStateOfSelectedStep(e.target.value) }
                            >
                              <MenuItem value={''}>Select State</MenuItem>
                              {StepStatesDetails.map(item =>
                                <MenuItem value={item.state_name}>{item.state_name}</MenuItem>
                              )}
                            </Select>
                          </FormControl> */}
                        </Grid>
                      </Grid> 
                    </Grid>
                  </Grid>
  
                  <Grid item xs={4} style={{marginTop:"1%"}}>
                    <AccessTimeIcon
                      fontSize="small"
                      style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                    ></AccessTimeIcon>
                    <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                    verticalAlign: "top", fontSize: "14px" , color:'#6e6d6b', marginLeft:'7%'}} >
                      {/* No due date */}
                      {SelectedStepAllDetails && SelectedStepAllDetails.due_date }
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{marginTop:"1%"}}>
                    <GroupIcon
                      fontSize="small"
                      style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                    ></GroupIcon>
                      <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block",
                      verticalAlign: "top", fontSize: "14px" }} >
                          {/* Unassigned */}
                          <Grid item xs={12} style={{ marginTop:'1%' }}>
                            {SelectedStepAllDetails && SelectedStepAllDetails.assigned_users.map((au, aui) =>(
                              // <AccountCircleIcon style={{ fontSize:40 }}/>
                                    // console.log("a assigned_users",au),
                              <Grid item xs={12} style={{marginLeft:'5%', color:'#6e6d6b'}}>{aui + 1}.{au.name}</Grid>
                            )
                            )}
                          </Grid>
                      </div>
                  </Grid>
                  <Grid container style={{marginTop:"1%"}}>
                    <Grid container item xs={10}>
                      <Grid item xs={11} style={{ fontSize: 15, fontWeight:'bold', color:'black'}}>Tasks</Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid style={{ fontSize: 15, color:'black'}}>
                        {toDoListInformationFromApi && toDoListInformationFromApi.completed} / 
                        {toDoListInformationFromApi && toDoListInformationFromApi.total_tasks}
                      </Grid>
                    </Grid> 
                  </Grid>
                </div>
                <hr/>
                {/* to show state list */}
                <Grid item xs={12}>
                  <ul style={{listStyle: "none", marginLeft: "-8%"}}>
                    {toDoListDetailsFromApi && toDoListDetailsFromApi.map((item, index) =>
                      <li key={item.item_id}>
                        <Grid container style={{marginTop:"1%"}}>
                          <Grid container item xs={1} style={{marginTop:"2.5%"}}>
                          {
                            ( item.is_checked == true)?
                            (
                              <input
                                type="checkbox"
                                checked={item.is_checked}
                                disabled
                              />
                            ):
                            (
                              <input
                                type="checkbox"
                                checked={item.is_checked}
                                // onChange={e => toggleToDoListCheckBox(e.target.checked, item)}
                              />
                            )
                          }
                          
                          </Grid>
                          <Grid container item xs={10}>
                            <Grid item xs={11} style={{ fontSize: 15, color:'black', padding: 10 }}>
                              {/* {item.item} */}
                              {item.item}&nbsp;{ item.is_compulsory == true ? "*" : ""} 
                            </Grid>
                          </Grid>
  
                          <Grid item xs={1}>
                              {
                                ( item.item_type == "user")?
                                (
                                  <Grid item xs={1} style={{marginTop: "20%"}} className={classes.moreVertIcon}>
                                    {/* <DeleteIcon 
                                      onClick={() => handleDeleteToDoListItem(item)} 
                                      style={{color:'black', cursor: "pointer", }}/> */}
                                  </Grid>
                                ):
                                (<div></div>)
                              }
                          </Grid>
                          <Grid style={{marginLeft:"10%", marginTop:"-2%"}}>
                            {
                              ( item.is_checked == true)?
                              (
                                <Grid style={{fontSize:10}}>(Checked By: {item.user_checked})</Grid>
                              ):
                              (<Grid></Grid>)
                            }
                          </Grid>
                        </Grid>  
                        {/* <hr/>  */}
  
                      </li>
                    )}
                  </ul>
                  {/* <Button
                    className={classes.addButtonStyle}
                    startIcon={<AddIcon />}
                    onClick={() => handleAddNewToDoModalOpen()}
                  >
                    Add New ToDo
                  </Button> */}
                </Grid>
              </div>
              </div>
            )
          ) 
  
        )
      }
      
    </div>
  )


  // Add new Todo all functions
  const handleAddNewToDoModalOpen = () =>{
    setOpenAddTodo(true);
    setopen(false);
    dispatch(userActions.checkSessionValidity());
  }

  const handleAddNewToDoModalClose = () =>{
    setOpenAddTodo(false);
    setopen(true);
    setitemErr("");
    dispatch(userActions.checkSessionValidity());
    setcreateNewToDoDetails({
      // todolist_id: "",
      ...createNewToDoDetails,
      item: "",
      is_active: true,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    });
  }

  const validate = () => {
    let itemErr = '';

    if(!createNewToDoDetails.item)  {
      itemErr = 'Please Enter ToDo Name';
    }

    if(itemErr){
        setitemErr(itemErr);
        return false;
      }
      return true;
  }

  const handelSubmitCreateNewToDo = () => {
    console.log("createNewToDoDetails",createNewToDoDetails);
    dispatch(userActions.checkSessionValidity());
    const isValid = validate();
    if(isValid){
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(createNewToDoDetails)
      };
      fetch( process.env.REACT_APP_CREATE_USER_TODO , requestOptions)
        .then((Response) => Response.json())
        .then( async(toDoItemAddedDetails) => {
          setShowProcedure(false);
          console.log("toDoItemAddedDetails new",toDoItemAddedDetails);
          if(toDoItemAddedDetails.success == true)
          {
            console.log("toDoItemAddedDetails",toDoItemAddedDetails.data.user_todo_item);
            handleAddNewToDoModalClose();
            refreshToDoList();
            setcreateNewToDoDetails({
              // todolist_id: "",
              ...createNewToDoDetails,
              item: "",
              is_active: true,
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')),
            });
          }
          else if(toDoItemAddedDetails.success == false){
            Swal.fire({
              icon: 'error',
              text: toDoItemAddedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 9000,
            })
          }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const refreshToDoList = () => {
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let step_id = SelectedStepId;

    let GetToDoListDetails = ({
      case_id: caseForDetailsFromAsync.case_id,
      step_id: step_id,
      // step_id: "6",
      device_token: device_token,
      session_id: session_id,
    })
    console.log("GetToDoListDetails",GetToDoListDetails)

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(GetToDoListDetails)
    };
    // REACT_APP_GET_CASE_STEP_TODO_LIST
    fetch( process.env.REACT_APP_GET_CASE_STEP_TODO_LIST , requestOptions)
      .then((Response) => Response.json())
      .then( async(toDoListDetailsRefreshed) => {
        // setShowProcedure(false);
        console.log("toDoListDetailsRefreshed",toDoListDetailsRefreshed);
        if(toDoListDetailsRefreshed.success == true)
        {
          console.log("toDoListDetailsRefreshed",toDoListDetailsRefreshed.data.case_step_todolist);
          // settoDoListDetailsFromApi(toDoListDetailsRefreshed.data.step_todo_list);
          settoDoListDetailsFromApi(toDoListDetailsRefreshed.data.case_step_todolist);
          settoDoListInformationFromApi(toDoListDetailsRefreshed.data.case_step_todolist_info);
        }
        else if(toDoListDetailsRefreshed.success == false){
          settoDoListDetailsFromApi([])
          settoDoListInformationFromApi([])
          Swal.fire({
            icon: 'error',
            text: toDoListDetailsRefreshed.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const bodyAddTodo = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        {/* <h2>Add New ToDo</h2> */}
        <Grid container >
          <Grid container item xs={11} style={{justifyContent: 'center',}}>
            <h2>Add New ToDo</h2>               
          </Grid>
          <Grid item xs={1} style={{marginTop: '4%',}}>
            <Grid item xs={1}  className={classes.moreVertIcon}>
              <CloseIcon onClick={handleAddNewToDoModalClose} style={{color:'black', cursor: "pointer", }}/>
            </Grid>
          </Grid>
        </Grid> 
      </div>
      <div className={classes.modalBody}>
        <TextField
          margin="normal"
          required
          fullWidth
          autoFocus
          id="item"
          label="ToDo Name"
          name="item"
          // autoComplete="item"
          value={createNewToDoDetails.item}
          onChange={(e) => setcreateNewToDoDetails({...createNewToDoDetails, item:e.target.value }) }
        />
        <div className={classes.validation}>{(createNewToDoDetails.item)?(<div></div>):(itemErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitCreateNewToDo}
          >
             Add
          </Button>
          <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleAddNewToDoModalClose}
            >
              Cancel
            </Button>
          </div>
      </div>
    </div>
  )

  // add new user functions
  const handleAddNewUserOpen = async(i) => {
    setopenAddUser(true);
    dispatch(userActions.checkSessionValidity());
    console.log("step to add users",i);
    setStepSelectedToAddUsers(i);
    console.log("assigned_users",i.assigned_users);
    console.log("allUsersList",allUsersList);
    await setallUsersList(
            allUsersList.map((f) => {
              console.log("f is",f)
              console.log("f  i.assigned_users.user_id ", i.assigned_users.user_id )
              i.assigned_users.map((au) => {
                if (f.user_id === au.user_id ) 
                  {
                    console.log("f.user_id === i.assigned_users.user_id is matched",f.user_id)
                    f.selected = true;
                  }
              })
              return f;
            })
          );
  }

  const handleAddNewUserClose = () => {
    setopenAddUser(false);
    setflag(false)
    dispatch(userActions.checkSessionValidity());
  }

  const handelSubmitAddNewUser = () => {
    setShowProcedure(true);
    setopenAddUser(false);
    console.log("allUsersList, StepSelectedToAddUsers",allUsersList, StepSelectedToAddUsers);
    var selected_users = []
    allUsersList.map((f)=>{
      if(f.selected){
        if(f.selected == true){
          console.log("selected users",f.user_id)
          selected_users = selected_users.concat(f.user_id);
          console.log("selected_users",selected_users);
        }
      }
    })
    console.log("selected_users baher",selected_users);

    // calling api to update users
    let dueDateSetObj = ({
      case_id: caseForDetailsFromAsync.case_id,
      step_id: StepSelectedToAddUsers.step_info.step_id,
      due_date: StepSelectedToAddUsers.due_date,
      assigned_users: selected_users,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    console.log("dueDateSetObj",dueDateSetObj)
    const requestOptionsDueDate = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(dueDateSetObj),
    };
    fetch( process.env.REACT_APP_UPDATE_CASE_STEP_DETAILS , requestOptionsDueDate)
      .then((Response) => Response.json())
      .then( async(caseDueDateDetails) => {
        setShowProcedure(false);
        console.log("caseDueDateDetails",caseDueDateDetails);
        if(caseDueDateDetails.success == true)
        {
          // Swal.fire({
          //   icon: 'success',
          //   text: 'Data Updated Succesfully!',
          //   confirmButtonColor: 'primary',
          //   confirmButtonText: 'OK',
          //   timer: 9000,
          // })
          setflag(false);
        }
        else if(caseDueDateDetails.success == false){
          Swal.fire({
            icon: 'error',
            text: caseDueDateDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  const UserSelectedFunction = async(item) => {
    console.log("in UserSelectedFunction",item)

    await setallUsersList(
      allUsersList.map((f)=> {
        if(f.user_id === item.user_id){
          f.selected = !f.selected;
        }
        return f ;
      })
    )
  }

  const bodyAddNewUser = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        {/* <h2>Add User</h2> */}
        <Grid container >
          <Grid container item xs={11} style={{justifyContent: 'center',}}>
            <h2>Assign Step </h2>               
          </Grid>
          <Grid item xs={1} style={{marginTop: '4%',}}>
            <Grid item xs={1}  className={classes.moreVertIcon}>
              <CloseIcon onClick={handleAddNewUserClose} style={{color:'black', cursor: "pointer", }}/>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody} style={{position:'absolute'}}>
      <Grid item xs={12}>
          <ul style={{listStyle: "none", marginLeft: "-8%", cursor: "pointer"}}>
            {allUsersList && allUsersList.map((item, index) =>
              
              <li key={item.tag_id} onClick={() => UserSelectedFunction(item)}>
                <Grid container style={{marginTop:"2%"}}>
                {/* { console.log("all user 1 item is",item)} */}
                  <Grid container item xs={12} style={{ backgroundColor: '#DCD4D3' }}>
                    <Grid item xs={2} style={{ color:'black', padding: 7 }}>
                      <AccountCircleIcon style={{ fontSize:40 }}/>
                    </Grid>
                    <Grid item xs={9} style={{ color:'black', padding: 12, marginTop:'2%' }}>
                      {item.name}
                    </Grid>
                    <Grid item xs={1} style={{ padding: 9, marginLeft:"-2%", marginTop:'2%' }}>
                      {
                        ( item.selected == true)?
                        (
                          <Grid item xs={1} className={classes.moreVertIcon}>
                            <CheckIcon style={{color:'black'}}/>
                          </Grid>
                        ):
                        (<div></div>)
                      }
                    </Grid>
                    
                  </Grid>
                </Grid>             
              </li>
            )}
          </ul>
        </Grid>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitAddNewUser}
          >
             Assign
          </Button>
          <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handleAddNewUserClose}
            >
              Cancel
            </Button>
          </div>
      </div>
    </div>
  )

  // due date set func
  const setNewDueDate = async(date,item) => {
    setShowProcedure(true);
    dispatch(userActions.checkSessionValidity());
    console.log("item is, date",item,  moment(date).format("DD/MM/YYYY"))
    console.log("assigned_users setNewDueDate",item.assigned_users)
    var user_ids = []
    item.assigned_users.map((f)=> {
      let user_id = f.user_id
      user_ids = user_ids.concat(user_id)
    })
    console.log("user_ids to pass api",user_ids)
    await setCaseStepInformation(
      CaseStepInformation.map((f) => {
        if (f.step_info.step_id === item.step_info.step_id) {
            f.step_info.due_date = date
        }
        return f;
      })
    )
    // calling api to update date
    let dueDateSetObj = ({
      case_id: caseForDetailsFromAsync.case_id,
      step_id: item.step_info.step_id,
      due_date: moment(date).format("DD/MM/YYYY"),
      assigned_users: user_ids,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    console.log("dueDateSetObj",dueDateSetObj)
    const requestOptionsDueDate = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(dueDateSetObj),
    };
    fetch( process.env.REACT_APP_UPDATE_CASE_STEP_DETAILS , requestOptionsDueDate)
      .then((Response) => Response.json())
      .then( async(caseDueDateDetails) => {
        setShowProcedure(false);
        console.log("caseDueDateDetails",caseDueDateDetails);
        if(caseDueDateDetails.success == true)
        {
          // Swal.fire({
          //   icon: 'success',
          //   text: 'Due Date Updated Succesfully!',
          //   confirmButtonColor: 'primary',
          //   confirmButtonText: 'OK',
          //   timer: 9000,
          // }).then(function (result) {
          //   console.log("result of swal",result)
          //   setflag(false);
          // })
          setflag(false);
        }
        else if(caseDueDateDetails.success == false){
          Swal.fire({
            icon: 'error',
            text: caseDueDateDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }       
    })
    .catch(err => {
      setShowProcedure(false);
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  console.log("role_of_user, caseForDetailsFromAsync.case_curr_state",role_of_user,caseForDetailsFromAsync.case_curr_state)

  if( RedirectTtoCaseDetailsPage == true ){
    return <Redirect to={`/TaxManagementCaseDetails`} />
  }
  // if(role_of_user == "client" || role_of_user== "trp"){
    if(role_of_user == "client" ){
    return (
      console.log("caseStepDetailsInOrder, CaseStepInformation",caseStepDetailsInOrder,CaseStepInformation),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
        <Grid container>
          <Grid item xs={12} className={classes.stepContainer}>
            <Steps direction="vertical" current={CurrentIndexForSteps && CurrentIndexForSteps} status="error" 
              size="small" >
              { 
                  // caseDetailsSteps.map((i, index) =>(
                    CaseStepInformation && CaseStepInformation.map((i, index) =>(
                      // console.log("item is",item)
                      <Step
                        title={
                          <div style={{ color: "#4D47DD" }}>
                            {/* {i.step_info.process} */}
                            {i.current_state.state_name}
                          </div>
                        }
                        subTitle={
                          <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000", cursor: "pointer"}}
                            id="stepIdDiv"
                            key={i.step_id}
                            value={i.step_id}
                            onClick={()=>StepClickedFunction(i.step_info.step_id, i)}
                          >
                            {i.step_info.name}
                          </div>
                        }
                        description={
                          <div className={classes.rootDescription}>
                          <Grid container>
                            <Grid item xs={4}>
                              <CheckBoxIcon
                                fontSize="small"
                                style={{ marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                              ></CheckBoxIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", 
                                  display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >
                                    {
                                      ( i.task_details.case_step_todolist_info )?
                                      ( <div>{i.task_details.case_step_todolist_info.completed} / {i.task_details.case_step_todolist_info.total_tasks} </div>)
                                      :(<div></div>)
                                    }
                                    {/* {i.task_details.case_step_todolist_info.completed} / {i.task_details.case_step_todolist_info.total_tasks} */}
                                    {/* 0/12 */}
                              </div>
                            </Grid>
                            {/* <Grid item xs={4}>
                              <AccessTimeIcon
                                fontSize="small"
                                style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                              ></AccessTimeIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                verticalAlign: "top", fontSize: "14px", marginLeft: '15%', marginTop: '-14%', cursor:'pointer', }} >
                                    <DatePicker 
                                      className={classes.calenderNewStyle}
                                      // selected={dueDate} 
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText='Due Date'
                                      // selected={i.step_info.due_date}
                                      // selected={i.due_date
                                      //   ? moment(i.due_date, 'dd/MM/yyyy')
                                      //   : null}
                                      // selected = {moment(isoDateStr).toDate(i.due_date)}
                                      // selected={Date(i.due_date)}
                                      selected={ Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) }
                                      // initiated_Date ? new Date(initiated_Date) : null
                                      // onChange={(date) => setNewDueDate(date,i)} 
                                      minDate={minDate}
                                      readOnly
                                    />                              
                              </div>
                            </Grid> */}
                            <Grid item xs={4}>
                              <Grid container>
                                <Grid>
                                  <AccessTimeIcon
                                    fontSize="small"
                                    style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                                  ></AccessTimeIcon>
                                </Grid>
                                <Grid>
                                  {/* <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                verticalAlign: "top", fontSize: "14px", marginLeft: '15%', marginTop: '-14%', cursor:'pointer', }} > */}
                                <DatePicker 
                                    className={classes.calenderNewStyle}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='Due Date'
                                    selected={ Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) }
                                    minDate={minDate}
                                    readOnly
                                    disabled
                                  />
                              {/* </div> */}
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Grid item xs={4}>
                              <GroupIcon
                                fontSize="small"
                                style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                              ></GroupIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                verticalAlign: "top", fontSize: "14px", cursor:'pointer', color:'blue' }} 
                                // onClick= { () => handleAddNewUserOpen(i) } 
                              >
                                Users
                              </div>
                            </Grid> */}
                            <Grid container >
                              {/* <Grid style={{marginLeft:'3%',color:'white'}}>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</Grid> */}
                              <Grid style={{marginLeft:'3%',color:'white'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop:'1%' }}>
                              <Grid style={{marginLeft:'2%'}}>Assigned To : </Grid>
                              {i.assigned_users.map((au, aui) =>(
                                // <AccountCircleIcon style={{ fontSize:40 }}/>
                                // console.log("a assigned_users",au),
                                // <Grid style={{marginLeft:'2%'}}>{aui + 1}.{au.name}</Grid>
                                <Grid >
                                  <Grid >{au.name}{aui === i.assigned_users.length - 1 ? "" : ","}</Grid>
                                </Grid>
                              )
                              )}
                            </Grid>
                          </Grid>
                        </div>
                        }
                        icon={
                          <AdjustIcon fontSize="small" style={{ color: "#4D47DD" }} />
                        }
                      />
                    ))
                  // }
              }
            </Steps>
          </Grid>
          <Modal
            open={open}
            onClose={handelShowStepTodoClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
  
          {/*  */}
          <Modal
            open={OpenAddTodo}
            onClose={handleAddNewToDoModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddTodo}
          </Modal>
  
          {/* to add new user */}
          <Modal
            open={openAddUser}
            onClose={handleAddNewUserClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddNewUser}
          </Modal>
        </Grid>
      </div>
    );
  }

  // else if(role_of_user == "trpadmin" || role_of_user== "trpdoc"){
  else {
    if(caseForDetailsFromAsync.case_curr_state == "CREATED" ){
    console.log("case status is CREATED in overview")
    return (
      console.log("caseStepDetailsInOrder",caseStepDetailsInOrder),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
        <Grid container>
          <Grid item xs={12} className={classes.stepContainer}>
          <Steps direction="vertical" current={CurrentIndexForSteps && CurrentIndexForSteps} status="error" size="small">
              { 
                    caseStepDetailsInOrder && caseStepDetailsInOrder.map((i, index) =>(
                      <Step
                        title={
                          <div style={{ color: "#4D47DD" }}>
                            {i.name}
                          </div>
                        }
                        subTitle={
                          <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000"}}
                            id="stepIdDiv"
                            key={i.description}
                            value={i.description}
                            // onClick={()=>StepClickedFunction(i.step_info.step_id, i)}
                          >
                            {i.description}
                          </div>
                        }
                        description={
                          <div className={classes.rootDescription}>
                          <Grid container>
                            <Grid item xs={4}>
                              <CheckBoxIcon
                                fontSize="small"
                                style={{ marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                              ></CheckBoxIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", 
                                  display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >
                                    0/12
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <AccessTimeIcon
                                fontSize="small"
                                style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                              ></AccessTimeIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                verticalAlign: "top", fontSize: "14px", cursor:'pointer', }} >
                                  Due Date
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        }
                        icon={
                          <AdjustIcon fontSize="small" style={{ color: "#4D47DD" }} />
                        }
                      />
                    ))
              }
            </Steps>
          </Grid>
          <Modal
            open={open}
            onClose={handelShowStepTodoClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
  
          {/*  */}
          <Modal
            open={OpenAddTodo}
            onClose={handleAddNewToDoModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddTodo}
          </Modal>
  
          {/* to add new user */}
          <Modal
            open={openAddUser}
            onClose={handleAddNewUserClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddNewUser}
          </Modal>
  
        </Grid>
      </div>
    );
  }

  if(caseForDetailsFromAsync.case_curr_state == "CLOSED" || caseForDetailsFromAsync.case_curr_state == "COMPLETED"){
    console.log("case status is CREATED in overview")
    return (
      console.log("caseStepDetailsInOrder",caseStepDetailsInOrder),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
        <Grid container>
          <Grid item xs={12} className={classes.stepContainer}>
          <Steps direction="vertical" current={CurrentIndexForSteps && CurrentIndexForSteps} status="error" 
              size="small" >
              { 
                  // caseDetailsSteps.map((i, index) =>(
                    CaseStepInformation && CaseStepInformation.map((i, index) =>(
                      // console.log("item is",item)
                      <Step
                        title={
                          <div style={{ color: "#4D47DD" }}>
                            {/* {i.step_info.process} */}
                            {i.current_state.state_name}
                          </div>
                        }
                        subTitle={
                          <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000", cursor: "pointer"}}
                            id="stepIdDiv"
                            key={i.step_id}
                            value={i.step_id}
                            onClick={()=>StepClickedFunction(i.step_info.step_id, i)}
                          >
                            {i.step_info.name}
                          </div>
                        }
                        description={
                          <div className={classes.rootDescription}>
                          <Grid container>
                            <Grid item xs={4}>
                              <CheckBoxIcon
                                fontSize="small"
                                style={{ marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                              ></CheckBoxIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", 
                                  display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >
                                    {
                                      ( i.task_details.case_step_todolist_info )?
                                      ( <div>{i.task_details.case_step_todolist_info.completed} / {i.task_details.case_step_todolist_info.total_tasks} </div>)
                                      :(<div></div>)
                                    }
                                    {/* {i.task_details.case_step_todolist_info.completed} / {i.task_details.case_step_todolist_info.total_tasks} */}
                                    {/* 0/12 */}
                              </div>
                            </Grid>
                            {/* <Grid item xs={4}>
                              <AccessTimeIcon
                                fontSize="small"
                                style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                              ></AccessTimeIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                verticalAlign: "top", fontSize: "14px", marginLeft: '15%', marginTop: '-14%', cursor:'pointer', }} >
                                    <DatePicker 
                                      className={classes.calenderNewStyle}
                                      // selected={dueDate} 
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText='Due Date'
                                      // selected={i.step_info.due_date}
                                      // selected={i.due_date
                                      //   ? moment(i.due_date, 'dd/MM/yyyy')
                                      //   : null}
                                      // selected = {moment(isoDateStr).toDate(i.due_date)}
                                      // selected={Date(i.due_date)}
                                      selected={ Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) }
                                      // initiated_Date ? new Date(initiated_Date) : null
                                      // onChange={(date) => setNewDueDate(date,i)} 
                                      minDate={minDate}
                                      readOnly
                                    />                              
                              </div>
                            </Grid> */}
                            <Grid item xs={4}>
                              <Grid container>
                                <Grid>
                                  <AccessTimeIcon
                                    fontSize="small"
                                    style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                                  ></AccessTimeIcon>
                                </Grid>
                                <Grid>
                                  {/* <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                verticalAlign: "top", fontSize: "14px", marginLeft: '15%', marginTop: '-14%', cursor:'pointer', }} > */}
                                <DatePicker 
                                    className={classes.calenderNewStyle}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='Due Date'
                                    selected={ Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) }
                                    minDate={minDate}
                                    readOnly
                                    disabled
                                  />
                              {/* </div> */}
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* <Grid item xs={4}>
                              <GroupIcon
                                fontSize="small"
                                style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                              ></GroupIcon>
                              <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                verticalAlign: "top", fontSize: "14px", cursor:'pointer', color:'blue' }} 
                                // onClick= { () => handleAddNewUserOpen(i) } 
                              >
                                Users
                              </div>
                            </Grid> */}
                            <Grid container >
                              {/* <Grid style={{marginLeft:'3%',color:'white'}}>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</Grid> */}
                              <Grid style={{marginLeft:'3%',color:'white'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{ marginTop:'1%' }}>
                              <Grid style={{marginLeft:'2%'}}>Assigned To : </Grid>
                              {i.assigned_users.map((au, aui) =>(
                                // <AccountCircleIcon style={{ fontSize:40 }}/>
                                // console.log("a assigned_users",au),
                                // <Grid style={{marginLeft:'2%'}}>{aui + 1}.{au.name}</Grid>
                                <Grid >
                                  <Grid >{au.name}{aui === i.assigned_users.length - 1 ? "" : ","}</Grid>
                                </Grid>
                              )
                              )}
                            </Grid>
                          </Grid>
                        </div>
                        }
                        icon={
                          <AdjustIcon fontSize="small" style={{ color: "#4D47DD" }} />
                        }
                      />
                    ))
                  // }
              }
            </Steps>
          </Grid>
          <Modal
            open={open}
            onClose={handelShowStepTodoClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
  
          {/*  */}
          <Modal
            open={OpenAddTodo}
            onClose={handleAddNewToDoModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddTodo}
          </Modal>
  
          {/* to add new user */}
          <Modal
            open={openAddUser}
            onClose={handleAddNewUserClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddNewUser}
          </Modal>
  
        </Grid>
      </div>
    );
  }

  // else if(caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" || caseForDetailsFromAsync.case_curr_state == "PENDING"
  // || caseForDetailsFromAsync.case_curr_state == "PAYMENT-PENDING" || caseForDetailsFromAsync.case_curr_state == "COMPLETED"){
    else if(caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" || caseForDetailsFromAsync.case_curr_state == "PENDING"
  || caseForDetailsFromAsync.case_curr_state == "PAYMENT-PENDING" ){
    console.log("case status is CONFIRMED in overview,CaseStepInformation",
      CaseStepInformation)
    console.log("SelectedStepAllDetails in return ===",SelectedStepAllDetails,caseForDetailsFromAsync.case_curr_state)
    return (
      // console.log("allUsersList in return",allUsersList),
      console.log("caseForDetailsFromAsync on case overview page,caseDetailsSteps",caseForDetailsFromAsync,caseDetailsSteps),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
        </modal>: null}
        <Grid container>
          <Grid item xs={12} className={classes.stepContainer}>
            <Steps direction="vertical" current={ CurrentIndexForSteps && CurrentIndexForSteps } 
              size="small" 
              status="error"
              // type="navigation"
              // initial={} 
              // icons="finish"
            >
              { 
                // caseDetailsSteps.map((i, index) =>(
                  CaseStepInformation && CaseStepInformation.map((i, index) =>(
                  // console.log("item is",i.task_details.case_step_todolist_info),
                  <Step
                    title={
                      <div style={{ color: "#4D47DD" }}>
                        {/* {i.step_info.process} */}
                        {i.current_state.state_name}
                      </div>
                    }
                    subTitle={
                      <div style={{ marginLeft: '-8px', marginTop: '5px', color: "#000000", cursor: "pointer"}}
                        id="stepIdDiv"
                        key={i.step_id}
                        value={i.step_id}
                        onClick={()=>StepClickedFunction(i.step_info.step_id, i)}
                      >
                        {i.step_info.name}
                      </div>
                    }
                    description={
                      <div className={classes.rootDescription}>
                      <Grid container>
                        <Grid item xs={4}>
                          <CheckBoxIcon
                            fontSize="small"
                            style={{ marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                          ></CheckBoxIcon>
                          <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", 
                              display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >
                                {
                                  ( i.task_details.case_step_todolist_info )?
                                  ( <div>{i.task_details.case_step_todolist_info.completed} / {i.task_details.case_step_todolist_info.total_tasks} </div>)
                                  :(<div></div>)
                                }

                                {/* {i.task_details.case_step_todolist_info.completed} / {i.task_details.case_step_todolist_info.total_tasks} */}

                                {/* 0/12 */}
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container>
                            <Grid>
                              <AccessTimeIcon
                                fontSize="small"
                                style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
                              ></AccessTimeIcon>
                            </Grid>
                            <Grid>
                               {/* <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                            verticalAlign: "top", fontSize: "14px", marginLeft: '15%', marginTop: '-14%', cursor:'pointer', }} > */}
                            {
                              ( i.current_state.state_name == "COMPLETED")?
                              (
                                <DatePicker 
                                  className={classes.calenderNewStyle}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText='Due Date'
                                  selected={ Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) }
                                  onChange={(date) => setNewDueDate(date,i)} 
                                  minDate={minDate}
                                  readOnly
                                  disabled
                                />
                              )
                              :(
                                <DatePicker 
                                  className={classes.calenderNewStyle}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText='Due Date'
                                  selected={ Date.parse(moment(i.due_date, 'DD/MM/YYYY').toISOString()) }
                                  onChange={(date) => setNewDueDate(date,i)} 
                                  minDate={minDate}
                                />
                              )
                            }
                            
                          {/* </div> */}
                            </Grid>
                          </Grid>
                        </Grid>
                        
                        {/* 8 jan */}
                        <Grid item xs={4} >
                          <Grid container>
                          {
                            (i.current_state.state_name !== "COMPLETED")?
                            (
                              <Grid container>
                                {role_of_user && role_of_user !== "client" && user_permissions && user_permissions.permissions.assign_user_to_step &&
                                  <Grid>
                                    <GroupIcon
                                      fontSize="small"
                                      style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
                                    ></GroupIcon>
                                    <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", 
                                      verticalAlign: "top", fontSize: "14px", cursor:'pointer', color:'blue' }} 
                                      onClick= { () => handleAddNewUserOpen(i) } 
                                    >
                                      {/* + Assign User */}
                                      + Assign Step
                                    </div>
                                  </Grid>
                                }
                              </Grid>
                            ):
                            (
                              <Grid></Grid>
                            )
                          }
                          </Grid>
                        </Grid>

                        <Grid container >
                          <Grid style={{marginLeft:'3%',color:'white'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{marginTop:'2%'}}>
                          <Grid item xs={2} style={{marginLeft:'1%', fontWeight:'bold'}}>Assigned To : </Grid>
                          <Grid item xs={9} container>
                            {i.assigned_users.map((au, aui) =>(
                              // <AccountCircleIcon style={{ fontSize:40 }}/>
                              // console.log("a assigned_users",au),
                              <Grid >
                                <Grid >{au.name}{aui === i.assigned_users.length - 1 ? "" : ","}</Grid>
                              </Grid>
                            )
                            )}
                          </Grid>
                        </Grid>

                        {/* <Grid item xs={12} style={{padding:5}}>
                          <Accordion >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.CollapseLableHeader}>Activity</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid>
                                to set activity here for stepss
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid> */}
                        
                      </Grid>
                    </div>
                    }
                    icon={
                      <AdjustIcon fontSize="small" style={{ color: "#4D47DD" }} />
                    }
                  />
                ))
              }            
            </Steps>
          </Grid>
          <Modal
            open={open}
            onClose={handelShowStepTodoClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
  
          {/*  */}
          <Modal
            open={OpenAddTodo}
            onClose={handleAddNewToDoModalClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddTodo}
          </Modal>
  
          {/* to add new user */}
          <Modal
            open={openAddUser}
            onClose={handleAddNewUserClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyAddNewUser}
          </Modal>
  
        </Grid>
      </div>
    );
  }
  }

}

            {/* <Step
              title={<div style={{ color: "rgba(0, 0, 0, 0.32);" }}><FormattedMessage id="notStartedLabel" /></div>}
              subTitle={<div style={{ marginLeft: '-8px', marginTop: '5px',color: "#000000" }}><FormattedMessage id="finishedLabel" /></div>}
              description={
                <CasedetailOverviewDescription />}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="small"
                  style={{ color: "#DADADA" }}
                />
              }
            /> */}
