import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
// import 'sweetalert2.scss';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '2%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  // mySwal: {
  //    zIndex: {X},
  // }
}));


export default function Category() {
    const classes = useStyles();
    // const [businessDetails, setbusinessDetails] = React.useState(JSON.parse(localStorage.getItem('businessDetailsForDashboard')));
    const [open, setOpen] = React.useState(false);
    const [categoryDetails, setcategoryDetails] = React.useState({
        business_id: JSON.parse(localStorage.getItem('selected_business_id')),
        category_type: "",
        shortcode: "",
        name: "",
        description: "",
        // is_active: "False",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [categoryTypeErr, setcategoryTypeErr] = React.useState();
    const [shortCodeErr, setshortCodeErr] = React.useState();
    const [nameErr, setnameErr] = React.useState();
    const [descriptionErr, setdescriptionErr] = React.useState();
    const [activeErr, setactiveErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
    }, [flag, open, openBulk ]);

    const handelCreateCategoryOpen = () => {
        setOpen(true);
        dispatch(userActions.checkSessionValidity());
    }

    const handelCreateCategoryClose = () => {
        setOpen(false);
        setcategoryTypeErr();
        setshortCodeErr();
        setnameErr();
        setdescriptionErr();
        setactiveErr();
        setcategoryDetails({
          business_id: JSON.parse(localStorage.getItem('selected_business_id')),
          category_type: "",
          shortcode: "",
          name: "",
          description: "",
          is_active: "",
          device_token: JSON.parse(localStorage.getItem('device_token')),
          session_id: JSON.parse(localStorage.getItem('session_id')),  
        })
        dispatch(userActions.checkSessionValidity());
    };

    const handelCreateCategoryClear = async() => {
      await setcategoryDetails({
        business_id: JSON.parse(localStorage.getItem('selected_business_id')),
        category_type: "",
        shortcode: "",
        name: "",
        description: "",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),  
      })
      // this setopen false and then true is for is_active it's not clearing without that
      setOpen(false);
      setOpen(true);
      // ////////////////////////////////////////////////////////////////////////////////
      setcategoryTypeErr();
      setshortCodeErr();
      setnameErr();
      setdescriptionErr();
      setactiveErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateCategoryMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
       let categoryTypeErr = '';
       let shortCodeErr = '';
       let nameErr = '';
       let descriptionErr = '';
       let activeErr = '';

      if(!categoryDetails.category_type)  {
          categoryTypeErr = 'Please Enter Category Type';
      }
      if(!categoryDetails.shortcode)  {
          shortCodeErr = 'Please Enter Shortcode';
      }
      if(!categoryDetails.name)  {
          nameErr = 'Please Enter Name';
      }
      if(!categoryDetails.description)  {
          descriptionErr = 'Please Enter Description';
      }
      if(categoryDetails.is_active == "" &&  categoryDetails.is_active !== false  && categoryDetails.is_active !== true )  {
          activeErr = 'Please Select is Active or Not';
      }

      if(categoryTypeErr || shortCodeErr || nameErr || descriptionErr || activeErr ){
        setcategoryTypeErr(categoryTypeErr);
        setshortCodeErr(shortCodeErr);
        setnameErr(nameErr);
        setdescriptionErr(descriptionErr);
        setactiveErr(activeErr)

        return false;
      }

      return true;
    }

    const handelSubmitCreateCategory = () => {
      console.log("category details", categoryDetails);
      const isValid = validate();
      dispatch(userActions.checkSessionValidity());
      if(isValid){
        setShowProcedure(true);
       
        const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(categoryDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_CATEGORY, requestOptions)
            .then((Response) => Response.json())
            .then(categoryCreated => {
              setShowProcedure(false);
              setOpen(false);
                console.log("categoryCreated on same page",categoryCreated.success);
                  console.log("categoryCreated on same page",categoryCreated);
                  if(categoryCreated.success == true){
                    Swal.fire({
                      //  customClass: {
                      //   container: 'mySwal'
                      // },
                      icon: 'success',
                      text: "Category Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                      // width: 600,
                    })
                    
                    setcategoryDetails({
                      business_id: JSON.parse(localStorage.getItem('selected_business_id')),
                      category_type: "",
                      shortcode: "",
                      name: "",
                      description: "",
                      is_active: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),  
                    })
                    setcategoryTypeErr();
                    setshortCodeErr();
                    setnameErr();
                    setdescriptionErr();
                    setactiveErr();
                    setflag(true);
                  }
                  if(categoryCreated.success == false || categoryCreated.success == null ){
                    // setOpen(true);
                    Swal.fire({
                      icon: 'error',
                      text: categoryCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      // timer: 5000,
                      // width: 500,
                      // padding: '3em',
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                  }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const body = (
        <div className={classes.paperModal}>
        {/* <h2 className={classes.addNewBusinessHeader}>Add New Business</h2> */}
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          {/* <h2>Create Category</h2> */}
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Create Category</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateCategoryMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateCategoryClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid> 
        </div>
        <div className={classes.modalBody}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={categoryDetails.name}
              onChange={(e) => setcategoryDetails({...categoryDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(categoryDetails.name)?(<div></div>):(nameErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              value={categoryDetails.description}
              onChange={(e) => setcategoryDetails({...categoryDetails, description:e.target.value }) }
            />
            <div className={classes.validation}>{(categoryDetails.description)?(<div></div>):(descriptionErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="category_type">Category Type*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Category Type*</Grid>
              <Select
                native
                labelId="category_type"
                id="category_type"
                value={categoryDetails.category_type}
                onChange={(e) => setcategoryDetails({...categoryDetails, category_type:e.target.value }) }
              >
                {/* <MenuItem value={'category'}>Category</MenuItem>
                <MenuItem value={'supercategory'}>Super Category</MenuItem>
                <MenuItem value={'service'}>Service</MenuItem> */}
                <option value="" style={{color:'grey'}}>Select Category Type</option>
                <option value="category" >Category</option>
                <option value="supercategory" >Super Category</option>
                <option value="service" >Service</option>
              </Select>
            </FormControl>
            <div className={classes.validation}>{(categoryDetails.category_type)?(<div></div>):(categoryTypeErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="shortcode"
              label="Short Code for Category"
              name="shortcode"
              autoComplete="shortcode"
              value={categoryDetails.shortcode}
              onChange={(e) => setcategoryDetails({...categoryDetails, shortcode:e.target.value }) }
            />
            <div className={classes.validation}>{(categoryDetails.shortcode)?(<div></div>):(shortCodeErr)}</div>
            
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_active">Is Active*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(categoryDetails.is_active)}
                  onChange={ (e) => setcategoryDetails({...categoryDetails, is_active: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>

              {/* <Select
                labelId="is_active"
                id="is_active"
                value={categoryDetails.is_active}
                onChange={(e) => setcategoryDetails({...categoryDetails, is_active:e.target.value }) }
              >
                <MenuItem value={'True'}>True</MenuItem>
                <MenuItem value={'False'}>False</MenuItem>
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(categoryDetails.is_active == "" &&  categoryDetails.is_active !== false  && categoryDetails.is_active !== true)?(activeErr):(<div></div>)}</div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateCategory}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateCategoryClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelCategoryBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCategoryBulkClose = () => {
      setopenBulk(false);
      setfileErr();
      setExcelFile();
      setExcelName();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCategoryBulkMinimize = () => {
      setopenBulk(false);
    }

    const handelCategoryBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        // setExcelFile(event.target.files)
        setExcelFile(event.target.files[0])
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitCategoryBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };

        return fetch( process.env.REACT_APP_BULK_CREATE_CATEGORY , requestOptions)
            .then((Response) => Response.json())
            .then( categoryBulk => {
              setShowProcedure(false);              
                console.log("categoryBulk on same page",categoryBulk.success);
                  console.log("categoryBulk on same page",categoryBulk);
                  if(categoryBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Category Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setExcelFile();
                    setfileErr();
                  }
                  if(categoryBulk.success == false){                   
                    Swal.fire({
                      icon: 'error',
                      text: categoryBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
      <div className={classes.paperModalBulk}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        {/* <h2>Category Bulk</h2> */}
        <Grid container item xs={12}>
          <Grid item xs={10} >
          <h2 style={{marginLeft:'40%'}}>Category Bulk</h2>
          </Grid>
          <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
            <MinimizeIcon onClick={()=>handelCategoryBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={1} style={{marginTop:'1%',}}>
            <CloseIcon onClick={()=>handelCategoryBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBodyBulk}>
        <Grid container>
          <Grid item xs={3}>
            <input type='file' 
              // id="files"
              // accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadExcel(e)} 
              hidden
            />
            <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
              cursor: "pointer"}}>Select file</label>
          </Grid>
          <Grid item xs={6} style={{marginLeft:'-2%'}}>
            {
              ( ExcelName)?
              (
                <div >
                  {/* <a 
                    href={this.state.BiographyResumeFileName} target = "_blank"> */}
                    <text>{ExcelName}</text>
                  {/* </a> */}
                </div>
              ):
              (
                <div>
                  No file Choosen 
                </div>
              )
            }
          </Grid>         
        </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitCategoryBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelCategoryBulkClear}
          >
            Clear
          </Button>
        </div>
      </div>
      </div>
    )

    return(
    console.log("selected_business_shortcode'))",JSON.parse(localStorage.getItem('selected_business_shortcode'))),
    <Grid item xs={4} >
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Category
                </Grid>
                {/* <Grid item xs={4} className={classes.moreVertIcon}>
                    <ExpandLessIcon />
                </Grid> */}
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12}  className={classes.userAddUpdateDelete}>
                  <Link to="CategoryList" style={{ textDecoration: 'none', color:'black' }}>
                      Category List
                  </Link>                     
                </Grid>
                <Grid onClick={handelCreateCategoryOpen} item xs={12}  className={classes.userAddUpdateDelete}>
                    Create Category
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateCategoryClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelCategoryBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk create business categories
                    {/* <Link to="CategoryBulkTrial" style={{ textDecoration: 'none', color:'black' }}>
                      Bulk create business categories
                    </Link>  */}
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelCategoryBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
    )
}
