import { authHeader } from '../helpers';

// import { useSelector, useDispatch } from 'react-redux';
// import {addBusinessId} from "../actions/logindetails.actions";
// const dispatch = useDispatch();
// const addBusinessIdRedux = () => dispatch(addBusinessId());

export const userService = {
    login,
    logout,
    getAll,
    createSession,
    checkSessionValidity,
    refreshSessionToken,
    checkSessionValidityWithValues
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'username': username, 'password': password })
    };
    return fetch( process.env.REACT_APP_LOGIN , requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('username', JSON.stringify(username));
            return user;
        });
}

function createSession (device_ip, device_info ){
    console.log("device_ip in create session ======",device_ip)
    console.log("device_info in create session=====",device_info)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'device_ip': device_ip, 'device_info':device_info })
    };
    return fetch( process.env.REACT_APP_CREATE_SESSION , requestOptions)
    .then(handleResponse)
    .then( async(sessionInfo) => {
        console.log("sessionInfo of create session device_token",sessionInfo.data.session_info.device_token);
        console.log("sessionInfo of create session session_id",sessionInfo.data.session_info.session_id);
        await localStorage.setItem('device_token', JSON.stringify(sessionInfo.data.session_info.device_token));
        await localStorage.setItem('session_id', JSON.stringify(sessionInfo.data.session_info.session_id));
        return sessionInfo;
    })
    .catch(error => {
      console.log(error);
      localStorage.clear();
    });
}

function checkSessionValidity (){
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    console.log("device_token in check session useraction======",device_token)
    console.log("session_id in check session useraction=====",session_id)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id':session_id })
    };
    return fetch( process.env.REACT_APP_CHECK_SESSION_VALIDITY , requestOptions)
    .then(handleResponse)
    .then( async(checkSessionInfo) => {
        // console.log("sessionInfo of check session on useraction",checkSessionInfo);
        // console.log("sessionInfo of create session session_id",sessionInfo.data.session_info.session_id);
        // await localStorage.setItem('device_token', JSON.stringify(sessionInfo.data.session_info.device_token));
        // await localStorage.setItem('session_id', JSON.stringify(sessionInfo.data.session_info.session_id));
        return checkSessionInfo;
    });
}

function checkSessionValidityWithValues(device_token,session_id){
    // console.log("device_token in checkSessionValidityWithValues======",device_token)
    // console.log("session_id in checkSessionValidityWithValues=====",session_id)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id':session_id })
    };
    return fetch( process.env.REACT_APP_CHECK_SESSION_VALIDITY , requestOptions)
    .then(handleResponse)
    .then( async(checkSessionInfo) => {
        // console.log("sessionInfo of check session on useraction",checkSessionInfo);
        return checkSessionInfo;
    });
}

function refreshSessionToken(device_token, session_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id':session_id })
    };
    return fetch( process.env.REACT_APP_REFRESH_SESSION_TOKEN , requestOptions)
    .then(handleResponse)
    .then( async(sessionRefresh) => {
        await localStorage.setItem('device_token', JSON.stringify(sessionRefresh.data.session_info.device_token));
        await localStorage.setItem('session_id', JSON.stringify(sessionRefresh.data.session_info.session_id));
        return sessionRefresh;
    });
}

function logout() {
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id':session_id })
    };
    return fetch( process.env.REACT_APP_LOGOUT , requestOptions)
    .then(handleResponse)
    .then(users => {
        console.log("handleResponse of logout",users);
        // localStorage.removeItem('user');
        // localStorage.removeItem('device_token');
        // localStorage.removeItem('session_id');
        localStorage.clear();
        // localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', 0);
        // localStorage.setItem('rowsPerPageOfTableCasesTableTrpAdmin', 5);
        return users ;
    })
    .catch(error => {
      console.log(error);
      localStorage.clear();
    });
}

function getAll(device_token, session_id) {
// function getAll() {
    // let userCheck = JSON.parse(localStorage.getItem('user'));
    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));
    // console.log("userCheck in get all=====----- ======",userCheck)
    console.log("device_token in get all=====----- ======",device_token)
    console.log("session_id in get all=====-----=====",session_id)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // body: JSON.stringify({'username': username})
        body: JSON.stringify({'device_token': device_token, 'session_id' : session_id})
    };
    return fetch( process.env.REACT_APP_GET_USER_DETAILS , requestOptions)
    .then(handleResponse)
    .then( async(users) => {
        console.log("handleResponse of getall users",users, users.success);
        // await localStorage.setItem('users', JSON.stringify(users));
        // await localStorage.setItem('user_permissions', JSON.stringify(users.data.user_details.user_permissions));
        // await localStorage.setItem('role_of_user', JSON.stringify(users.data.user_details.role));

        if(users.success === true){
            console.log("get all success is true")
            await localStorage.setItem('users', JSON.stringify(users));
            await localStorage.setItem('user_permissions', JSON.stringify(users.data.user_details.user_permissions));
            await localStorage.setItem('role_of_user', JSON.stringify(users.data.user_details.role));
            if(users.data.user_details.profile_picture ){
                await localStorage.setItem('profile_picture_local', JSON.stringify(users.data.user_details.profile_picture.url));
            }
            
            if(users.data.user_details.role == "business-user" ){
                // if(users.data.user_details.role !== "client" ){
                    if(users.data.user_details.business !== null){
                        await localStorage.setItem('logedIn_users_businessId', JSON.stringify(users.data.user_details.business.business_id));
                        await localStorage.setItem('selected_business_id', JSON.stringify(users.data.user_details.business.business_id));
                        // await addBusinessIdRedux(users.data.user_details.business.business_id)
                    }
                }
                else if(users.data.user_details.role == "client"){
                    if(users.data.user_details.business_link !== null){
                        await localStorage.setItem('logedIn_users_businessId', JSON.stringify(users.data.user_details.business_link.business_id));
                        await localStorage.setItem('selected_business_id', JSON.stringify(users.data.user_details.business_link.business_id));
                        //    await addBusinessIdRedux(users.data.user_details.business_link.business_id)
                    }
                    // if(users.data.user_details.business_link === null){
                    //     // await localStorage.setItem('logedIn_users_businessId', JSON.stringify(users.data.user_details.business_link.business_id));
                    //     // await localStorage.setItem('selected_business_id', JSON.stringify(users.data.user_details.business_link.business_id));
                    //     //    await addBusinessIdRedux(users.data.user_details.business_link.business_id)
                    //     logout();
                    // }
                }
        }

        if(users.success === false){
            console.log("get all success is false",users.errors)
            localStorage.clear();
            alert(users.errors)
            logout();
            
        }

        return users;
    })
    .catch(error => {
        console.log("error of getall", error);
        // localStorage.clear();
        // logout();
        // alert(error)
    });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
                window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
