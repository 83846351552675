import * as updatecaseConstant from '../constants/updatecase.constants';

export function addSelectedCaseDetails(selectedCaseDetails) {
    return { 
        type: updatecaseConstant.SELECTED_CASE_DETAILS, 
        selectedCaseDetails 
    };
}

export function addNewCase(newCaseDetails) {
    return { 
        type: updatecaseConstant.NEW_CASE_DETAILS, 
        newCaseDetails 
    };
}

export function addSelfAssesment(selfAssesment) {
    return { 
        type: updatecaseConstant.SELF_ASSESMENT, 
        selfAssesment 
    };
}

export function addSuperCategory(superCategory) {
    return { 
        type: updatecaseConstant.SUPER_CATEGORY, 
        superCategory 
    };
}

export function addSubCategory(subCategory) {
    return { 
        type: updatecaseConstant.SUB_CATEGORY, 
        subCategory 
    };
}

export function addSubCategoryFetched(subCategoryFetched) {
    return { 
        type: updatecaseConstant.SUB_CATEGORY_FETCHED, 
        subCategoryFetched 
    };
}

export function addSelectedCategoryFlag(selectedCategoryFlag) {
    return { 
        type: updatecaseConstant.SELECTED_CATEGORY_IS_CATEGORY_FLAG, 
        selectedCategoryFlag 
    };
}

export function addLastCategory(lastCategory) {
    return { 
        type: updatecaseConstant.LAST_CATEGORY, 
        lastCategory 
    };
}

export function addsseInfo(sseInfo) {
    return { 
        type: updatecaseConstant.SSE_INFO, 
        sseInfo 
    };
}

export function addcreateCaseDetails(createCaseDetails) {
    return { 
        type: updatecaseConstant.CREATE_CASE_DETAILS, 
        createCaseDetails 
    };
}

export function addqsnList(qsnList) {
    return { 
        type: updatecaseConstant.QSN_LIST, 
        qsnList 
    };
}

export function addqsnListLevelTwo(qsnListLevelTwo) {
    return { 
        type: updatecaseConstant.QSN_LIST_LEVEL_TWO, 
        qsnListLevelTwo 
    };
}

export function addqsnListLevelThree(qsnListLevelThree) {
    return { 
        type: updatecaseConstant.QSN_LIST_LEVEL_THREE, 
        qsnListLevelThree 
    };
}

export function addnewCaseCreateCaseId(newCaseCreatedCaseId) {
    return { 
        type: updatecaseConstant.NEW_CASE_CREATED_CASE_ID, 
        newCaseCreatedCaseId 
    };
}

export function addYearAss(yearAss) {
    return { 
        type: updatecaseConstant.YEAR_ASS, 
        yearAss 
    };
}

export function updateCaseActionReset() {
    return {
      type: updatecaseConstant.UPDATE_CASE_RESET_ACTION,
    }
}