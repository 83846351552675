import * as logindetailsConstant from '../constants/logindetails.constants';

const initialState = []

export function loginDetails(state = initialState, action) {
  switch (action.type) {

    case logindetailsConstant.LOGEDIN_BUSINESS_ID:
      return Object.assign({}, state, {
        businessId: action.businessId
      });

    case logindetailsConstant.LOGEDIN_ROLE:
      return Object.assign({}, state, {
        logedInRole: action.logedInRole
      });

    case logindetailsConstant.DEVICE_TOKEN:
      return Object.assign({}, state, {
        deviceToken: action.deviceToken
      });

    case logindetailsConstant.SESSION_ID:
      return Object.assign({}, state, {
        sessionId: action.sessionId
      });

    case logindetailsConstant.LOGEDIN_USERS_DETAILS:
      return Object.assign({}, state, {
        logedInUsersDetails: action.logedInUsersDetails
      });
      
    case logindetailsConstant.RESET_ACTION:
      return initialState;

    default:
      return state
  }
}