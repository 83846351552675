import React, { useEffect } from "react";
import { Link , Route, Redirect} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'15%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function Task(stepListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [taskDetails, settaskDetails] = React.useState({
        todolist_id: "",
        item: "",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [taskListDropdown, settaskListDropdown] = React.useState([]);
    const [todoListIdErr, settodoListIdErr] = React.useState();
    const [itemErr, setitemErr] = React.useState();
    const [activeErr, setactiveErr] = React.useState();

    // create Task List
    const [openTaskList, setopenTaskList] = React.useState(false);
    const [taskListDetails, settaskListDetails] =  React.useState({
      name: "",
      description: "",
      business_id:  JSON.parse(localStorage.getItem('selected_business_id')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [nameErr, setnameErr] = React.useState();
    const[descriptionErr, setdescriptionErr] = React.useState();

    // Task List Step Link
    const [openTaskListStepLink, setopenTaskListStepLink] = React.useState(false);
    const [taskListStepLinkDetails, settaskListStepLinkDetails] = React.useState({
        todolist_id: "",
        step_id: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    // const [taskListDropdown, settaskListDropdown] = React.useState([]);
    const [todoListIdTLSLErr, settodoListIdTLSLErr] = React.useState();
    const [stepIdErr, setstepIdErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      // dispatch(userActions.checkSessionValidity());
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id':business_id,
          'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_TODO_LIST_FOR_BUSINESS , requestOptions)
        .then((Response) => Response.json())
        .then(taskDetails => {
          console.log("taskDetails on same page",taskDetails.data.todo_list);
          if(taskDetails.data.todo_list)
          {
            settaskListDropdown(taskDetails.data.todo_list)
          }
          if(taskDetails.success == false){
            settaskListDropdown([])
          }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }, [flag, open, openTaskList, openTaskListStepLink]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelCreateTaskOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskClose = () => {
      setOpen(false);
      settaskDetails({
        todolist_id: "",
        item: "",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      settodoListIdErr();
      setitemErr();
      setactiveErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskClear = async() => {
      await settaskDetails({
        todolist_id: "",
        item: "",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      settodoListIdErr();
      setitemErr();
      setactiveErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let todoListIdErr = '';
      let itemErr = '';
      let activeErr = '';

      if(!taskDetails.todolist_id)  {
          todoListIdErr = 'Please Enter Task Id';
      }
      if(!taskDetails.item)  {
          itemErr = 'Please Enter Task Name';
      }
      if(taskDetails.is_active == "" && taskDetails.is_active !== false && taskDetails.is_active !== true)  {
        activeErr = 'Please Select Is Active';
    }

      if(todoListIdErr || itemErr || activeErr){
        settodoListIdErr(todoListIdErr);
        setitemErr(itemErr);
        setactiveErr(activeErr);

        return false;
      }
      return true;
    }

    const handelSubmitCreateTask = () => {
      console.log("taskDetails",taskDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(taskDetails)
          };
          return fetch( process.env.REACT_APP_CREATE_TODO , requestOptions)
              .then((Response) => Response.json())
              .then(taskCreated => {
                  setShowProcedure(false);
                  console.log("taskCreated on same page",taskCreated.success);
                  console.log("taskCreated on same page",taskCreated);
                  if(taskCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Task Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    settaskDetails({
                      todolist_id: "",
                      item: "",
                      is_active: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    settodoListIdErr();
                    setitemErr();
                    setactiveErr();
                  }
                  if(taskCreated.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: taskCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }          
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
      }
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'45%'}}>Create Task</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateTaskMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateTaskClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody}>
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="todolist_id"
              label="Task List Id"
              name="todolist_id"
              autoComplete="todolist_id"
              autoFocus
              value={taskDetails.todolist_id}
              onChange={(e) => settaskDetails({...taskDetails, todolist_id:e.target.value }) }
            /> */}
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="todolist_id">Task*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Task*</Grid>
              <Select
                native
                labelId="todolist_id"
                id="todolist_id"
                value={taskDetails.todolist_id}
                onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                // onChange = {handleChangeCategoryDropdown}
              >
                {/* <MenuItem value={''}>Select Task</MenuItem>
                {taskListDropdown && taskListDropdown.map(item =>
                  <MenuItem value={item.todolist_id} >{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Task</option>
                  {taskListDropdown && taskListDropdown.map(item =>
                    <option value={item.todolist_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(taskDetails.todolist_id)?(<div></div>):(todoListIdErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="item"
              label="Name"
              name="item"
              autoComplete="item"
              value={taskDetails.item}
              onChange={(e) => settaskDetails({...taskDetails, item:e.target.value }) }
            />
            <div className={classes.validation}>{(taskDetails.item)?(<div></div>):(itemErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_active">Is Active*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(taskDetails.is_active)}
                  onChange={ (e) => settaskDetails({...taskDetails, is_active: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
            </FormControl>
            <div className={classes.validation}>{(taskDetails.is_active == "" &&  taskDetails.is_active !== false  && taskDetails.is_active !== true)?(activeErr):(<div></div>)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateTask}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateTaskClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    // Create Task list functions
    const handelCreateTaskListOpen = () => {
      setopenTaskList(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskListClose = () => {
      setopenTaskList(false);
      settaskListDetails({
        name: "",
        description: "",
        business_id:  JSON.parse(localStorage.getItem('selected_business_id')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setnameErr();
      setdescriptionErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskListClear = () => {
      settaskListDetails({
        name: "",
        description: "",
        business_id:  JSON.parse(localStorage.getItem('selected_business_id')),
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setnameErr();
      setdescriptionErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskListMinimize = () => {
      setopenTaskList(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validateTaskList = () => {
      let nameErr = '';
      let descriptionErr = '';

      if(!taskListDetails.name)  {
          nameErr = 'Please Enter Name';
      }
      if(!taskListDetails.description)  {
          descriptionErr = 'Please Enter Description';
      }

      if(nameErr || descriptionErr ){
        setnameErr(nameErr);
        setdescriptionErr(descriptionErr);
        return false;
      }
      return true;
    }

    const handelSubmitCreateTaskList = () => {
      console.log("taskListDetails",taskListDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validateTaskList();
      if(isValid){
        setShowProcedure(true);
        setopenTaskList(false);
        console.log("taskListDetails",taskListDetails)
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(taskListDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_TODO_LIST , requestOptions)
          .then((Response) => Response.json())
          .then(taskListCreated => {
            setShowProcedure(false);
            console.log("taskListCreated on same page",taskListCreated.success);
            console.log("taskListCreated on same page",taskListCreated);
            if(taskListCreated.success == true){
              Swal.fire({
                icon: 'success',
                text: "Task List Created Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })             
              settaskListDetails({
                name: "",
                description: "",
                business_id:  JSON.parse(localStorage.getItem('selected_business_id')),
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')),
              })
              setnameErr();
              setdescriptionErr();
            }
            if(taskListCreated.success == false){
              Swal.fire({
                icon: 'error',
                text: taskListCreated.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              }).then(function (result) {
                console.log("result of swal",result)
                setopenTaskList(true);
              })
            }             
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
    }

    const bodyTaskList = (
      <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Create Task List</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateTaskListMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateTaskListClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
      </div>
        <div className={classes.modalBody}>
          <FormControl className={classes.formControl}>
          <TextField
            autoFocus
            margin="name"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            value={taskListDetails.name}
            onChange={(e) => settaskListDetails({...taskListDetails, name:e.target.value }) }
          />
          </FormControl>
          <div className={classes.validation}>{(taskListDetails.name)?(<div></div>):(nameErr)}</div>

          <FormControl className={classes.formControl}>
          <TextField
            margin="description"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            autoComplete="description"
            value={taskListDetails.description}
            onChange={(e) => settaskListDetails({...taskListDetails, description:e.target.value }) }
          />
          </FormControl>
          <div className={classes.validation}>{(taskListDetails.description)?(<div></div>):(descriptionErr)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitCreateTaskList}
            >
              Create
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handelCreateTaskListClear}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
    )

    // Task list step link
    const handelCreateTaskListStepLinkOpen = () => {
      setopenTaskListStepLink(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskListStepLinkClose = () => {
      setopenTaskListStepLink(false);
      settaskListStepLinkDetails({
        todolist_id: "",
        step_id: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      settodoListIdTLSLErr();
      setstepIdErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskListStepLinkClear = () => {
      settaskListStepLinkDetails({
        todolist_id: "",
        step_id: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      settodoListIdTLSLErr();
      setstepIdErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateTaskListStepLinkMinimize = () => {
      setopenTaskListStepLink(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validateTLST = () => {
      let todoListIdTLSLErr = '';
      let stepIdErr = '';

      if(!taskListStepLinkDetails.todolist_id)  {
          todoListIdTLSLErr = 'Please Select Task';
      }
      if(!taskListStepLinkDetails.step_id)  {
          stepIdErr = 'Please Select Step';
      }

      if(todoListIdTLSLErr || stepIdErr){
        settodoListIdTLSLErr(todoListIdTLSLErr);
        setstepIdErr(stepIdErr);

        return false;
      }
      return true;
    }

    const handelSubmitCreateTaskListStepLink = () => {
      console.log("taskListStepLinkDetails",taskListStepLinkDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validateTLST();
      if(isValid){
        setShowProcedure(true);
        setopenTaskListStepLink(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(taskListStepLinkDetails)
          };
          return fetch( process.env.REACT_APP_CREATE_STEP_TODO_LINK , requestOptions)
              .then((Response) => Response.json())
              .then(taskLinkStepLinksCreated => {
                  setShowProcedure(false);
                  console.log("taskLinkStepLinksCreated on same page",taskLinkStepLinksCreated.success);
                  console.log("taskLinkStepLinksCreated on same page",taskLinkStepLinksCreated);
                  if(taskLinkStepLinksCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Task Link Step Link Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                   
                    settaskListStepLinkDetails({
                      todolist_id: "",
                      step_id: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    settodoListIdTLSLErr();
                    setstepIdErr();
                  }
                  if(taskLinkStepLinksCreated.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: taskLinkStepLinksCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenTaskListStepLink(true);
                    })
                }            
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
      }
    }

    const bodyTaskListStepLink = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'28%'}}>Create Task List Step Link</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateTaskListStepLinkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateTaskListStepLinkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody}>
          <FormControl className={classes.formControl}>
            {/* <InputLabel id="todolist_id">Task*</InputLabel> */}
            <Grid style={{marginTop:"2%", color:"grey"}}>Task*</Grid>
            <Select
              native
              labelId="todolist_id"
              id="todolist_id"
              value={taskListStepLinkDetails.todolist_id}
              onChange={(e) => settaskListStepLinkDetails({...taskListStepLinkDetails, todolist_id:e.target.value }) }
            >
              {/* <MenuItem value={''}>Select Task</MenuItem>
              {taskListDropdown && taskListDropdown.map(item =>
                <MenuItem value={item.todolist_id} >{item.name}</MenuItem>
              )} */}
              <option value="" style={{color:'grey'}}>Select Task</option>
                  {taskListDropdown && taskListDropdown.map(item =>
                    <option value={item.todolist_id}>{item.name}</option>
                )}
            </Select>
          </FormControl>
          <div className={classes.validation}>{(taskListStepLinkDetails.todolist_id)?(<div></div>):(todoListIdTLSLErr)}</div>

          <FormControl className={classes.formControl}>
            {/* <InputLabel id="step_id">Step*</InputLabel> */}
            <Grid style={{marginTop:"2%", color:"grey"}}>Step*</Grid>
            <Select
              native
              labelId="step_id"
              id="step_id"
              value={taskListStepLinkDetails.step_id}
              onChange={(e) => settaskListStepLinkDetails({...taskListStepLinkDetails, step_id:e.target.value }) }
            >
              {/* <MenuItem value={''}>Select Step</MenuItem>
              {stepListDropdown.stepListDropdown.map(item =>
                <MenuItem value={item.step_id}>{item.name}</MenuItem>
              )} */}
              <option value="" style={{color:'grey'}}>Select Task</option>
                  {stepListDropdown.stepListDropdown.map(item =>
                    <option value={item.step_id}>{item.name}</option>
                )}
            </Select>
          </FormControl>
          <div className={classes.validation}>{(taskListStepLinkDetails.step_id)?(<div></div>):(stepIdErr)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateTaskListStepLink}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateTaskListStepLinkClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Task Lists
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="TaskList" style={{ textDecoration: 'none', color:'black' }}>
                    Task List
                  </Link>
                </Grid>

                <Grid item xs={12} onClick={handelCreateTaskListOpen} className={classes.userAddUpdateDelete}>
                    Create Task List
                </Grid>
                <Modal
                    open={openTaskList}
                    onClose={handelCreateTaskListClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyTaskList}
                </Modal>

                <Grid item xs={12} onClick={handelCreateTaskOpen} className={classes.userAddUpdateDelete}>
                    Create Task
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateTaskClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>

                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="TaskListStepLinkList" style={{ textDecoration: 'none', color:'black' }}>
                    Task Lists Step Link List
                  </Link>
                </Grid>

                <Grid item xs={12} onClick={handelCreateTaskListStepLinkOpen} className={classes.userAddUpdateDelete}>
                    Create Task LIst Step Link
                </Grid>
                <Modal
                    open={openTaskListStepLink}
                    onClose={handelCreateTaskListStepLinkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bodyTaskListStepLink}
                </Modal>

                
            </Grid>
        </Paper>
    </Grid>
  )
}
