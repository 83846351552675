import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormattedMessage, injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: "rgba(0, 0, 0, 0.32)",
    textAlign: "left",
  },
  leftAlignWithoutColor: {
    textAlign: "left",
  },
  rightAlignWithoutColor: {
    textAlign: "right",
  },
  messageButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "#4D47DD",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  addMessageLabel: {
    textAlign: 'left',
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: "16px",
    fontWeight: 600,
  },
  inputPadding: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: '5%',
  }
}));

function TaxManagementCasesMessages() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.marginBottom}>
          <Grid container>
            <Grid item xs={12} className={classes.addMessageLabel}>
              <FormattedMessage id="addMessageLabel" />
            </Grid>
            <Grid item xs={12}>
            <Input
            id="sendButton"
            type="text"
            fullWidth
            className={classes.inputPadding}
            endAdornment={
              <InputAdornment position="end">
                 <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    <FormattedMessage id="sendLabel" />
                  </Button>
              </InputAdornment>
            }
          />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.gridContianer}>
            <Grid item xs={1}>
              <Avatar src="/static/images/avatar/1.jpg" />
            </Grid>
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={12} className={classes.leftAlignWithoutColor}>
                  <Grid container>
                    <Grid item xs={8}>
                      {"TRP2"}
                    </Grid>
                    <Grid item xs={2} className={classes.rightAlignWithoutColor}>
                      <Button
                        className={classes.messageButton}
                      >
                        <FormattedMessage id="editLabel" />
                      </Button>
                    </Grid>
                    <Grid item xs={2} className={classes.rightAlignWithoutColor}>
                      <Button
                        className={classes.messageButton}
                      >
                        <FormattedMessage id="replyLabel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.leftAlign}>
                  {"TRP2 Created Client RL1123"}
                </Grid>
                <Grid item xs={12} className={classes.leftAlign}>
                  {"14 hours ago"}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </div>
  );
}

export default injectIntl(TaxManagementCasesMessages);
