import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormattedMessage, injectIntl } from "react-intl";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
  confirmStyle: {
    textTransform: "none",
    background: "#4D47DD",
  },
  textTransformNone: {
    textTransform: "none",
  },
  marginTop: {
    marginTop: "50px",
  },
  marginLeft: {
    marginLeft: "20px",
  }
}));

function ConfirmMessageNewClient({ handleModalClose }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
        <FormattedMessage id="confirmResendPANMessage" />
        </Grid>
        <Grid item xs={12} className={classes.marginTop}>
          <Grid container justify="flex-end">
            <Grid item className={classes.marginLeft}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleModalClose}
              >
                <FormattedMessage id="cancelLabel" />
              </Button>
            </Grid>
            <Grid item className={classes.marginLeft}>
              <Button
                size="small"
                variant="outlined"
                className={classes.confirmStyle}
              >
                <FormattedMessage id="confirmLabel" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default injectIntl(ConfirmMessageNewClient);
