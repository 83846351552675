import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left'
  }
}));

export default function TaxManagementCasesDocuments() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <Grid container>
      <Grid item xs={12}>
        <Grid container className={classes.gridContianer}>
          <Grid item xs={1}>
            <PictureAsPdfIcon color="disabled"/>
          </Grid>
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={12} className={classes.leftAlign}>
                {"https://samples.leanpub.com/thereactnativebook-sample.pdf"}
              </Grid>
              <Grid item xs={12} className={classes.leftAlign}>
                {'24 Jan'}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
            <Divider />
      </Grid>
    </Grid>
  </div>
  );
}
