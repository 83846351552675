export const SELF_ASSESSMENT_NAME='SELF_ASSESSMENT_NAME';

export const SELF_ASSESSMENT_PAN='SELF_ASSESSMENT_PAN';

export const SSE_DETAILS='SSE_DETAILS';

// export const caseupdateConstant = {
//     SSE_DETAILS: 'SSE_DETAILS',
//     SELF_ASSESSMENT_NAME: 'SELF_ASSESSMENT_NAME',
//     SELF_ASSESSMENT_PAN: 'SELF_ASSESSMENT_PAN',
// };