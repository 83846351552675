export const LOGEDIN_BUSINESS_ID = 'LOGEDIN_BUSINESS_ID';

export const LOGEDIN_ROLE = 'LOGEDIN_ROLE';

export const DEVICE_TOKEN = 'DEVICE_TOKEN';

export const SESSION_ID = 'SESSION_ID';

export const LOGEDIN_USERS_DETAILS = 'LOGEDIN_USERS_DETAILS';

export const RESET_ACTION='RESET_ACTION';