export const NEW_CASE_DETAILS = 'NEW_CASE_DETAILS';

export const SELF_ASSESMENT = 'SELF_ASSESMENT';

export const SUPER_CATEGORY = 'SUPER_CATEGORY';

export const SUB_CATEGORY = 'SUB_CATEGORY';

export const SUB_CATEGORY_FETCHED = 'SUB_CATEGORY_FETCHED';

export const SELECTED_CATEGORY_IS_CATEGORY_FLAG = 'SELECTED_CATEGORY_IS_CATEGORY_FLAG';

export const LAST_CATEGORY = 'LAST_CATEGORY';

export const SSE_INFO = 'SSE_INFO';

export const CREATE_CASE_DETAILS = 'CREATE_CASE_DETAILS';

export const QSN_LIST = 'QSN_LIST';

export const QSN_LIST_LEVEL_TWO = 'QSN_LIST_LEVEL_TWO';

export const QSN_LIST_LEVEL_THREE = 'QSN_LIST_LEVEL_THREE';

export const NEW_CASE_CREATED_CASE_ID = 'NEW_CASE_CREATED_CASE_ID';

export const YEAR_ASS = 'YEAR_ASS';

export const NEW_CASE_ACTION_RESET='NEW_CASE_ACTION_RESET';

export const ASSENAME_OR_OTHER_CONTACT_SELECTED = 'ASSENAME_OR_OTHER_CONTACT_SELECTED';

export const ADD_NEW_CONTACT_SELECTED = 'ADD_NEW_CONTACT_SELECTED';
