import React, { useEffect } from "react";
import { Link , Route, Redirect, Switch} from 'react-router-dom';
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {navigate} from 'hookrouter';
import { useSelector , useDispatch} from 'react-redux';
import useCheckSessionApi from "../SessionValidity";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { modal } from 'reactstrap';
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TableSortLabel from '@material-ui/core/TableSortLabel';

import BusinessDashboard from '../BusinessDashboard';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(businessDetails, comparator) {
  const stabilizedThis = businessDetails && businessDetails.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'tan', numeric: false, label: 'PAN', },
  { id: 'actions', numeric: false, label: 'Actions', },
  // { id: 'contachName', numeric: false, label: 'Contact Name' ,},
  // { id: 'contactNumber', numeric: false, label: 'Contact Number' , },
  // { id: 'email', numeric: false, label: 'Email' ,},
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      {headCells.map((headCell) => (
          ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'center' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
           )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  table: {
    minWidth: 500, 
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  tableHeaderBar: {
    marginBottom: "1%",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
  },
  leftAlign: {
    textAlign: 'left'
  },
  rightAlign: {
    textAlign: 'right'
  },
  verticalAlignTop: {
    verticalAlign: 'top'
  },
  chipColor: {
    background: 'rgba(77, 71, 221, 0.16)'
  },
  sortByClasses: {
    padding: 0,
    lineHeight: 0,
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.64)',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  // hover: {
  //   cursor: "pointer",
  // },
  customAvatar: {
    position: "inherit"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  }
}));


export default function BusinessesList() {
    // const classes = useStyles();
    const [redirect, setredirect] = React.useState(false);
    const [business_id, setbusiness_id] = React.useState()
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [businessDetails, setbusinessDetails] = React.useState();
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));

    const [openEditBusiness, setopenEditBusiness] = React.useState(false);
    const [EditBusinessId, setEditBusinessId] = React.useState();
    const [EditBusinessName, setEditBusinessName] = React.useState();
    const [EditBusinessAddress, setEditBusinessAddress] = React.useState();
    const [EditBusinessPan, setEditBusinessPan] = React.useState();
    const [EditBusinessWebsite, setEditBusinessWebsite] = React.useState();
    const [EditBusinessShortcode, setEditBusinessShortcode] = React.useState();
    const [nameErr, setnameErr] = React.useState();
    const [shortCodeErr, setshortCodeErr] = React.useState();
    const [addressErr, setaddressErr] = React.useState();
    // const [websiteErr, setwebsiteErr] = React.useState();
    const [panErr, setpanErr] = React.useState();

    const classes = useStyles2();
    const [page, setPage] = React.useState(0);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const [DeviceToken, setDeviceToken] =   React.useState();
    const [SessionId, setSessionId] =   React.useState();
    const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
    const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
    let user = JSON.parse(localStorage.getItem('user'));
    // const [apiURL, setAPIURL] =  React.useState("https://api.trpflow.com");
    const [data, checkSessionValidity] = useCheckSessionApi({
      // url: apiURL + "/users/checksessionvalidity/",
      url: process.env.REACT_APP_CHECK_SESSION_VALIDITY,
      options: {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + user.key,
        },
        // body: ({'device_token':deviceTokenFromRedux, 
        //       'session_id':sessionIdFromRedux}),
        body: JSON.stringify({
          device_token: deviceTokenFromRedux,
          session_id: sessionIdFromRedux,
        }),
      },
      // props,
    });
    
    useEffect(() => {
      setflag(true)

        const requestOptions = {
          method: 'POST',
          // headers: { 'Content-Type': 'application/json' },
          headers: authHeader(),
          body: JSON.stringify({'device_token': device_token, 'session_id': session_id })
        };
        fetch( process.env.REACT_APP_GET_BUSINESSES , requestOptions)
        .then((Response) => Response.json())
        .then(businessesDetails => {
            console.log("businessesDetails respones from api businesses in list",businessesDetails.data.businesses);
            if(businessesDetails.data.businesses)
            {
                setbusinessDetails(businessesDetails.data.businesses)
                // localStorage.setItem('businessesDetails', JSON.stringify(businessesDetails.data.businesses));
            }
            if(businessesDetails.success == false){
                Swal.fire({
                icon: 'error',
                text: businessesDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

        // readItemFromStorage();
    }, [flag]);
    
    const readItemFromStorage = async() => {
      try {
        setDeviceToken(deviceTokenFromRedux)
        setSessionId(sessionIdFromRedux)
        setflag(false)    
      } catch (error) {
        console.log("ERROR:",error);        }
    };

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, businessDetails && businessDetails.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    // mu function
    const handleNameClick = async(business_id) => {
        console.log("item id",business_id);
        await localStorage.setItem('selected_business_id', JSON.stringify(business_id));
        // setredirect(true);
        // setbusiness_id(business_id);
        //  <Switch>
        //     {/* <Route exact path="/BusinessDashboard"> */}
        //       <Redirect to="/BusinessDashboard" />
        //     {/* </Route> */}
        // </Switch>
    }

    // if( redirect == true){
    //     return(
    //         // <Redirect to="/BusinessDashboard" />
    //         (<Redirect to={{
    //             pathname: '/BusinessDashboard',
    //             state: {
    //                 business_id: business_id,
    //             }
    //         }} />)
    //     )
    // }
    // else{

    const handleClickEditBusiness = (editBusinessDetails) => {
      console.log("in Edit Business",editBusinessDetails)
      setEditBusinessId(editBusinessDetails.business_id);
      setEditBusinessName(editBusinessDetails.name);
      setEditBusinessAddress(editBusinessDetails.address);
      setEditBusinessPan(editBusinessDetails.tan);
      setEditBusinessWebsite(editBusinessDetails.website);
      setEditBusinessShortcode(editBusinessDetails.shortcode);
      handelEditBusinessOpen();
    }

    const handelEditBusinessOpen = () =>{
      setopenEditBusiness(true);
    }

    // const handelEditBusinessMinimize = () => {
    //   setopenEditBusiness(false);
    // } 

    const handelEditBusinessClose = () => {
      setopenEditBusiness(false);
      setnameErr();
      setshortCodeErr();
      setaddressErr();
      // setwebsiteErr();
      setpanErr();
    }

    const handelAddNewBusinessReset = async() =>{
      // setEditBusinessId();
      await setEditBusinessName("");
      await setEditBusinessAddress("");
      await setEditBusinessPan("");
      await setEditBusinessWebsite("");
      await setEditBusinessShortcode("");

      await setnameErr("");
      await setshortCodeErr("");
      await setaddressErr("");
      // await setwebsiteErr("");
      await setpanErr("");
    }

    const validate = () => {
      console.log("in validate func")
      let nameErr = '';
      let shortCodeErr = '';
      let addressErr = '';
      // let websiteErr = '';
      let panErr = '';

      if(!EditBusinessName)  {
          nameErr = 'Please Enter Name';
      }
      if(!EditBusinessShortcode)  {
          shortCodeErr = 'Please Enter Short Code';
      }
      if(!EditBusinessAddress)  {
          addressErr = 'Please Enter Address';
      }
      // if(!EditBusinessWebsite)  {
      //     websiteErr = 'Please Enter Website';
      // }

      if(!EditBusinessPan)  {
          panErr = 'Please Enter PAN';
          console.log("in blank pan")
      }
      else if (typeof EditBusinessPan !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(EditBusinessPan.length !== 10){
          console.log("in != 10")
          panErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(EditBusinessPan)) {
          console.log("in string check")
          panErr = "PAN Number is Not Valid";
        } 
      }
      else{
            setpanErr();
      }

      if(nameErr || addressErr || panErr || shortCodeErr){
        setnameErr(nameErr);
        setshortCodeErr(shortCodeErr);
        setaddressErr(addressErr);
        // setwebsiteErr(websiteErr);
        setpanErr(panErr);
        return false;
      }
      return true;
    }


    const handelSubmitEditBusiness = () => {
      const isValid = validate();
      if(isValid){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({ 'business_id': EditBusinessId,'name': EditBusinessName,
            'shortcode': EditBusinessShortcode, 'address': EditBusinessAddress,'website': EditBusinessWebsite,
          'tan': EditBusinessPan, 'device_token': device_token, 'session_id': session_id })
        };
        return fetch( process.env.REACT_APP_CREATE_BUSINESS , requestOptions)
          .then((Response) => Response.json())
          .then( businessAdded => {
                console.log("businessAdded on same page",businessAdded.success);
                  console.log("businessAdded on same page",businessAdded);
                  if(businessAdded.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Business Edited successfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setopenEditBusiness(false);
                    setEditBusinessId();
                    setEditBusinessName();
                    setEditBusinessAddress();
                    setEditBusinessPan();
                    setEditBusinessWebsite();
                    setEditBusinessShortcode();

                    setnameErr();
                    setshortCodeErr();
                    setaddressErr();
                    // setwebsiteErr();
                    setpanErr();
                  }
                  if(businessAdded.success == false){
                    setopenEditBusiness(true);
                    Swal.fire({
                      icon: 'error',
                      text: businessAdded.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                }
              setflag(false);
              refreshBusinesses();
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const refreshBusinesses = () => {
      const requestOptions = {
        method: 'POST',
        // headers: { 'Content-Type': 'application/json' },
        headers: authHeader(),
        body: JSON.stringify({'device_token': device_token, 'session_id': session_id })
      };
  
      fetch( process.env.REACT_APP_GET_BUSINESSES , requestOptions)
      .then((Response) => Response.json())
      .then(businessesDetails => {
          console.log("businessesDetails respones from api",businessesDetails.data.businesses);
          if(businessesDetails.data.businesses)
          {
              setbusinessDetails(businessesDetails.data.businesses)
          }
          if(businessesDetails.success == false){
              Swal.fire({
              icon: 'error',
              text: businessesDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
          })
          }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }

    const bodyEditBusiness = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid item xs={10} >
          <h2 style={{marginLeft:'42%'}}>Edit Business</h2>
        </Grid>
        {/* <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
          <MinimizeIcon onClick={()=>handelEditBusinessMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
        </Grid> */}
        <Grid item xs={1} style={{marginTop:'1%',}}>
          <CloseIcon onClick={()=>handelEditBusinessClose()} style={{fontSize: 20, cursor:'pointer'}}/>
        </Grid>
      </div>
      <div className={classes.modalBody}>
          <TextField
            //   variant="outlined"
              margin="normal"
              required
              fullWidth
              id="EditBusinessName"
              label="Edit Business Name"
              name="EditBusinessName"
              autoComplete="EditBusinessName"
              autoFocus
              value={EditBusinessName}
              onChange={(e) => setEditBusinessName(e.target.value)}
            />
          <div className={classes.validation}>{(EditBusinessName)?(<div></div>):(nameErr)}</div>

          <TextField
              margin="normal"
              required
              fullWidth
              id="EditBusinessPan"
              label="Edit Business Pan"
              name="EditBusinesPAN"
              autoComplete="EditBusinessPan"
              value={EditBusinessPan}
              placeholder="XXXXX0000X"
              inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
              onChange={(e) => setEditBusinessPan(e.target.value.toUpperCase())}
          />
          <div className={classes.validation}>{panErr}</div>

          <TextField
              margin="normal"
              required
              fullWidth
              id="EditBusinessAddress"
              label="Edit Business Address"
              name="EditBusinessAddress"
              autoComplete="EditBusinessAddress"
              value={EditBusinessAddress}
              onChange={(e) => setEditBusinessAddress(e.target.value)}
          />
          <div className={classes.validation}>{(EditBusinessAddress)?(<div></div>):(addressErr)}</div>

          <TextField
              margin="normal"
              required
              fullWidth
              id="EditBusinessShortcode"
              label="Edit Business Shortcode"
              name="EditBusinessShortcode"
              autoComplete="EditBusinessShortcode"
              value={EditBusinessShortcode}
              onChange={(e) => setEditBusinessShortcode(e.target.value)}
          />
          <div className={classes.validation}>{(EditBusinessShortcode)?(<div></div>):(shortCodeErr)}</div>

          <TextField
              margin="normal"
              // required
              fullWidth
              id="EditBusinessWebsite"
              label="Edit Business Website"
              name="EditBusinessWebsite"
              autoComplete="EditBusinessWebsite"
              value={EditBusinessWebsite}
              onChange={(e) => setEditBusinessWebsite(e.target.value)}
          />
          {/* <div className={classes.validation}>{(EditBusinessWebsite)?(<div></div>):(websiteErr)}</div> */}

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
              <Button
              type="submit"
              //   fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitEditBusiness}
              >
                Submit
              </Button>
              <div style={{marginLeft:'2%'}}></div>
              <Button
                  type="submit"
                  //   fullWidth
                  variant="contained"
                  className={classes.submit}
                  // onClick={handelAddNewBusinessReset}
                  onClick={handelEditBusinessClose}
              >
                Cancel
              </Button>
          </div>
      </div>
      </div>
  );

  

    return(
        console.log("businessDetails on show table page",businessDetails),
        <Grid>
        <TableContainer className={classes.tableWrapper} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={businessDetails && businessDetails.length}
            />
            {/* <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Short Code</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="right">Website</TableCell>
                    <TableCell align="right">Tan</TableCell>
                </TableRow>
            </TableHead> */}
            <TableBody>
                {/* {(rowsPerPage > 0
                    ? businessDetails.businessDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : businessDetails.businessDetails
                    ).map((businessDetails) => ( */}
                {stableSort(businessDetails && businessDetails, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((businessDetails) => {
                  // console.log("row in table map",businessDetails)
                    return (
                    <TableRow 
                        key={businessDetails.business_id} 
                        hover={true} classes={{hover: classes.hover}}
                        // onRowClick={(event, rowData) => console.log("rowData",rowData)}
                        // hover
                        tabIndex={-1}
                        align="center"
                    >
                        <TableCell 
                            align="center"
                            width="10%"
                            component={Link} 
                            to="/BusinessDashboard" 
                            onClick={() => handleNameClick(businessDetails.business_id)}
                        >
                        {businessDetails.name}
                        </TableCell>
                        <TableCell width="10%" align="center">{businessDetails.tan}</TableCell>
                        <TableCell width="5%" align="center" >
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => handleClickEditBusiness(businessDetails)}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        {/* <TableCell >{businessDetails.shortcode}</TableCell>
                        <TableCell >{businessDetails.address}</TableCell>
                        <TableCell >{businessDetails.website}</TableCell> */}
                        
                    </TableRow>
                    );
                })}
                    {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                    )} */}
                </TableBody>
                <TableFooter>
                    <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: businessDetails && businessDetails.length }]}
                        colSpan={8}
                        count={businessDetails && businessDetails.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    </TableRow>
                </TableFooter>
        </Table>
        </TableContainer>
        <Modal
          open={openEditBusiness}
          onClose={handelEditBusinessClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyEditBusiness}
      </Modal>
    </Grid>
    )
}