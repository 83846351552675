import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import { FormattedMessage, injectIntl } from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';

const TagInput = withStyles((theme) => ({
  input: {
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#FDD600",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addNewTagLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "21px",
    color: "rgba(0, 0, 0, 0.96)",
  },
  addNewTagClose: {
    textAlign: "right",
    marginTop: "-5px",
  },
  saveStyle: {
    textTransform: "none",
    background: "#4D47DD",
    color: "#FFFFFF",
  },
  createNewTagLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "#4D47DD",
    marginTop: "15px",
    marginBottom: "10px",
  },
  textTransformNone: {
    textTransform: "none",
  },
  marginTop: {
    marginTop: "50px",
  },
  marginLeft: {
    marginLeft: "20px",
  },
  addButtonStyle: {
    textTransform: "none",
    lineHeight: "20px",
    fontWeight: "500",
    fontSize: "14px",
    color: "#4D47DD",
  },
  textAlign: {
    textAlign: "center",
    color: "#DADADA",
    marginTop:"5px"
  }
}));

function AddNewTagModalView({ handleDrawerClose, intl, handleModalClose }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} className={classes.addNewTagLabel}>
              <FormattedMessage id="selectTagsLabel" />
            </Grid>
            <Grid item xs={6} className={classes.addNewTagClose}>
              <IconButton aria-label="close" onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item xs={11}><TagInput defaultValue="Tag" id="bootstrap-input" fullWidth /></Grid>
            <Grid item xs={1} className={classes.textAlign}><EditIcon/></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.createNewTagLabel}>
        <Button
                className={classes.addButtonStyle}
                startIcon={<AddIcon />}
              >
                <FormattedMessage id="createNewTagLabel" />
              </Button>
        </Grid>

        <Grid item xs={12} className={classes.marginTop}>
          <Grid container justify="flex-end">
            <Grid item className={classes.marginLeft}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleModalClose}
              >
                <FormattedMessage id="cancelLabel" />
              </Button>
            </Grid>
            <Grid item className={classes.marginLeft}>
              <Button
                size="small"
                variant="outlined"
                className={classes.saveStyle}
              >
                <FormattedMessage id="saveLabel" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default injectIntl(AddNewTagModalView);

// from tax mgmt case details
// {/* <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         className={classes.modal}
//         open={modalTagOpen}
//         onClose={handleModalClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         disableBackdropClick={true}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={modalTagOpen}>
//           <div className={classes.paperModal}>
//             <AddNewTagModalView handleModalClose={handleModalClose} />
//           </div>
//         </Fade>
//       </Modal> */}
