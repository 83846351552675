import React, { useContext, useState } from 'react'

const ThemeContext = React.createContext()

const ThemeUpdateContext = React.createContext()

export function useThemeContext() {
    // console.log(" in theme useThemeContext return");
    return useContext(ThemeContext)
}

export function useThemeUpdate() {
    // console.log(" in theme useThemeUpdate return");
    return useContext(ThemeUpdateContext)
}

export function ThemeProvider( {children } ) {
    const [darkTheme, setDarkTheme] = useState(false)
    // const [darkTheme, setDarkTheme] = useState(JSON.parse(localStorage.getItem('drawerOpenClose')))

    function toggleTheme() {
        setDarkTheme( prevDarkTheme => !prevDarkTheme )
    }

    return(
        // console.log(" in theme context return",darkTheme),
        <ThemeContext.Provider value={darkTheme}>
        {/* <ThemeContext.Provider value={true}> */}
            <ThemeUpdateContext.Provider value={toggleTheme}>
                {children}
            </ThemeUpdateContext.Provider>
        </ThemeContext.Provider>
    )
}