import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Chip from '@material-ui/core/Chip';
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  chipColor :{
    background: 'rgba(77, 71, 221, 0.16)'
  },
  cursorPointer: {
    cursor: "pointer"
  }
}));

function TaxManagementRecentCases({intl, handleChange}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={3} onClick={handleChange} className={classes.cursorPointer}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={6}>
                <Chip className={classes.chipColor} size="small" label={intl.formatMessage({ id: "statusLabel" })}/>
              </Grid>
              <Grid item xs={6} className={classes.moreVertIcon}>
                <MoreVertIcon />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.recentCaseName}>
                Case Name
              </Grid>
              <Grid item xs={12} className={classes.recentCaseClientName}>
                Client Name
              </Grid>
              <Grid item xs={12} className={classes.recentCaseTime}>
                Assigned 14 hours ago              
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default injectIntl(TaxManagementRecentCases);