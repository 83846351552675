// import { caseupdateConstant } from '../constants';
import * as caseupdateConstant from '../constants/caseupdate.constants';

const initialState = []

export function caseUpdate(state = initialState, action) {
  switch (action.type) {
    case caseupdateConstant.SELF_ASSESSMENT_NAME:
       return Object.assign({}, state, {
              sseName: action.sseName
            });
    case caseupdateConstant.SELF_ASSESSMENT_PAN:
      return Object.assign({}, state, {
        // type: 'sse-pan',
        ssePan: action.ssePan
      });
    case caseupdateConstant.SSE_DETAILS:
     return Object.assign({}, state, {
              sseDetails: action.sseDetails
            });
    default:
      return state
  }
}