import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import AddIcon from "@material-ui/icons/Add";
import TaxManagementClientTable from "../TaxManagementClientTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import FilterViewClient from "../FilterViewClient";
import Drawer from "@material-ui/core/Drawer";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link, Redirect} from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { Modal } from 'reactstrap';
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from 'react-router-dom'

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStyles from '../ThemeStyles';
import Switch from '@material-ui/core/Switch';

export default function UpdatePermissions() {
    const [ device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [ session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [checked, setChecked] = React.useState(false);
    const [businessRoles, setBusinessRoles] = React.useState([]);
    const [roleDetails, setRoleDetails] = React.useState([]);
    const dispatch = useDispatch();
    const [allActions, setAllActions] = React.useState([]);
    const [roleName, setRoleName] = React.useState("");
    const [roleShortcode, setRoleShortcode] = React.useState("");
    const [rolePermissions, setRolePermissions] = React.useState([]);
    const [newRolePermissions, setNewRolePermissions] = React.useState([]);
    const [rolePermissionDetails, setRolePermissionDetails] = React.useState({});
    const [rolePermissionsData, setRolePermissionsData] = React.useState({});
    const [actionDetails, setActionDetails] = React.useState({});
    const [changedPermissions, setChangedPermissions] = React.useState([]);
    const [refresh, setRefresh] = React.useState(0);

    const [refreshAfterSwitch, setrefreshAfterSwitch] = React.useState(false);

    const roleId = localStorage.getItem("roleId");
    const usersData = JSON.parse(localStorage.getItem("username"));
    const business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    const classes = useStyles();
    const history = useHistory();

    const [nameErr, setnameErr] = React.useState('');
    const [shortcodeErr, setshortcodeErr] = React.useState('');

    const attachPermissionData = (data) => {
        // console.log("attach permission button click");
        // console.log("usersData", usersData);
        // console.log("roleName", roleShortcode);
        // console.log("roleId", roleId);
        // console.log("business_role_id", business_role_id);
        // console.log("rolePermissionsData", rolePermissionsData);

        let user_id = JSON.parse(localStorage.getItem('user_id'));
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                "username": usersData,
                "business_role_id": data.business_role_id,
                "permissions":changedPermissions,
                "device_token": device_token,
                "session_id": session_id,
                "user_id" : user_id,
            })
        };

        fetch( process.env.REACT_APP_SET_ROLE_PERMISSIONS, requestOptions)
        .then((Response) => Response.json())
        .then( permissionDetails => {
            console.log("permissionDetails on same page***********************---->", permissionDetails.data);
            if(permissionDetails.success)
            // {
            //     setRefresh(refresh+1);
            // }
            {
                Swal.fire({
                    icon: 'success',
                    text: 'Successfully updated',
                    confirmButtonColor: 'green',
                    confirmButtonText: 'OK'
                })
            }
        })
        .catch(err => {
            console.log("error", err);
        });
    }

    const validate = () => {
        let nameErr = '';
        let shortcodeErr = '';

        if(!roleName)  {
            nameErr = 'Please Enter Role Name';
        }

        if(!roleShortcode)  {
            shortcodeErr = 'Please Enter Role Shortcode';
        }

        if( nameErr || shortcodeErr ){
            setnameErr(nameErr);
            setshortcodeErr(shortcodeErr);
      
            return false;
        }
        return true;
    }

    const permissionUpdateBtn = () => {
        // console.log("permission button click");
        // console.log("usersData", usersData);
        // console.log("roleName", roleShortcode);
        console.log("roleId", roleId);
        // console.log("business_role_id", business_role_id);
        // console.log("rolePermissionsData", rolePermissionsData);
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));
        let user_id = JSON.parse(localStorage.getItem('user_id'));

        const isValid = validate();
        if(isValid){

            //console.log("values ====>");
            if(roleId == "new"){
                    console.log("inside if new");
                    const requestOptions = {
                        method: 'POST',
                        headers: authHeader(),
                        body: JSON.stringify({
                            "name": roleName,
                            "shortcode": roleShortcode,
                            "business_id": business_id,
                            "device_token": device_token,
                            "session_id": session_id,
                        })
                    };
                    console.log("requestOptions---------->", requestOptions);
                    fetch( process.env.REACT_APP_CREATE_BUSINESS_ROLE, requestOptions)
                    .then((Response) => Response.json())
                    .then( businessRolesDetails => {
                        console.log("businessRolesDetails on same page***********************---->", businessRolesDetails.data.role_info);
                        if(businessRolesDetails.success)
                        // {
                        //     setRefresh(refresh+1);
                        // }
                        {
                            attachPermissionData(businessRolesDetails.data.role_info);
                            history.push(`/roles`);
                            // Swal.fire({
                            //     icon: 'success',
                            //     text: 'Successfully updated',
                            //     confirmButtonColor: 'green',
                            //     confirmButtonText: 'OK'
                            // })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: "failed",
                                confirmButtonColor: 'red',
                                confirmButtonText: 'OK'
                            })
                        }
                    })
                    .catch(err => {
                        console.log("error", err);
                    });
            } 
            else {
                console.log("inside else new");
                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify({
                        "username": usersData,
                        "business_role_id": roleId,
                        "permissions": changedPermissions,
                        "device_token": device_token,
                        "session_id": session_id,
                        "user_id" : user_id,
                    })
                };

                fetch( process.env.REACT_APP_SET_ROLE_PERMISSIONS, requestOptions)
                .then((Response) => Response.json())
                .then( permissionDetails => {
                    console.log("permissionDetails on same page***********************---->", permissionDetails.data);
                    if(permissionDetails.success)
                    // {
                    //     setRefresh(refresh+1);
                    // }
                    {
                        Swal.fire({
                            icon: 'success',
                            text: 'Successfully updated',
                            confirmButtonColor: 'green',
                            confirmButtonText: 'OK'
                        })
                    }
                    history.push(`/roles`);
                })
                .catch(err => {
                    console.log("error", err);
                });
            }
        }
        else{
            Swal.fire({
                icon: 'error',
                text: "Missed Name or Shortcode",
                confirmButtonColor: 'red',
                confirmButtonText: 'OK'
            })
        }
    }

    const handleChange = (e, action, category) => {
        //console.log(rolePermissions, action, category, "PERMISSION UPDATE ====>");
        console.log("PERMISSION UPDATE ====>",e, action, category );
        console.log("PERMISSION UPDATE e.target.value ====>",e.target.checked );
        

        // for api may be
        const buffChangedData = changedPermissions;

        if(action.action_shortcode == "all"){
            setrefreshAfterSwitch(true)
            buffChangedData.map((l) => {
                console.log("buffChangedData is",l)
                if(l.action_category_name == category.action_category_name){
                    console.log("buffChangedData category matched true",l)
                    if( e.target.checked == true){
                        l.has_permission = true;
                    }
                    else if( e.target.checked == false){
                        console.log("buffChangedData category matched false",l)
                        l.has_permission = false;
                    }
                }
            });
        }
        else{
            buffChangedData.map((l) => {
                if(l.action_shortcode == action.action_shortcode){
                    l.has_permission = !l.has_permission;
                }
            });
        }
        setChangedPermissions(changedPermissions);
        console.log("changedPermissions in handle change",changedPermissions)
        console.log("buffChangedData in handle change",buffChangedData)
        

        // for ui may be
        const newRolePermissions = rolePermissions;
        

        if(action.action_shortcode == "all"){
            setrefreshAfterSwitch(true)
            // setRolePermissions(
            newRolePermissions.map((m,n) => {
                console.log("newRolePermissions map is 1st",m)
                if(m.action_category_name == category.action_category_name){
                    m.actions.map((o, p)=> {
                        console.log("newRolePermissions map is 2nd",o)
                        if(e.target.checked == true){
                            o.has_permission = true;
                        }
                        else if(e.target.checked == false){
                            o.has_permission = false;
                        }
                    })
                }
                
            })
            // )
            
            
        }
        else{
            newRolePermissions.map((m,n) => {
                if(m.action_category_shortcode == category.action_category_shortcode) {
                    m.actions.map((o, p)=> {
                        if(o.action_shortcode == action.action_shortcode){
                                o.has_permission = !o.has_permission;
                        }
                    })
                }
            });
        }

        // newRolePermissions.map((m,n) => {
        //     if(m.action_category_name == category.action_category_name){
        //         m.actions.map((o, p)=> {
        //             if(action.action_shortcode == "all"){
        //                 if(e.target.checked == true){
        //                     o.has_permission = true;
        //                 }
        //                 else if(e.target.checked == false){
        //                     o.has_permission = false;
        //                 }
        //             }
        //             else{
        //                 if(o.action_shortcode == action.action_shortcode){
        //                     o.has_permission = !o.has_permission;
        //                 }
        //             }
        //         })
        //     }
        // })

        console.log("role_permission_list--------->///",newRolePermissions );
        console.log("ChangedPermissions--------->///", changedPermissions);

        setRolePermissions(newRolePermissions);
        setRefresh(refresh+1);
        setrefreshAfterSwitch(false)

    }

    useEffect(() => {

        dispatch(userActions.checkSessionValidity());

        let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
        console.log("business_id clientDetails on same page",business_id)
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));
        console.log("roleId in return of update permissions", roleId);

        if(roleId == "new"){
            //if(roleName!==null && roleShortcode!==null){
                const requestOptions = {
                    method: 'POST',
                    headers: authHeader(),
                    body: JSON.stringify({
                        'business_id': business_id,
                        'device_token': device_token,
                        'session_id': session_id
                    })
                };

                fetch( process.env.REACT_APP_GET_ALL_ACTIONS, requestOptions)
                .then((Response) => Response.json())
                .then( actionsDetailsData => {
                    console.log("actionDetails on same page----------------------999999999, ", actionsDetailsData);
                    if(actionsDetailsData.success)
                    {
                        //setAllActions(actionsDetailsData.data.action_categories)
                        // setRolePermissions(actionsDetailsData.data.action_categories);
                        setChangedPermissions(actionsDetailsData.data.permissions);

                        // let allIs = {
                        //     "action_name": "All",
                        //     "action_shortcode": "all",
                        //     "has_permission":false
                        // }
                        let arrayWithAll = actionsDetailsData.data.action_categories
                        arrayWithAll.map((item, index)=>{
                            console.log("for adding all in each 3 category", item, index)
                            console.log("actions item is", item.actions)
                            if(item.action_category_name === "Clients"){
                                item.actions.map((iActions,eActions)=>{
                                    console.log("iActions is",iActions)
                                        if(eActions === 0 ){
                                            // item.actions.push(allIs);
                                            item.actions.splice(0,0, {"action_name": "All",
                                                "action_shortcode": "all",
                                                // "has_permission":false,
                                                "action_category_name":"Clients",
                                                "action_category_shortcode": "clients"});
                                        }
                                })
                            }
                            if(item.action_category_name === "Cases"){
                                item.actions.map((iActions,eActions)=>{
                                    console.log("iActions is",iActions)
                                        if(eActions === 0 ){
                                            // item.actions.push(allIs);
                                            item.actions.splice(0,0, {"action_name": "All",
                                                "action_shortcode": "all",
                                                // "has_permission":false,
                                                "action_category_name":"Cases",
                                                "action_category_shortcode": "cases"});
                                        }
                                })
                            }
                            if(item.action_category_name === "Admin"){
                                item.actions.map((iActions,eActions)=>{
                                    console.log("iActions is",iActions)
                                        if(eActions === 0 ){
                                            // item.actions.push(allIs);
                                            item.actions.splice(0,0, {"action_name": "All",
                                                "action_shortcode": "all",
                                                // "has_permission":false,
                                                "action_category_name":"Admin",
                                                "action_category_shortcode": "admin"});
                                        }
                                })
                            }
                           
                        })
                        console.log("arrayWithAll after push is",arrayWithAll);
                        setRolePermissions(arrayWithAll);

                        
                    }
                    if(roleDetails.success == false){
                        setAllActions([])
                        Swal.fire({
                            icon: 'error',
                            text: actionDetails.errors,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(err => {
                    Swal.fire({
                        icon: 'error',
                        // text: "Something went wrong",
                        text: "actions not found",
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    });
                });

        } else {
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    'business_id': business_id,
                    'business_role_id': roleId ,
                    'device_token': device_token,
                    'session_id': session_id
                })
            };

            fetch( process.env.REACT_APP_GET_ROLE_PERMISSIONS, requestOptions)
            .then((Response) => Response.json())
            .then( rolePermissionsDetails => {
                console.log("rolePermissionDetails on same page ==>", rolePermissionsDetails);
                console.log("CHECK HERE=======>", rolePermissionsDetails.data.role_permissions_category);
                if(rolePermissionsDetails.success)
                {
                    setRolePermissionsData(rolePermissionsDetails.data);
                    setChangedPermissions(rolePermissionsDetails.data.role_permissions);
                    
                    //setNewRolePermissions(rolePermissionsDetails.data.role_permissions_category);
                    setRoleName(rolePermissionsDetails.data.role_info.role_name);
                    setRoleShortcode(rolePermissionsDetails.data.role_info.role_shortcode);

                    // this is array for ui mapping 
                    // if( rolePermissionsDetails.data.role_info.role_shortcode === "business-admin" || "team-member" ){
                    //     rolePermissionsDetails.data.role_permissions_category.map((item,index)=>{
                    //         console.log("in map rolePermissionsDetails.data.role_permissions_category", item)
                    //         if( item.action_category_shortcode === "admin" ){
                    //             // console.log("its a admin", item, index)
                    //             item.actions.filter(item => item.action_shortcode !== "roles").map((itemFiltered, indexFiltered) =>(
                    //                 console.log("filtered array is", itemFiltered)
                    //             ))
                    //         }
                    //     })
                    //     // setRolePermissions(rolePermissionsDetails.data.role_permissions_category);
                    // }
                    // else{
                        // setRolePermissions(rolePermissionsDetails.data.role_permissions_category);
                    // }

                    // setRolePermissions(rolePermissionsDetails.data.role_permissions_category);

                    // for adding all in each 3 category
                    // let allIs = {
                    //     "action_name": "All",
                    //     "action_shortcode": "all",
                    //     "has_permission":false
                    // }
                    let arrayWithAll = rolePermissionsDetails.data.role_permissions_category
                    arrayWithAll.map((item, index)=>{
                        console.log("for adding all in each 3 category", item, index)
                        console.log("actions item is", item.actions)
                       item.actions.map((iActions,eActions)=>{
                        console.log("iActions is",iActions)

                        if(item.action_category_name === "Clients"){
                            if(eActions === 0 ){
                                // item.actions.push(allIs);
                                item.actions.splice(0,0, {"action_name": "All",
                                "action_shortcode": "all",
                                "has_permission":false,
                                "action_category_name":"Clients",
                                "action_category_shortcode": "clients"});
                            }
                        }
                        if(item.action_category_name === "Cases"){
                            if(eActions === 0 ){
                                // item.actions.push(allIs);
                                item.actions.splice(0,0, {"action_name": "All",
                                "action_shortcode": "all",
                                "has_permission":false,
                                "action_category_name":"Cases",
                                "action_category_shortcode": "cases"});
                            }
                        }
                        if(item.action_category_name === "Admin"){
                            if(eActions === 0 ){
                                // item.actions.push(allIs);
                                item.actions.splice(0,0, {"action_name": "All",
                                "action_shortcode": "all",
                                "has_permission":false,
                                "action_category_name":"Admin",
                                "action_category_shortcode": "admin"});
                            }
                        }     
                        // arrayWithAll.push({
                        //     "action_name": "All",
                        //     "action_shortcode": "all",
                        // });                            
                       })
                        
                    })
                    console.log("arrayWithAll after push is",arrayWithAll);
                    setRolePermissions(arrayWithAll);

                }
                if(roleDetails.success == false){
                    setRolePermissionsData([]);
                    Swal.fire({
                        icon: 'error',
                        text: rolePermissionDetails.errors,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
            .catch(err => {
                console.log("error", err);
                //Swal.fire({
                //    icon: 'error',
                //    // text: "Something went wrong",
                //    text: "Server Error. Please try again.",
                //    confirmButtonColor: '#d33',
                //    confirmButtonText: 'OK'
                //});
            });
        }
    }, []);

    useEffect(() => { console.log("role permissions updated");},[refresh])

  return (
    console.log("roleId in permissions update roleId",roleId, localStorage.getItem("roleId")),
    console.log("rolePermissions in return",rolePermissions),
    console.log("changedPermissions in return",changedPermissions),
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Grid container>
                    <Grid item xs={12} className={classes.pageHeaderLabel} >
                    <Button href="/roles" className={classes.backButton}>
                        <span className={classes.backArrow}>
                        <ArrowBackIcon fontSize="small" />
                        </span>
                        Back
                    </Button>
                    </Grid>
                </Grid>
                <br/>
                <Grid  container  className={classes.pageHeaderContainer}>
                    <Grid item xs={10} className={classes.pageHeaderLabel}>
                        Role Permissions<br/>
                    </Grid>
                </Grid>
                    <div style={{ backgroundColor: '#fff', padding: '4%', marginTop:'-2%',}}>
                            {/* <br/> */}
                            <b style={{fontSize:'16px', color: '#000'}}>Role Details</b>
                            <br/><br/>
                            <Grid container>
                                <Grid xs={6}>
                                    <span style={{fontSize:'14px', color: '#000'}}>Name</span><br/><br/>
                                    <input  style={{fontSize:'14px', color: '#000'}} 
                                        disabled={( roleId === 'new' ? false : true)}
                                        type="text" value={roleName} 
                                        onChange={(e) => {setRoleName(e.target.value)}} 
                                    />
                                    <div className={classes.validationMaxTopMargin}>{(roleName)?(<div></div>):(nameErr)}</div>
                                </Grid>
                                
                                <Grid xs={6}>
                                    <span style={{fontSize:'14px', color: '#000'}}>Shortcode</span><br/><br/>
                                    <input style={{fontSize:'14px', color: '#000'}} 
                                        disabled={( roleId === 'new' ? false : true)}
                                        type="text" value={roleShortcode} 
                                        onChange={(e) => {setRoleShortcode(e.target.value)}} 
                                    />
                                    <div className={classes.validationMaxTopMargin}>{(roleShortcode)?(<div></div>):(shortcodeErr)}</div>
                                </Grid>
                                

                            </Grid>
                            
                            <br/><br/>

                            <b style={{fontSize:'16px', color: '#000'}}>Permissions</b>
                            <br/>
                            { rolePermissions && rolePermissions.map((i, j) => (
                                <div style={{'padding':'2%', alignItems:'center'}}>
                                    <h2 style={{ fontSize:'18px', color:'#000'}}>{i.action_category_name}</h2>
                                    <br/>
                                    <Grid container>
                                        <Grid item xs={6} style={{ fontSize:'16px', color:'#000'}}>Action </Grid>
                                        <Grid item xs={6} style={{ fontSize:'16px', color:'#000'}}> Permission </Grid>
                                    </Grid>
                                    <br/>

                                    {
                                        ( refreshAfterSwitch == false )?
                                        (
                                            <Grid>
                                                {
                                                    ( roleShortcode  === "business-admin" || roleShortcode  === "team-member" )?
                                                    (
                                                        <Grid>
                                                            {i.actions.filter(k => k.action_shortcode !== "roles").map((k, l) => (
                                                                <Grid container>
                                                                    {console.log("in 1  st if",roleShortcode)}
                                                                    <Grid item xs={6} style={{ fontSize:'14px', color:'rgba(0,0,0,0.5)'}}> {k.action_name}</Grid>
                                                                    <Grid item xs={6}>
                                                                        {/* {k.has_permission === "not-set" &&
                                                                            <Switch
                                                                                key={k}
                                                                                checked={false}
                                                                                onChange={(e) => {handleChange(e, k, i)}}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />
                                                                        }
                                                                        {k.has_permission !== "not-set" && */}
                                                                            <Switch
                                                                                key={k}
                                                                                checked={k.has_permission}
                                                                                onChange={(e) => {handleChange(e, k, i)}}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />
                                                                        {/* } */}
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    ):
                                                    (
                                                        <Grid>
                                                            {i.actions.map((k, l) => (
                                                                <Grid container>
                                                                    {console.log("in 2 nd st if",roleShortcode)}
                                                                    <Grid item xs={6} style={{ fontSize:'14px', color:'rgba(0,0,0,0.5)'}}> {k.action_name}</Grid>
                                                                    <Grid item xs={6}>
                                                                        {/* {k.has_permission === "not-set" &&
                                                                            <Switch
                                                                                key={k}
                                                                                checked={false}
                                                                                onChange={(e) => {handleChange(e, k, i)}}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />
                                                                        }
                                                                        {k.has_permission !== "not-set" && */}
                                                                            <Switch
                                                                                key={k}
                                                                                checked={k.has_permission}
                                                                                onChange={(e) => {handleChange(e, k, i)}}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />
                                                                        {/* } */}
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        ):
                                        (<Grid></Grid>)
                                    }

                                    

                                    
                                </div>
                            ))}

                            <hr style={{ marginTop:'1.5%', color:'black', width: '100%',}}/>
                            <br/><br/>
                            <span style={{backgroundColor:'blue', padding:'2%', color:'#fff', fontSize:'14px', cursor:'pointer'}} onClick={permissionUpdateBtn} >SUBMIT</span>
                        </div>

                    
            </div>
        </div>
  );
}