import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
  clientDetailInfo: {
    textAlign: "left",
  }
}));



function TaxManagementClientDetails({ handleChange, intl }) {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={4} className={classes.clientDetailInfo}>
          <Grid container>
            <Grid item xs={12}>
              <Button onClick={handleChange} className={classes.backButton}>
                <span className={classes.backArrow}>
                  <ArrowBackIcon fontSize="small" />
                </span>
                <FormattedMessage id="backLabel" />
              </Button>
            </Grid>
            </Grid>
        </Grid>
        <Grid item xs={8}>
        </Grid>
      </Grid>
    </div>
  );
}

export default injectIntl(TaxManagementClientDetails);
