// Collapse table trial on processed.js

import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import BackupIcon from "@material-ui/icons/Backup";
import TaxManagementClientTable from "../TaxManagementClientTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import FilterViewClient from "../FilterViewClient";
import Drawer from "@material-ui/core/Drawer";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link, Redirect } from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useHistory } from 'react-router-dom'

import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStyles from '../ThemeStyles';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(ClientPendingZipData, comparator) {
  const stabilizedThis = ClientPendingZipData && ClientPendingZipData.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'arrow', numeric: false, label: '', },
  { id: 'file_id', numeric: false, label: 'Id', },
  { id: 'client_file_type', numeric: false, label: 'File Type', },
  { id: 'client_zip_file', numeric: false, label: 'File Name', },
  { id: 'file_state', numeric: false, label: 'File Status', },
  { id: 'total_items', numeric: false, label: 'Total Items', },
  { id: 'total_success', numeric: false, label: 'Total Success', },
  { id: 'total_failed', numeric: false, label: 'Total Fail', },
  { id: 'actions', numeric: false, label: 'Actions', },
];





function createData(name, calories, fat, carbs, protein, detail) {
  return { name, calories, fat, carbs, protein, detail };
}

const rows = [
  createData(
    'Frozen yoghurt',
    159,
    6.0,
    24,
    4.0,
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
  ),
  createData(
    'Ice cream sandwich',
    237,
    9.0,
    37,
    4.3,
    'Maecenas rutrum urna vel lacus viverra, id ultrices dui rutrum'
  ),
  createData(
    'Eclair',
    262,
    16.0,
    24,
    6.0,
    'Morbi congue gravida nunc, eu cursus felis vulputate id'
  ),
  createData(
    'Cupcake',
    305,
    3.7,
    67,
    4.3,
    'Vestibulum efficitur, ipsum consectetur finibus maximus, ligula dolor vehicula ex, sed viverra nulla mauris id purus'
  ),
  createData(
    'Gingerbread',
    356,
    16.0,
    49,
    3.9,
    ' Suspendisse vehicula eu libero eget viverra'
  )
];

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps} >
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow  >
          <TableCell padding="checkbox" />
          <TableCell padding="checkbox" />
          <TableCell padding="checkbox" />
          {/* // <> */}

          {expandComponent}

          {/* </> */}

        </TableRow>
      )}
    </>
  );
};

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (headCell.id === "actions") ?
            (
              <TableCell
                key={headCell.id}
                align="center"
                // style={{ fontWeight: 'bold', fontSize: 17, width: "1%" }}
                style={{ fontWeight: 'bold', fontSize: 17, }}
              >
                {headCell.label}
              </TableCell>
            )
            : (
              (headCell.id === "arrow") ?
                (
                  <TableCell
                    key={headCell.id}
                    align="center"
                    style={{ fontWeight: 'bold', fontSize: 17, width: "0%" }}
                  >
                    {headCell.label}
                  </TableCell>
                )
                : (
                  <TableCell
                    key={headCell.id}
                    // align={headCell.numeric ? 'center' : 'center'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    align="center"
                    // style={{ fontWeight: 'bold', fontSize: 17, width: "10%" }}
                    style={{ fontWeight: 'bold', fontSize: 17, }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                )

            )

        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export default function Processed(props) {
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [businessRoles, setBusinessRoles] = React.useState([]);
  const [roleDetails, setRoleDetails] = React.useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [ClientPendingZipData, setClientPendingZipData] = React.useState([]);
  const [Flag, setFlag] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
  ];

  // sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, ClientPendingZipData && ClientPendingZipData.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setFlag(true);
    setShowProcedure(true);
    dispatch(userActions.checkSessionValidity());

    getData();
  }, [Flag]);

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {/* {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.file_id}
          </TableCell>
          <TableCell align="center">{row.client_file_type}</TableCell>
          <TableCell align="center">{row.client_zip_file.substring(row.client_zip_file.lastIndexOf('/') + 1)}</TableCell>
          <TableCell align="center">{row.file_state}</TableCell>
          <TableCell align="center">{row.total_items}</TableCell>
          <TableCell align="center">{row.total_success}</TableCell>
          <TableCell align="center">{row.total_failed}</TableCell>
          <TableCell width="5%" align="center" >
            <Button
              size="small"
              variant="contained"
              color="primary"
              // className={classes.button}
              disabled={row.file_state === "extracted" || row.file_state === "finished" ? false : true}
              onClick={() => handleClickInvitedAllFile(row)}
            >
              {
                (row.client_zip_file.split('.').pop() === "zip" || "xlsx" || "xls") ?
                  (
                    <>
                      Invite All
                    </>
                  ) :
                  (
                    (row.client_zip_file.split('.').pop() === "json") ?
                      (
                        <>
                          Invite
                        </>
                      ) :
                      (<></>)

                  )
              }
              {/* Invite */}
            </Button>
          </TableCell>

        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {
                  (row.client_file_type === "excel")?
                  (
                    <>
                      {/* <Typography gutterBottom component="div">
                        Excel: {row.file_id}
                      </Typography> */}
                      {/* {row.extracted_clients.map(item2 => (
                        <> */}
                          <Table size="small" aria-label="purchases" style={{marginLeft:'8%'}}>
                                  <TableHead>
                                    <TableRow >
                                      <TableCell width="5%" align="center" style={{ fontWeight: 'bold' }} >Row Id</TableCell>
                                      <TableCell width="10%" align="center" style={{ fontWeight: 'bold' }}>File Status</TableCell>
                                      <TableCell width="5%" align="center" style={{ fontWeight: 'bold' }}>Client Id</TableCell>
                                      <TableCell width="20%" align="center" style={{ fontWeight: 'bold' }}>Client Name</TableCell>
                                      <TableCell width="20%" align="center" style={{ fontWeight: 'bold' }}>Reason</TableCell>
                                      <TableCell width="5%" align="center" style={{ fontWeight: 'bold' }}>Invited</TableCell>
                                      <TableCell width="10%" align="center" style={{ fontWeight: 'bold' }}>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {row.extracted_clients.map(item2 => (
                                      console.log("row for expand is in rrow.extracted_files.map", item2),
                                      <TableBody>
                                        <TableRow>
                                          <TableCell width="5%" align="center" >{item2.row_no}</TableCell>
                                          <TableCell width="10%" align="center" >{item2.row_status === true ? "Processed" : "Failed"}</TableCell>
                                          <TableCell width="5%" align="center" >{item2.client_info.client_id}</TableCell>
                                          <TableCell width="20%" align="center" >{item2.client_info.client_name}</TableCell>
                                          <TableCell width="20%" align="center" >{item2.row_status === true ? "-" : item2.row_error }</TableCell>
                                          {/* <TableCell width="20%" align="center" >{item2.row_status === true ? item2.client_info.client_name : "Reason : "+item2.row_error }</TableCell> */}
                                          <TableCell width="5%" align="center" >{item2.email_sent === true ? "Yes" : "No"}</TableCell>
                                          <TableCell width="10%" align="center" >
                                            {
                                              (item2.row_status === true)?
                                              (
                                                <Button
                                                  size="small"
                                                  variant="contained"
                                                  color="primary"
                                                  className={classes.button}                                              
                                                  // disabled={item2.extracted_file_state === "finished" ? false : true}
                                                  onClick={() => handleClickInvitedSingle(item2)}
                                                >
                                                  Invite
                                                </Button>
                                              ):
                                              (<></>)
                                            }
                                            
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    ))}

                                </Table>
                                <Grid style={{marginTop:'2%'}}></Grid>
                        {/* </>
                      ))} */}
                    </>
                  ):
                  (
                    (
                      (row.client_file_type === "zip")?
                      (
                        <>
                           {/* <Typography variant="h6" gutterBottom component="div">
                            Zip
                          </Typography> */}
                          <Table size="small" aria-label="purchases" style={{marginLeft:'8%'}}>
                          <TableHead>
                            <TableRow>
                              <TableCell width="5%" align="center" style={{ fontWeight: 'bold' }}>File Id</TableCell>
                              <TableCell width="15%" align="center" style={{ fontWeight: 'bold' }}>File Name</TableCell>
                              <TableCell width="5%" align="center" style={{ fontWeight: 'bold' }}>File State</TableCell>
                              <TableCell width="10%" align="center" style={{ fontWeight: 'bold' }}>Action</TableCell>
                            </TableRow>
                           </TableHead>
                            {row.extracted_files.map(item2 => (
                                console.log("row for expand is in rrow.extracted_files.map", item2),
                                <TableBody>
                                  <TableRow>
                                    <TableCell width="5%" align="center" >{item2.extracted_file_id}</TableCell>
                                    {/* <TableCell width="10%" align="center" >{item2.extracted_file}</TableCell> */}
                                    <TableCell width="15%" align="center" >{item2.extracted_file.substring(item2.extracted_file.lastIndexOf('/') + 1)}</TableCell>
                                    <TableCell width="5%" align="center" >{item2.extracted_file_state}</TableCell>
                                    <TableCell width="10%" align="center" >
                                      <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        // disabled={i.isEditable == false ? true : false}
                                        // disabled = {item2.extracted_file_state == "invite-sent" ? true : false}
                                        // disabled={item2.extracted_file_state === "finished" && item.file_state === "extracted" ? false : true}
                                        disabled={item2.extracted_file_state === "finished" ? false : true}
                                        onClick={() => handleClickInvitedSingle(item2)}
                                      >
                                        Invite
                                      </Button>
                                    </TableCell>

                                  </TableRow>
                                </TableBody>
                              ))}

                          </Table>
                          <Grid style={{marginTop:'2%'}}></Grid>
                        </>
                      ):
                      (
                        <></>
                      )
                    )
                  )
                }
               
                {/* <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.extracted_clients.map((historyRow) => (
                      <TableRow >
                        {console.log("historyRow is", historyRow)}
                        <TableCell component="th" scope="row">
                          {historyRow.row_no}
                        </TableCell>
                        <TableCell>{historyRow.row_status}</TableCell>
                        <TableCell>{historyRow.row_error}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table> */}

              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const getData = () => {
    console.log("in get data of uploded processed")

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'file_state': 'extracted, finished, invites-queued, invites-processed', 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    return fetch(process.env.REACT_APP_GET_CLIENT_ZIP_FILES, requestOptions)
      .then((Response) => Response.json())
      .then(checkResponseClientZipDetails => {
        setShowProcedure(false);
        console.log("checkResponseClientZipDetails====", checkResponseClientZipDetails)
        setClientPendingZipData(checkResponseClientZipDetails.data.clientzips)

      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error (s3). Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleClickInvitedAllFile = (item) => {
    console.log("handleClickInvitedAllFile is cliecked", item, item.file_id)
    setShowProcedure(true);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_clientzip_id': item.file_id,
        'device_token': device_token, 'session_id': session_id
      })
    }
    return fetch(process.env.REACT_APP_BULK_INVITE_CLIENT_EXTRACTED, requestOptions)
      .then((Response) => Response.json())
      .then(BulkInvites => {
        console.log("BulkInvites ", BulkInvites);
        setShowProcedure(false);
        if (BulkInvites.success == true) {
          setFlag(false)
          Swal.fire({
            icon: 'success',
            text: "Client Invited",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
        if (BulkInvites.success == false) {
          Swal.fire({
            icon: 'error',
            text: BulkInvites.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleClickInvitedSingle = (item) => {
    console.log("handleClickInvitedSingle is clicked", item, item.extracted_file_id, item.row_no)
    setShowProcedure(true);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'extracted_client_file_id': item.extracted_file_id,
        'extracted_excel_row_id': item.row_no,
        'device_token': device_token, 'session_id': session_id
      })
    }
    return fetch(process.env.REACT_APP_INVITE_CLIENT_EXTRACTED, requestOptions)
      .then((Response) => Response.json())
      .then(SingleInvite => {
        console.log("SingleInvite ", SingleInvite);
        setShowProcedure(false);
        if (SingleInvite.success == true) {
          setFlag(false)
          Swal.fire({
            icon: 'success',
            text: "Clients Invited",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
        if (SingleInvite.success == false) {
          Swal.fire({
            icon: 'error',
            text: SingleInvite.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  return (
    <div >
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
      </modal> : null}
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.pageHeaderContainer}>
            <Grid item xs={8} className={classes.pageHeaderLabel}>
              PROCESSED

            </Grid>

            <Grid style={{ marginTop: '2%' }}>
              <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="collapsible table">
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={ClientPendingZipData && ClientPendingZipData.length}
                  />
                  {/* <TableBody> */}
                  {stableSort(ClientPendingZipData && ClientPendingZipData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      // console.log("row in table map",item)
                      console.log("extention is", item.client_zip_file.split('.').pop())
                      return (
                        <TableBody>

                          <Row key={item.file_id} row={item} />

                          {/* <TableCell width="5%" align="center" >
                              {item.file_id}
                            </TableCell>
                            <TableCell width="10%" align="center" >{item.client_file_type}</TableCell>
                            <TableCell width="10%" align="center" >{item.client_zip_file.substring(item.client_zip_file.lastIndexOf('/')+1)}</TableCell>
                            <TableCell width="10%" align="center" >{item.file_state}</TableCell>
                            <TableCell width="10%" align="center" >{item.total_items}</TableCell>
                            <TableCell width="10%" align="center" >{item.total_success}</TableCell>
                            <TableCell width="10%" align="center" >{item.total_failed}</TableCell>
                            <TableCell width="5%" align="center" >
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={item.file_state === "extracted" || item.file_state === "finished" ? false : true}
                                onClick={() => handleClickInvitedAllFile(item)}
                              >
                                {
                                  (item.client_zip_file.split('.').pop() === "zip" || "xlsx" || "xls" )?
                                  (
                                    <>
                                      Invite All
                                    </>
                                  ):
                                  (
                                    (item.client_zip_file.split('.').pop() === "json")?
                                    (
                                      <>
                                        Invite 
                                      </>
                                    ):
                                    (<></>)
                                    
                                  )
                                }
                               
                              </Button>
                            </TableCell> */}

                        </TableBody>
                      );
                    })}


                  {/* <TableBody>
                                {ClientPendingZipData && ClientPendingZipData.map(row => (
                                    <ExpandableTableRow
                                    key={row.name}
                                    expandComponent={<TableCell colSpan="5">{row.file_state}</TableCell>}
                                    >
                                    <TableCell component="th" scope="row">
                                        {row.file_state}
                                    </TableCell>
                                    <TableCell align="right">{row.file_id}</TableCell>
                                    <TableCell align="right">{row.client_zip_file}</TableCell>
                                    </ExpandableTableRow>
                                ))}
                                </TableBody>  */}

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: ClientPendingZipData && ClientPendingZipData.length }]}
                        colSpan={8}
                        count={ClientPendingZipData && ClientPendingZipData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <Table className={classes.table} aria-label="simple table">

              </Table>
            </Grid>
          </Grid>
          {/* UPLAODED */}
        </Grid>
      </div>
    </div>
  )
}
