import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GroupIcon from "@material-ui/icons/Group";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "500px",
    marginTop: "5px",
    marginBottom: "25px",
  },
}));

export default function CasedetailOverviewDescription() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={4}>
          <CheckBoxIcon
            fontSize="small"
            style={{ marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
          ></CheckBoxIcon>
          <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >0/12</div>
        </Grid>
        <Grid item xs={4}>
          <AccessTimeIcon
            fontSize="small"
            style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32);" }}
          ></AccessTimeIcon>
          <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >No due date</div>
        </Grid>
        <Grid item xs={4}>
          <GroupIcon
            fontSize="small"
            style={{  marginRight:"5px", color: "rgba(0, 0, 0, 0.32);" }}
          ></GroupIcon>
          <div style={{ marginRight:"5px",  color: "rgba(0, 0, 0, 0.32)", display:"inline-block", verticalAlign: "top", fontSize: "14px" }} >Unassigned</div>
        </Grid>
      </Grid>
    </div>
  );
}
