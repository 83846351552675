import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
    width:'100%',
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  formControlLevel: {
    // margin: theme.spacing(1),
    marginTop: '1%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function Questions(serviceListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [questionDetails, setquestionDetails] = React.useState({
        service_id: "",
        title: "",
        qtype: "",
        level: "",
        is_active: "",
        is_default: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });

    const [singleOrMultipleAns, setsingleOrMultipleAns] = React.useState(false); 

    const [optionOne, setoptionOne] = React.useState("");
    const [optionTwo, setoptionTwo] = React.useState("");
    const [optionThree, setoptionThree] = React.useState("");
    const [optionFour, setoptionFour] = React.useState("");
    const [optionsDetails, setoptionsDetails] = React.useState([]);
    // const [optionsDetails, setoptionsDetails] = React.useState([optionOne, optionTwo, optionThree, optionFour]);
    const [ optionOneErr, setoptionOneErr ] = React.useState();
    const [ optionTwoErr, setoptionTwoErr ] = React.useState();
    const [ optionThreeErr, setoptionThreeErr ] = React.useState();
    const [ optionFourErr, setoptionFourErr ] = React.useState();

    const [trueFalse, settrueFalse] = React.useState([
      { value: true , name: 'True'  },
      { value: false , name: 'False'  },
    ]);
    const [serviceIdErr, setserviceIdErr] = React.useState();
    const [titleErr, settitleErr] = React.useState();
    const [qtypeErr, setqtypeErr] = React.useState();
    const [levelErr, setlevelErr] = React.useState();
    const [isActiveErr, setisActiveErr] = React.useState();
    const [isDefaultErr, setisDefaultErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
    }, [flag, open, openBulk]);

    const handelCreateQuestionOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateQuestionClose = () => {
      setOpen(false);
      setquestionDetails({
        service_id: "",
        title: "",
        qtype: "",
        level: "",
        is_active: "",
        is_default: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setserviceIdErr();
      settitleErr();
      setqtypeErr();
      setlevelErr();
      setisActiveErr();
      setisDefaultErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateQuestionClear = async() => {
      await setquestionDetails({
        service_id: "",
        title: "",
        qtype: "",
        level: "",
        is_active: "",
        is_default: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      setoptionOne();
      setoptionTwo();
      setoptionThree();
      setoptionFour();

      setserviceIdErr();
      settitleErr();
      setqtypeErr();
      setlevelErr();
      setisActiveErr();
      setisDefaultErr();

      setoptionOneErr();
      setoptionTwoErr();
      setoptionThreeErr();
      setoptionFourErr(); 
    }

    const handelCreateQuestionMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let serviceIdErr = '';
      let titleErr = '';
      let qtypeErr = '';
      let levelErr = '';
      let isActiveErr = '';
      let isDefaultErr = '';

      let optionOneErr = "";
      let optionTwoErr = "";
      let optionThreeErr = "";
      let optionFourErr = ""; 

      if(!questionDetails.service_id)  {
          serviceIdErr = 'Please Select Service';
      }
      if(!questionDetails.title)  {
          titleErr = 'Please Enter Title';
      }
      if(!questionDetails.qtype)  {
          qtypeErr = 'Please Select Question Type';
      }
      if(!questionDetails.level)  {
          levelErr = 'Please Enter Level';
      }
        else if (typeof questionDetails.level !== "undefined") {
          var pattern = new RegExp(/^[0-9\b]+$/)
          if (!pattern.test(questionDetails.level)) {
            // isValid = false;
            levelErr = "Please enter only number";
          }
        }

      if( questionDetails.is_active == "" && questionDetails.is_active !== false && questionDetails.is_active !== true )  {
          isActiveErr = 'Please Select Is Active';
      }
      if( questionDetails.is_default == "" && questionDetails.is_default !== false && questionDetails.is_default !== true )  {
          isDefaultErr = 'Please Select Is Default';
      }

      if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
        if(!optionOne){
          optionOneErr = 'Please Add Option One';
        }
      }

      if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
        if(!optionTwo)  {
            optionTwoErr = 'Please Add Option Two';
        }
      }

      if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
        if(!optionThree)  {
            optionThreeErr = 'Please Add Option Three';
        }
      }

      if(questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER")  {
        if(!optionFour)  {
            optionFourErr = 'Please Add Option Four';
        }
      }

      if( serviceIdErr || titleErr || qtypeErr || levelErr || isActiveErr || isDefaultErr ||
         optionOneErr || optionTwoErr || optionThreeErr || optionFourErr){
        setserviceIdErr(serviceIdErr);
        settitleErr(titleErr);
        setqtypeErr(qtypeErr);
        setlevelErr(levelErr);
        setisActiveErr(isActiveErr);
        setisDefaultErr(isDefaultErr);

        setoptionOneErr(optionOneErr);
        setoptionTwoErr(optionTwoErr);
        setoptionThreeErr(optionThreeErr);
        setoptionFourErr(optionFourErr);

        return false;
      }

      return true;

    }

    const handelSubmitCreateQustion = () => {
      // console.log("questionDetails",questionDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(questionDetails)
          };
          return fetch( process.env.REACT_APP_ADD_QUESTION , requestOptions)
              .then((Response) => Response.json())
              .then(questionCreated => {
                  setShowProcedure(false);
                  console.log("questionCreated on same page",questionCreated.data);
                  console.log("questionCreated on same page",questionCreated.success);
                  console.log("questionCreated on same page",questionCreated.data.question_info.question_id);
                  if(questionCreated.success == true){
                    console.log("(questionCreated.success == true"); 
                    if(questionCreated.data.question_info.qtype == "SINGLE-ANSWER" || 
                        questionCreated.data.question_info.qtype == "MULTIPLE-ANSWER" )
                      { 
                        console.log("single or multiple ans"); 
                        const createOptionObj = {   
                          question_id: questionCreated.data.question_info.question_id,
                          options: [optionOne, optionTwo, optionThree, optionFour ],
                          device_token: JSON.parse(localStorage.getItem('device_token')),
                          session_id: JSON.parse(localStorage.getItem('session_id')),
                        };
                        console.log("createOptionObj",createOptionObj)
                        const requestOptionsOptions = {
                          method: 'POST',
                          headers: authHeader(),
                          body: JSON.stringify(createOptionObj)
                        };                    
                        return fetch( process.env.REACT_APP_ADD_QUESTION_OPTIONS , requestOptionsOptions)
                          .then((Response) => Response.json())
                          .then(optionCreated => {
                              console.log("optionCreated on same page",optionCreated.success);
                              console.log("optionCreated on same page",optionCreated);
                              if(optionCreated.success == true){
                                Swal.fire({
                                  icon: 'success',
                                  text: "Question Added Succesfully",
                                  confirmButtonColor: 'primary',
                                  confirmButtonText: 'OK',
                                  timer: 5000,
                                })

                                setOpen(false);
                                setquestionDetails({
                                  service_id: "",
                                  title: "",
                                  qtype: "",
                                  level: "",
                                  is_active: "",
                                  is_default: "",
                                  device_token: JSON.parse(localStorage.getItem('device_token')),
                                  session_id: JSON.parse(localStorage.getItem('session_id')),
                                })
                                setoptionOne();
                                setoptionTwo();
                                setoptionThree();
                                setoptionFour();

                                setserviceIdErr();
                                settitleErr();
                                setqtypeErr();
                                setlevelErr();
                                setisActiveErr();
                                setisDefaultErr();

                                setoptionOneErr();
                                setoptionTwoErr();
                                setoptionThreeErr();
                                setoptionFourErr(); 
                              }
                              if(questionCreated.success == false){
                                Swal.fire({
                                  icon: 'error',
                                  text: optionCreated.errors,
                                  confirmButtonColor: '#d33',
                                  confirmButtonText: 'OK',
                                  timer: 5000,
                                }).then(function (result) {
                                  console.log("result of swal",result)
                                  setOpen(true);
                                })
                              }
                        })
                        .catch(err => {
                          setShowProcedure(false);
                          Swal.fire({
                            icon: 'error',
                            // text: "Something went wrong",
                            text: "Server Error. Please try again.",
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                          })
                        });
                    }
                    else if(questionCreated.data.question_info.qtype == "BOOLEAN" || 
                        questionCreated.data.question_info.qtype == "TEXT-ANSWER" )
                    {
                      console.log("boolean or text ans");
                      Swal.fire({
                        icon: 'success',
                        text: "Question Created Succesfully",
                        confirmButtonColor: 'primary',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      })
                      setOpen(false);
                      setquestionDetails({
                        service_id: "",
                        title: "",
                        qtype: "",
                        level: "",
                        is_active: "",
                        is_default: "",
                        device_token: JSON.parse(localStorage.getItem('device_token')),
                        session_id: JSON.parse(localStorage.getItem('session_id')),
                      })
                      setoptionOne();
                      setoptionTwo();
                      setoptionThree();
                      setoptionFour();

                      setserviceIdErr();
                      settitleErr();
                      setqtypeErr();
                      setlevelErr();
                      setisActiveErr();
                      setisDefaultErr();

                      setoptionOneErr();
                      setoptionTwoErr();
                      setoptionThreeErr();
                      setoptionFourErr();
                    } 
                        
                  }
                  else if(questionCreated.success == false)
                  {
                    setOpen(true);
                    Swal.fire({
                      icon: 'error',
                      text: questionCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                  }
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
      }
    }

    // const handleChangeOption = async(e) => {
    //   console.log(e.target.value)
    //   setoptionOne(e.target.value)
    //   let optionsDetails1 = optionsDetails.concat(e.target.value);
    //   console.log("doptionsDetails1 ",optionsDetails1);
    //   await setoptionsDetails(optionsDetails1)
    //   console.log("optionsDetails", optionsDetails)
    // optionsDetails.push( ...optionsDetails, optionOne)
    // }

    // const handelSubmitAddOptions = async() => {
    //   // await setoptionsDetails( [optionOne, optionTwo, optionThree, optionFour ])
    //   // console.log("optionsDetails", optionsDetails)

    //   const isValid = validateOptions();
    //   if(isValid){
    //     setsingleOrMultipleAns(true)
    //     // console.log("optionsDetails", optionsDetails)
    //     const createOptionObj = {   
    //       question_id: "1",
    //       options: [optionOne, optionTwo, optionThree, optionFour ],
    //       device_token: JSON.parse(localStorage.getItem('device_token')),
    //       session_id: JSON.parse(localStorage.getItem('session_id')),
    //     };
    //     console.log("createOptionObj",createOptionObj)
    //     const requestOptions = {
    //       method: 'POST',
    //       headers: authHeader(),
    //       body: JSON.stringify(createOptionObj)
    //       };
    //       return fetch('process.env.REACT_APP_ADD_QUESTION_OPTIONS', requestOptions)
    //           .then((Response) => Response.json())
    //           .then(optionCreated => {
    //               console.log("optionCreated on same page",optionCreated.success);
    //               console.log("optionCreated on same page",optionCreated);
    //               if(optionCreated.success == true){
    //                 Swal.fire({
    //                   icon: 'success',
    //                   text: "Options Added Succesfully",
    //                   confirmButtonColor: 'primary',
    //                   confirmButtonText: 'OK',
    //                   timer: 5000,
    //                 })
    //                 // setOpen(false);
    //                 // setoptionOne();
    //                 // setoptionTwo();
    //                 // setoptionThree();
    //                 // setoptionFour();

    //                 setoptionOneErr();
    //                 setoptionTwoErr();
    //                 setoptionThreeErr();
    //                 setoptionFourErr();               
    //               }
    //               if(optionCreated.success == false){
    //                 // setOpen(true);
    //                 Swal.fire({
    //                   icon: 'error',
    //                   text: optionCreated.errors,
    //                   confirmButtonColor: '#d33',
    //                   confirmButtonText: 'OK',
    //                   timer: 5000,
    //                 })
    //             }
              
    //           });
    //   }
      
    // }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Create Question</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateQuestionMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateQuestionClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody} style={{position:'absolute'}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              value={questionDetails.title}
              onChange={(e) => setquestionDetails({...questionDetails, title:e.target.value }) }
            />
            <div className={classes.validation}>{(questionDetails.title)?(<div></div>):(titleErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="service_id">Service*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Service*</Grid>
              <Select
                native
                labelId="service_id"
                id="service_id"
                value={questionDetails.service_id}
                onChange={(e) => setquestionDetails({...questionDetails, service_id:e.target.value }) }
              >
                {/* <MenuItem value={''}>Select Service</MenuItem>
                {serviceListDropdown.serviceListDropdown.map(item =>
                  <MenuItem value={item.category_id}>{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Category or Super Category</option>
                  {serviceListDropdown.serviceListDropdown.map(item =>
                    <option value={item.category_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(questionDetails.service_id)?(<div></div>):(serviceIdErr)}</div>
            
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="qtype">Question Type*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Question Type*</Grid>
              <Select
                native
                labelId="qtype"
                id="qtype"
                value={questionDetails.qtype}
                onChange={(e) => setquestionDetails({...questionDetails, qtype:e.target.value }) }
              >
                {/* <MenuItem value={''}>Select Question Type</MenuItem>
                <MenuItem value={'BOOLEAN'}>Boolean</MenuItem>
                <MenuItem value={'SINGLE-ANSWER'}>Single-Answer</MenuItem>
                <MenuItem value={'MULTIPLE-ANSWER'}>Multiple-Answer</MenuItem>
                <MenuItem value={'TEXT-ANSWER'}>Text-Answer</MenuItem>              */}
                <option value="" style={{color:'grey'}}>Select  Question Type</option>
                <option value="BOOLEAN" style={{color:'grey'}}>Boolean</option>
                <option value="SINGLE-ANSWER" style={{color:'grey'}}>Single-Answer</option>
                <option value="MULTIPLE-ANSWER" style={{color:'grey'}}>Multiple-Answer</option>
                <option value="TEXT-ANSWER" style={{color:'grey'}}>Text-Answer</option>
              </Select>
            </FormControl>
            {
              ( questionDetails.qtype == "SINGLE-ANSWER" || questionDetails.qtype == "MULTIPLE-ANSWER" )?(
                <div style={{marginTop:"5%", borderTop: "1px solid grey" , borderBottom:"1px solid grey",
                  borderLeft: "1px solid grey", borderRight:"1px solid grey"   }}>
                {
                  <div style={{ marginLeft:"2%", marginRight:"2%", marginBottom: "2%" }}>
                    {/* setsingleOrMultipleAns(true), */}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="optionOne"
                      label="Option One"
                      name="optionOne"
                      autoComplete="optionOne"
                      value={optionOne}
                      onChange={async(e) => await setoptionOne(e.target.value)}
                      // onChange={handleChangeOption}
                    />
                    <div className={classes.validation}>{(optionOne)?(<div></div>):(optionOneErr)}</div>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="optionTwo"
                      label="Option Two"
                      name="optionTwo"
                      autoComplete="optionTwo"
                      value={optionTwo}
                      onChange={async(e) => await setoptionTwo(e.target.value)}
                    />
                    <div className={classes.validation}>{(optionTwo)?(<div></div>):(optionTwoErr)}</div>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="optionThree"
                      label="Option Three"
                      name="optionThree"
                      autoComplete="optionThree"
                      value={optionThree}
                      onChange={async(e) => await setoptionThree(e.target.value)}
                    />
                    <div className={classes.validation}>{(optionThree)?(<div></div>):(optionThreeErr)}</div>

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="optionFour"
                      label="Option Four"
                      name="optionFour"
                      autoComplete="optionFour"
                      value={optionFour}
                      onChange={async(e) => await setoptionFour(e.target.value)}
                    />
                    <div className={classes.validation}>{(optionFour)?(<div></div>):(optionFourErr)}</div>

                    {/* <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                      <Button
                      type="submit"
                      //   fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handelSubmitAddOptions}
                      >
                        Add options
                      // </Button>
                    </div> */}
                  </div>
                }
                </div>
              ):(
                <div></div>
              )
            }
            <div className={classes.validation}>{(questionDetails.qtype)?(<div></div>):(qtypeErr)}</div>
            <FormControl className={classes.formControlLevel}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="level"
                label="Level"
                name="level"
                autoComplete="level"
                value={questionDetails.level}
                onChange={(e) => setquestionDetails({...questionDetails, level:e.target.value }) }
              />
            </FormControl>
            {/* <div className={classes.validation}>{(questionDetails.level)?(<div></div>):(levelErr)}</div> */}
            <div className={classes.validation}>{levelErr}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_active">Is Active*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(questionDetails.is_active)}
                  onChange={ (e) => setquestionDetails({...questionDetails, is_active: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_active"
                id="is_active"
                value={questionDetails.is_active}
                onChange={(e) => setquestionDetails({...questionDetails, is_active:e.target.value }) }
              >
                {trueFalse.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(questionDetails.is_active == "" &&  questionDetails.is_active !== false && questionDetails.is_active !== true)?(isActiveErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_default">Is Default*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Default*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_default"
                  aria-label="is_default"
                  name="is_default"
                  defaultValue={JSON.stringify(questionDetails.is_default)}
                  onChange={ (e) => setquestionDetails({...questionDetails, is_default: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_default"
                id="is_default"
                value={questionDetails.is_default}
                onChange={(e) => setquestionDetails({...questionDetails, is_default:e.target.value }) }
              >
                {trueFalse.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(questionDetails.is_default == "" && questionDetails.is_default !== false && questionDetails.is_default !== true)?(isDefaultErr):(<div></div>)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateQustion}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateQuestionClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelQuestionBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelQuestionBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelQuestionBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelQuestionBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitQuestionBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_QUESTIONS , requestOptions)
            .then((Response) => Response.json())
            .then( questionBulk => {
                setShowProcedure(false);
                console.log("questionBulk on same page",questionBulk.success);
                  console.log("questionBulk on same page",questionBulk);
                  if(questionBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Question Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                    
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(questionBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: questionBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
      <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Question Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelQuestionBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelQuestionBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcel(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitQuestionBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelQuestionBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
      </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Questions List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>                   
                  <Link to="QuestionList" style={{ textDecoration: 'none', color:'black' }}>
                    Question List
                  </Link>
                </Grid>
                <Grid item xs={12} onClick={handelCreateQuestionOpen} className={classes.userAddUpdateDelete}>
                    Create Question
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateQuestionClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelQuestionBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Questions
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelQuestionBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
  )
}
