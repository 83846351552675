import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import routes from "../routes";
import TaxManagementNotFound from "../TaxManagementNotFound";
import { useThemeContext, useThemeUpdate } from '../ThemeContext'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#E5E5E5",
  },
}));

export default function TaxManagementWrapper({ users, matchRoute }) {
  const classes = useStyles();
  const darkTheme = useThemeContext()

  const themeStyles = {
    // backgroundColor: darkTheme ? {marginLeft: '10%'} : '',
    // color: darkTheme ? '#CCC' : '#333',
    marginLeft: darkTheme ? '15%' : '0%',
    // marginTop: darkTheme ? '10%' : '0%',
    // padding: '2rem',
    // margin: '2rem',
  }

  return (
    <div className={classes.root} style={themeStyles}>
      <Grid container>
        <Grid item xs={12}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/search" />
            </Route>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            ))}
            <Route path="*">
              <TaxManagementNotFound />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
}
