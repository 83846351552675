import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import fetch from "cross-fetch";
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import { modal } from 'reactstrap';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
import { userActions } from "../actions";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AntSwitch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },

  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    width:'100%',
    color: theme.palette.text.secondary,
    marginTop:'-1%',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginLeft: "5%",
    marginTop: '2%',
    width: '90%',
  },
  CategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    // marginLeft: "2%",
    // marginTop: "1%",
  },
  ServiceCategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "#200a80",
    textAlign: "left",
    // marginLeft: "2%",
    // marginTop: "1%",
  },
  SubCategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "#5e0545",
    textAlign: "left",
    // marginLeft: "2%",
    // marginTop: "1%",
  },
  ServiceForSubCategoryLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "20px",
    color: "#09874a",
    textAlign: "left",
    // marginLeft: "2%",
    // marginTop: "1%",
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submitButton: {
    margin: 1,
  }

}));

function TaxManagementCategoryCreation({ intl , props}) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const[Flag, setFlag] =  React.useState(false);
  const[isSubmit, setisSubmit] = React.useState(false);
  const[FlagRefreshApi, setFlagRefreshApi] =  React.useState(false);
  const[business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const[device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const[session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const[DataIsSubmited, setDataIsSubmited] = React.useState(false);

  const[CategoryCreation, setCategoryCreation] = React.useState([]);

  const[FlagToTriggerCategoryShortcodeError, setFlagToTriggerCategoryShortcodeError] = React.useState(true);

  const[categoryNameErr, setcategoryNameErr] = React.useState("");
  const[categoryShortcodeErr, setcategoryShortcodeErr] = React.useState("");
  const[categoryDescriptionErr, setcategoryDescriptionErr] = React.useState("");
  
  const[serviceNameErr, setserviceNameErr] = React.useState("");
  const[serviceShortcodeErr, setserviceShortcodeErr] = React.useState("");
  const[serviceDescriptionErr, setserviceDescriptionErr] = React.useState("");

  const[subcategoryNameErr, setsubcategoryNameErr] = React.useState("");
  const[subcategoryShortcodeErr, setsubcategoryShortcodeErr] = React.useState("");
  const[subcategoryDescriptionErr, setsubcategoryDescriptionErr] = React.useState("");

  const[subcategoryServiceNameErr, setsubcategoryServiceNameErr] = React.useState("");
  const[subcategoryServiceShortcodeErr, setsubcategoryServiceShortcodeErr] = React.useState("");
  const[subcategoryServiceDescriptionErr, setsubcategoryServiceDescriptionErr] = React.useState("");

  // edit category
  const [openEditCat, setopenEditCat] = React.useState(false);
  const [catNameEditErr, setcatNameEditErr] = React.useState("");
  const [catShortcodeEditErr, setcatShortcodeEditErr] = React.useState("");
  const [catDescriptionEditErr, setcatDescriptionEditErr] = React.useState("");

  // edit service for category
  const [openEditCatService, setopenEditCatService] = React.useState(false);
  const [catServiceNameEditErr, setcatServiceNameEditErr] = React.useState("");
  const [catServiceShortcodeEditErr, setcatServiceShortcodeEditErr] = React.useState("");
  const [catServiceDescriptionEditErr, setcatServiceDescriptionEditErr] = React.useState("");

  // edit sub categoryt
  const [openEditSubCat, setopenEditSubCat] = React.useState(false);
  const [subcatNameEditErr, setsubcatNameEditErr] = React.useState("");
  const [subcatShortcodeEditErr, setsubcatShortcodeEditErr] = React.useState("");
  const [subcatDescriptionEditErr, setsubcatDescriptionEditErr] = React.useState("");

  // edit subcat service
  const [openEditSubCatService, setopenEditSubCatService] = React.useState(false);
  const [subcatServiceNameEditErr, setsubcatServiceNameEditErr] = React.useState("");
  const [subcatServiceShortcodeEditErr, setsubcatServiceShortcodeEditErr] = React.useState("");
  const [subcatServiceDescriptionEditErr, setsubcatServiceDescriptionEditErr] = React.useState("");

  // const[CategoryCreation, setCategoryCreation] = React.useState([
  //   {
  //       name: "a",
  //       shortcode: "a",
  //       description: "a",
  //       is_active: true,   
  //       category_id: 1,       
  //       sub_category: [
  //         {
  //           name: "a",
  //           shortcode: "a",
  //           description: "a",
  //           is_active: true,   
  //           category_id: 1,
  //           services:[
  //             {
  //               name: "a",
  //               shortcode: "a",
  //               description: "a",
  //               is_active: true,   
  //               category_id: 1,
  //             },
  //             {
  //               name: "a",
  //               shortcode: "a",
  //               description: "a",
  //               is_active: true,   
  //               category_id: "",
  //             },
  //           ],
  //         },
  //         {
  //           name: "b",
  //           shortcode: "b",
  //           description: "b",
  //           is_active: false,   
  //           category_id: "",
  //           services:[
  //             {
  //               name: "c",
  //               shortcode: "c",
  //               description: "c",
  //               is_active: true,   
  //               category_id: "",
  //             },
  //             {
  //               name: "c",
  //               shortcode: "c",
  //               description: "c",
  //               is_active: true,   
  //               category_id: "",
  //             },
  //           ],
  //         },
  //       ],
  //       services: [
  //         {
  //           name: "a",
  //           shortcode: "a",
  //           description: "a",
  //           is_active: true,   
  //           category_id: 1,
  //         },
  //         {
  //           name: "b",
  //           shortcode: "b",
  //           description: "b",
  //           is_active: false,   
  //           category_id: "",
  //         },
  //       ],
  //   },
  //   {
  //     name: "b",
  //     shortcode: "b",
  //     description: "b",
  //     is_active: false,          
  //     sub_category: [],
  //     services: [],
  // },
  // ]);


  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');
    
    setFlagRefreshApi(true);
    setShowProcedure(true);

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_ALL_CATEGORY_INFO , requestOptions)
      .then((Response) => Response.json())
      .then( async(categoryDetails) => {
        setShowProcedure(false);
        console.log("categoryDetails",categoryDetails);
        if(categoryDetails.success == true)
        {
          // setCategoryCreation(categoryDetails.data.category_tree);
          
          let CategoryCreationHere = categoryDetails.data.category_tree
          setCategoryCreation(
            CategoryCreationHere.filter(item => item.is_active == true ).map((f) => {
              
                f.isEditable = false
                
                f.services.map((ser)=>{
                  ser.isEditable = false
                  return ser
                })

                f.sub_category.map((subcat)=>{
                  subcat.isEditable = false
                  
                  subcat.services.map((subcatser)=>{
                    subcatser.isEditable = false
                    return subcatser
                  })

                  return subcat
                })

                return f;
            })

          )

        }
        else if(categoryDetails.success == false){
          setCategoryCreation([]);
          Swal.fire({
            icon: 'error',
            text: categoryDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });

  }, [FlagRefreshApi]);

  useEffect(() => {
    setFlag(true);
  }, [Flag]);

  const RefreshApiToAsItIs = () => {
    dispatch(userActions.checkSessionValidity());
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_ALL_CATEGORY_INFO , requestOptions)
      .then((Response) => Response.json())
      .then( async(categoryDetails) => {
        setShowProcedure(false);
        console.log("categoryDetails",categoryDetails);
        if(categoryDetails.success == true)
        {
          // setCategoryCreation(categoryDetails.data.category_tree);
          
          let CategoryCreationHere = categoryDetails.data.category_tree
          setCategoryCreation(
            CategoryCreationHere.filter(item => item.is_active == true ).map((f) => {
              
                f.isEditable = false
                
                f.services.map((ser)=>{
                  ser.isEditable = false
                  return ser
                })

                f.sub_category.map((subcat)=>{
                  subcat.isEditable = false
                  
                  subcat.services.map((subcatser)=>{
                    subcatser.isEditable = false
                    return subcatser
                  })

                  return subcat
                })

                return f;
            })

          )

        }
        else if(categoryDetails.success == false){
          setCategoryCreation([]);
          Swal.fire({
            icon: 'error',
            text: categoryDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        // text: "Something went wrong",
        text: "Server Error. Please try again.",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    });
  }

// Create Category Functions
    const handelAddMoreCategory = () => {
      dispatch(userActions.checkSessionValidity());
      setisSubmit(true);
      setCategoryCreation(prevState => [...prevState, {
        name: "",
        shortcode: "",
        description: "",
        is_active: true,  
        category_id: "",        
        sub_category: [],
        services: [],
      }]);
    }

    const handelRemoveCreateCategory = async(index) =>{
      dispatch(userActions.checkSessionValidity());
      console.log("in remove category",index);
        
      let CategoryCreationHere = CategoryCreation;
      CategoryCreationHere.splice(index, 1);
      await setCategoryCreation(CategoryCreationHere);
      await setFlag(false);
    }

    const handelIsActiveCreateCategory = async(index) => {
      dispatch(userActions.checkSessionValidity());
      console.log("in delete is active category",index);

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: classes.buttonSwalDelete,
          cancelButton: classes.buttonSwalCancel,
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, deactivate it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {

        if (result.value) {
          // setShowProcedure(true);
          let CategoryCreationHere = [...CategoryCreation];
          CategoryCreationHere[index] = {...CategoryCreationHere[index], is_active: false};
          // setCategoryCreation(CategoryCreationHere);
          // setShowProcedure(false);
          // setFlag(true);
          // Swal.fire({
          //   icon: 'success',
          //   text: "Category Deleted",
          //   confirmButtonColor: 'primary',
          //   confirmButtonText: 'OK',
          //   timer: 5000,
          // })
          setShowProcedure(true);
          const isValid = validate();
          if(isValid){
            setDataIsSubmited(true);
            const categoryData = {
              business_id: business_id,
              category_list: CategoryCreationHere,
              device_token: device_token,
              session_id: session_id,
            }
            console.log("categoryData to submit",categoryData)

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(categoryData)
            };

            return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
              .then((Response) => Response.json())
              .then( async(categoryDetails) => {
                setShowProcedure(false);
                console.log("categoryDetails after submit",categoryDetails);
                if(categoryDetails.success == true)
                {
                  // setCategoryCreation(categoryDetails.data.category_tree);
                  let CategoryCreationHere = categoryDetails.data.category_tree
                  await setCategoryCreation(
                    CategoryCreationHere.map((f) => {
                      f.isEditable = false
                      
                      f.services.map((ser)=>{
                        ser.isEditable = false
                        return ser
                      })

                      f.sub_category.map((subcat)=>{
                        subcat.isEditable = false
                        
                        subcat.services.map((subcatser)=>{
                          subcatser.isEditable = false
                          return subcatser
                        })

                        return subcat
                      })

                      return f;
                    })
                  )
                  Swal.fire({
                    icon: 'success',
                    text: "Categories Deleteted",
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                  setisSubmit(false);
                }
                else if(categoryDetails.success == false){
                  setCategoryCreation([]);
                  Swal.fire({
                    icon: 'error',
                    text: categoryDetails.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            });

          }
          else{
            Swal.fire({
              icon: 'error',
              // text: "You have missed some fields",
              text: "Please enter all mandatory fields",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }

        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) 
        {
          // swalWithBootstrapButtons.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      })

    }

    const handelEditCreateCategory = async(index) => {
      dispatch(userActions.checkSessionValidity());
      setisSubmit(true);
      console.log("in edit",index);
      let CategoryCreationHere = [...CategoryCreation];
      CategoryCreationHere[index] = {...CategoryCreationHere[index], isEditable: true};
      setCategoryCreation(CategoryCreationHere);

      // to edit by modal 
      setopenEditCat(true);
    }

    const handelEditCatClose = async() => {
      dispatch(userActions.checkSessionValidity());
      await setopenEditCat(false);
      RefreshApiToAsItIs();

      setcatNameEditErr();
      setcatShortcodeEditErr();
      setcatDescriptionEditErr();
    }

    const handelEditCatReset = async() => {
      setcatNameEditErr("");
      setcatShortcodeEditErr("");
      setcatDescriptionEditErr("");

      setCategoryCreation(
        CategoryCreation.map((i, index) =>{
          if(i.isEditable == true){
            i.name = ""
            i.shortcode = ""
            i.description = ""
            return i
          }
          return i;
        })
      )
       
    }

    const handelEditCatMinimize = async() => {
      setopenEditCat(false);
      // RefreshApiToAsItIs();
    }

    const validateEditCategory = () => {
      let catNameEditErr = '';
      let catShortcodeEditErr = '';
      let catDescriptionEditErr = '';

      CategoryCreation.map((i, index)=>{
        // for categories
        if( !i.name ){
          catNameEditErr = "Please Enter Category Name"
        }
        if( !i.shortcode ){
          catShortcodeEditErr = "Please Enter Shortcode"
        }
        // else if( i.shortcode ){
        //   catShortcodeEditErr = "";
        //   if ( i.ShortCodeAlreadyExistErr !== "" ){
        //     catShortcodeEditErr = "Please change the shortcode in validation"
        //   }
        //   else if( i.ShortCodeAlreadyExistErr == "" ){
        //     catShortcodeEditErr = ""
        //   }
        // }
        if( !i.description ){
          catDescriptionEditErr = "Please Enter Description"
        }
      })

      if( catNameEditErr || catShortcodeEditErr || catDescriptionEditErr){
        setcatNameEditErr(catNameEditErr);
        setcatShortcodeEditErr(catShortcodeEditErr);
        setcatDescriptionEditErr(catDescriptionEditErr);

        return false;
      }
      return true;
    }

    const handelEditCatSubmit = async() => {
      dispatch(userActions.checkSessionValidity());
      console.log("submit edit is clicked", CategoryCreation)

    // to check all shorcode are diff
    // 1. category
      var checkShorcodesErrOfCategoryIsBlank = "true";
      CategoryCreation.map((item,index)=>{
        console.log("item in submit is====",item)
            if(item.ShortCodeAlreadyExistErr){
              if(item.ShortCodeAlreadyExistErr !== ""){
                console.log("item in submit is====ShortCodeAlreadyExistErr !== ",item)
                checkShorcodesErrOfCategoryIsBlank = "false";
              }
            } 
      })
      console.log("checkShorcodesErrOfCategoryIsBlank",checkShorcodesErrOfCategoryIsBlank)

      const isValid = validateEditCategory();
      if(isValid){

        if( checkShorcodesErrOfCategoryIsBlank == "true" ){
          setShowProcedure(false);
          const categoryData = {
            business_id: business_id,
            category_list: CategoryCreation,
            device_token: device_token,
            session_id: session_id,
          }
          console.log("categoryData to submit",categoryData)
  
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(categoryData)
          };
  
          return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
            .then((Response) => Response.json())
            .then( async(categoryDetails) => {
              setShowProcedure(false);
              console.log("categoryDetails after submit",categoryDetails);
              if(categoryDetails.success == true)
              {
                setopenEditCat(false);
                // setCategoryCreation(categoryDetails.data.category_tree);
                let CategoryCreationHere = categoryDetails.data.category_tree
                await setCategoryCreation(
                  CategoryCreationHere.map((f) => {
                    f.isEditable = false
                    
                    f.services.map((ser)=>{
                      ser.isEditable = false
                      return ser
                    })
  
                    f.sub_category.map((subcat)=>{
                      subcat.isEditable = false
                      
                      subcat.services.map((subcatser)=>{
                        subcatser.isEditable = false
                        return subcatser
                      })
  
                      return subcat
                    })
  
                    return f;
                  })
                )
                Swal.fire({
                  icon: 'success',
                  text: "Categories Edited Succesfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setcatNameEditErr();
                setcatShortcodeEditErr();
                setcatDescriptionEditErr();
                setisSubmit(false);
              }
              else if(categoryDetails.success == false){
                setCategoryCreation([]);
                Swal.fire({
                  icon: 'error',
                  text: categoryDetails.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          });
        }
        else{
          Swal.fire({
            // icon: 'error',
            // text: "Something went wrong",
            text: "Please change the existed shortcodes",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }

      }
      else{
        setopenEditCat(true);
        // Swal.fire({
        //   icon: 'error',
        //   text: "You have missed some fields",
        //   confirmButtonColor: 'primary',
        //   confirmButtonText: 'OK',
        //   timer: 5000,
        // })
      }
    }

    const BodyEditCat = (
      <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            {/* <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Edit Category</h2>
            </Grid> */}
            <Grid item xs={10} >
              <h2 style={{marginLeft:'42%'}}>Edit Category</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelEditCatMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelEditCatClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid> 
        </div>
        <div className={classes.modalBody}>
          {
            CategoryCreation.map((i, index) =>(
              ( i.isEditable == true )?
              (
                <Grid>
                  <TextField
                    autoFocus
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    // id="name"
                    label="Category Name"
                    name="name"
                    value={i.name}
                    onChange={(e) => handleChangeCreateCategory(e, index) }
                  />
                  {
                    <div className={classes.validation}>
                      { (i.name != "") ?( <div></div>) :(catNameEditErr) }
                    </div>
                  }

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    label="Category Shortcode"
                    name="shortcode"
                    value={i.shortcode}
                    // disabled={i.isEditable == false ? true : false}
                    // onChange={(e) => handleChangeCreateCategory(e, index) }
                    onChange={(e) => handleChangeCreateCategoryShortCode(e, index) }
                  />
                  {
                    <div className={classes.validation}>
                      { (i.shortcode != "") ?( <div></div>) :(catShortcodeEditErr) }
                    </div>
                  }  
                  {
                    // <div className={classes.validation}>
                    //   { i.ShortCodeAlreadyExistErr }
                    // </div>
                    (FlagToTriggerCategoryShortcodeError == true)?
                      (
                        <div className={classes.validation}>
                          {console.log("i.ShortCodeAlreadyExistErr in category", i.ShortCodeAlreadyExistErr, FlagToTriggerCategoryShortcodeError)}
                          {i.ShortCodeAlreadyExistErr}
                        </div>
                      ) :
                     (<></>)
                  }

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    label="Description"
                    name="description"
                    value={i.description}
                    // disabled={i.isEditable == false ? true : false}
                    onChange={(e) => handleChangeCreateCategory(e, index) }
                  />
                  {
                    <div className={classes.validation}>
                      { (i.description != "") ?( <div></div>) :(catDescriptionEditErr) }
                    </div>
                  }

                </Grid>  
              )
              :(<Grid></Grid>)

            ))
          }

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
            <Button
              type="submit"
              //   fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={handelEditCatSubmit}
              >
                Update
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              // color="grey"
              className={classes.submitButton}
              onClick={handelEditCatClose}
              >
              Cancel
            </Button>
            </div>
        </div>
      </div>
    )

    const handelEditCancelCreateCategory = async(index) => {
      dispatch(userActions.checkSessionValidity());
      console.log("in edit",index);
      let CategoryCreationHere = [...CategoryCreation];
      CategoryCreationHere[index] = {...CategoryCreationHere[index], isEditable: false};
      setCategoryCreation(CategoryCreationHere);
    }

    const handleOnBlurCreateCategoryShortCode = async(e, index) => {
      console.log(" handle OnBlurCreateCategoryShortCode is called", e, index)
    }

    const handleChangeCreateCategoryShortCode = async(e, index) => {
      console.log(" handleChangeCreateCategory e, index",e.target.value, index, e.target.name)
      setFlagToTriggerCategoryShortcodeError(false);
      let valuHere = e.target.value
      const { name, value } = e.target;

      let CategoryCreationHere = [...CategoryCreation];
      CategoryCreationHere[index] = {...CategoryCreationHere[index], 
      [name]: value,
      };
      await setCategoryCreation(CategoryCreationHere);

      // await setCategoryCreation(
      //   CategoryCreation.map((f,i)=>{
      //     console.log("insetCategoryCreation", f, i)
      //     if(i == index){
      //       console.log("insetCategoryCreation i === index condi", index, i, f)
      //       f[index] = {...f[index], [name]: value};
      //     }
      //     return f;
      //   })
      // )

      if ( valuHere !== ""){
        // await setFlag(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
          'device_token': device_token, 'session_id': session_id })
        };
        return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
        .then((Response) => Response.json())
        .then( async(checkShortcode) => {
          setFlagToTriggerCategoryShortcodeError(false)
          console.log("checkShortcode is valid or not",checkShortcode)
          console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
          if( checkShortcode.success == true ){
            setFlagToTriggerCategoryShortcodeError(true)
            if( checkShortcode.data.is_valid == false ){
              // setFlagToTriggerCategoryShortcodeError(true)
              setFlagToTriggerCategoryShortcodeError(false)
              CategoryCreationHere[index] = {...CategoryCreationHere[index], 
                [name]: value,
                ShortCodeAlreadyExistErr: "Short code already exist. Define another one"};
                await setCategoryCreation(CategoryCreationHere);
                // await setFlag(true);
              // setCategoryCreation(
              //   CategoryCreation.map((f,i)=>{
              //     if(i == index){
              //       f[index] = {...f[index], 
              //         [name]: value,
              //         ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
              //       };
              //     }
              //     return f;
              //   })
              // )
              setFlagToTriggerCategoryShortcodeError(true)
            }
            else if( checkShortcode.data.is_valid == true ){
              setFlagToTriggerCategoryShortcodeError(false)
              CategoryCreationHere[index] = {...CategoryCreationHere[index], 
                [name]: value,
                ShortCodeAlreadyExistErr: ""};
              await setCategoryCreation(CategoryCreationHere);
              // await setFlag(true);
              // setCategoryCreation(
              //   CategoryCreation.map((f,i)=>{
              //     if(i == index){
              //       f[index] = {...f[index], 
              //         [name]: value,
              //         ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
              //       };
              //     }
              //     return f;
              //   })
              // )
              setFlagToTriggerCategoryShortcodeError(true)
            }
          }
          else{
            setFlagToTriggerCategoryShortcodeError(false)
            CategoryCreationHere[index] = {...CategoryCreationHere[index], 
              [name]: value,
              ShortCodeAlreadyExistErr: ""};
            await setCategoryCreation(CategoryCreationHere);
            // await setFlag(true);
            // setCategoryCreation(
            //   CategoryCreation.map((f,i)=>{
            //     if(i == index){
            //       f[index] = {...f[index], 
            //         [name]: value,
            //         ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
            //       };
            //     }
            //     return f;
            //   })
            // )
            setFlagToTriggerCategoryShortcodeError(true)
          }
          
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
    }

    const handleChangeCreateCategory = async(e, index) => {
      // dispatch(userActions.checkSessionValidity());
      console.log(" handleChangeCreateCategory e, index",e.target.value, index, e.target.name)
      const { name, value } = e.target;

      let CategoryCreationHere = [...CategoryCreation];
      CategoryCreationHere[index] = {...CategoryCreationHere[index], [name]: value};
      setCategoryCreation(CategoryCreationHere);
      console.log("CategoryCreationHere in handleChangeCreateCategory",CategoryCreationHere)
      
      // if ( name == "shortcode" ){
       
      //   const requestOptions = {
      //     method: 'POST',
      //     headers: authHeader(),
      //     body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
      //     'device_token': device_token, 'session_id': session_id })
      //   };
      //   return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
      //   .then((Response) => Response.json())
      //   .then( checkShortcode => {
      //     console.log("checkShortcode is valid or not",checkShortcode)
      //     console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
      //     if( checkShortcode.success == true ){
      //       if( checkShortcode.data.is_valid == false ){
      //         // setShortCodeAlreadyExistErr("Short code already exist. Define another one")
      //         // let CategoryCreationHere = [...CategoryCreation];
      //         CategoryCreationHere[index] = {...CategoryCreationHere[index], 
      //           [name]: value,
      //           ShortCodeAlreadyExistErr: "Short code already exist. Define another one"};
      //         setCategoryCreation(CategoryCreationHere);
      //       }
      //       else if( checkShortcode.data.is_valid == true ){
      //         // setShortCodeAlreadyExistErr("")
      //         // let CategoryCreationHere = [...CategoryCreation];
      //         CategoryCreationHere[index] = {...CategoryCreationHere[index], 
      //           [name]: value,
      //           ShortCodeAlreadyExistErr: ""};
      //         setCategoryCreation(CategoryCreationHere);
      //       }
      //     }
      //     else{
      //       CategoryCreationHere[index] = {...CategoryCreationHere[index], 
      //         [name]: value,
      //         ShortCodeAlreadyExistErr: ""};
      //       setCategoryCreation(CategoryCreationHere);
      //     }
      //   })
      //   .catch(err => {
      //     Swal.fire({
      //       icon: 'error',
      //       // text: "Something went wrong",
      //       text: "Server Error. Please try again.",
      //       confirmButtonColor: '#d33',
      //       confirmButtonText: 'OK'
      //     })
      //   });
      // }
      // else{
      //   CategoryCreationHere[index] = {...CategoryCreationHere[index], [name]: value};
      //   setCategoryCreation(CategoryCreationHere);
      //   console.log("CategoryCreationHere in handleChangeCreateCategory",CategoryCreationHere)
      // }
    }
  
  // to add sub category
  const handelAddSubCategory = (index) => {
    dispatch(userActions.checkSessionValidity());
    console.log("add sub category",index);
    setisSubmit(true);
    // let CategoryCreationHere = [...CategoryCreation];
    // CategoryCreationHere[index] = {...CategoryCreationHere[index], 
    //   sub_category: 
    //   [
    //    { name: "",
    //     shortcode: "",
    //     description: "",
    //     is_active: "", 
    //      isEditable: true,         
    //     services: [],}
    //   ]
    // };
    // setCategoryCreation(CategoryCreationHere);
    setCategoryCreation(state => {
      const CategoryCreationCopy = [...state]
     
      CategoryCreationCopy[index] = {
        ...CategoryCreationCopy[index],
        sub_category: [...state[index].sub_category, 
        { name: "",
          shortcode: "",
          description: "",
          is_active: true,   
          category_id: "",
          isEditable : true,      
          services: [],
        }
      ]
      }
      return CategoryCreationCopy
    })
    setFlag(false);
  }

  const handelRemoveCreateSubCategory = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in remove subcategory",index, indexsub);

    await setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i === index){
          f.sub_category.splice(indexsub, 1);
        }
        return f;
      })
    )

    await setFlag(false);
  }

  const handelIsActiveCreateSubCategory = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in dekete subcategory",index, indexsub);
    // setCategoryCreation(
    //   CategoryCreation.map((f,i)=>{
    //     if(i == index){
    //       f.sub_category[indexsub] = {...f.sub_category[indexsub], is_active: false};
    //     }
    //     return f;
    //   })
    // )
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        let CategoryCreationHere = CategoryCreation
        CategoryCreationHere.map((f,i)=>{
          if(i == index){
            f.sub_category[indexsub] = {...f.sub_category[indexsub], is_active: false};
          }
          return f;
        })
        console.log("CategoryCreationHere in delete sub category",CategoryCreationHere)

        setShowProcedure(true);
        const isValid = validate();
        if(isValid){
          setDataIsSubmited(true);
          const categoryData = {
            business_id: business_id,
            category_list: CategoryCreationHere,
            device_token: device_token,
            session_id: session_id,
          }
          console.log("categoryData to submit",categoryData)

          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(categoryData)
          };

          return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
            .then((Response) => Response.json())
            .then( async(categoryDetails) => {
              setShowProcedure(false);
              console.log("categoryDetails after submit",categoryDetails);
              if(categoryDetails.success == true)
              {
                // setCategoryCreation(categoryDetails.data.category_tree);
                let CategoryCreationHere = categoryDetails.data.category_tree
                await setCategoryCreation(
                  CategoryCreationHere.map((f) => {
                    f.isEditable = false
                    
                    f.services.map((ser)=>{
                      ser.isEditable = false
                      return ser
                    })

                    f.sub_category.map((subcat)=>{
                      subcat.isEditable = false
                      
                      subcat.services.map((subcatser)=>{
                        subcatser.isEditable = false
                        return subcatser
                      })

                      return subcat
                    })

                    return f;
                  })
                )
                Swal.fire({
                  icon: 'success',
                  text: "Subcategory Deleteted",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setisSubmit(false);
              }
              else if(categoryDetails.success == false){
                setCategoryCreation([]);
                Swal.fire({
                  icon: 'error',
                  text: categoryDetails.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          });

        }
        else{
          Swal.fire({
            icon: 'error',
            // text: "You have missed some fields",
            text: "Please enter all mandatory fields",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) 
      {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }

  const handelEditCreateSubCategory  = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in edit subcategory",index, indexsub);
    setisSubmit(true);
    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.sub_category[indexsub] = {...f.sub_category[indexsub], isEditable: true};
        }
        return f;
      })
    )
    
    // to edit by modal 
    setopenEditSubCat(true);
  }

  const handelEditSubCatClose   = async() => {
    dispatch(userActions.checkSessionValidity());
    await setopenEditSubCat(false);
    RefreshApiToAsItIs();
  }

  const handelEditSubCatMinimize = async() => {
    setopenEditSubCat(false);
  }

  const validateEditSubCategory = () =>{
    let subcatNameEditErr = '';
    let subcatShortcodeEditErr = '';
    let subcatDescriptionEditErr = '';

    CategoryCreation.map((i, index)=>{
      i.sub_category.map((isubcategory, indexsubcategory)=>{
        if( !isubcategory.name ){
          subcatNameEditErr = "Please Enter Subcategory Name"
        }
        if( !isubcategory.shortcode ){
          subcatShortcodeEditErr = "Please Enter Shortcode"
        }
        if( !isubcategory.description ){
          subcatDescriptionEditErr = "Please Enter Description"
        }
      })
    })

    if( subcatNameEditErr || subcatShortcodeEditErr ){
      setsubcatNameEditErr(subcatNameEditErr);
      setsubcatShortcodeEditErr(subcatShortcodeEditErr);
      setsubcatDescriptionEditErr(subcatDescriptionEditErr);

      return false;
    }

    return true;

  }

  const handelEditSubCatSubmit = async() => {
    dispatch(userActions.checkSessionValidity());
    console.log("submit edit is clicked")

    // 3. subcategory for category
    var checkShorcodesErrOfSubCatOfCategoryIsBlank = "true";
    CategoryCreation.map((item,index)=>{
      item.sub_category.map((isub, indexsub) => {
        if(isub.ShortCodeAlreadyExistErr){
          if(isub.ShortCodeAlreadyExistErr !== ""){
            console.log("item sub category for category in submit is====ShortCodeAlreadyExistErr !== ",isub)
            checkShorcodesErrOfSubCatOfCategoryIsBlank = "false";
          }
        }
      })
    })
    console.log("checkShorcodesErrOfSubCatOfCategoryIsBlank",checkShorcodesErrOfSubCatOfCategoryIsBlank)


    const isValid = validateEditSubCategory();
    if(isValid){

      if( checkShorcodesErrOfSubCatOfCategoryIsBlank == "true" ){
        setShowProcedure(false);
        const categoryData = {
          business_id: business_id,
          category_list: CategoryCreation,
          device_token: device_token,
          session_id: session_id,
        }
        console.log("categoryData to submit",categoryData)
  
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(categoryData)
        };
  
        return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
          .then((Response) => Response.json())
          .then( async(categoryDetails) => {
            console.log("categoryDetails after submit",categoryDetails);
            if(categoryDetails.success == true)
            {
              setopenEditSubCat(false);
              // setCategoryCreation(categoryDetails.data.category_tree);
              let CategoryCreationHere = categoryDetails.data.category_tree
              await setCategoryCreation(
                CategoryCreationHere.map((f) => {
                  f.isEditable = false
                  
                  f.services.map((ser)=>{
                    ser.isEditable = false
                    return ser
                  })
  
                  f.sub_category.map((subcat)=>{
                    subcat.isEditable = false
                    
                    subcat.services.map((subcatser)=>{
                      subcatser.isEditable = false
                      return subcatser
                    })
  
                    return subcat
                  })
  
                  return f;
                })
              )
              Swal.fire({
                icon: 'success',
                text: "Subcategories Edited Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setisSubmit(false);
            }
            else if(categoryDetails.success == false){
              setCategoryCreation([]);
              Swal.fire({
                icon: 'error',
                text: categoryDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        });
      }
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the existed shortcodes",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
      

    }
    else{
      setopenEditSubCat(true);
      // Swal.fire({
      //   icon: 'error',
      //   text: "You have missed some fields",
      //   confirmButtonColor: '#d33',
      //   confirmButtonText: 'OK',
      //   timer: 5000,
      // })
    }
  }

  const BodyEdiSubCat = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
          {/* <Grid item xs={10} >
          <h2 style={{marginLeft:'40%'}}>Edit Subcategory</h2>
          </Grid> */}
          <Grid item xs={10} >
              <h2 style={{marginLeft:'42%'}}>Edit Subcategory</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelEditSubCatMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelEditSubCatClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid> 
      </div>
      <div className={classes.modalBody}>
        {
          CategoryCreation.map((i, index) =>(
            i.sub_category.map((isub, indexsub) => (
              ( isub.isEditable == true )?
              (
                <Grid>

                  <TextField
                    autoFocus
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    // id="name"
                    label="Subcategory Name"
                    name="name"
                    value={isub.name}
                    onChange={(e) => handleChangeCreateSubCategory(e, index, indexsub) }
                  />
                  {
                    <div className={classes.validation}>
                      { (isub.name != "") ?( <div></div>) :(subcatNameEditErr) }
                    </div>
                  }

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    label="Subcategory Shortcode"
                    name="shortcode"
                    value={isub.shortcode}
                    // onChange={(e) => handleChangeCreateSubCategory(e, index, indexsub) }
                    onChange={(e) => handleChangeCreateSubCategoryShortCode(e, index, indexsub) }
                  />
                  {
                    <div className={classes.validation}>
                      { (isub.shortcode != "") ?( <div></div>) :(subcatShortcodeEditErr) }
                    </div>
                  }
                  {
                    <div className={classes.validation}>
                      { isub.ShortCodeAlreadyExistErr }
                    </div>
                  }

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    label="Subcategory Description"
                    name="description"
                    value={isub.description}
                    onChange={(e) => handleChangeCreateSubCategory(e, index, indexsub) }
                  />
                  {
                    <div className={classes.validation}>
                      { (isub.description != "") ?( <div></div>) :(subcatDescriptionEditErr) }
                    </div>
                  }

                </Grid>
              )
              :(<Grid></Grid>)
            ))
          ))
        }

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handelEditSubCatSubmit}
            >
              Update
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submitButton}
            onClick={handelEditSubCatClose}
            >
            Cancel
          </Button>
          </div>
      </div>
    </div>
  )

  const handelEditCancelCreateSubCategory   = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in edit cancel subcategory",index, indexsub);
    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.sub_category[indexsub] = {...f.sub_category[indexsub], isEditable: false};
        }
        return f;
      })
    )
    await setFlag(true);
  }

  const handleChangeCreateSubCategoryShortCode = async(e, index, indexsub) => {
    console.log("e, index",e.target.value, index, e.target.name)
    let valuHere = e.target.value
    const { name, value } = e.target;
    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.sub_category[indexsub] = {...f.sub_category[indexsub], [name]: value};
        }
        return f;
      })
    )

    if ( valuHere !== ""){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
      .then((Response) => Response.json())
      .then( checkShortcode => {
        console.log("checkShortcode is valid or not service for cat",checkShortcode)
        console.log("checkShortcode is valid or not service for cat",checkShortcode.data.is_valid)
        if( checkShortcode.success == true ){
          if( checkShortcode.data.is_valid == false ){
            setCategoryCreation(
              CategoryCreation.map((f,i)=>{
                if(i == index){
                  f.sub_category[indexsub] = {...f.sub_category[indexsub], 
                    [name]: value,
                    ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
                  };
                }
                return f;
              })
            )
          }
          else if( checkShortcode.data.is_valid == true ){
            setCategoryCreation(
              CategoryCreation.map((f,i)=>{
                if(i == index){
                  f.sub_category[indexsub] = {...f.sub_category[indexsub], 
                    [name]: value,
                    ShortCodeAlreadyExistErr: ""
                  };
                }
                return f;
              })
            )
          }
        }
        else{
          setCategoryCreation(
            CategoryCreation.map((f,i)=>{
              if(i == index){
                f.sub_category[indexsub] = {...f.sub_category[indexsub], 
                  [name]: value,
                  ShortCodeAlreadyExistErr: ""
                };
              }
              return f;
            })
          )
        }
      })
    }
  }

  const handleChangeCreateSubCategory = async(e, index, indexsub) => {
    // dispatch(userActions.checkSessionValidity());
    console.log("e, index",e.target.value, index, e.target.name)
    const { name, value } = e.target;

    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.sub_category[indexsub] = {...f.sub_category[indexsub], [name]: value};
        }
        return f;
      })
    )

    // if ( name == "shortcode" ){
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
    //     'device_token': device_token, 'session_id': session_id })
    //   };
    //   return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
    //   .then((Response) => Response.json())
    //   .then( checkShortcode => {
    //     console.log("checkShortcode is valid or not service for cat",checkShortcode)
    //     console.log("checkShortcode is valid or not service for cat",checkShortcode.data.is_valid)
    //     if( checkShortcode.success == true ){
    //       if( checkShortcode.data.is_valid == false ){
    //         setCategoryCreation(
    //           CategoryCreation.map((f,i)=>{
    //             if(i == index){
    //               f.sub_category[indexsub] = {...f.sub_category[indexsub], 
    //                 [name]: value,
    //                 ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
    //               };
    //             }
    //             return f;
    //           })
    //         )
    //       }
    //       else if( checkShortcode.data.is_valid == true ){
    //         setCategoryCreation(
    //           CategoryCreation.map((f,i)=>{
    //             if(i == index){
    //               f.sub_category[indexsub] = {...f.sub_category[indexsub], 
    //                 [name]: value,
    //                 ShortCodeAlreadyExistErr: ""
    //               };
    //             }
    //             return f;
    //           })
    //         )
    //       }
    //     }
    //     else{
    //       setCategoryCreation(
    //         CategoryCreation.map((f,i)=>{
    //           if(i == index){
    //             f.sub_category[indexsub] = {...f.sub_category[indexsub], 
    //               [name]: value,
    //               ShortCodeAlreadyExistErr: ""
    //             };
    //           }
    //           return f;
    //         })
    //       )
    //     }
    //   })
    // }
    // else{
    //   setCategoryCreation(
    //     CategoryCreation.map((f,i)=>{
    //       if(i == index){
    //         f.sub_category[indexsub] = {...f.sub_category[indexsub], [name]: value};
    //       }
    //       return f;
    //     })
    //   )
    // }

  }

  // to add service for sub category
  const handelAddServiceForSubCategory = async(index,indexsub) => {
    // dispatch(userActions.checkSessionValidity());
    console.log("add service for sub category",index,indexsub)
    setisSubmit(true);
    await CategoryCreation.map((f,i)=>{
      if(i == index){
        console.log("i == index",i,index)
        f.sub_category.map((ff,ii)=>{

        if(ii == indexsub){
          ff.services= [...ff.services, 
            { name: "",
              shortcode: "",
              description: "",
              is_active: true,  
              category_id: "",  
              isEditable : true,      
              services: [],
            }
          ]
        }
      })

      }
    })


    await setFlag(false);

    // setCategoryCreation(state => {
    //   const CategoryCreationCopy = [...state]

    //   CategoryCreationCopy[index] = {
    //     ...CategoryCreationCopy[index],
    //     sub_category:[...state[index] ={
    //       ...sub_category[indexsub],
    //       services:[...state[indexsub],
    //       { name: "",
    //         shortcode: "",
    //         description: "",
    //         is_active: "",   
    //          isEditable : true,
              //  category_id: "",       
    //       }
    //       ]
    //     }]
    //   }
    //   return CategoryCreationCopy
    // })

    // setCategoryCreation(
    //   CategoryCreation.map((f,i)=>{
    //     if(i == index){
    //       f.sub_category[indexsub] = [...f.sub_category[indexsub], f.sub_category.services = { name: "",
    //       shortcode: "",
    //       description: "",
    //        isEditable : true,
    //       is_active: "",          
    //     }];
      
    //     }
    //     return f;
    //   })
    // )
  }

  const handelRemoveCreateServiceForSubCategory = async(index, indexsub,indexservice) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in remove service from sub category",index, indexsub,indexservice);
    await setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i === index){
          f.sub_category.map((ff, ii)=>{
            if(ii == indexsub){
              ff.services.splice(indexservice, 1);
            }
          })
        }
        return f;
      })
    )
    await setFlag(false);
  }

  const handelIsActiveCreateServiceForSubCategory = async(index, indexsub,indexservice) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in remove service from sub category",index, indexsub,indexservice);
    // setCategoryCreation(
    //   CategoryCreation.map((f,i)=>{
    //     if(i == index){
    //       f.sub_category.map((ff, ii)=>{
    //         if(ii == indexsub){
    //           ff.services[indexservice] = {...ff.services[indexservice], is_active: false};
    //         }
    //       })
    //     }
    //     return f;
    //   })
    // )

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        let CategoryCreationHere = CategoryCreation
        CategoryCreationHere.map((f,i)=>{
          if(i == index){
            f.sub_category.map((ff, ii)=>{
              if(ii == indexsub){
                ff.services[indexservice] = {...ff.services[indexservice], is_active: false};
              }
            })
          }
          return f;
        })
        console.log("CategoryCreationHere in delete sub category",CategoryCreationHere)

        setShowProcedure(true);
        const isValid = validate();
        if(isValid){
          setDataIsSubmited(true);
          const categoryData = {
            business_id: business_id,
            category_list: CategoryCreationHere,
            device_token: device_token,
            session_id: session_id,
          }
          console.log("categoryData to submit",categoryData)

          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(categoryData)
          };

          return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
            .then((Response) => Response.json())
            .then( async(categoryDetails) => {
              setShowProcedure(false);
              console.log("categoryDetails after submit",categoryDetails);
              if(categoryDetails.success == true)
              {
                // setCategoryCreation(categoryDetails.data.category_tree);
                let CategoryCreationHere = categoryDetails.data.category_tree
                await setCategoryCreation(
                  CategoryCreationHere.map((f) => {
                    f.isEditable = false
                    
                    f.services.map((ser)=>{
                      ser.isEditable = false
                      return ser
                    })

                    f.sub_category.map((subcat)=>{
                      subcat.isEditable = false
                      
                      subcat.services.map((subcatser)=>{
                        subcatser.isEditable = false
                        return subcatser
                      })

                      return subcat
                    })

                    return f;
                  })
                )
                Swal.fire({
                  icon: 'success',
                  text: "Service Deleteted",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setisSubmit(false);
              }
              else if(categoryDetails.success == false){
                setCategoryCreation([]);
                Swal.fire({
                  icon: 'error',
                  text: categoryDetails.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          });

        }
        else{
          Swal.fire({
            icon: 'error',
            // text: "You have missed some fields",
            text: "Please enter all mandatory fields",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) 
      {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
    
  }

  const handelEditServiceForSubCategory = async(index, indexsub,indexservice) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in edit service from sub category",index, indexsub,indexservice);
    setisSubmit(true);
    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.sub_category.map((ff, ii)=>{
            if(ii == indexsub){
              ff.services[indexservice] = {...ff.services[indexservice], isEditable: true};
            }
          })
        }
        return f;
      })
    )

    // to edit by modal 
    setopenEditSubCatService(true);
  }

  const handelEditSubCatServiceClose = async() => {
    await setopenEditSubCatService(false);
    RefreshApiToAsItIs();
  }

  const handelEditSubCatServiceReset = async() => {
    setsubcatServiceNameEditErr("");
    setsubcatServiceShortcodeEditErr("");
    setsubcatServiceDescriptionEditErr("");

    setCategoryCreation(
      CategoryCreation.map((i, index) =>{
        i.sub_category.map((isub, indexsub) => {
          isub.services.map((iservice, indexservice)=>{
            if(iservice.isEditable == true){
              iservice.name = ""
              iservice.shortcode = ""
              iservice.description = ""
              return iservice
            }
            return iservice;
          })
        })
      })
    )
  }

  const handelEditSubCatServiceMinimize = async() => {
    await setopenEditSubCatService(false);
  }



  const validateSubcatServiceEdit = () => {
    let subcatServiceNameEditErr = '';
    let subcatServiceShortcodeEditErr = '';
    let subcatServiceDescriptionEditErr = '';

    CategoryCreation.map((i, index)=>{
      i.sub_category.map((isubcategory, indexsubcategory)=>{
        isubcategory.services.map((isubcategoryservice, indexsubcategoryservice)=>{
          if( !isubcategoryservice.name ){
            subcatServiceNameEditErr = "Please Enter Service Name"
          }
          if( !isubcategoryservice.shortcode ){
            subcatServiceShortcodeEditErr = "Please Enter Service Shortcode"
          }
          if( !isubcategoryservice.description ){
            subcatServiceDescriptionEditErr = "Please Enter Service Description"
          }
        })
      })
    })

    if( subcatServiceNameEditErr || subcatServiceShortcodeEditErr || subcatServiceDescriptionEditErr){
      setsubcatServiceNameEditErr(subcatServiceNameEditErr);
      setsubcatServiceShortcodeEditErr(subcatServiceShortcodeEditErr);
      setsubcatServiceDescriptionEditErr(subcatServiceDescriptionEditErr);

      return false;
    }
    return true;
  }

  const handelEditSubCatServiceSubmit = async() => {
    dispatch(userActions.checkSessionValidity());
    console.log("submit edit sub cat service is clicked",CategoryCreation)

    // 4. service for subcategory
    var checkShorcodesErrOfserviceforSubCatIsBlank = "true";
    CategoryCreation.map((item,index)=>{
      item.sub_category.map((isub, indexsub) => {
        isub.services.map((iservice, indexservice)=>{
          if(iservice.ShortCodeAlreadyExistErr){
            if(iservice.ShortCodeAlreadyExistErr !== ""){
              console.log("item service for sub category  in submit is====ShortCodeAlreadyExistErr !== ",iservice)
              checkShorcodesErrOfserviceforSubCatIsBlank = "false";
            }
          }
        })
      })
    })
    console.log("checkShorcodesErrOfserviceforSubCatIsBlank",checkShorcodesErrOfserviceforSubCatIsBlank)

    const isValid = validateSubcatServiceEdit();
    if(isValid){

      if( checkShorcodesErrOfserviceforSubCatIsBlank == "true" ){
        setShowProcedure(false);
        const categoryData = {
          business_id: business_id,
          category_list: CategoryCreation,
          device_token: device_token,
          session_id: session_id,
        }
        console.log("categoryData to submit",categoryData)
  
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(categoryData)
        };
  
        return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
          .then((Response) => Response.json())
          .then( async(categoryDetails) => {
            setShowProcedure(false);
            console.log("categoryDetails after submit",categoryDetails);
            if(categoryDetails.success == true)
            {
              setopenEditSubCatService(false);
              // setCategoryCreation(categoryDetails.data.category_tree);
              let CategoryCreationHere = categoryDetails.data.category_tree
              await setCategoryCreation(
                CategoryCreationHere.map((f) => {
                  f.isEditable = false
                  
                  f.services.map((ser)=>{
                    ser.isEditable = false
                    return ser
                  })
  
                  f.sub_category.map((subcat)=>{
                    subcat.isEditable = false
                    
                    subcat.services.map((subcatser)=>{
                      subcatser.isEditable = false
                      return subcatser
                    })
  
                    return subcat
                  })
  
                  return f;
                })
              )
              Swal.fire({
                icon: 'success',
                text: "Service Edited Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setisSubmit(false);
            }
            else if(categoryDetails.success == false){
              setCategoryCreation([]);
              Swal.fire({
                icon: 'error',
                text: categoryDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        });
  
      }
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the existed shortcodes",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
      
    }
    else{
      setopenEditSubCatService(true);
      // Swal.fire({
      //   icon: 'error',
      //   text: "You have missed some fields",
      //   confirmButtonColor: 'primary',
      //   confirmButtonText: 'OK',
      //   timer: 5000,
      // })
    }
  }

  const BodyEdiSubCatService =  (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <Grid container item xs={12}>
            {/* <h2 style={{marginLeft:'40%'}}>Edit Service</h2> */}
            <Grid item xs={10} >
              <h2 style={{marginLeft:'42%'}}>Edit Service</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelEditSubCatServiceMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelEditSubCatServiceClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
        </Grid> 
      </div>
      <div className={classes.modalBody}>
        {
          CategoryCreation.map((i, index) =>(
            i.sub_category.map((isub, indexsub) => (
              isub.services.map((iservice, indexservice)=>(
                ( iservice.isEditable == true  )?
                (
                  <Grid>
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      // id="name"
                      label="Service Name"
                      name="name"
                      value={iservice.name}
                      onChange={(e) => handleChangeServiceForSubCategory(e, index, indexsub,indexservice) }
                    />
                    {
                      <div className={classes.validation}>
                        { (iservice.name != "") ?( <div></div>) :(subcatServiceNameEditErr) }
                      </div>
                    }

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Service Shortcode"
                      name="shortcode"
                      value={iservice.shortcode}
                      // onChange={(e) => handleChangeServiceForSubCategory(e, index, indexsub,indexservice) }
                      onChange={(e) => handleChangeServiceForSubCategoryShortCode(e, index, indexsub,indexservice) }
                      
                    />
                    {
                      <div className={classes.validation}>
                        { (iservice.shortcode != "") ?( <div></div>) :(subcatServiceShortcodeEditErr) }
                      </div>
                    }
                    {
                      <div className={classes.validation}>
                        { iservice.ShortCodeAlreadyExistErr }
                      </div>
                    }

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Service Description"
                      name="description"
                      // disabled
                      value={iservice.description}
                      onChange={(e) => handleChangeServiceForSubCategory(e, index, indexsub,indexservice) }
                    />
                    {
                      <div className={classes.validation}>
                        { (iservice.description != "") ?( <div></div>) :(subcatServiceDescriptionEditErr) }
                      </div>
                    }

                  </Grid>
                )
                :(<Grid></Grid>)
              ))
            ))
          ))
        }

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            //   fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handelEditSubCatServiceSubmit}
            >
              Update
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submitButton}
            onClick={handelEditSubCatServiceClose}
            >
            Cancel
          </Button>
          </div>
      </div>
    </div>
  )


  const handelEditCancelServiceForSubCategory = async(index, indexsub,indexservice) => {
    dispatch(userActions.checkSessionValidity());
    console.log("in edit service from sub category",index, indexsub,indexservice);
    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.sub_category.map((ff, ii)=>{
            if(ii == indexsub){
              ff.services[indexservice] = {...ff.services[indexservice], isEditable: false};
            }
          })
        }
        return f;
      })
    )
  }

  const handleChangeServiceForSubCategoryShortCode = (e, index, indexsub,indexservice) => {
    console.log("e, index",e.target.value, index, e.target.name,index, indexsub,indexservice)
    let valuHere = e.target.value
    const { name, value } = e.target;

    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          // f.services[indexsub] = {...f.services[indexsub], [name]: value};
          f.sub_category.map((ff, ii)=>{
            if(ii == indexsub){
              ff.services[indexservice] = {...ff.services[indexservice], [name]: value};
            }
          })
        }
        return f;
      })
    )

    if ( valuHere !== ""){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
      .then((Response) => Response.json())
      .then( checkShortcode => {
        console.log("checkShortcode is valid or not",checkShortcode)
        console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
        if( checkShortcode.success == true ){
          if( checkShortcode.data.is_valid == false ){
            setCategoryCreation(
              CategoryCreation.map((f,i)=>{
                if(i == index){
                  f.sub_category.map((ff, ii)=>{
                    if(ii == indexsub){
                      ff.services[indexservice] = {...ff.services[indexservice], 
                        [name]: value,
                        ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
                      };
                    }
                  })
                }
                return f;
              })
            )
          }
          else if( checkShortcode.data.is_valid == true ){
            setCategoryCreation(
              CategoryCreation.map((f,i)=>{
                if(i == index){
                  f.sub_category.map((ff, ii)=>{
                    if(ii == indexsub){
                      ff.services[indexservice] = {...ff.services[indexservice], 
                        [name]: value,
                        ShortCodeAlreadyExistErr: ""
                      };
                    }
                  })
                }
                return f;
              })
            )
          }
        }
        else{
          setCategoryCreation(
            CategoryCreation.map((f,i)=>{
              if(i == index){
                f.sub_category.map((ff, ii)=>{
                  if(ii == indexsub){
                    ff.services[indexservice] = {...ff.services[indexservice], 
                      [name]: value,
                      ShortCodeAlreadyExistErr: ""
                    };
                  }
                })
              }
              return f;
            })
          )
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const handleChangeServiceForSubCategory = (e, index, indexsub,indexservice) => {
    // dispatch(userActions.checkSessionValidity());
    console.log("e, index",e.target.value, index, e.target.name,index, indexsub,indexservice)
    const { name, value } = e.target;

    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          // f.services[indexsub] = {...f.services[indexsub], [name]: value};
          f.sub_category.map((ff, ii)=>{
            if(ii == indexsub){
              ff.services[indexservice] = {...ff.services[indexservice], [name]: value};
            }
          })
        }
        return f;
      })
    )

    // if ( name == "shortcode" ){
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
    //     'device_token': device_token, 'session_id': session_id })
    //   };
    //   return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
    //   .then((Response) => Response.json())
    //   .then( checkShortcode => {
    //     console.log("checkShortcode is valid or not",checkShortcode)
    //     console.log("checkShortcode is valid or not",checkShortcode.data.is_valid)
    //     if( checkShortcode.success == true ){
    //       if( checkShortcode.data.is_valid == false ){
    //         setCategoryCreation(
    //           CategoryCreation.map((f,i)=>{
    //             if(i == index){
    //               f.sub_category.map((ff, ii)=>{
    //                 if(ii == indexsub){
    //                   ff.services[indexservice] = {...ff.services[indexservice], 
    //                     [name]: value,
    //                     ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
    //                   };
    //                 }
    //               })
    //             }
    //             return f;
    //           })
    //         )
    //       }
    //       else if( checkShortcode.data.is_valid == true ){
    //         setCategoryCreation(
    //           CategoryCreation.map((f,i)=>{
    //             if(i == index){
    //               f.sub_category.map((ff, ii)=>{
    //                 if(ii == indexsub){
    //                   ff.services[indexservice] = {...ff.services[indexservice], 
    //                     [name]: value,
    //                     ShortCodeAlreadyExistErr: ""
    //                   };
    //                 }
    //               })
    //             }
    //             return f;
    //           })
    //         )
    //       }
    //     }
    //     else{
    //       setCategoryCreation(
    //         CategoryCreation.map((f,i)=>{
    //           if(i == index){
    //             f.sub_category.map((ff, ii)=>{
    //               if(ii == indexsub){
    //                 ff.services[indexservice] = {...ff.services[indexservice], 
    //                   [name]: value,
    //                   ShortCodeAlreadyExistErr: ""
    //                 };
    //               }
    //             })
    //           }
    //           return f;
    //         })
    //       )
    //     }
    //   })
    //   .catch(err => {
    //     Swal.fire({
    //       icon: 'error',
    //       // text: "Something went wrong",
    //       text: "Server Error. Please try again.",
    //       confirmButtonColor: '#d33',
    //       confirmButtonText: 'OK'
    //     })
    //   });
    // }
    // else{
    //   setCategoryCreation(
    //     CategoryCreation.map((f,i)=>{
    //       if(i == index){
    //         // f.services[indexsub] = {...f.services[indexsub], [name]: value};
    //         f.sub_category.map((ff, ii)=>{
    //           if(ii == indexsub){
    //             ff.services[indexservice] = {...ff.services[indexservice], [name]: value};
    //           }
    //         })
    //       }
    //       return f;
    //     })
    //   )
    // }
  }

  // to add service for category
  const handelAddServiceForCategory = async(index) => {
    dispatch(userActions.checkSessionValidity());
    console.log("add service for category",index)
    setisSubmit(true);
    setCategoryCreation(state => {
      const CategoryCreationCopy = [...state]
     
      CategoryCreationCopy[index] = {
        ...CategoryCreationCopy[index],
        services: [...state[index].services, 
        { name: "",
          shortcode: "",
          description: "",
          is_active: true, 
          isEditable : true,  
          category_id: "",       
        }
      ]
      }
      return CategoryCreationCopy
    })
    await setFlag(false);
  }

  const handelRemoveCreateServiceForCategory = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in remove service from category",index, indexsub);

    await setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i === index){
          f.services.splice(indexsub, 1);
        }
        return f;
      })
    )

    await setFlag(false);
  }

  const handelIsActiveCreateServiceForCategory = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in is active delete service from category",index, indexsub);
    // setCategoryCreation(
    //   CategoryCreation.map((f,i)=>{
    //     if(i == index){
    //       f.services[indexsub] = {...f.services[indexsub], is_active: false};
    //     }
    //     return f;
    //   })
    // )
    // await setFlag(false);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: classes.buttonSwalDelete,
        cancelButton: classes.buttonSwalCancel,
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        let CategoryCreationHere = CategoryCreation 
        CategoryCreationHere.map((f,i)=>{
          if(i == index){
            f.services[indexsub] = {...f.services[indexsub], is_active: false};
          }
          return f;
        })
        console.log("CategoryCreationHere in delete service",CategoryCreationHere)

        setShowProcedure(true);
        const isValid = validate();
        if(isValid){
          setDataIsSubmited(true);
          const categoryData = {
            business_id: business_id,
            category_list: CategoryCreationHere,
            device_token: device_token,
            session_id: session_id,
          }
          console.log("categoryData to submit",categoryData)

          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(categoryData)
          };

          return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
            .then((Response) => Response.json())
            .then( async(categoryDetails) => {
              setShowProcedure(false);
              console.log("categoryDetails after submit",categoryDetails);
              if(categoryDetails.success == true)
              {
                // setCategoryCreation(categoryDetails.data.category_tree);
                let CategoryCreationHere = categoryDetails.data.category_tree
                await setCategoryCreation(
                  CategoryCreationHere.map((f) => {
                    f.isEditable = false
                    
                    f.services.map((ser)=>{
                      ser.isEditable = false
                      return ser
                    })

                    f.sub_category.map((subcat)=>{
                      subcat.isEditable = false
                      
                      subcat.services.map((subcatser)=>{
                        subcatser.isEditable = false
                        return subcatser
                      })

                      return subcat
                    })

                    return f;
                  })
                )
                Swal.fire({
                  icon: 'success',
                  text: "Service Deleteted",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setisSubmit(false);
              }
              else if(categoryDetails.success == false){
                setCategoryCreation([]);
                Swal.fire({
                  icon: 'error',
                  text: categoryDetails.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          });

        }
        else{
          Swal.fire({
            icon: 'error',
            // text: "You have missed some fields",
            text: "Please enter all mandatory fields",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) 
      {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })

 
  }

  const handelEditCreateServiceForCategory  = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in edit service from category",index, indexsub);
    setisSubmit(true);
    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.services[indexsub] = {...f.services[indexsub], isEditable: true};
        }
        return f;
      })
    )

    // to edit by modal 
    setopenEditCatService(true);
  }

  const handelEditCatServiceClose = async() => {
    dispatch(userActions.checkSessionValidity());
    await setopenEditCatService(false);
    RefreshApiToAsItIs();
  }

  const handelEditCatServiceMinimize = async() => {
    setopenEditCatService(false);
  }

  const validateCatService = () => {
    let catServiceNameEditErr = '';
    let catServiceShortcodeEditErr = '';
    let catServiceDescriptionEditErr = '';

    CategoryCreation.map((i, index)=>{
      i.services.map((iservice, indexservice)=>{
        if( !iservice.name ){
          catServiceNameEditErr = "Please Enter Service Name"
        }
        if( !iservice.shortcode ){
          catServiceShortcodeEditErr = "Please Enter Shortcode"
        }
        if( !iservice.description ){
          catServiceDescriptionEditErr = "Please Enter Description"
        }
      })
    })
    if( catServiceNameEditErr || catServiceShortcodeEditErr || catServiceDescriptionEditErr ){
      setcatServiceNameEditErr(catServiceNameEditErr);
      setcatServiceShortcodeEditErr(catServiceShortcodeEditErr);
      setcatServiceDescriptionEditErr(catServiceDescriptionEditErr);

      return false;
    }
    return true;

  }

  const handelEditCatServiceSubmit = async() => {
    dispatch(userActions.checkSessionValidity());
      console.log("submit edit service is clicked")

      // 2. service for category
      var checkShorcodesErrOfServicefORCategoryIsBlank = "true";
      CategoryCreation.map((item,index)=>{
        item.services.map((iservice, indexservice)=>{
          if(iservice.ShortCodeAlreadyExistErr){
            if(iservice.ShortCodeAlreadyExistErr !== ""){
              console.log("item service for category in submit is====ShortCodeAlreadyExistErr !== ",iservice)
              checkShorcodesErrOfServicefORCategoryIsBlank = "false";
              // break;
            }
          }
        })
      })
      console.log("checkShorcodesErrOfServicefORCategoryIsBlank",checkShorcodesErrOfServicefORCategoryIsBlank)

      const isValid = validateCatService();
      if(isValid){
        if( checkShorcodesErrOfServicefORCategoryIsBlank == "true" ){
          setShowProcedure(false);
          const categoryData = {
            business_id: business_id,
            category_list: CategoryCreation,
            device_token: device_token,
            session_id: session_id,
          }
          console.log("categoryData to submit",categoryData)
  
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(categoryData)
          };
  
          return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
            .then((Response) => Response.json())
            .then( async(categoryDetails) => {
              setShowProcedure(false);
              console.log("categoryDetails after submit",categoryDetails);
              if(categoryDetails.success == true)
              {
                setopenEditCatService(false);
                // setCategoryCreation(categoryDetails.data.category_tree);
                let CategoryCreationHere = categoryDetails.data.category_tree
                await setCategoryCreation(
                  CategoryCreationHere.map((f) => {
                    f.isEditable = false
                    
                    f.services.map((ser)=>{
                      ser.isEditable = false
                      return ser
                    })
  
                    f.sub_category.map((subcat)=>{
                      subcat.isEditable = false
                      
                      subcat.services.map((subcatser)=>{
                        subcatser.isEditable = false
                        return subcatser
                      })
  
                      return subcat
                    })
  
                    return f;
                  })
                )
                Swal.fire({
                  icon: 'success',
                  text: "Service Edited Succesfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
                setisSubmit(false);
              }
              else if(categoryDetails.success == false){
                setCategoryCreation([]);
                Swal.fire({
                  icon: 'error',
                  text: categoryDetails.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          });
        }
        else{
          Swal.fire({
            // icon: 'error',
            // text: "Something went wrong",
            text: "Please change the existed shortcodes",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }

      }
      else{
        setopenEditCatService(true);
        // Swal.fire({
        //   icon: 'error',
        //   text: "You have missed some fields",
        //   confirmButtonColor: 'primary',
        //   confirmButtonText: 'OK',
        //   timer: 5000,
        // })
      }
  }

  const BodyEditCatService = (
    <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            {/* <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Edit Service</h2>
            </Grid> */}
            <Grid item xs={10} >
              <h2 style={{marginLeft:'42%'}}>Edit Service</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelEditCatServiceMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelEditCatServiceClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid> 
        </div>
        <div className={classes.modalBody}>
          {
            CategoryCreation.map((i, index) =>(
              i.services.map((isub, indexsub)=>(
                ( isub.isEditable == true )?
                (
                  <Grid>
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      // id="name"
                      label="Service Name"
                      name="name"
                      value={isub.name}
                      onChange={(e) => handleChangeCreateServiceForCategory(e, index, indexsub) }
                    />
                    {
                      <div className={classes.validation}>
                        { (isub.name != "") ?( <div></div>) :(catServiceNameEditErr) }
                      </div>
                    }

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Service Shortcode"
                      name="shortcode"
                      value={isub.shortcode}
                      // onChange={(e) => handleChangeCreateServiceForCategory(e, index, indexsub) }
                      onChange={(e) => handleChangeCreateServiceForCategoryShortCode(e, index, indexsub) }
                    />
                    {
                      <div className={classes.validation}>
                        { (isub.shortcode != "") ?( <div></div>) :(catServiceShortcodeEditErr) }
                      </div>
                    }
                    {
                        <div className={classes.validation}>
                          { isub.ShortCodeAlreadyExistErr }
                        </div>
                      }

                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      label="Service Description"
                      name="description"
                      value={isub.description}
                      onChange={(e) => handleChangeCreateServiceForCategory(e, index, indexsub) }
                    />
                    {
                      <div className={classes.validation}>
                        { (isub.description != "") ?( <div></div>) :(catServiceDescriptionEditErr) }
                      </div>
                    }

                  </Grid>
                )
                :(<Grid></Grid>)
              ))
            ))
          }

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
            <Button
              type="submit"
              //   fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={handelEditCatServiceSubmit}
              >
                Update
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              // color="grey"
              className={classes.submitButton}
              onClick={handelEditCatServiceClose}
              >
              Cancel
            </Button>
            </div>
        </div>
      </div>
  )

  const handelEditCancelCreateServiceForCategory   = async(index,indexsub) =>{
    dispatch(userActions.checkSessionValidity());
    console.log("in edit service from category",index, indexsub);
    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.services[indexsub] = {...f.services[indexsub], isEditable: false};
        }
        return f;
      })
    )
    await setFlag(false);
  }

  const handleChangeCreateServiceForCategoryShortCode  = (e, index, indexsub) => {
    console.log("e, index",e.target.value, index, e.target.name)
    let valuHere = e.target.value
    const { name, value } = e.target;

    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.services[indexsub] = {...f.services[indexsub], [name]: value};
        }
        return f;
      })
    )

    if ( valuHere !== ""){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
      .then((Response) => Response.json())
      .then( checkShortcode => {
        console.log("checkShortcode is valid or not service for cat",checkShortcode)
        console.log("checkShortcode is valid or not service for cat",checkShortcode.data.is_valid)
        if( checkShortcode.success == true ){
          if( checkShortcode.data.is_valid == false ){
            setCategoryCreation(
              CategoryCreation.map((f,i)=>{
                if(i == index){
                  f.services[indexsub] = {...f.services[indexsub], 
                    [name]: value,
                    ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
                  };
                }
                return f;
              })
            )
          }
          else if( checkShortcode.data.is_valid == true ){
            setCategoryCreation(
              CategoryCreation.map((f,i)=>{
                if(i == index){
                  f.services[indexsub] = {...f.services[indexsub], 
                    [name]: value,
                    ShortCodeAlreadyExistErr: ""
                  };
                }
                return f;
              })
            )
          }
        }
        else{
          setCategoryCreation(
            CategoryCreation.map((f,i)=>{
              if(i == index){
                f.services[indexsub] = {...f.services[indexsub], 
                  [name]: value,
                  ShortCodeAlreadyExistErr: ""
                };
              }
              return f;
            })
          )
        }
      })
    }
  }

  const handleChangeCreateServiceForCategory = (e, index, indexsub) => {
    // dispatch(userActions.checkSessionValidity());
    console.log("e, index",e.target.value, index, e.target.name)
    const { name, value } = e.target;

    setCategoryCreation(
      CategoryCreation.map((f,i)=>{
        if(i == index){
          f.services[indexsub] = {...f.services[indexsub], [name]: value};
        }
        return f;
      })
    )

    // if ( name == "shortcode" ){
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: JSON.stringify({'shortcode': value, 'entity_type': "category", 'business_id': business_id,
    //     'device_token': device_token, 'session_id': session_id })
    //   };
    //   return fetch( process.env.REACT_APP_SHORTCODE_VALIDATION, requestOptions)
    //   .then((Response) => Response.json())
    //   .then( checkShortcode => {
    //     console.log("checkShortcode is valid or not service for cat",checkShortcode)
    //     console.log("checkShortcode is valid or not service for cat",checkShortcode.data.is_valid)
    //     if( checkShortcode.success == true ){
    //       if( checkShortcode.data.is_valid == false ){
    //         setCategoryCreation(
    //           CategoryCreation.map((f,i)=>{
    //             if(i == index){
    //               f.services[indexsub] = {...f.services[indexsub], 
    //                 [name]: value,
    //                 ShortCodeAlreadyExistErr: "Short code already exist. Define another one"
    //               };
    //             }
    //             return f;
    //           })
    //         )
    //       }
    //       else if( checkShortcode.data.is_valid == true ){
    //         setCategoryCreation(
    //           CategoryCreation.map((f,i)=>{
    //             if(i == index){
    //               f.services[indexsub] = {...f.services[indexsub], 
    //                 [name]: value,
    //                 ShortCodeAlreadyExistErr: ""
    //               };
    //             }
    //             return f;
    //           })
    //         )
    //       }
    //     }
    //     else{
    //       setCategoryCreation(
    //         CategoryCreation.map((f,i)=>{
    //           if(i == index){
    //             f.services[indexsub] = {...f.services[indexsub], 
    //               [name]: value,
    //               ShortCodeAlreadyExistErr: ""
    //             };
    //           }
    //           return f;
    //         })
    //       )
    //     }
    //   })
    // }
    // else {
    //   setCategoryCreation(
    //     CategoryCreation.map((f,i)=>{
    //       if(i == index){
    //         f.services[indexsub] = {...f.services[indexsub], [name]: value};
    //       }
    //       return f;
    //     })
    //   )
    // }

  }

  const validate = () => {
    let categoryNameErr = '';
    let categoryShortcodeErr = '';
    let categoryDescriptionErr = '';

    let serviceNameErr = '';
    let serviceShortcodeErr = '';
    let serviceDescriptionErr = '';

    let subcategoryNameErr = '';
    let subcategoryShortcodeErr = '';
    let subcategoryDescriptionErr = '';

    let subcategoryServiceNameErr = '';
    let subcategoryServiceShortcodeErr = '';
    let subcategoryServiceDescriptionErr = '';

    CategoryCreation.map((i, index)=>{

      // for categories
      if( !i.name ){
        categoryNameErr = "Please Enter Category Name"
      }
      if( !i.shortcode ){
        categoryShortcodeErr = "Please Enter Shortcode"
      }
      if( !i.description ){
        categoryDescriptionErr = "Please Enter Description"
      }

      // for categories
      i.services.map((iservice, indexservice)=>{
        if( !iservice.name ){
          serviceNameErr = "Please Enter Service Name"
        }
        if( !iservice.shortcode ){
          serviceShortcodeErr = "Please Enter Shortcode"
        }
        if( !iservice.description ){
          serviceDescriptionErr = "Please Enter Description"
        }
      })

      // for sub categories
      i.sub_category.map((isubcategory, indexsubcategory)=>{
        if( !isubcategory.name ){
          subcategoryNameErr = "Please Enter Subcategory Name"
        }
        if( !isubcategory.shortcode ){
          subcategoryShortcodeErr = "Please Enter Shortcode"
        }
        if( !isubcategory.description ){
          subcategoryDescriptionErr = "Please Enter Description"
        }

        // for services in sub categories
        isubcategory.services.map((isubcategoryservice, indexsubcategoryservice)=>{
          if( !isubcategoryservice.name ){
            subcategoryServiceNameErr = "Please Enter Service Name"
          }
          if( !isubcategoryservice.shortcode ){
            subcategoryServiceShortcodeErr = "Please Enter Service Shortcode"
          }
          if( !isubcategoryservice.description ){
            subcategoryServiceDescriptionErr = "Please Enter Service Description"
          }
        })

      })


    })

    if(categoryNameErr || categoryShortcodeErr || categoryDescriptionErr || 
        serviceNameErr || serviceShortcodeErr || serviceDescriptionErr ||
        subcategoryNameErr || subcategoryShortcodeErr || subcategoryDescriptionErr ||
        subcategoryServiceNameErr || subcategoryServiceShortcodeErr || subcategoryServiceDescriptionErr ){
      setcategoryNameErr(categoryNameErr);
      setcategoryShortcodeErr(categoryShortcodeErr);
      setcategoryDescriptionErr(categoryDescriptionErr);

      setserviceNameErr(serviceNameErr);
      setserviceShortcodeErr(serviceShortcodeErr);
      setserviceDescriptionErr(serviceDescriptionErr);

      setsubcategoryNameErr(subcategoryNameErr);
      setsubcategoryShortcodeErr(subcategoryShortcodeErr);
      setsubcategoryDescriptionErr(subcategoryDescriptionErr);

      setsubcategoryServiceNameErr(subcategoryServiceNameErr);
      setsubcategoryServiceShortcodeErr(subcategoryServiceShortcodeErr);
      setsubcategoryServiceDescriptionErr(subcategoryServiceDescriptionErr);

      return false;
    }
    return true;
  }

  const handelSubmitAll = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("submit is clicked",CategoryCreation)

    // to check all shorcode are diff
    // 1. category
    var checkShorcodesErrOfCategoryIsBlank = "true";
    CategoryCreation.map((item,index)=>{
      console.log("item in submit is====",item)
        // for (let index = 0; index < CategoryCreation.length; index++) {
          if(item.ShortCodeAlreadyExistErr){
            if(item.ShortCodeAlreadyExistErr !== ""){
              console.log("item in submit is====ShortCodeAlreadyExistErr !== ",item)
              checkShorcodesErrOfCategoryIsBlank = "false";
              // break;
            }
          }
        //   else{console.log("item in submit is==== ShortCodeAlreadyExistErr == ",item)
        //     checkShorcodesErrOfCategoryIsBlank = "true";
        //   }
        // }
      
    })
    console.log("checkShorcodesErrOfCategoryIsBlank",checkShorcodesErrOfCategoryIsBlank)
    // 2. service for category
    var checkShorcodesErrOfServicefORCategoryIsBlank = "true";
    CategoryCreation.map((item,index)=>{
      item.services.map((iservice, indexservice)=>{
        if(iservice.ShortCodeAlreadyExistErr){
          if(iservice.ShortCodeAlreadyExistErr !== ""){
            console.log("item service for category in submit is====ShortCodeAlreadyExistErr !== ",iservice)
            checkShorcodesErrOfServicefORCategoryIsBlank = "false";
          }
        }
      })
    })
    console.log("checkShorcodesErrOfServicefORCategoryIsBlank",checkShorcodesErrOfServicefORCategoryIsBlank)
    // 3. subcategory for category
    var checkShorcodesErrOfSubCatOfCategoryIsBlank = "true";
    CategoryCreation.map((item,index)=>{
      item.sub_category.map((isub, indexsub) => {
        if(isub.ShortCodeAlreadyExistErr){
          if(isub.ShortCodeAlreadyExistErr !== ""){
            console.log("item sub category for category in submit is====ShortCodeAlreadyExistErr !== ",isub)
            checkShorcodesErrOfSubCatOfCategoryIsBlank = "false";
          }
        }
      })
    })
    console.log("checkShorcodesErrOfSubCatOfCategoryIsBlank",checkShorcodesErrOfSubCatOfCategoryIsBlank)
    // 4. service for subcategory
    var checkShorcodesErrOfserviceforSubCatIsBlank = "true";
    CategoryCreation.map((item,index)=>{
      item.sub_category.map((isub, indexsub) => {
        isub.services.map((iservice, indexservice)=>{
          if(iservice.ShortCodeAlreadyExistErr){
            if(iservice.ShortCodeAlreadyExistErr !== ""){
              console.log("item service for sub category  in submit is====ShortCodeAlreadyExistErr !== ",iservice)
              checkShorcodesErrOfserviceforSubCatIsBlank = "false";
            }
          }
        })
      })
    })
    console.log("checkShorcodesErrOfserviceforSubCatIsBlank",checkShorcodesErrOfserviceforSubCatIsBlank)


    const isValid = validate();
    if(isValid){

      if( checkShorcodesErrOfCategoryIsBlank == "true" && checkShorcodesErrOfServicefORCategoryIsBlank == "true"
        && checkShorcodesErrOfSubCatOfCategoryIsBlank == "true" && checkShorcodesErrOfserviceforSubCatIsBlank == "true"){
        console.log("in checkShorcodesErrOfCategoryIsBlank checkShorcodesErrOfServicefORCategoryIsBlank true ")
        setDataIsSubmited(true);
        const categoryData = {
          business_id: business_id,
          category_list: CategoryCreation,
          device_token: device_token,
          session_id: session_id,
        }
        console.log("categoryData to submit",categoryData)
  
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(categoryData)
        };
  
        return fetch( process.env.REACT_APP_CREATE_ALL_CATEGORIES , requestOptions)
          .then((Response) => Response.json())
          .then( async(categoryDetails) => {
            setShowProcedure(false);
            console.log("categoryDetails after submit",categoryDetails.data.category_tree);
            if(categoryDetails.success == true)
            {
              // setCategoryCreation(categoryDetails.data.category_tree);
              let CategoryCreationHere = categoryDetails.data.category_tree
              await setCategoryCreation(
                CategoryCreationHere.map((f) => {
                  f.isEditable = false
                  
                  f.services.map((ser)=>{
                    ser.isEditable = false
                    return ser
                  })
  
                  f.sub_category.map((subcat)=>{
                    subcat.isEditable = false
                    
                    subcat.services.map((subcatser)=>{
                      subcatser.isEditable = false
                      return subcatser
                    })
  
                    return subcat
                  })
  
                  return f;
                })
              )
              Swal.fire({
                icon: 'success',
                text: "Categories Added Succesfully",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
              setisSubmit(false);
            }
            else if(categoryDetails.success == false){
              setCategoryCreation([]);
              Swal.fire({
                icon: 'error',
                text: categoryDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        });
      }
      else{
        Swal.fire({
          // icon: 'error',
          // text: "Something went wrong",
          text: "Please change the existed shortcodes",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }

    }
    else{
      Swal.fire({
        icon: 'error',
        // text: "You have missed some fields",
        text: "Please enter all mandatory fields",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK',
        timer: 5000,
      })
    }

  }

  return (
    console.log("CategoryCreation in returns",CategoryCreation),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
      <div className={classes.wrapper}>
        <Grid container className={classes.pageHeaderContainer} style={{marginTop:'2%'}}>
          <Grid item xs={10} className={classes.pageHeaderLabel}>
            Create Categories
          </Grid>

          <Grid item xs={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
              startIcon={<AddIcon />}
              onClick={handelAddMoreCategory}
            >
              Category
            </Button>
          </Grid>
        </Grid>
        <div style={{marginTop:'-2%'}}></div>
        {
          // CategoryCreation.filter(item => item.is_active == true ).map((i, index) =>(
            CategoryCreation.map((i, index) =>(
            <Grid>
              {
                ( i.is_active == true)?
                (
                  <Accordion defaultExpanded={ i.category_id == "" ? true : false} style={{marginTop:'1%'}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {
                      ( i.name == "")?
                      (
                        <Typography className={classes.CategoryLabel}> {index + 1}. Create New Category</Typography>
                      )
                      :(<Typography className={classes.CategoryLabel}> {index + 1}. {i.name}</Typography>)
                    }
                  </AccordionSummary>
                  <AccordionDetails>
                  <Paper className={classes.paper}>
                  {/* Create Category */}
                    <Grid item xs={12} >
                    <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                    {/* <Typography className={classes.CategoryLabel}>Category Details</Typography> */}
                    <Grid container item xs={12} className={classes.CategoryLabel} >
                        <Grid style={{width:'55%'}} >Category Details</Grid>
                        <Grid style={{width:'20%'}}> </Grid>
                        <Grid style={{width:'10%'}}> </Grid>
                        <Grid style={{width:'2%', marginLeft:'10%'}}>
                        {
                          (i.category_id == "")?
                          (
                            <Grid>
                              <CloseIcon 
                                onClick={()=>handelRemoveCreateCategory(index)} 
                                style={{fontSize: 20, cursor:'pointer',marginTop:'25%'}}
                              />                        
                            </Grid>
                          )
                          :(
                            <Grid>
                              {/* <DeleteIcon 
                                onClick={()=>handelIsActiveCreateCategory(index)} 
                                style={{fontSize: 20, cursor:'pointer',marginTop:'25%'}}
                              /> */}
                              {/* <AntSwitch 
                                checked={i.is_active} 
                                onChange={() => handelIsActiveCreateCategory(index)} 
                                size="small" 
                                name="item.is_active" 
                                value="item.is_active"
                                color="primary"
                              /> */}
                             {
                               ( i.is_live == true )?(
                                <Grid>
                                  <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                        color: "green"}}>Live</Grid>
                                </Grid>
                               )
                               :(
                                <Grid >
                                  <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                        color: "red"}}>Not Live</Grid>
                                </Grid>
                               )
                             } 
                            </Grid>
                          )
                        }
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Paper className={classes.paper}>
                      <Grid container spacing={1} style={{marginTop:'-6.5%'}}>
                        <Grid item xs={6}></Grid>
                        {/* <Grid> */}
                          {/* <Grid item xs={2}> */}
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submitButton}
                              startIcon={<AddIcon />}
                              onClick={() => handelAddSubCategory(index)}
                            >
                              Subcategory
                            </Button>
                          {/* </Grid> */}
                          {/* <Grid item xs={2}> */}
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.submitButton}
                              startIcon={<AddIcon />}
                              onClick={() => handelAddServiceForCategory(index)}
                            >
                              Service
                            </Button>
                          {/* </Grid> */}
                          {/* <Grid item xs={1} style={{marginLeft:'-4.5%'}}> */}
                          <Grid >
                            {
                              (i.category_id !== "" && i.is_live == false )?
                              (
                                <Grid>
                                  {/* {
                                    (i.isEditable == true)?
                                    (
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={() => handelEditCancelCreateCategory(index)}
                                      >
                                        Cancel
                                      </Button>
                                    )
                                    :( */}
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submitButton}
                                        startIcon={<EditIcon />}
                                        onClick={() => handelEditCreateCategory(index)}
                                      >
                                        Edit
                                      </Button>
                                    {/* )
                                  } */}
                                                          
                                </Grid>
                              )
                              :(
                                <Grid></Grid>
                              )
                            }
                          </Grid>
                        {/* </Grid> */}
                      </Grid>
                      
                      <Grid style={{marginTop:'1%', marginLeft:'4%', marginRight:'5%'}}>

                        <Grid container spacing={2}>
                          <Grid item xs={8} >
                            <TextField
                              autoFocus
                              margin="normal"
                              required
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              // id="name"
                              label="Category Name"
                              name="name"
                              value={i.name}
                              disabled={i.isEditable == false ? true : false}
                              // disabled
                              onChange={(e) => handleChangeCreateCategory(e, index) }
                            />
                            {
                              <div className={classes.validation}>
                                { (i.name != "") ?( <div></div>) :(categoryNameErr) }
                              </div>
                            }
                          </Grid>
                          {/* <Grid item xs={1}></Grid> */}
                          <Grid item xs={4}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              label="Category Shortcode"
                              name="shortcode"
                              value={i.shortcode}
                              disabled={i.isEditable == false ? true : false}
                              // onBlur={(e) => handleOnBlurCreateCategoryShortCode(e,index)}
                              // disabled
                              // onChange={(e) => handleChangeCreateCategory(e, index) }
                              onChange={(e) => handleChangeCreateCategoryShortCode(e, index) }
                            />
                            {
                              <div className={classes.validation}>
                                { (i.shortcode != "") ?( <div></div>) :(categoryShortcodeErr) }
                              </div>
                            }
                            {
                              (FlagToTriggerCategoryShortcodeError == true)?
                              (
                                <div className={classes.validation}>
                                  {console.log("i.ShortCodeAlreadyExistErr in category",i.ShortCodeAlreadyExistErr,FlagToTriggerCategoryShortcodeError )}
                                  { i.ShortCodeAlreadyExistErr }
                                </div>
                              ):
                              (<></>)

                              // <div className={classes.validation}>
                              //   {console.log("i.ShortCodeAlreadyExistErr in category",i.ShortCodeAlreadyExistErr,FlagToTriggerCategoryShortcodeError )}
                              //   {
                              //     ( FlagToTriggerCategoryShortcodeError === true )?
                              //     (
                              //       <>{ i.ShortCodeAlreadyExistErr }</>
                              //     ):
                              //     (<></>)
                              //   }
                              //   {/* // { i.ShortCodeAlreadyExistErr } */}
                              // </div>
                            }
                          </Grid>
                        </Grid>
                        
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="Description"
                          name="description"
                          value={i.description}
                          disabled={i.isEditable == false ? true : false}
                          onChange={(e) => handleChangeCreateCategory(e, index) }
                        />
                        {
                          <div className={classes.validation}>
                            { (i.description != "") ?( <div></div>) :(categoryDescriptionErr) }
                          </div>
                        }

                        {/* <Grid id="is_active" style={{marginTop:"2%", color:"grey",marginLeft:"0.5%"}}>Is Active*</Grid>
                        <Grid container item xs={12} style={{marginLeft:"0.5%"}}>
                          <RadioGroup
                            row
                            id ="is_active"
                            aria-label="is_active"
                            name="is_active"
                            // defaultValue={JSON.stringify(processDetails.status)}
                            value={i.is_active}
                            onChange={ (e) => handleChangeCreateCategory(e, index)}
                          >                  
                            <div>
                              <FormControlLabel
                                value="true"
                                classes={{ label: classes.label }}
                                control={<Radio size="small" color="primary" />}
                                label= "Yes"
                              />
                            </div>
                            <div>
                              <FormControlLabel
                                value="false"
                                classes={{ label: classes.label }}
                                control={<Radio size="small" color="primary" />}
                                label= "No"
                              />
                            </div>
                          </RadioGroup>
                        </Grid> */}
                        
                      </Grid>
                      </Paper>
                      </AccordionDetails>
                      </Accordion>
                      <hr style={{width:'95%', color:'black'}}/>

                      {/* create services for category */}
                      {
                        i.services.map((isub, indexsub) => (
                          <Grid style={{marginLeft:'5%'}}>
                            {
                              (isub.is_active == true)?
                              (
                                <Grid>
                                  <Accordion defaultExpanded={ isub.category_id == "" ? true : false} style={{marginTop:'1%'}}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                  <Grid container item xs={12} className={classes.ServiceCategoryLabel}>
                                    {/* <Grid item xs={11} >Service Details(For Category):({isub.name})</Grid> */}
                                    <Grid item xs={6}>
                                      {
                                        ( isub.name == "")?
                                        (
                                          <Grid >Service Details(For Category)</Grid>
                                        )
                                        :(<Grid >Service Details for {isub.name} </Grid>)
                                      }
                                    </Grid>
                                    <Grid item xs={6} style={{}}>
                                      {
                                        (isub.category_id == "")?
                                        (
                                          <Grid >
                                            <CloseIcon onClick={()=>handelRemoveCreateServiceForCategory(index, indexsub)} 
                                              style={{fontSize: 20, cursor:'pointer', marginLeft:'3%'}}
                                            />                            
                                          </Grid>
                                        )
                                        :(
                                          <Grid>
                                            {/* <DeleteIcon 
                                              onClick={()=>handelIsActiveCreateServiceForCategory(index, indexsub)} 
                                              style={{fontSize: 20, cursor:'pointer'}}
                                            /> */}
                                            {/* <AntSwitch 
                                              checked={isub.is_active} 
                                              onChange={() => handelIsActiveCreateServiceForCategory(index, indexsub)} 
                                              size="small" 
                                              name="item.is_active" 
                                              value="item.is_active"
                                              color="primary"
                                            /> */}
                                            {
                                              ( isub.is_live == true)?(
                                                <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                                color: "green", marginLeft:'90%'}}>
                                                  Live
                                                </Grid>
                                              )
                                              :(
                                                <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                                color: "red",marginLeft:'90%'}}>
                                                  Not Live
                                                </Grid>
                                              )
                                            } 
                                          </Grid>
                                        )
                                      }                           
                                    </Grid>
                                  </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                  <Paper className={classes.paper}>
                                    <Grid container item xs={12} className={classes.CategoryLabel} 
                                      style={{marginTop:'-6.5%', marginLeft:"-7%"}}>
                                      <Grid style={{width:'60%'}} ></Grid>
                                      <Grid style={{width:'17%'}}></Grid>
                                      <Grid style={{width:'10%'}}></Grid>
                                      <Grid style={{width:'2%', marginLeft:'1%'}}>
                                        {
                                          (isub.category_id !== "" && isub.is_live == false)?
                                          (
                                            <Grid>
                                              {/* {
                                                (isub.isEditable == true)?
                                                (
                                                  <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    onClick={() => handelEditCancelCreateServiceForCategory(index, indexsub)}
                                                  >
                                                    Cancel
                                                  </Button>
                                                )
                                                :( */}
                                                  <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submitButton}
                                                    startIcon={<EditIcon />}
                                                    onClick={() => handelEditCreateServiceForCategory(index, indexsub)}
                                                  >
                                                    Edit
                                                  </Button>
                                                {/* )
                                              }                                                                      */}
                                            </Grid>
                                          )
                                          :(
                                            <Grid></Grid>
                                          )
                                        }
                                      </Grid>
                                    </Grid>
                                    <Grid style={{marginTop:'4%', marginLeft:'4%', marginRight:'5%'}}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={8}>
                                        <TextField
                                          autoFocus
                                          margin="normal"
                                          required
                                          fullWidth
                                          id="outlined-basic"
                                          variant="outlined"
                                          // id="name"
                                          label="Service Name"
                                          name="name"
                                          value={isub.name}
                                          disabled = {isub.isEditable == false ? true : false}
                                          onChange={(e) => handleChangeCreateServiceForCategory(e, index, indexsub) }
                                        />
                                        {
                                          <div className={classes.validation}>
                                            { (isub.name != "") ?( <div></div>) :(serviceNameErr) }
                                          </div>
                                        }
                                      </Grid>
                                      
                                      <Grid item xs={4} >
                                        <TextField
                                          margin="normal"
                                          required
                                          fullWidth
                                          id="outlined-basic"
                                          variant="outlined"
                                          label="Service Shortcode"
                                          name="shortcode"
                                          value={isub.shortcode}
                                          disabled = {isub.isEditable == false ? true : false}
                                          // onChange={(e) => handleChangeCreateServiceForCategory(e, index, indexsub) }
                                          onChange={(e) => handleChangeCreateServiceForCategoryShortCode(e, index, indexsub) }
                                        />
                                        {
                                          <div className={classes.validation}>
                                            { (isub.shortcode != "") ?( <div></div>) :(serviceShortcodeErr) }
                                          </div>
                                        }
                                        {
                                          <div className={classes.validation}>
                                            { isub.ShortCodeAlreadyExistErr }
                                          </div>
                                        }
                                      </Grid>
                                    </Grid>

                                      <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Service Description"
                                        name="description"
                                        value={isub.description}
                                        disabled = {isub.isEditable == false ? true : false}
                                        onChange={(e) => handleChangeCreateServiceForCategory(e, index, indexsub) }
                                      />
                                      {
                                        <div className={classes.validation}>
                                          { (isub.description != "") ?( <div></div>) :(serviceDescriptionErr) }
                                        </div>
                                      }

                                      {/* <Grid id="is_active" style={{marginTop:"2%", color:"grey",marginLeft:"0.5%"}}>Is Active*</Grid>
                                      <Grid container item xs={12} style={{marginLeft:"0.5%"}}>
                                        <RadioGroup
                                          row
                                          id ="is_active"
                                          aria-label="is_active"
                                          name="is_active"
                                          value={isub.is_active}
                                          onChange={ (e) => handleChangeCreateServiceForCategory(e, index, indexsub)}
                                        >                  
                                          <div>
                                            <FormControlLabel
                                              value="true"
                                              classes={{ label: classes.label }}
                                              control={<Radio size="small" color="primary" />}
                                              label= "Yes"
                                            />
                                          </div>
                                          <div>
                                            <FormControlLabel
                                              value="false"
                                              classes={{ label: classes.label }}
                                              control={<Radio size="small" color="primary" />}
                                              label= "No"
                                            />
                                          </div>
                                        </RadioGroup>
                                      </Grid> */}
                                    </Grid>
                                  </Paper>
                                  </AccordionDetails>
                                  </Accordion>
                                  <hr style={{width:'99%',marginLeft:'-2%', color:'black'}}/>
                                </Grid>
                              )
                              :(<Grid></Grid>)
                            }
                            
                          </Grid>
                        ))
                      }
                                
                      {/* Sub category */}
                      {
                        i.sub_category.map((isub, indexsub) => (
                          <Grid style={{marginLeft:'5%'}}>
                            {
                              (isub.is_active == true)?
                              (
                                <Grid>
                                  <Accordion defaultExpanded={ isub.category_id == "" ? true : false} style={{marginTop:'1%',borderBottom:'none'}}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                  <Grid container item xs={12} className={classes.SubCategoryLabel}>
                                      {/* <Grid style={{width:'90%'}} >Subcategory Details:({isub.name})</Grid> */}
                                      {
                                        ( isub.name == "")?
                                        (
                                          <Grid style={{width:'90%'}}>Subcategory Details</Grid>
                                        )
                                        :(<Grid style={{width:'90%'}}>Subcategory Details for {isub.name} </Grid>)
                                      }
                                      <Grid style={{}}>
                                        {
                                          (isub.category_id == "")?
                                          (
                                            <Grid >
                                              <CloseIcon onClick={()=>handelRemoveCreateSubCategory(index, indexsub)} 
                                                style={{fontSize: 20, cursor:'pointer', marginTop:'25%',marginLeft:'5%'}}
                                              />

                                            </Grid>
                                          )
                                          :(
                                            <Grid >
                                              {/* <DeleteIcon 
                                                onClick={()=>handelIsActiveCreateSubCategory(index, indexsub)} 
                                                style={{fontSize: 20, cursor:'pointer'}}
                                              /> */}
                                              {/* <AntSwitch 
                                                checked={isub.is_active} 
                                                onChange={() => handelIsActiveCreateSubCategory(index, indexsub)} 
                                                size="small" 
                                                name="item.is_active" 
                                                value="item.is_active"
                                                color="primary"
                                              /> */}
                                              {
                                                ( isub.is_live == true)?(
                                                  <Grid>
                                                    <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                                          color: "green", marginLeft:'90%'}}>Live</Grid>
                                                  </Grid>
                                                )
                                                :(
                                                  <Grid >
                                                    <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                                          color: "red", marginLeft:'90%'}}>Not Live</Grid>
                                                  </Grid>
                                                )
                                              } 
                                            </Grid>
                                          )
                                        }                                  
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                  <Paper className={classes.paper}>
                                    <Grid container spacing={1} style={{marginTop:'-6.5%'}}>
                                      <Grid item xs={8}></Grid>
                                      {/* <Grid> */}
                                        {/* <Grid item xs={2}> */}
                                        <Grid >
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.submitButton}
                                            startIcon={<AddIcon />}
                                            onClick={() => handelAddServiceForSubCategory(index,indexsub)}
                                          >
                                            Service
                                          </Button>
                                        </Grid>
                                        {/* <Grid item xs={1} style={{marginLeft:'-4%'}}> */}
                                        <Grid >
                                        {
                                          (isub.category_id !== "" && isub.is_live == false)?
                                          (
                                            <Grid>
                                              {/* {
                                                (isub.isEditable == true)?
                                                (
                                                  <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    onClick={() => handelEditCancelCreateSubCategory(index, indexsub)}
                                                  >
                                                    Cancel
                                                  </Button>
                                                )
                                                :( */}
                                                  <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submitButton}
                                                    startIcon={<EditIcon />}
                                                    onClick={() => handelEditCreateSubCategory(index, indexsub)}
                                                  >
                                                    Edit
                                                  </Button>
                                                {/* )
                                              }                        */}
                                            </Grid>
                                          )
                                          :(
                                            <Grid></Grid>
                                          )
                                        }
                                        </Grid>
                                      {/* </Grid> */}
                                    </Grid>

                                    <Grid style={{marginTop:'1%', marginLeft:'4%', marginRight:'5%'}}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                          <TextField
                                            autoFocus
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-basic"
                                            variant="outlined"
                                            // id="name"
                                            label="Subcategory Name"
                                            name="name"
                                            disabled = {isub.isEditable == false ? true : false}
                                            value={isub.name}
                                            onChange={(e) => handleChangeCreateSubCategory(e, index, indexsub) }
                                          />
                                          {
                                            <div className={classes.validation}>
                                              { (isub.name != "") ?( <div></div>) :(subcategoryNameErr) }
                                            </div>
                                          }
                                        </Grid>

                                        <Grid item xs={4}>
                                          <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Subcategory Shortcode"
                                            name="shortcode"
                                            disabled  = {isub.isEditable == false ? true : false}
                                            value={isub.shortcode}
                                            // onChange={(e) => handleChangeCreateSubCategory(e, index, indexsub) }
                                            onChange={(e) => handleChangeCreateSubCategoryShortCode(e, index, indexsub) }
                                          />
                                          {
                                            <div className={classes.validation}>
                                              { (isub.shortcode != "") ?( <div></div>) :(subcategoryShortcodeErr) }
                                            </div>
                                          }
                                          {
                                            <div className={classes.validation}>
                                              { isub.ShortCodeAlreadyExistErr }
                                            </div>
                                          }
                                        </Grid>
                                      </Grid>

                                      <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Subcategory Description"
                                        name="description"
                                        disabled = {isub.isEditable == false ? true : false}
                                        value={isub.description}
                                        onChange={(e) => handleChangeCreateSubCategory(e, index, indexsub) }
                                      />
                                      {
                                        <div className={classes.validation}>
                                          { (isub.description != "") ?( <div></div>) :(subcategoryDescriptionErr) }
                                        </div>
                                      }

                                      {/* <Grid id="is_active" style={{marginTop:"2%", color:"grey",marginLeft:"0.5%"}}>Is Active*</Grid>
                                      <Grid container item xs={12} style={{marginLeft:"0.5%"}}>
                                        <RadioGroup
                                          row
                                          id ="is_active"
                                          aria-label="is_active"
                                          name="is_active"
                                          value={isub.is_active}
                                          onChange={ (e) => handleChangeCreateSubCategory(e, index, indexsub)}
                                        >                  
                                          <div>
                                            <FormControlLabel
                                              value="true"
                                              classes={{ label: classes.label }}
                                              control={<Radio size="small" color="primary" />}
                                              label= "Yes"
                                            />
                                          </div>
                                          <div>
                                            <FormControlLabel
                                              value="false"
                                              classes={{ label: classes.label }}
                                              control={<Radio size="small" color="primary" />}
                                              label= "No"
                                            />
                                          </div>
                                        </RadioGroup>
                                      </Grid> */}

                                    </Grid>
                                  </Paper>
                                  </AccordionDetails>
                                  </Accordion>

                                  
                                <hr style={{width:'95%', color:'black'}}/>
                                </Grid>
                              )
                              :(<Grid></Grid>)
                            }
                            

                            {
                              isub.services.map((iservice, indexservice)=>(
                                <Grid style={{marginLeft:'7%'}}>
                                {
                                  (iservice.is_active == true && isub.is_active == true)?
                                  (
                                    <Grid>
                                      <Accordion defaultExpanded={ iservice.category_id == "" ? true : false} style={{marginTop:'1%',borderBottom:'none'}}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                      <Grid container item xs={12} className={classes.ServiceForSubCategoryLabel}>
                                        {/* <Grid item xs={11} >Service(For Subcategory):({iservice.name})</Grid> */}
                                        {
                                          ( iservice.name == "")?
                                          (
                                            <Grid item xs={11} >Service(For Subcategory)</Grid>
                                          )
                                          :(<Grid item xs={11} >Service for {iservice.name}</Grid>)
                                        }
                                        <Grid style={{}}>
                                        {
                                          (iservice.category_id == "")?
                                          (
                                            <Grid>
                                              <CloseIcon onClick={()=>handelRemoveCreateServiceForSubCategory(index, indexsub,indexservice)} 
                                                style={{fontSize: 20, cursor:'pointer',marginLeft:'2%'}}
                                              />                           
                                            </Grid>
                                          )
                                          :(
                                            <Grid>
                                              {/* <DeleteIcon 
                                                onClick={()=>handelIsActiveCreateServiceForSubCategory(index, indexsub,indexservice)} 
                                                style={{fontSize: 20, cursor:'pointer'}}
                                              /> */}
                                              {/* <AntSwitch 
                                                checked={iservice.is_active} 
                                                onChange={() => handelIsActiveCreateServiceForSubCategory(index, indexsub,indexservice)} 
                                                size="small" 
                                                name="item.is_active" 
                                                value="item.is_active"
                                                color="primary"
                                              /> */}
                                              {
                                                ( iservice.is_live == true)?(
                                                  <Grid>
                                                    <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                                          color: "green",marginLeft:'90%'}}>Live</Grid>
                                                  </Grid>
                                                )
                                                :(
                                                  <Grid >
                                                    <Grid style={{fontStyle: "normal",fontWeight: "500",fontSize: "18px",
                                                          color: "red",marginLeft:'70%'}}>Not Live</Grid>
                                                  </Grid>
                                                )
                                              } 
                                            </Grid>
                                          )
                                        }
                                        </Grid>
                                      </Grid>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                      <Paper className={classes.paper}>
                                        <Grid container item xs={12} className={classes.CategoryLabel} 
                                          style={{marginTop:'-6.5%'}}>
                                          <Grid style={{width:'60%'}} ></Grid>
                                          <Grid style={{width: '11%', marginLeft:'9%'}}></Grid>
                                          <Grid style={{width:'3%', marginLeft:'1%',marginTop:'-1%'}}>
                                            {
                                              (iservice.category_id !== "" && iservice.is_live == false)?
                                              (
                                                <Grid>
                                                  {/* {
                                                    ( iservice.isEditable == true )?
                                                    (
                                                      <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.submit}
                                                        onClick={() => handelEditCancelServiceForSubCategory(index, indexsub,indexservice)}
                                                      >
                                                        Cancel
                                                      </Button> 
                                                    )
                                                    :( */}
                                                        <Button
                                                          type="submit"
                                                          variant="contained"
                                                          color="primary"
                                                          className={classes.submitButton}
                                                          startIcon={<EditIcon />}
                                                          onClick={() => handelEditServiceForSubCategory(index, indexsub,indexservice)}
                                                        >
                                                          Edit
                                                        </Button> 
                                                    {/* )
                                                  }                   */}
                                                </Grid>
                                              )
                                              :(
                                                <Grid></Grid>
                                              )
                                            }
                                          </Grid>
                                        </Grid>

                                        <Grid style={{marginTop:'4%', marginLeft:'4%', marginRight:'5%'}}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                              <TextField
                                                autoFocus
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-basic"
                                                variant="outlined"
                                                // id="name"
                                                label="Service Name"
                                                name="name"
                                                disabled = {iservice.isEditable == false ? true : false}
                                                value={iservice.name}
                                                onChange={(e) => handleChangeServiceForSubCategory(e, index, indexsub,indexservice) }
                                              />
                                              {
                                                <div className={classes.validation}>
                                                  { (iservice.name != "") ?( <div></div>) :(subcategoryServiceNameErr) }
                                                </div>
                                              }
                                            </Grid>

                                            <Grid item xs={4}>
                                              <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="outlined-basic"
                                                variant="outlined"
                                                label="Service Shortcode"
                                                name="shortcode"
                                                disabled = {iservice.isEditable == false ? true : false}
                                                value={iservice.shortcode}
                                                // onChange={(e) => handleChangeServiceForSubCategory(e, index, indexsub,indexservice) }
                                                onChange={(e) => handleChangeServiceForSubCategoryShortCode(e, index, indexsub,indexservice) }
                                              />
                                              {
                                                <div className={classes.validation}>
                                                  { (iservice.shortcode != "") ?( <div></div>) :(subcategoryServiceShortcodeErr) }
                                                </div>
                                              }
                                              {
                                                <div className={classes.validation}>
                                                  { iservice.ShortCodeAlreadyExistErr }
                                                </div>
                                              }
                                            </Grid>
                                          </Grid>

                                          <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Service Description"
                                            name="description"
                                            disabled = {iservice.isEditable == false ? true : false}
                                            value={iservice.description}
                                            onChange={(e) => handleChangeServiceForSubCategory(e, index, indexsub,indexservice) }
                                          />
                                          {
                                            <div className={classes.validation}>
                                              { (iservice.description != "") ?( <div></div>) :(subcategoryServiceDescriptionErr) }
                                            </div>
                                          }

                                          {/* <Grid id="is_active" style={{marginTop:"2%", color:"grey",marginLeft:"0.5%"}}>Is Active*</Grid>
                                          <Grid container item xs={12} style={{marginLeft:"0.5%"}}>
                                            <RadioGroup
                                              row
                                              id ="is_active"
                                              aria-label="is_active"
                                              name="is_active"
                                              value={iservice.is_active}
                                              onChange={ (e) => handleChangeServiceForSubCategory(e, index, indexsub,indexservice)}
                                            >                  
                                              <div>
                                                <FormControlLabel
                                                  value="true"
                                                  classes={{ label: classes.label }}
                                                  control={<Radio size="small" color="primary" />}
                                                  label= "Yes"
                                                />
                                              </div>
                                              <div>
                                                <FormControlLabel
                                                  value="false"
                                                  classes={{ label: classes.label }}
                                                  control={<Radio size="small" color="primary" />}
                                                  label= "No"
                                                />
                                              </div>
                                            </RadioGroup>
                                          </Grid> */}

                                        </Grid>
                                      </Paper>
                                      </AccordionDetails>
                                      </Accordion>
                
                                      <hr style={{width:'95%', color:'black'}}/>
                                    </Grid>
                                  )
                                  :(<Grid></Grid>)
                                }
                                
                                </Grid>
                              ))
                            }
                          </Grid>
                        ))
                      }
                      
                    </Grid>
                    
                  </Paper>
                  </AccordionDetails>
              </Accordion>
                ):
                (<Grid></Grid>)
              }
            </Grid>
          ))
        }  
        {/*Submit Button */}
        <div style={{marginTop:'2%'}}>
          {
            ( isSubmit == true )?
            (
              <Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  // startIcon={<AddIcon />}
                  disabled = {isSubmit == false ? true : false}
                  onClick={handelSubmitAll}
                >
                  Submit
                </Button>
              </Grid>
            ):(
              <Grid></Grid>
            )
          }
        </div>

        {/* {
          (DataIsSubmited == true)?
          (
            <Grid>
              {
                CategoryCreation.map((i, index)=>(
                  <Grid>
                    <Grid style={{fontWeight:'bold'}}>
                      {index + 1}. Category Details
                    </Grid>
                    <Grid >
                      Category Name: {i.name}
                    </Grid>
                    <Grid >
                      Category Shortcode: {i.shortcode}
                    </Grid>
                    <Grid >
                      Category Description: {i.description}
                    </Grid>
                    <Grid >
                      Is Category Active: {i.is_active}
                    </Grid>
                    <Grid style={{fontWeight:'bold'}}>
                      Service Details
                    </Grid>
                    {
                      i.services.map((iservice, indexservice)=>(
                        <Grid>
                        <Grid>
                          Service Name: {iservice.name}
                        </Grid>
                        <Grid >
                          Service Shortcode: {iservice.shortcode}
                        </Grid>
                        <Grid >
                          Service Description: {iservice.description}
                        </Grid>
                        <Grid >
                          Is Service Active: {iservice.is_active}
                        </Grid>
                        </Grid>
                      ))
                    }
                    <Grid style={{fontWeight:'bold'}}>
                      Subcategory Details
                    </Grid>
                    {
                      i.sub_category.map((isubcat, indexsubcat)=>(
                        <Grid>
                        <Grid>
                          Subcategory Name: {isubcat.name}
                        </Grid>
                        <Grid >
                          Subcategory Shortcode: {isubcat.shortcode}
                        </Grid>
                        <Grid >
                          Subcategory Description: {isubcat.description}
                        </Grid>
                        <Grid >
                          Is Subcategory Active: {isubcat.is_active}
                        </Grid>
                        </Grid>
                      ))
                    }
                    <hr/>
                  </Grid>
                ))
              }
            </Grid>
          ):
          (<div></div>)
        } */}

        {/* modal category ediut */}
        <Modal
          open={openEditCat}
          onClose={handelEditCatClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {BodyEditCat}
        </Modal>

        {/* modal service for category edit */}
        <Modal
          open={openEditCatService}
          onClose={handelEditCatServiceClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {BodyEditCatService}
        </Modal>

        {/* modal service for sub category edit */}
        <Modal
          open={openEditSubCat}
          onClose={handelEditSubCatClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {BodyEdiSubCat}
        </Modal>

        {/* modal service for sub category service edit */}
        <Modal
          open={openEditSubCatService}
          onClose={handelEditSubCatServiceClose}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {BodyEdiSubCatService}
        </Modal>

      </div>
    </div>
  );
}

export default injectIntl(TaxManagementCategoryCreation);
