import React, {useParams, useEffect} from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ConfirmMessageNewClient from "../ConfirmMessageNewClient";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
// import 'react-phone-input-2/lib/high-res.css';
// import 'react-phone-input-2/lib/bootstrap.css';
// import 'react-phone-input-2/lib/semantic-ui.css';
// import 'react-phone-input-2/lib/plain.css';
// import startsWith from 'lodash.startswith';


import { Link } from "react-router-dom";
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({

}));

export default function AddBulkClientModal({open, JsonUpload}) {

    const classes = useStyles();

    // const uploadFileClose = () => {
    //     setOpen(false);
    //     setJsonUpload(false);
    //     setZipUpload(false);
    //     setDocumentName();
    //     setDocumentFile();
    //     setFileErr("")
    // }

    // const onUploadFile = (event) => {
    //     console.log("event.target.files[0]",event.target.files[0])
    //     console.log("event.target.files[0] name",event.target.files[0].name)
    //     console.log("uri",event.target.files.uri)
    //     setDocumentName(event.target.files[0].name);
    //     setDocumentFile(event.target.files[0])
    // }

    // const validate = () => {
    //     let FileErr = '';

    //     if( !DocumentFile ){
    //         FileErr = "Please Select File To Upload";
    //     }

    //     if( FileErr ){
    //         setFileErr(FileErr);
    
    //         return false;
    //       }
    //       return true;
    // }

    // const handelSubmitUploadFile = () => {
    //     const isValid = validate();
    //     if(isValid){
    //         console.log('in handelSubmitUploadFile');
    //         setOpen(false);
    //         setJsonUpload(false);
    //         setZipUpload(false);

    //         setFileErr("")
    //     }
    // }

    // const body = (
    //     <div className={classes.paperModal}>
    //         {console.log("in body ")}
    //         <div className={classes.addNewBusinessHeaderBackgroud}> 
    //         <Grid container item xs={12}>
    //             <Grid  item xs={11}>
    //                 {
    //                     ( JsonUpload == true )?
    //                     (
    //                         <h2 style={{marginLeft:'38%'}}>UPLOAD JSON</h2>
    //                     ):
    //                     (
    //                         ( ZipUpload == true )?
    //                         (
    //                             <h2 style={{marginLeft:'40%'}}>UPLOAD ZIP</h2>
    //                         ):
    //                         (
    //                             <Grid>Upload JSON</Grid>
    //                         )
    //                     )
    //                 }
    //             </Grid>
    //             <Grid item xs={1} style={{marginTop:'1%',}}>
    //                 <CloseIcon onClick={()=>uploadFileClose()} style={{fontSize: 20, cursor:'pointer'}}/>
    //             </Grid>
    //         </Grid>
                
    //         </div>
    //         <div className={classes.modalBody}>
    //             <div style={{padding: 10     }}>
    //                 <input type='file' 
    //                     accept={( JsonUpload == true ? ".json" : ".zip")}
    //                     id="myBulk"
    //                     onChange={ (e) => onUploadFile(e)} 
    //                 />
    //                 <div className={classes.validationMaxTopMargin}>{(DocumentFile)?(<div></div>):(FileErr)}</div>
    //             </div>

    //             <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',marginTop:'2%'}}>
    //                 <Button
    //                     type="submit"
    //                     variant="contained"
    //                     color="primary"
    //                     className={classes.submit}
    //                     onClick={handelSubmitUploadFile}
    //                 >
    //                     UPLOAD
    //                 </Button>
    //                 <div style={{marginLeft:'2%'}}></div>
    //                 <Button
    //                     type="submit"
    //                     variant="contained"
    //                     // color="grey"
    //                     className={classes.submit}
    //                     onClick={uploadFileClose}
    //                 >
    //                     Cancel
    //                 </Button>
    //             </div>
    //         </div> 
    //     </div>
    // )

    return(
        <Grid>
            <Grid> ok </Grid>
            {/* <Modal
                open={open}
                onClose={uploadFileClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal> */}
        </Grid>
    )

}