import React, { useEffect } from "react";
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(stepList, comparator) {
  const stabilizedThis = stepList && stepList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'process', numeric: false, label: 'Process', },
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'description', numeric: false, label: 'Description' ,},
  // { id: 'review', numeric: false, label: 'Review' ,},
  { id: 'is_start', numeric: false, label: 'Is Start' ,},
  { id: 'is_end', numeric: false, label: 'Is End' ,},
  // { id: 'is_branch_step', numeric: false, label: 'Is Branch Step' ,},
  { id: 'no_of_branches', numeric: false, label: 'No of Branches' ,},
  // { id: 'is_parallel', numeric: false, label: 'Is Parallel' ,},
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            align="center"
            style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  rootrp: {
    flexGrow: 1,
    // backgroundColor: 'white',
  },
  cursorPointer: {
    cursor: "pointer"
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  table: {
    minWidth: 500, 
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

export default function StepList() {
    const classes = useStyles();
    const [stepList, setstepList] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, stepList && stepList.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      dispatch(userActions.checkSessionValidity());
        let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id':business_id,
                'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_ALL_STEPS , requestOptions)
        .then((Response) => Response.json())
        .then(stepDetails => {
            console.log("stepDetails on same page",stepDetails.data.steps);
            if(stepDetails.data.steps)
            {
                setstepList(stepDetails.data.steps)
            }
            if(stepDetails.success == false){
              setstepList([])
              Swal.fire({
                icon: 'error',
                text: stepDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }, [flag]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };
    
    return(
        // console.log("stepList",stepList),
        <div className={classes.rootrp}>
        <div className={classes.wrapper}>
            <Breadcrumbs  aria-label="breadcrumb">
                <Link to="BusinessDashboard" >
                    BusinessDashboard
                </Link>
                <Typography color="textPrimary">StepList</Typography>
            </Breadcrumbs>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={stepList && stepList.length}
                    />
                    <TableBody>
                        {stableSort(stepList && stepList, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((item) => {
                          console.log("row in table map",item)
                            return (
                            <TableRow 
                                key={item.step_id}
                                hover={true} classes={{hover: classes.hover}}
                                hover
                                tabIndex={-1}
                                // align="center"
                            >
                                <TableCell width="10%" align="center">{item.process}</TableCell>
                                <TableCell width="10%" align="center">{item.name}</TableCell>
                                <TableCell width="10%" align="center">{item.description}</TableCell>
                                {/* <TableCell width="10%" align="center">{item.review}</TableCell> */}
                                <TableCell width="10%" align="center">{item.is_start ? 'True' : 'false'}</TableCell> 
                                <TableCell width="10%" align="center">{item.is_end ? 'True' : 'false'}</TableCell> 
                                {/* <TableCell width="10%" align="center">{item.is_branch_step}</TableCell>   */}
                                <TableCell width="10%" align="center">{item.no_of_branches}</TableCell> 
                                {/* <TableCell width="10%" align="center">{item.is_parallel}</TableCell>  */}
                            </TableRow>
                            );
                        })}
                            {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                            )} */}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: stepList && stepList.length }]}
                                colSpan={8}
                                count={stepList && stepList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
        </div>
        </div>
    )
    
}