import * as casedetailsConstant from '../constants/casedetails.constants';

const initialState = []

export function caseDetails(state = initialState, action) {
  switch (action.type) {

    case casedetailsConstant.CASE_SHOW_DETAILS:
      return Object.assign({}, state, {
        caseDetailsShow: action.caseDetailsShow
      });

    case casedetailsConstant.CASE_ALL_TAGS:
      return Object.assign({}, state, {
        caseAllTags: action.caseAllTags
      });

    case casedetailsConstant.CASE_SHOW_DETAILS_RESET:
      return initialState;

    default:
      return state
  }
}