import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CategoryList from "./CategoryList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  rootmy: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  buttonContainer:{
    textAlign:"right",
  },
}));





export default function CategoryBulkTrial() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [ExcelName, setExcelName] = React.useState();

const onUploadExcel= (event) => {
    console.log("event.target.files[0]",event.target.files[0])
    console.log("event.target.files[0] name",event.target.files[0].name)
    setExcelName(event.target.files[0].name);
}

function getSteps() {
  return ['Download CSV File ', 'Show Table', 'Upload File'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (<div><Button>Ok</Button></div>);
    case 1:
      return (<CategoryList/>);
    case 2:
      return (  <div>
                    <input type='file' 
                    accept=".xlsx"
                    id="myInputResume"
                    onChange={ (e) => onUploadExcel(e)} hidden />
                    <label htmlFor="myInputResume" 
                        style={{color:'blue', fontSize: "15px", marginTop:'1.5%'}}>
                        Upload Excel
                    </label>
                    <br/>
                    <label>{ExcelName}</label>
                </div>
            );
    default:
      return 'Unknown step';
  }
}

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.rootmy}>
    <div className={classes.wrapper}>
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
    </div>
    </div>
  );
}