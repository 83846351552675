import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import { Link , Route, Redirect} from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { useSelector , useDispatch} from 'react-redux';
// import useCheckSessionApi from "../../SessionValidity";
import { userActions } from "../../actions";

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      cursor: "pointer",
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  paperModalCreateRole: {
    position: 'absolute',
    width: 500,
    // height: 810,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },

}));


export default function Clients() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [XMLFile, setXMLFile] = React.useState();
    const [XMLName, setXMLName] = React.useState();
    const [fileErr, setfileErr] = React.useState();
  
    const [openCreateMarker, setopenCreateMarker] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
    }, [flag]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelAddNewUXMLOpen = () => {
        setOpen(true);
        dispatch(userActions.checkSessionValidity());
    }

    const handelAddNewUXMLClose = () => {
        setOpen(false);
        setfileErr();
        dispatch(userActions.checkSessionValidity());
    };

    const onUploadXML= (event) => {
      console.log("event.target.files[0]",event.target.files[0])
      console.log("event.target.files[0] name",event.target.files[0].name)
      setXMLName(event.target.files[0].name);
      setXMLFile(event.target.files[0]);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let fileErr = '';

      if(!XMLFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitAddNewXML = () => {
      const isValid = validate();
      if(isValid){
        console.log("handelSubmitAddNewXML",XMLFile);
        setXMLName();
        setXMLFile();
        setfileErr();
        setOpen(false);
        dispatch(userActions.checkSessionValidity());
      }
    }

    const body = (
        <div className={classes.paperModal}>
        {/* <h2 className={classes.addNewBusinessHeader}>Add New Business</h2> */}
        <div className={classes.addNewBusinessHeaderBackgroud}> 
            <h2>Add New XML</h2>
        </div>
        <div className={classes.modalBodyBulk}>
          <div>
            <input type='file' 
              // accept=".xml"
              id="myBulk"
              onChange={ (e) => onUploadXML(e)} 
            />
          </div>
          <div className={classes.validationBulk}>{(XMLFile)?(<div></div>):(fileErr)}</div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitAddNewXML}
                >
                Add
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelAddNewUXMLClose}
                >
                Cancel
                </Button>
            </div>
        </div>
        </div>
    )

    const handelCreateNewMarkerOpen = () => {
      setopenCreateMarker(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateNewMarkerClose = () => {
      setopenCreateMarker(false);
      dispatch(userActions.checkSessionValidity());
    }

    // const validateCreateMarker = () => {
    //   let roleShortCodeErr = '';

    //   if(!roleDetails.shortcode)  {
    //       roleShortCodeErr = 'Please Enter Short Code';
    //   }

    //   if(roleNameErr ){
    //     setroleNameErr(roleNameErr);
    //     return false;
    //   }

    //   return true;
    // }

    const handelSubmitCreateNewMarker = () => {
      console.log("handelSubmitCreateNewMarker");
      dispatch(userActions.checkSessionValidity());
      // const isValid = validateCreateMarker();
      // if(isValid){

      // }
    }

    const bodyCreateMarker = (
      <div className={classes.paperModalCreateRole}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Create New Marker</h2>
      </div>
      <div className={classes.modalBody}>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitCreateNewMarker}
          >
            Create
          </Button>
          <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handelCreateNewMarkerClose}
            >
              Cancel
            </Button>
          </div>
      </div>
      </div>
    )

    return(
    // ( redirectUserList == true )?( <Redirect to="/UsersList" /> ):(
        <Grid item xs={4} >
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={1} className={classes.moreVertIcon}>
                        <PersonIcon className={classes.personIconColor}/>
                    </Grid>
                    <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                        Clients
                    </Grid>
                </Grid>
                <hr/>
                <Grid container>
                    {/* <Grid onClick={handleUserList} item xs={12}  className={classes.userAddUpdateDelete}> */}
                    <Grid item xs={12}  className={classes.userAddUpdateDelete}>
                      <Link to="ClientsList" style={{ textDecoration: 'none', color:'black' }}>
                        Clients XML List
                      </Link>
                    </Grid>
                    <Grid onClick={handelAddNewUXMLOpen} item xs={12} className={classes.userAddUpdateDelete}>
                        Add Clients XML
                    </Grid>
                    <Modal
                        open={open}
                        onClose={handelAddNewUXMLClose}
                        className={classes.modal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>
                    <Grid onClick={handelCreateNewMarkerOpen} item xs={12} className={classes.userAddUpdateDelete}>
                        Define Markers
                    </Grid>
                    <Modal
                        open={openCreateMarker}
                        onClose={handelCreateNewMarkerClose}
                        className={classes.modal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {bodyCreateMarker}
                    </Modal>
                </Grid>
            </Paper>
        </Grid>
        )
    // )

}
