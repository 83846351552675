import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   // color: "#eaeaf0",
  //   // display: "flex",
  //   // height: 22,
  //   alignItems: "center",
  // },
  // root: {
  //   flexGrow: 1,
  // },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  showProcedureStyle: {
    // display: "flex",
    // alignItems: "center",
    // alignSelf: "center",
    // marginLeft: "50%",
    // marginTop: "-20%",
     position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  additionalDetail: {
    background: "#E5E5E5",
  },
  buttonContainer: {
    textAlign: "right",
    margin: 1,
  },
  buttonStyleBulkDashboard: {
    margin: 1,
    marginLeft: '3%',
  },
  casesdisplay: {
    display: "block",
  },
  casesdisplayNone: {
    display: "none",
  },
  customFilterLabel: {
    textTransform: "none",
    background: "#FFFFFF",
  },
  drawerPaper: {
    marginTop: "145px",
    width: "425px",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  marginTopStyle: {
    marginTop:"250px"
  },
  table: {
    minWidth: 500, 
  },
  tableWrapper:{
    boxShadow: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '0px',
  },
  // hover: {
  //   cursor: "pointer",
  // },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  SearchBoxStyle:{
    background: "#FFFFFF",
    width: "150%",
    marginLeft: "-50%",
    // height: "10%",
  },
  hover: {
    cursor: "pointer",
  },
  containedPurple: {
    color: 'white',
    backgroundColor: '#294b94',
    "&:hover": {
      backgroundColor: '#294b94',
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: '#294b94'
      }
    }
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  // 
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
    marginLeft: "-10%",
  },
  buttonContainer: {
    textAlign: "right",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3), 
  },
  validationMaxTopMargin: {
    color: 'red',
    fontSize: 12,
    marginTop: '2%',
  },
  customPaperStyle: {
    borderRadius: "10px",
    // marginLeft: "3%",
  },
  casesDetailSubMenu: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
  },
  tabCustomStyle: {
    textTransform: "none",
    // width:'10%'
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  button: {
    margin: "1%",
  },
}));

export default useStyles;
