import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  notfoundWrapper: {
    position: "relative",
    height: "75vh",
    marginTop: "10%"
  },
  notfound: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "520px",
    width : "100%",
    lineHeight: "1.4",
    textAlign: "center"
  },
  notfound404: {
    position: "relative",
    height: "240px"
  },
  notfound404h1: {
    position: "absolute",
    left: "50%",
    top: "50%",
    fontFamily: [
      "Montserrat",
      "sans-serif",
    ].join(","),
    transform: "translate(-50%, -50%)",
    fontSize: "252px",
    fontWeight: "900",
    margin: "0px",
    color: "#4D47DD",
    textTransform: "uppercase",
    letterSpacing: "-40px",
    marginLeft: "-20px"
  },
  notfoundspan: {
    textShadow: "-8px 0px 0px #fff"
  },
  notfoundh3: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    position: "relative",
    fontSize: "16px",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#262626",
    margin: "0px",
    letterSpacing: "3px",
    paddingLeft: "6px"
  },
  notfoundh2: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontSize: "20px",
    fontWeight: "400",
    textTransform: "uppercase",
    color: "#000",
    marginTop: "0px",
    marginBottom: "25px"
  }
}));

export default function TaxManagementNotFound() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.notfoundWrapper}>
            <div className={classes.notfound}>
              <div className={classes.notfound404}>
                <h3 className={classes.notfoundh3}><FormattedMessage id="404NotFoundHead1" /></h3>
                <h1 className={classes.notfound404h1}>
                  <span className={classes.notfoundspan}>4</span>
                  <span className={classes.notfoundspan}>0</span>
                  <span className={classes.notfoundspan}>4</span>
                </h1>
              </div>
              <h2 className={classes.notfoundh2}><FormattedMessage id="404NotFoundHead2" /></h2>
              <Link to="/search"><FormattedMessage id="404NotfoundSearchLink" /></Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
