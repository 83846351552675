import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Swal from 'sweetalert2';
import { authHeader } from '../../helpers';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
// import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ReceiptIcon from "@material-ui/icons/Receipt";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function CategoryTree() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [categoryTreeDetails, setcategoryTreeDetails] = React.useState({
        category_id: "",
        sub_category_id: "",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const[categoryListDropdown, setcategoryListDropdown] = React.useState([])
    const[subCategoryListDropdown ,setsubCategoryListDropdown] = React.useState([]);
    const[serviceListDropdown, setserviceListDropdown] = React.useState([]);
    const[selectedCategoryType, setselectedCategoryType] =  React.useState("");
    const[categoryAndSuperCatList ,setcategoryAndSuperCatList] = React.useState([]);
    const[categoryAndServiceListDropdown, setcategoryAndServiceListDropdown] = React.useState([]);
    const [categoryErr, setcategoryErr] = React.useState();
    const [subCategoryErr, setsubCategoryErr] = React.useState();
    const [activeErr, setactiveErr] = React.useState(); 
    const [count, setCount] = React.useState(0);

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());

      // let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // let device_token = JSON.parse(localStorage.getItem('device_token'));
      // let session_id = JSON.parse(localStorage.getItem('session_id'));

      // let category_type = "category"
      //   const requestOptionsCategory = {
      //       method: 'POST',
      //       headers: authHeader(),
      //       body: JSON.stringify({'category_type': category_type,'business_id':business_id,
      //       'device_token': device_token, 'session_id': session_id })
      //   };

      //   fetch(process.env.REACT_APP_GET_CATEGORIES, requestOptionsCategory)
      //   .then((Response) => Response.json())
      //   .then(categoryDetails => {
      //       console.log("categoryDetails for category dropdown on tree",categoryDetails.data.categories);
      //       if(categoryDetails.data.categories)
      //       {
      //         setcategoryListDropdown(categoryDetails.data.categories)
      //       }
      //       else{
      //         setcategoryListDropdown([])
      //       }
      //   })
      //   .catch(err => {
      //     Swal.fire({
      //       icon: 'error',
      //       // text: "Something went wrong",
      //       text: "Server Error. Please try again.",
      //       confirmButtonColor: '#d33',
      //       confirmButtonText: 'OK'
      //     })
      //   });

      // let category_type_for_sub = "supercategory"
      // const requestOptionsTwo = {
      //   method: 'POST',
      //   headers: authHeader(),
      //   body: JSON.stringify({'category_type': category_type_for_sub, 'business_id':business_id,
      //     'device_token': device_token, 'session_id': session_id })
      // };

      // fetch(process.env.REACT_APP_GET_CATEGORIES, requestOptionsTwo)
      // .then((Response) => Response.json())
      // .then(categoryDetails => {
      //   console.log("sub category on category tree page",categoryDetails.data.categories);
      //   if(categoryDetails.data.categories)
      //   {
      //     setsubCategoryListDropdown(categoryDetails.data.categories)
      //     // const categoryAndSuperCatListMerger = [...categoryListDropdown, ...subCategoryListDropdown]
      //     // return(
      //     //   setcategoryAndSuperCatList(categoryAndSuperCatListMerger),
      //     //   console.log("categoryAndSuperCatList ================================",categoryAndSuperCatList)
      //     // )
      //   }
      //   else{
      //     setsubCategoryListDropdown([])
      //   }
      //   // let categoryAndSuperCatListMerger = categoryListDropdown.concat(subCategoryListDropdown);
      //   // const categoryAndSuperCatListMerger = [...categoryListDropdown, ...subCategoryListDropdown]
      //   // setcategoryAndSuperCatList(categoryAndSuperCatListMerger)
      //   // console.log("categoryAndSuperCatList ================================",categoryAndSuperCatList)     


      //   // let a= ['a', 'b', 'c']
      //   // let b= ['aa', 'bb', 'cc']
      //   // const categoryAndSuperCatListMerger = [...a, ...b]
      //   // setcategoryAndSuperCatList(categoryAndSuperCatListMerger)
      //   // console.log("categoryAndSuperCatList ================================",categoryAndSuperCatList)
      // })
      // .catch(err => {
      //   Swal.fire({
      //     icon: 'error',
      //     // text: "Something went wrong",
      //     text: "Server Error. Please try again.",
      //     confirmButtonColor: '#d33',
      //     confirmButtonText: 'OK'
      //   })
      // });

      // let category_type_for_service = "service"
      // const requestOptionsService = {
      //   method: 'POST',
      //   headers: authHeader(),
      //   body: JSON.stringify({'category_type': category_type_for_service, 'business_id':business_id,
      //     'device_token': device_token, 'session_id': session_id })
      // };

      // fetch(process.env.REACT_APP_GET_CATEGORIES, requestOptionsService)
      // .then((Response) => Response.json())
      // .then(categoryDetails => {
      //   console.log("service category on category tree page",categoryDetails.data.categories);
      //   if(categoryDetails.data.categories)
      //   {
      //     setserviceListDropdown(categoryDetails.data.categories)
      //   }
      //   else{
      //     setserviceListDropdown([])
      //   }
      // })
      // .catch(err => {
      //   Swal.fire({
      //     icon: 'error',
      //     // text: "Something went wrong",
      //     text: "Server Error. Please try again.",
      //     confirmButtonColor: '#d33',
      //     confirmButtonText: 'OK'
      //   })
      // });

    }, [flag, count]);

    // const handelCreateCategoryTreeOpen = () => {
    //     dispatch(userActions.checkSessionValidity());
    //     setflag(false);
    //     setCount(count + 1);
        
    //     // setOpen(true);
        
    //     // refreshCategoryForDropdown();
    //     const categoryAndSuperCatListMerger = [...categoryListDropdown, ...subCategoryListDropdown]
    //     setcategoryAndSuperCatList(categoryAndSuperCatListMerger)
    //     console.log("categoryAndSuperCatList ================================",categoryAndSuperCatList)
    //     console.log("categoryListDropdown open",categoryListDropdown)
    //     console.log("subCategoryListDropdown open",subCategoryListDropdown)
    //     console.log("serviceListDropdown open",serviceListDropdown)
    //     const categoryAndServiceListDropdownMerge = [...categoryListDropdown, ...serviceListDropdown]
    //     setcategoryAndServiceListDropdown(categoryAndServiceListDropdownMerge)
    //     console.log("categoryAndServiceListDropdown ================================",categoryAndServiceListDropdown)
    // }

    const handelCreateCategoryTreeOpen = async() => {
      console.log("in handelCreateCategoryTreeOpen")
      dispatch(userActions.checkSessionValidity());
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      let category_type = "category"
        const requestOptionsCategory = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'category_type': category_type,'business_id':business_id,
            'device_token': device_token, 'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptionsCategory)
        .then((Response) => Response.json())
        .then( async(categoryDetails) => {
            setflag(false);
            setCount(count + 1);
            console.log("categoryDetails for category dropdown on tree",categoryDetails.data.categories);
            if(categoryDetails.data.categories)
            {
              await setcategoryListDropdown(categoryDetails.data.categories)
            }
            else{
              await setcategoryListDropdown([])
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

      let category_type_for_sub = "supercategory"
      const requestOptionsTwo = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'category_type': category_type_for_sub, 'business_id':business_id,
          'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptionsTwo)
      .then((Response) => Response.json())
      .then( async(categoryDetails) => {
        setflag(false);
        setCount(count + 1);
        console.log("sub category on category tree page",categoryDetails.data.categories);
        if(categoryDetails.data.categories)
        {
          await setsubCategoryListDropdown(categoryDetails.data.categories)
        }
        else{
          await setsubCategoryListDropdown([])
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

      let category_type_for_service = "service"
      const requestOptionsService = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'category_type': category_type_for_service, 'business_id':business_id,
          'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptionsService)
      .then((Response) => Response.json())
      .then( async(categoryDetails) => {
        setflag(false);
        setCount(count + 1);
        console.log("service category on category tree page",categoryDetails.data.categories);
        if(categoryDetails.data.categories)
        {
          await setserviceListDropdown(categoryDetails.data.categories)
        }
        else{
          await setserviceListDropdown([])
        }
        
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
      handelCreateCategoryTreeOpenThree()
    }

    const handelCreateCategoryTreeOpenTwo = async() => {
      setflag(false);
      setCount(count + 1);
      console.log("in handelCreateCategoryTreeOpenTwo")
      const categoryAndSuperCatListMerger = [...categoryListDropdown, ...subCategoryListDropdown]
        await setcategoryAndSuperCatList(categoryAndSuperCatListMerger)
        console.log("categoryAndSuperCatList ================================",categoryAndSuperCatList)
        console.log("categoryListDropdown open",categoryListDropdown)
        console.log("subCategoryListDropdown open",subCategoryListDropdown)
        console.log("serviceListDropdown open",serviceListDropdown)
        const categoryAndServiceListDropdownMerge = [...categoryListDropdown, ...serviceListDropdown]
        await setcategoryAndServiceListDropdown(categoryAndServiceListDropdownMerge)
        console.log("categoryAndSuperCatListMerger ================================",categoryAndSuperCatListMerger)
        console.log("categoryAndServiceListDropdown ================================",categoryAndServiceListDropdown)

        handelCreateCategoryTreeOpenThree()
    }

    const handelCreateCategoryTreeOpenThree = () => {
      setflag(false);
      setCount(count + 1);
      console.log("in handelCreateCategoryTreeOpenThree")
      setOpen(true);
    }

    const handelCreateCategoryTreeClose = () => {
        setOpen(false);
        setcategoryTreeDetails({
          category_id: "",
          sub_category_id: "",
          is_active: "",
          device_token: JSON.parse(localStorage.getItem('device_token')),
          session_id: JSON.parse(localStorage.getItem('session_id')),
        })
        setcategoryErr();
        setsubCategoryErr();
        setactiveErr();
        dispatch(userActions.checkSessionValidity());
    };

    const handelCreateCategoryTreeClear = async() => {
      await setcategoryTreeDetails({
        category_id: "",
        sub_category_id: "",
        is_active: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      setcategoryErr();
      setsubCategoryErr();
      setactiveErr();
    }

    const handelCreateCategoryTreeMinimize = () => {
      setOpen(false);
    }

    const validate = () => {
       let categoryErr = '';
       let subCategoryErr = '';
       let activeErr = '';

      if(!categoryTreeDetails.category_id)  {
          categoryErr = 'Please Select Category Type';
      }
      if(!categoryTreeDetails.sub_category_id)  {
          subCategoryErr = 'Please Select Sub Category Type';
      }
      if(categoryTreeDetails.is_active == "" && categoryTreeDetails.is_active !== false && categoryTreeDetails.is_active !== true)  {
          activeErr = 'Please Select is Active';
      }

      if(categoryErr || subCategoryErr || activeErr ){
        setcategoryErr(categoryErr);
        setsubCategoryErr(subCategoryErr);
        setactiveErr(activeErr);

        return false;
      }

      return true;
    }

    const handelSubmitCreateCategoryTree = () => {
      dispatch(userActions.checkSessionValidity());
      console.log("categoryTreeDetails", categoryTreeDetails)
      const isValid = validate();
        if(isValid){
            setShowProcedure(true);
            setOpen(false);
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(categoryTreeDetails)
            };
            return fetch( process.env.REACT_APP_UPDATE_CATEGORY_TREE , requestOptions)
                .then((Response) => Response.json())
                .then(categoryTreeCreated => {
                  setShowProcedure(false);
                  console.log("categoryTreeCreated on same page",categoryTreeCreated.success);
                  console.log("categoryTreeCreated on same page",categoryTreeCreated);
                  if(categoryTreeCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Category Tree Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setcategoryTreeDetails({
                      category_id: "",
                      sub_category_id: "",
                      is_active: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    setcategoryErr();
                    setsubCategoryErr();
                    setactiveErr();
                  }
                  if(categoryTreeCreated.success == false || categoryTreeCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: categoryTreeCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                  }
                  
                })
                .catch(err => {
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
        }
    }

    const handleChangeCategoryDropdown = (e) => {
      console.log("e",e.target.value)
      console.log("selectedCategoryType",selectedCategoryType)
      setcategoryTreeDetails({...categoryTreeDetails, category_id:e.target.value })

        // this is mergerd array which is not having last added value without refresh
        // categoryAndSuperCatList.map( async(item) =>{
        //   if( item.category_id == e.target.value){
        //     console.log("id is matched",item)
        //     console.log("item category_type",item.category_type)
        //     return(
        //       await setselectedCategoryType(item.category_type),
        //       console.log("selectedCategoryType",selectedCategoryType)
        //     )
        //   }
        // })
        // //////////////////////////////////////////////////////////////////////////

        categoryListDropdown.map( async(item) =>{
          if( item.category_id == e.target.value){
            console.log("id is matched",item)
            console.log("item category_type",item.category_type)
            return(
              await setselectedCategoryType(item.category_type),
              console.log("selectedCategoryType",selectedCategoryType)
            )
          }
        })

        subCategoryListDropdown.map( async(item) =>{
          if( item.category_id == e.target.value){
            console.log("id is matched",item)
            console.log("item category_type",item.category_type)
            return(
              await setselectedCategoryType(item.category_type),
              console.log("selectedCategoryType",selectedCategoryType)
            )
          }
        })

    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          {/* <h2>Create Category Tree</h2> */}
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'35%'}}>Create Category Tree</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateCategoryTreeMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateCategoryTreeClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody}>
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="category_id">Parent Category*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Parent Category*</Grid>
              <Select
                native
                labelId="category_id"
                id="category_id"
                value={categoryTreeDetails.category_id}
                // onChange={(e) => setcategoryTreeDetails({...categoryTreeDetails, category_id:e.target.value }) }
                onChange = {handleChangeCategoryDropdown}
              >
                <option value="" style={{color:'grey'}}>Select Category or Super Category</option>
                  {
                  // categoryAndSuperCatList && categoryAndSuperCatList.map(item =>
                  //   <option value={item.category_id}>{item.name}</option>
                  // )
                  categoryListDropdown && categoryListDropdown.map(item =>
                      <option value={item.category_id}>{item.name}</option>
                    )
                  
                  }
                  {
                    subCategoryListDropdown && subCategoryListDropdown.map(item =>
                      <option value={item.category_id}>{item.name}</option>
                    )
                  }
              </Select>
            </FormControl>
            <div className={classes.validation}>{(categoryTreeDetails.category_id)?(<div></div>):(categoryErr)}</div>

            {
              ( categoryTreeDetails.category_id !== "" )?
              (
                ( selectedCategoryType == "category" )?
              (
                <div>
                <FormControl className={classes.formControl}>
                  {/* <InputLabel id="category_id">Child Category*</InputLabel> */}
                  <Grid style={{marginTop:"2%", color:"grey"}}>Child Category*</Grid>
                  <Select
                    native
                    labelId="sub_category_id"
                    id="sub_category_id"
                    value={categoryTreeDetails.sub_category_id}
                    onChange={(e) => setcategoryTreeDetails({...categoryTreeDetails, sub_category_id:e.target.value }) }
                  > 
                    {/* <MenuItem value={''}>Select Service</MenuItem>
                    {serviceListDropdown && serviceListDropdown.map(item =>
                      <MenuItem value={item.category_id}>{item.name}</MenuItem>
                    )} */}
                    <option value="" style={{color:'grey'}}>Select Service</option>
                    {serviceListDropdown && serviceListDropdown.map(item =>
                      <option value={item.category_id}>{item.name}</option>
                    )}
                  </Select>
                </FormControl>
                <div className={classes.validation}>{(categoryTreeDetails.sub_category_id)?(<div></div>):(subCategoryErr)}</div>
              </div>
              ):
              (
                <div>
                <FormControl className={classes.formControl}>
                  <InputLabel id="category_id">Child Category*</InputLabel>
                  <Select
                    labelId="sub_category_id"
                    id="sub_category_id"
                    value={categoryTreeDetails.sub_category_id}
                    onChange={(e) => setcategoryTreeDetails({...categoryTreeDetails, sub_category_id:e.target.value }) }
                  > 
                    <option value="" style={{color:'grey'}}>Select Category or Service</option>
                    {
                      // categoryAndServiceListDropdown && categoryAndServiceListDropdown.map(item =>
                      // <option value={item.category_id}>{item.name}</option>
                      // )
                      categoryListDropdown && categoryListDropdown.map(item =>
                        <option value={item.category_id}>{item.name}</option>
                        )
                    }
                    {
                      serviceListDropdown && serviceListDropdown.map(item =>
                        <option value={item.category_id}>{item.name}</option>
                      )
                    }
                   
                  </Select>
                </FormControl>
                <div className={classes.validation}>{(categoryTreeDetails.sub_category_id)?(<div></div>):(subCategoryErr)}</div>
                </div>
              )
              ):(<div></div>)

              
            }
            

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_active">Is Active*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(categoryTreeDetails.is_active)}
                  onChange={ (e) => setcategoryTreeDetails({...categoryTreeDetails, is_active: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_active"
                id="is_active"
                value={categoryTreeDetails.is_active}
                onChange={(e) => setcategoryTreeDetails({...categoryTreeDetails, is_active:e.target.value }) }
              >
                <MenuItem value={'True'}>True</MenuItem>
                <MenuItem value={'False'}>False</MenuItem>
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(categoryTreeDetails.is_active == "" && categoryTreeDetails.is_active !== false && categoryTreeDetails.is_active !== true)?(activeErr):(<div></div>)}</div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateCategoryTree}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                    onClick={handelCreateCategoryTreeClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelCategoryTreeBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCategoryTreeBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCategoryTreeBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCategoryTreeBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0])
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitCategoryTreeBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);
      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_CATEGORY , requestOptions)
            .then((Response) => Response.json())
            .then( categoryTreeBulk => {
                setShowProcedure(false);
                console.log("categoryTreeBulk on same page",categoryTreeBulk.success);
                  console.log("categoryTreeBulk on same page",categoryTreeBulk);
                  if(categoryTreeBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Category Tree Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(categoryTreeBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: categoryTreeBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'35%'}}>Category Tree Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCategoryTreeBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCategoryTreeBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
          <div className={classes.modalBodyBulk}>
            {/* <input type='file' 
              accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadExcel(e)} 
            /> */}
            <Grid container>
              <Grid item xs={3}>
                <input type='file' 
                  // id="files"
                  // accept=".xlsx"
                  id="myBulk"
                  onChange={ (e) => onUploadExcel(e)}  
                  hidden
                />
                <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                  cursor: "pointer"}}>Select file</label>
              </Grid>
              <Grid item xs={6} style={{marginLeft:'-2%'}}>
                {
                  ( ExcelName)?
                  (
                    <div >
                      {/* <a 
                        href={this.state.BiographyResumeFileName} target = "_blank"> */}
                        <text>{ExcelName}</text>
                      {/* </a> */}
                    </div>
                  ):
                  (
                    <div>
                      No file Choosen 
                    </div>
                  )
                }
              </Grid>         
            </Grid>
            <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitCategoryTreeBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelCategoryTreeBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

    return(
    console.log("categoryAndSuperCatList in category treee", categoryAndSuperCatList),
    <Grid item xs={4} >
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <ReceiptIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Category Tree List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12}  className={classes.userAddUpdateDelete}>
                    <Link to="CategoryTreeList" style={{ textDecoration: 'none', color:'black' }}>
                      Category Tree List
                    </Link>   
                </Grid>
                <Grid item xs={12} onClick={handelCreateCategoryTreeOpen} className={classes.userAddUpdateDelete}>
                    Create Category Tree
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateCategoryTreeClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelCategoryTreeBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Category Tree
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelCategoryTreeBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
    )
}
