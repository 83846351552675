import * as newcaseConstant from '../constants/newcase.constants';

const initialState = []

export function newCase(state = initialState, action) {
  switch (action.type) {

    case newcaseConstant.NEW_CASE_DETAILS:
      return Object.assign({}, state, {
        newCaseDetails: action.newCaseDetails
      });

    case newcaseConstant.SELF_ASSESMENT:
      return Object.assign({}, state, {
        selfAssesment: action.selfAssesment
      });

    case newcaseConstant.SUPER_CATEGORY:
      return Object.assign({}, state, {
        superCategory: action.superCategory
      });

    case newcaseConstant.SUB_CATEGORY:
      return Object.assign({}, state, {
        subCategory: action.subCategory
      });

    case newcaseConstant.SUB_CATEGORY_FETCHED:
      return Object.assign({}, state, {
        subCategoryFetched: action.subCategoryFetched
      });

    case newcaseConstant.SELECTED_CATEGORY_IS_CATEGORY_FLAG:
      return Object.assign({}, state, {
        selectedCategoryFlag: action.selectedCategoryFlag
      });

    case newcaseConstant.LAST_CATEGORY:
      return Object.assign({}, state, {
        lastCategory: action.lastCategory
      });

    case newcaseConstant.SSE_INFO:
      return Object.assign({}, state, {
        sseInfo: action.sseInfo
      });

    case newcaseConstant.CREATE_CASE_DETAILS:
      return Object.assign({}, state, {
        createCaseDetails: action.createCaseDetails
      });

    case newcaseConstant.QSN_LIST:
      return Object.assign({}, state, {
        qsnList: action.qsnList
      });

    case newcaseConstant.QSN_LIST_LEVEL_TWO:
      return Object.assign({}, state, {
        qsnListLevelTwo: action.qsnListLevelTwo
      });

    case newcaseConstant.QSN_LIST_LEVEL_THREE:
      return Object.assign({}, state, {
        qsnListLevelThree: action.qsnListLevelThree
      });

    case newcaseConstant.NEW_CASE_CREATED_CASE_ID:
      return Object.assign({}, state, {
        newCaseCreatedCaseId: action.newCaseCreatedCaseId
      });

    case newcaseConstant.YEAR_ASS:
      return Object.assign({}, state, {
        yearAss: action.yearAss
      });

    case newcaseConstant.ASSENAME_OR_OTHER_CONTACT_SELECTED:
      return Object.assign({}, state, {
        asseNameOrContactSelected: action.asseNameOrContactSelected
      });

    case newcaseConstant.ADD_NEW_CONTACT_SELECTED:
      return Object.assign({}, state, {
        newContactSelected: action.newContactSelected
      });

    case newcaseConstant.NEW_CASE_ACTION_RESET:
      return initialState;

    default:
      return state
  }
}
