import * as casedetailsConstant from '../constants/casedetails.constants';


export function addcaseDetailsShow(caseDetailsShow) {
    return { 
        type: casedetailsConstant.CASE_SHOW_DETAILS, 
        caseDetailsShow 
    };
}

export function addcaseAllTags(caseAllTags) {
    return { 
        type: casedetailsConstant.CASE_ALL_TAGS, 
        caseAllTags 
    };
}

export function caseDetailsActionReset() {
    return {
      type: casedetailsConstant.CASE_SHOW_DETAILS_RESET,
    }
}