import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormattedMessage, injectIntl } from "react-intl";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import useCheckSessionApi from "../../SessionValidity";
import { userActions } from "../../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: "rgba(0, 0, 0, 0.32)",
    textAlign: "left",
  },
  leftAlignWithoutColor: {
    textAlign: "left",
  },
  rightAlignWithoutColor: {
    textAlign: "right",
  },
  messageButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "#4D47DD",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  addMessageLabel: {
    textAlign: 'left',
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: "16px",
    fontWeight: 600,
  },
  inputPadding: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: '5%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
}));

function TaxManagementCasesQandA(caseQuestionAnswers) {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);

  const [flag, setflag] = React.useState(false);
  const dispatch = useDispatch();

    React.useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      dispatch(userActions.checkSessionValidity());
    }, [flag]);


    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

  const showQandA = () =>{
    // console.log("in show q and a")
    return(
      <Grid>
      <Grid style={{textAlign:"left", fontSize: 20, fontWeight: "bold"}}>Level 1:</Grid>
      {
        caseQuestionAnswers.caseQuestionAnswers.filter(item => item.level == "1").map((item, index) =>(
            <Grid>
              {
                ( item.qtype == "BOOLEAN" )?
                (
                  <Grid container className={classes.gridContianer}>
                    <Grid item xs={1}>
                      {/* <Avatar src="/static/images/avatar/1.jpg" /> */}
                      {index + 1}
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {item.title}
                        </Grid>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {/* {JSON.stringify(item.answer_bool)} */}
                          {
                            (item.answer_bool == true)?
                            (
                              <Grid>Yes</Grid>
                            ):
                            (
                              <Grid>No</Grid>
                            )
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ):
                (
                  ( item.qtype == "SINGLE-ANSWER" )?
                  (
                    <Grid container className={classes.gridContianer}>
                      <Grid item xs={1}>
                        {index + 1}
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container>
                          <Grid item xs={12} className={classes.leftAlign}>
                            {item.title}
                          </Grid>
                          
                            {/* {JSON.stringify(item.answer_bool)} */}
                            {
                              item.answer_option.map((i, e) =>(
                                <Grid item xs={12} className={classes.leftAlign}>
                                  {i.option}
                                </Grid>
                              ))
                            }
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  ):
                  (
                    ( item.qtype == "TEXT-ANSWER" )?
                    (
                      <Grid container className={classes.gridContianer}>
                        <Grid item xs={1}>
                          {index + 1}
                        </Grid>
                        <Grid item xs={11}>
                          <Grid container>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.title}
                            </Grid>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.answer_other}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    ):
                    (
                      (item.qtype == "MULTIPLE-ANSWER" )?
                      (
                        <Grid container className={classes.gridContianer}>
                          <Grid item xs={1}>
                            {index + 1}
                          </Grid>
                          <Grid item xs={11}>
                            <Grid container>
                              <Grid item xs={12} className={classes.leftAlign}>
                                {item.title}
                              </Grid>
                              
                                {/* {JSON.stringify(item.answer_bool)} */}
                                {
                                  item.answer_option.map((i, e) =>(
                                    <Grid item xs={12} className={classes.leftAlign}>
                                      {i.option}
                                    </Grid>
                                  ))
                                }
                              
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      ):
                      (<div></div>)
                    )
                  )
                )
              }
            </Grid>

            ))
      }
      <Grid style={{textAlign:"left", fontSize: 20, fontWeight: "bold", marginTop:"2%"}}>Level 2:</Grid>
      {
        caseQuestionAnswers.caseQuestionAnswers.filter(item => item.level == "2").map((item, index) =>(
            <Grid>
              {
                ( item.qtype == "BOOLEAN" )?
                (
                  <Grid container className={classes.gridContianer}>
                    <Grid item xs={1}>
                      {/* <Avatar src="/static/images/avatar/1.jpg" /> */}
                      {index + 1}
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {item.title}
                        </Grid>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {JSON.stringify(item.answer_bool)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ):
                (
                  ( item.qtype == "SINGLE-ANSWER" )?
                  (
                    <Grid container className={classes.gridContianer}>
                      <Grid item xs={1}>
                        {index + 1}
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container>
                          <Grid item xs={12} className={classes.leftAlign}>
                            {item.title}
                          </Grid>
                          
                            {/* {JSON.stringify(item.answer_bool)} */}
                            {
                              item.answer_option.map((i, e) =>(
                                <Grid item xs={12} className={classes.leftAlign}>
                                  {i.option}
                                </Grid>
                              ))
                            }
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  ):
                  (
                    ( item.qtype == "TEXT-ANSWER" )?
                    (
                      <Grid container className={classes.gridContianer}>
                        <Grid item xs={1}>
                          {index + 1}
                        </Grid>
                        <Grid item xs={11}>
                          <Grid container>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.title}
                            </Grid>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.answer_other}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    ):
                    (
                      (item.qtype == "MULTIPLE-ANSWER" )?
                      (
                        <Grid container className={classes.gridContianer}>
                          <Grid item xs={1}>
                            {index + 1}
                          </Grid>
                          <Grid item xs={11}>
                            <Grid container>
                              <Grid item xs={12} className={classes.leftAlign}>
                                {item.title}
                              </Grid>
                              
                                {/* {JSON.stringify(item.answer_bool)} */}
                                {
                                  item.answer_option.map((i, e) =>(
                                    <Grid item xs={12} className={classes.leftAlign}>
                                      {i.option}
                                    </Grid>
                                  ))
                                }
                              
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      ):
                      (<div></div>)
                    )
                  )
                )
              }
            </Grid>

            ))
      }
      <Grid style={{textAlign:"left", fontSize: 20, fontWeight: "bold", marginTop:"2%"}}>Level 3:</Grid>
      {
        caseQuestionAnswers.caseQuestionAnswers.filter(item => item.level == "3").map((item, index) =>(
            <Grid>
              {
                ( item.qtype == "BOOLEAN" )?
                (
                  <Grid container className={classes.gridContianer}>
                    <Grid item xs={1}>
                      {/* <Avatar src="/static/images/avatar/1.jpg" /> */}
                      {index + 1}
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {item.title}
                        </Grid>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {JSON.stringify(item.answer_bool)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ):
                (
                  ( item.qtype == "SINGLE-ANSWER" )?
                  (
                    <Grid container className={classes.gridContianer}>
                      <Grid item xs={1}>
                        {index + 1}
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container>
                          <Grid item xs={12} className={classes.leftAlign}>
                            {item.title}
                          </Grid>
                          
                            {/* {JSON.stringify(item.answer_bool)} */}
                            {
                              item.answer_option.map((i, e) =>(
                                <Grid item xs={12} className={classes.leftAlign}>
                                  {i.option}
                                </Grid>
                              ))
                            }
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  ):
                  (
                    ( item.qtype == "TEXT-ANSWER" )?
                    (
                      <Grid container className={classes.gridContianer}>
                        <Grid item xs={1}>
                          {index + 1}
                        </Grid>
                        <Grid item xs={11}>
                          <Grid container>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.title}
                            </Grid>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.answer_other}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    ):
                    (
                      (item.qtype == "MULTIPLE-ANSWER" )?
                      (
                        <Grid container className={classes.gridContianer}>
                          <Grid item xs={1}>
                            {index + 1}
                          </Grid>
                          <Grid item xs={11}>
                            <Grid container>
                              <Grid item xs={12} className={classes.leftAlign}>
                                {item.title}
                              </Grid>
                              
                                {/* {JSON.stringify(item.answer_bool)} */}
                                {
                                  item.answer_option.map((i, e) =>(
                                    <Grid item xs={12} className={classes.leftAlign}>
                                      {i.option}
                                    </Grid>
                                  ))
                                }
                              
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      ):
                      (<div></div>)
                    )
                  )
                )
              }
            </Grid>

            ))
      }
      {/* <Grid style={{textAlign:"left", fontSize: 20, fontWeight: "bold", marginTop:"2%"}}>Level 4:</Grid>
      {
        caseQuestionAnswers.caseQuestionAnswers.filter(item => item.level == "4").map((item, index) =>(
            <Grid>
              {
                ( item.qtype == "BOOLEAN" )?
                (
                  <Grid container className={classes.gridContianer}>
                    <Grid item xs={1}>
                      {index + 1}
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {item.title}
                        </Grid>
                        <Grid item xs={12} className={classes.leftAlign}>
                          {JSON.stringify(item.answer_bool)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ):
                (
                  ( item.qtype == "SINGLE-ANSWER" )?
                  (
                    <Grid container className={classes.gridContianer}>
                      <Grid item xs={1}>
                        {index + 1}
                      </Grid>
                      <Grid item xs={11}>
                        <Grid container>
                          <Grid item xs={12} className={classes.leftAlign}>
                            {item.title}
                          </Grid>
                        
                            {
                              item.answer_option.map((i, e) =>(
                                <Grid item xs={12} className={classes.leftAlign}>
                                  {i.option}
                                </Grid>
                              ))
                            }
                          
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  ):
                  (
                    ( item.qtype == "TEXT-ANSWER" )?
                    (
                      <Grid container className={classes.gridContianer}>
                        <Grid item xs={1}>
                          {index + 1}
                        </Grid>
                        <Grid item xs={11}>
                          <Grid container>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.title}
                            </Grid>
                            <Grid item xs={12} className={classes.leftAlign}>
                              {item.answer_other}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    ):
                    (
                      (item.qtype == "MULTIPLE-ANSWER" )?
                      (
                        <Grid container className={classes.gridContianer}>
                          <Grid item xs={1}>
                            {index + 1}
                          </Grid>
                          <Grid item xs={11}>
                            <Grid container>
                              <Grid item xs={12} className={classes.leftAlign}>
                                {item.title}
                              </Grid>
                            
                                {
                                  item.answer_option.map((i, e) =>(
                                    <Grid item xs={12} className={classes.leftAlign}>
                                      {i.option}
                                    </Grid>
                                  ))
                                }
                              
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      ):
                      (<div></div>)
                    )
                  )
                )
              }
            </Grid>

            ))
      } */}
      </Grid>
    )
  }

  return (
    console.log("caseQuestionAnswers on case qa page",caseQuestionAnswers.caseQuestionAnswers),
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          { 
            showQandA()
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default injectIntl(TaxManagementCasesQandA);
