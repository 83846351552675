// EnterOtpForgotPassword

import React from 'react';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { authHeader } from '../helpers';
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { injectIntl, FormattedMessage } from "react-intl";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { history } from "../helpers";
import { Router, Route, Redirect} from "react-router-dom";
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import { PropagateLoader } from 'react-spinners';
import OtpTimer from 'otp-timer';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(../Image/loginBanner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarLocked: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSignIn: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    marginLeft: "-1%",
    width: "105%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftAlign: {
    textAlign: "left",
  },
  errorBlock: {
    // marginLeft: "5%",
    // marginRight: "5%",
    width: "100%",
    paddingTop: "2%",
    textAlign: "left",
  },
  overFlowHidden: {
    overflow: "hidden",
  },
  dontHaveAccText: {
    cursor: "pointer",
    color: "#3f51b5",
  },
  verifyLabel: {
    width: "100%",
    marginTop: theme.spacing(5),
    // textAlign: "left",
    fontSize: 20,
  },
  verifyText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 15,
  },
  didntOtpText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 12,
  },
  resendText: {
    width: "100%",
    marginTop: "1%",
    textAlign: "left",
    fontSize: 12,
    cursor: "pointer",
    color: "#3f51b5",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
}));



export default function EnterOtpForgotPassword() {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const [redirectResetPassword, setredirectResetPassword] = React.useState(false);
  const [emailForChangePassword, setemailForChangePassword] = React.useState(JSON.parse(localStorage.getItem('emailForChangePassword')));
  const [otp, setotp] = React.useState("");
  const[otpErr, setotpErr] = React.useState("");
  const[SubmitOtp, setSubmitOtp] = React.useState(false);
  const[ShowTimer, setShowTimer] = React.useState(true);

  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  
    const dispatch = useDispatch()

    React.useEffect(() => {
      // console.log("loggingIn in login",loggingIn)
      console.log("device_token in useeffect login",device_token)
      console.log("session_id in useeffect login",session_id)
      
      let user = JSON.parse(localStorage.getItem('user'));
      console.log("user in useeffect login",user)
  
      if( device_token !== null && session_id !== null && user !== null){
        console.log("device token , session id, user info found in useeffect of login")
        // Swal.fire({
        //   icon: 'error',
        //   text: "You are already logged in in app",
        //   confirmButtonColor: '#d33',
        //   confirmButtonText: 'OK'
        // })
        dispatch(userActions.logout());
      }
      else{
        console.log("device token , session id, user info is empty in useeffect of login")
      }
  
    }, []);
  
  const validate = () => {
    let otpErr = '';

    if(!otp)  {
        // otpErr = 'OTP is require';
        otpErr = 'Verification Code is require';
    }

      if(otpErr){
        setotpErr(otpErr);
        return false;
      }
  
      return true;

  }

  const submitOtpFunc = () => {
    setSubmitOtp(true);
    const isValid = validate();
    if(isValid){
      console.log("submitEmailFunc",otp);
      localStorage.setItem('verificationCodeChangePassword', JSON.stringify(otp));
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'email': emailForChangePassword, 'verification_code': otp })
      };
      fetch( process.env.REACT_APP_VERIFY_FORGOT_PASSWORD , requestOptions)
      .then((Response) => Response.json())
      .then(verificationDetails => {
        setShowProcedure(false);
        console.log("verificationDetails",verificationDetails);
        if(verificationDetails.success == true )
        {
          setredirectResetPassword(true);
        }
        else
        {
          Swal.fire({
            icon: 'error',
            text: verificationDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }
  }

  const handleClick=()=>{
    //desired function to be performed on clicking resend button
    setShowProcedure(true);
    setShowTimer(false);
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'email': emailForChangePassword})
    };
    fetch( process.env.REACT_APP_FORGOT_PASSWORD , requestOptions)
      .then((Response) => Response.json())
      .then(emailDetails => {
        setShowProcedure(false);
        setShowTimer(true);
        console.log("emailDetails ====",emailDetails.data.password_reset);
        if(emailDetails.success == true )
        {
          console.log(" check OTP again ")
        }
        else
        {
          Swal.fire({
            icon: 'error',
            text: emailDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  if(redirectResetPassword == true){
    return(
      <Redirect to={'/ResetForgotPassword'}/>
    )
  }
  else{
  return (
    console.log("emailForChangePassword",emailForChangePassword),
    <div>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.overFlowHidden}
      >
        <div className={classes.paper} style={{marginTop:'30%'}}>
            {/* <Avatar className={classes.avatarLocked}>
              <LockOutlinedIcon />
            </Avatar> */}
          <Typography component="h1" variant="h5">
            Enter Verification Code
          </Typography>
          <div>
              <div>
                <div className={classes.form}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="otp"
                    label="Verification Code"
                    name="otp"
                    autoComplete="otp"
                    autoFocus
                    value={otp}
                    // type="number"
                    onChange={(e) => setotp(e.target.value) }
                  />            

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={submitOtpFunc}
                  >
                    Submit Verification Code
                  </Button>
                </div>

                {
                  ( ShowTimer == true )?
                  (
                    <OtpTimer 
                      seconds= {1} 
                      minutes={5} 
                      // text = ""
                      background = "#303f9f"
                      resend={ () => handleClick()} 
                    />
                  )
                  :(<Grid></Grid>)
                }
                

                <Grid item xs={12}>
                  <div>
                    {
                      (SubmitOtp == true && otpErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          <div>
                            {otpErr}
                          </div>
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }

                  </div>
                </Grid>
              </div>
          </div> 
        </div>
      </Grid>
    </Grid>
    </div>  
  );
  }
}