import React, { useParams, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ConfirmMessageNewClient from "../ConfirmMessageNewClient";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
// import 'react-phone-input-2/lib/high-res.css';
// import 'react-phone-input-2/lib/bootstrap.css';
// import 'react-phone-input-2/lib/semantic-ui.css';
// import 'react-phone-input-2/lib/plain.css';
// import startsWith from 'lodash.startswith';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";


import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";

// import {
//   isPossiblePhoneNumber,
//   isValidPhoneNumber,
//   validatePhoneNumberLength
// } from 'libphonenumber-js'

// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

// import MaterialUiPhoneNumber from "material-ui-phone-number";

// import isEmail from 'validator/lib/isEmail';
// import isMobilePhone from 'validator/lib/isMobilePhone';

import AddBulkClientModal from './AddBulkClientModal';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    background: "#ffffff",
    marginTop: '-3%',
  },
  wrapper: {
    width: "100%",
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "1%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: "#FFFFFF",
    boxShadow: theme.shadows[5],
    borderRadius: "5px",
    width: "300px",
    maxHeight: "480px",
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    marginBottom: "10px",
    textAlign: "left",
  },
  requestPANLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "#4D47DD",
    marginTop: "2px",
    marginBottom: "35px",
    textAlign: "left",
    cursor: "pointer",
  },
  dividerStyle: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  checkboxCustomStyle: {
    textAlign: "right",
  },
  contactDetailsContainer: {
    alignItems: "center",
  },
  createButtonContainer: {
    marginTop: "1%",
  },
  messageBlock: {
    marginTop: "5%",
    marginBottom: "5%",
  },
  submit: {
    margin: "1%",
  },
  formControl: {
    // margin: theme.spacing(1),
    // marginTop: '4%',
    width: '100%',
    textAlign: "left",
  },
  validation: {
    marginTop: '0.5%',
    color: 'red',
    fontSize: 12,
    textAlign: "left",
  },
  phoneInputCssMy: {
    '&:focus': {
      borderColor: 'red'
    },
  },
  customRadioButton: {
    border: "1px solid rgba(0, 0, 0, 0.32)",
    borderRadius: "6px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginRight: "20px",
  },
  label: {
    fontSize: "14px",
  },
}));

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export default function NewClientGeneralDetails() {
  // const {oldpage} = useParams({});
  const classes = useStyles();
  const [clientDetails, setclientDetails] = React.useState({
    client_type: "person",

    first_name: "",
    middle_name: "",
    surname: "",

    // name: "",
    client_pan: "",
    email: "",
    phone: "",
    country_code: "",
    address: "Test test",

    org_name: "",
    contact_name: "",
    contact_email: "",
    contact_pan: "",
    contact_countrycode: "",
    contact_phone: "",

    self_assessment: true,
    username: JSON.parse(localStorage.getItem("username")),
    business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [CountryCode, setCountryCode] = React.useState();
  const [OtherContactCountryCode, setOtherContactCountryCode] = React.useState();
  const [yesNo, setyesNo] = React.useState([
    { value: true, name: 'Yes' },
    { value: false, name: 'No' },
  ]);
  const [nameErr, setnameErr] = React.useState();
  const [panErr, setpanErr] = React.useState();
  const [emailErr, setemailErr] = React.useState();
  const [phoneErr, setphoneErr] = React.useState();
  // const [selfAssErr, setselfAssErr] = React.useState();
  const [showPhoneIp, setshowPhoneIp] = React.useState(true);
  const [firstNameErr, setfirstNameErr] = React.useState();
  // const[middleNameErr, setmiddleNameErr] = React.useState();
  const [lastNameErr, setlastNameErr] = React.useState();
  const [contactEmailErr, setcontactEmailErr] = React.useState();
  const [contactPanErr, setcontactPanErr] = React.useState();
  const [contactPhoneErr, setcontactPhoneErr] = React.useState();

  const [onlyCountryArray, setonlyCountryArray] = React.useState([
    'in', 'us', 'gb', 'ca', 'ae', 'sg', 'np', 'jp', 'de', 'bt', 'au'
  ]);
  // gb: United Kingdom +44   ca: Canada +1   ae:United Arab Emirates +971    sg: Singapore +65
  // np: Nepal 977    jp: Japan 81    de: Germany +49   bt: Bhutan +975   au: Australia +61


  const [modalTagOpen, setModalTagOpen] = React.useState(false);

  const [ForIndividual, setForIndividual] = React.useState("true");

  const [flag, setflag] = React.useState(false);
  const [RedirectClientTable, setRedirectClientTable] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setflag(true);
    dispatch(userActions.checkSessionValidity());
    // readItemFromStorage();
  }, [flag]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);        }
  // };

  const handleModalOpen = () => {
    setModalTagOpen(true);
    dispatch(userActions.checkSessionValidity());
  };

  const handleModalClose = () => {
    setModalTagOpen(false);
    dispatch(userActions.checkSessionValidity());
  };

  const validate = () => {
    let nameErr = '';
    let panErr = '';
    let emailErr = '';
    let phoneErr = '';
    // let selfAssErr = '';
    let firstNameErr = '';
    // let middleNameErr = '';
    let lastNameErr = '';
    let contactEmailErr = '';
    let contactPanErr = '';
    let contactPhoneErr = '';

    if (ForIndividual === "true") {
      if (!clientDetails.first_name) {
        firstNameErr = 'Please Enter First Name';
      }
      // if(!clientDetails.middle_name)  {
      //   middleNameErr = 'Please Enter Middle Name';
      // }
      if (!clientDetails.surname) {
        lastNameErr = 'Please Enter Last Name';
      }
    }
    else if (ForIndividual === "false") {
      if (!clientDetails.org_name) {
        nameErr = 'Please Enter Name';
      }

      if (clientDetails.contact_email) {
        if (typeof clientDetails.contact_email !== "undefined") {
          let lastAtPos = clientDetails.email.lastIndexOf('@');
          let lastDotPos = clientDetails.email.lastIndexOf('.');
          if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
            contactEmailErr = "Email is not valid";
            if (contactEmailErr) {
              setcontactEmailErr(contactEmailErr);
            }
          }
          else {
            setcontactEmailErr();
          }
        }
        else {
          setcontactEmailErr();
        }
      }

      if (clientDetails.contact_pan) {
        if (typeof clientDetails.contact_pan !== "undefined") {
          var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
          if (clientDetails.contact_pan.length !== 10) {
            console.log("in != 10")
            contactPanErr = "PAN Number is Not Valid";
          }
          else if (!pattern.test(clientDetails.contact_pan)) {
            contactPanErr = "PAN Number is Not Valid";
          }
        }
      }

      if (clientDetails.contact_phone) {
        if (OtherContactCountryCode == "in") {
          console.log("clientDetails.contact_phone.length", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 10) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "us") {
          console.log("clientDetails.contact_phone.length us", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 10) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "gb") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 10) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "ca") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 10) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "ae") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 12) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "sg") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 8) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "np") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 12) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "jp") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 10) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "de") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 12) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "bt") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 12) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
        else if (OtherContactCountryCode == "au") {
          console.log("clientDetails.contact_phone.length gb", clientDetails.phone.length)
          if (clientDetails.contact_phone.length !== 13) {
            contactPhoneErr = "Please enter valid phone number.";
          }
        }
      }

    }

    if (!clientDetails.client_pan) {
      panErr = 'Please Enter PAN';
    }
    else if (typeof clientDetails.client_pan !== "undefined") {
      var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
      if (clientDetails.client_pan.length !== 10) {
        console.log("in != 10")
        panErr = "PAN Number is Not Valid";
      }
      else if (!pattern.test(clientDetails.client_pan)) {
        // console.log("in string check")
        panErr = "PAN Number is Not Valid";
      }
      else if (ForIndividual === "true") {
        console.log("4th pan letter is", clientDetails.client_pan[3])
        if (clientDetails.client_pan[3] !== "P") {
          panErr = "PAN Number is Not Valid";
        }
      }
    }


    if (!clientDetails.email) {
      emailErr = 'Please Enter Email';
    }
    else if (typeof clientDetails.email !== "undefined") {
      // let lastAtPos = clientDetails.email.lastIndexOf('@');
      // let lastDotPos = clientDetails.email.lastIndexOf('.');
      // if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
      //     emailErr = "Email is not valid";
      //     if(emailErr) {
      //       setemailErr(emailErr);
      //     }
      // }

      // if (!isEmail(clientDetails.email)) {
      //   // errors.email = "Wrong email";
      //   console.log("Chcking email by fun")
      //   setemailErr(emailErr);
      // }

      // var pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]/g)
      var pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)

      if (!pattern.test(clientDetails.email)) {
        console.log("Chcking email by reg here === not valid")
        emailErr = "Email is not valid";
        setemailErr(emailErr);
      }
      else {
        console.log("Chcking email by reg here === valid")
        setemailErr();
      }
    }
    else {
      setemailErr();
    }

    if (!clientDetails.phone) {
      phoneErr = 'Please Enter Phone';
    }

    else if (typeof clientDetails.phone !== "undefined") {
      // var pattern = new RegExp(/^[0-9\b]+$/)

      // if (!pattern.test(clientDetails.phone)) {
      //   // isValid = false;
      //   phoneErr = "Please enter only number.";
      // }
      // else if(clientDetails.phone.length <= 10){
      //   // isValid = false;
      //   phoneErr = "Please enter valid phone number.";
      // }

      // console.log("clientDetails.phone, CountryCode",clientDetails.phone, CountryCode)
      // console.log("clientDetails.phone, CountryCode us",isValidPhoneNumber("11234567843", "us"))
      // console.log("isValidPhoneNumber",isValidPhoneNumber(clientDetails.phone, CountryCode))


      if (CountryCode == "in") {
        console.log("clientDetails.phone.length", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 15){
        // if(clientDetails.phone.length !== 14){
        if (clientDetails.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "us") {
        console.log("clientDetails.phone.length us", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 17){
        // if(clientDetails.phone.length !== 13){
        if (clientDetails.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "gb") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 14){
        if (clientDetails.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "ca") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 13){
        if (clientDetails.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "ae") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 17){
        if (clientDetails.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "sg") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 12){
        if (clientDetails.phone.length !== 8) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "np") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 17){
        if (clientDetails.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "jp") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 14){
        if (clientDetails.phone.length !== 10) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "de") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 16){
        if (clientDetails.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "bt") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 17){
        if (clientDetails.phone.length !== 12) {
          phoneErr = "Please enter valid phone number.";
        }
      }
      else if (CountryCode == "au") {
        console.log("clientDetails.phone.length gb", clientDetails.phone.length)
        // if(clientDetails.phone.length !== 17){
        if (clientDetails.phone.length !== 13) {
          phoneErr = "Please enter valid phone number.";
        }
      }
    }

    // if(clientDetails.self_assessment  == "" && clientDetails.self_assessment !== false && clientDetails.self_assessment !== true)  {
    //   selfAssErr = 'Please Select Self Assessment';
    // }


    if (nameErr || panErr || emailErr || phoneErr || firstNameErr || lastNameErr || contactEmailErr ||
      contactPanErr || contactPhoneErr) {
      setnameErr(nameErr);
      setpanErr(panErr);
      setemailErr(emailErr);
      setphoneErr(phoneErr);
      // setselfAssErr(selfAssErr);
      setfirstNameErr(firstNameErr);
      // setmiddleNameErr(middleNameErr);
      setlastNameErr(lastNameErr);
      setcontactEmailErr(contactEmailErr);
      setcontactPanErr(contactPanErr);
      setcontactPhoneErr(contactPhoneErr);

      return false;
    }
    return true;
  }

  const handleCreateButton = () => {
    dispatch(userActions.checkSessionValidity());
    console.log("clientDetails", clientDetails);
    // let usersData = JSON.parse(localStorage.getItem("username"));
    // // let business_id = "1";
    // let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
    //   console.log("business_id",business_id)
    // let device_token = JSON.parse(localStorage.getItem('device_token'));
    // let session_id = JSON.parse(localStorage.getItem('session_id'));
    // console.log("is email",isEmail('foo@bar.com'));
    // console.log("isMobilePhone", isMobilePhone("1111111111","any"))
    const isValid = validate();
    if (isValid) {
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(clientDetails)
      };
      return fetch(process.env.REACT_APP_CREATE_CLIENT, requestOptions)
        .then((Response) => Response.json())
        .then(clientCreated => {
          console.log("clientCreated on same page", clientCreated.success);
          console.log("clientCreated on same page", clientCreated);
          if (clientCreated.success == true) {
            setshowPhoneIp(false);
            Swal.fire({
              icon: 'success',
              text: "Client Created Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK'
            }).then(function (result) {
              // console.log("result of swal",result)
              // setOpen(true);
              <Link to="/client"></Link>
            })
            setclientDetails({
              // name: "",
              // client_pan: "",
              // email: "",
              // phone: "",
              client_type: "person",

              first_name: "",
              middle_name: "",
              surname: "",

              // name: "",
              client_pan: "",
              email: "",
              phone: "",
              country_code: "",
              address: "Test test",

              org_name: "",
              contact_name: "",
              contact_email: "",
              contact_pan: "",
              contact_countrycode: "",
              contact_phone: "",

              self_assessment: true,
              username: JSON.parse(localStorage.getItem("username")),
              business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')),
            })
            setshowPhoneIp(true);
            setnameErr();
            setpanErr();
            setemailErr();
            setphoneErr();
            setfirstNameErr();
            // setmiddleNameErr();
            setlastNameErr();
            setcontactEmailErr();
            setcontactPanErr();
            setcontactPhoneErr();
            setRedirectClientTable(true);
            // setselfAssErr();
          }
          if (clientCreated.success == false || clientCreated.success == null) {
            Swal.fire({
              icon: 'error',
              text: clientCreated.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
            setnameErr();
            setpanErr();
            setemailErr();
            setphoneErr();
            setfirstNameErr();
            // setmiddleNameErr();
            setlastNameErr();
            setcontactEmailErr();
            setcontactPanErr();
            setcontactPhoneErr();
            // setselfAssErr();
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleReset = async () => {
    dispatch(userActions.checkSessionValidity());
    setshowPhoneIp(false);
    await setclientDetails({
      // name: "",
      // client_pan: "",
      // email: "",
      // phone: "",
      client_type: "person",

      first_name: "",
      middle_name: "",
      surname: "",

      // name: "",
      client_pan: "",
      email: "",
      phone: "",
      country_code: "",
      address: "Test test",

      org_name: "",
      contact_name: "",
      contact_email: "",
      contact_pan: "",
      contact_countrycode: "",
      contact_phone: "",

      self_assessment: true,
      username: JSON.parse(localStorage.getItem("username")),
      business_id: JSON.parse(localStorage.getItem('logedIn_users_businessId')),
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    })
    setshowPhoneIp(true);
    setnameErr();
    setpanErr();
    setemailErr();
    setphoneErr();
    setfirstNameErr();
    // setmiddleNameErr();
    setlastNameErr();
    setcontactEmailErr();
    setcontactPanErr();
    setcontactPhoneErr();
  }

  // isValid(value, country, countries, hiddenAreaCodes)

  const phoneNumberChange = (clientNumber, e, formattedValue, value) => {
    console.log("phoneNumber Change clientNumber", clientNumber)
    console.log("phoneNumber Change e", e)
    console.log("phoneNumber Change value", value, e.dialCode)
    let splitNoIs = ""
    let countryCodeIs = "+" + e.dialCode
    console.log("countryCodeIs", countryCodeIs)
    if (e.countryCode == "in") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "us") {
      //  splitNoIs = value.split(" ")[1];

      // this is showing 1st digit before white space
      // splitNoIs = value.substr(0,value.indexOf(' ')); 

      // this is showing digits after white space
      // splitNoIs = value.substr(value.indexOf(' ')+1);

      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "gb") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ca") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ae") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "sg") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "np") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "jp") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "de") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "bt") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "au") {
      splitNoIs = value.split(" ")[1];
    }

    console.log("splitNoIs", splitNoIs)
    // if(formattedValue !== null){
    // console.log("formattedValue",formattedValue.target.value)
    // }
    let noHereIs = "+" + e.dialCode + "-" + splitNoIs;
    console.log("noHereIs", noHereIs)
    setCountryCode(e.countryCode)
    // setclientDetails({...clientDetails, phone:clientNumber })
    // setclientDetails({...clientDetails, phone:noHereIs })
    setclientDetails({ ...clientDetails, phone: splitNoIs, country_code: countryCodeIs })
  }

  const phoneNumberChangeContact = (clientNumber, e, formattedValue, value) => {
    console.log("phoneNumber Change clientNumber", clientNumber)
    console.log("phoneNumber Change e", e)
    console.log("phoneNumber Change value", value, e.dialCode)
    let splitNoIs = ""
    let countryCodeIs = "+" + e.dialCode
    console.log("countryCodeIs", countryCodeIs)
    if (e.countryCode == "in") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "us") {
      //  splitNoIs = value.split(" ")[1];

      // this is showing 1st digit before white space
      // splitNoIs = value.substr(0,value.indexOf(' ')); 

      // this is showing digits after white space
      // splitNoIs = value.substr(value.indexOf(' ')+1);

      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "gb") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ca") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "ae") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "sg") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "np") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "jp") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "de") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "bt") {
      splitNoIs = value.split(" ")[1];
    }
    if (e.countryCode == "au") {
      splitNoIs = value.split(" ")[1];
    }

    console.log("splitNoIs", splitNoIs)
    // if(formattedValue !== null){
    // console.log("formattedValue",formattedValue.target.value)
    // }
    let noHereIs = "+" + e.dialCode + "-" + splitNoIs;
    console.log("noHereIs", noHereIs)
    setOtherContactCountryCode(e.countryCode)
    // setclientDetails({...clientDetails, phone:clientNumber })
    // setclientDetails({...clientDetails, phone:noHereIs })
    setclientDetails({ ...clientDetails, contact_phone: splitNoIs, contact_countrycode: countryCodeIs })
  }

  const individualOrNotRadioChange = (e) => {
    console.log("individual radio change", e, e.target.value)
    setForIndividual(e.target.value)

    setnameErr();
    setpanErr();
    setemailErr();
    setphoneErr();
    setfirstNameErr();
    // setmiddleNameErr();
    setlastNameErr();
    setcontactEmailErr();
    setcontactPanErr();
    setcontactPhoneErr();

    if (e.target.value === "true") {
      setclientDetails({ ...clientDetails, client_type: "person", first_name: "", middle_name: "", surname: "", org_name: "" })
    }
    else if (e.target.value === "false") {
      setclientDetails({ ...clientDetails, client_type: "business", first_name: "", middle_name: "", surname: "", org_name: "" })
    }
  }

  if (RedirectClientTable == true) {
    return <Redirect to={`/client`} />
  }
  else {
    return (
      console.log("ForIndividual is", ForIndividual),
      // console.log("userparam is". useParams(),),
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Grid container>
            {/* <AddBulkClientModal/> */}
            {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Client Details
        </Grid>
        <Grid item xs={12} className={classes.dividerStyle}>
          <Divider />
        </Grid> */}
            {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Client Name
        </Grid> */}
            <Grid item xs={12} style={{ marginTop: '3%' }}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={ForIndividual && ForIndividual}
                onChange={individualOrNotRadioChange}
              >
                <div className={classes.customRadioButton}>
                  <FormControlLabel
                    value="true"
                    classes={{ label: classes.label }}
                    control={<Radio size="small" color="primary" />}
                    label="Individual"
                  />
                </div>
                <div className={classes.customRadioButton}>
                  <FormControlLabel
                    value="false"
                    classes={{ label: classes.label }}
                    control={<Radio size="small" color="primary" />}
                    label="Non Individual"
                  />
                </div>
              </RadioGroup>
            </Grid>


            <Grid item xs={12} style={{ marginTop: '2%' }}>
              {
                (ForIndividual === "true") ?
                  (
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={4}>
                        <TextField
                          autoFocus
                          margin="normal"
                          required
                          id="name"
                          // id="outlined-basic"
                          fullWidth
                          variant="outlined"
                          // size="small"
                          label="First Name"
                          name="name"
                          value={clientDetails.first_name}
                          onChange={(e) => setclientDetails({ ...clientDetails, first_name: e.target.value })}
                        />
                        <div className={classes.validation}>{(clientDetails.first_name) ? (<div></div>) : (firstNameErr)}</div>
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          margin="normal"
                          // required
                          id="name"
                          // id="outlined-basic"
                          fullWidth
                          variant="outlined"
                          // size="small"
                          label="Middle Name"
                          name="name"
                          value={clientDetails.middle_name}
                          onChange={(e) => setclientDetails({ ...clientDetails, middle_name: e.target.value })}
                        />
                        {/* <div className={classes.validation}>{(clientDetails.middle_name)?(<div></div>):(middleNameErr)}</div> */}
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          margin="normal"
                          required
                          id="name"
                          // id="outlined-basic"
                          fullWidth
                          variant="outlined"
                          // size="small"
                          label="Surname"
                          name="name"
                          value={clientDetails.surname}
                          onChange={(e) => setclientDetails({ ...clientDetails, surname: e.target.value })}
                        />
                        <div className={classes.validation}>{(clientDetails.surname) ? (<div></div>) : (lastNameErr)}</div>
                      </Grid>

                    </Grid>
                  ) :
                  (
                    <Grid>
                      <Grid item xs={12}>
                        <Grid>
                          <TextField
                            autoFocus
                            margin="normal"
                            required
                            id="name"
                            // id="outlined-basic"
                            fullWidth
                            variant="outlined"
                            // size="small"
                            // label="Client Name"
                            label="Organization Name"
                            name="name"
                            value={clientDetails.org_name}
                            onChange={(e) => setclientDetails({ ...clientDetails, org_name: e.target.value })}
                          />
                          <div className={classes.validation}>{(clientDetails.org_name) ? (<div></div>) : (nameErr)}</div>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              margin="normal"
                              id="name"
                              // id="outlined-basic"
                              fullWidth
                              variant="outlined"
                              // size="small"
                              // label="Client Name"
                              label="Contact Name"
                              name="name"
                              value={clientDetails.contact_name}
                              onChange={(e) => setclientDetails({ ...clientDetails, contact_name: e.target.value })}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              margin="normal"
                              id="name"
                              // id="outlined-basic"
                              fullWidth
                              variant="outlined"
                              // size="small"
                              // label="Client Name"
                              label="Contact Email"
                              name="name"
                              value={clientDetails.contact_email}
                              onChange={(e) => setclientDetails({ ...clientDetails, contact_email: e.target.value })}
                            />
                            <div className={classes.validation}>{(contactEmailErr) ? (contactEmailErr) : (<div></div>)}</div>
                          </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              margin="normal"
                              id="name"
                              // id="outlined-basic"
                              fullWidth
                              variant="outlined"
                              // size="small"
                              // label="Client Name"
                              placeholder="XXXXX0000X"
                              label="Contact Pan"
                              name="name"
                              value={clientDetails.contact_pan}
                              onChange={(e) => setclientDetails({ ...clientDetails, contact_pan: e.target.value.toUpperCase() })}
                            />
                            <div className={classes.validation}>{(contactPanErr) ? (contactPanErr) : (<div></div>)}</div>
                          </Grid>

                          <Grid item xs={6} style={{ marginTop: '1.5%' }}>
                            <PhoneInput
                              inputStyle={{
                                width: "100%",
                                height: "55px",
                                fontSize: "15px",
                              }}
                              countryCodeEditable={false}
                              onlyCountries={onlyCountryArray}
                              country={'in'}
                              value={clientDetails.contact_countrycode + clientDetails.contact_phone}
                              specialLabel="Contact Mobile Number"
                              masks={{
                                in: '..........', us: '..........', gb: '..........', ca: '..........', ae: '............',
                                sg: '........', np: '............', jp: '..........', de: '............', bt: '............',
                                au: '.............'
                              }}
                              onChange={(clientNumber, e, formattedValue, value) =>
                                phoneNumberChangeContact(clientNumber, e, formattedValue, value)}
                            />
                            <div className={classes.validation}>{(contactPhoneErr) ? (contactPhoneErr) : (<div></div>)}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
              }

            </Grid>

            {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Pan Number
        </Grid> */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                id="client_pan"
                name="client_pan"
                variant="outlined"
                placeholder="XXXXX0000X"
                // size="small"
                label="PAN Number"
                fullWidth
                inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
                value={clientDetails.client_pan}
                onChange={(e) => setclientDetails({ ...clientDetails, client_pan: e.target.value.toUpperCase() })}
              />
              {/* <div className={classes.validation}>{(clientDetails.client_pan)?(<div></div>):(panErr)}</div> */}
              <div className={classes.validation}>{panErr}</div>
            </Grid>
            {/* <Grid
          item
          xs={12}
          className={classes.requestPANLabel}
          onClick={handleModalOpen}
        >
          Request PAN?
        </Grid> */}

            {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          <Grid container className={classes.contactDetailsContainer}>
            <Grid item xs={11}>
              Contact Details
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.dividerStyle}>
          <Divider />
        </Grid> */}
            {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Email Id
        </Grid> */}

            <Grid item xs={12} style={{ marginTop: '1%' }}>
              <TextField
                margin="normal"
                required
                id="email"
                name="email"
                variant="outlined"
                // size="small"
                fullWidth
                label="Email Id"
                value={clientDetails.email}
                onChange={(e) => setclientDetails({ ...clientDetails, email: e.target.value })}
              />
              {/* <div className={classes.validation}>{(clientDetails.email)?(<div></div>):(emailErr)}</div> */}
              <div className={classes.validation}>{emailErr}</div>
            </Grid>

            {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Mobile Number
        </Grid> */}
            <Grid item xs={12} style={{ marginTop: '2%' }} >
              {/* <TextField
            id="phone"
            name="phone"
            variant="outlined"
            size="small"
            fullWidth
            value={clientDetails.phone}
            onChange={(e) => setclientDetails({...clientDetails, phone:e.target.value }) }
          />
          <div className={classes.validation}>{(clientDetails.phone)?(<div></div>):(phoneErr)}</div> */}

              {/* phone ip react-phone-input-2 */}
              {
                (showPhoneIp == true) ?
                  (
                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                      countryCodeEditable={false}
                      onlyCountries={onlyCountryArray}
                      country={'in'}
                      value={clientDetails.country_code + clientDetails.phone}
                      specialLabel="Mobile Number*"
                      masks={{
                        in: '..........', us: '..........', gb: '..........', ca: '..........', ae: '............',
                        sg: '........', np: '............', jp: '..........', de: '............', bt: '............',
                        au: '.............'
                      }}
                      onChange={(clientNumber, e, formattedValue, value) =>
                        phoneNumberChange(clientNumber, e, formattedValue, value)}
                    // isValid = {false}

                    // isValid={(value, country) => {
                    //   console.log("in contry validation ..",value, country)
                    //   if (value.match(/12345/)) {
                    //     return 'Invalid value: '+value+', '+country.name;
                    //   } else if (value.match(/1234/)) {
                    //     return false;
                    //   } else {
                    //     return true;
                    //   }
                    // }}

                    // isValid={(value, country) => {
                    //   console.log("in contry validation ..",value, country)
                    //   if (value.match(/^(\+?91|0)?[6789]\d{10}$/)) {
                    //     return true;
                    //   } else {
                    //     return 'Invalid value: '+value+', '+country.name;
                    //     // return false;
                    //   }
                    // }}

                    // isValid={(inputNumber, country, countries) => {
                    //   return countries.some((country) => {
                    //     return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                    //   });
                    // }}

                    // isValid={(inputNumber, onlyCountries) => {
                    //   return onlyCountries.some((country) => {
                    //     return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                    //   });
                    // }}

                    // isValid={(inputNumber, country, countries) => {
                    //   console.log("in contry validation ..")
                    //   return countries.some((country) => {
                    //     return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                    //     return false;
                    //   });

                    // }}

                    // isValid={(inputNumber, country, countries) => {
                    //   return countries.some((country) => {
                    //     console.log("in contry validation ..",country)
                    //     return  startsWith(inputNumber, country.dialCode)  || startsWith(country.dialCode, inputNumber);
                    //   });
                    // }}
                    // onChange={(clientNumber) => setclientDetails({...clientDetails, phone:clientNumber })}


                    />
                  )
                  : (
                    <Grid></Grid>
                  )
              }
              <div className={classes.validation}>{phoneErr}</div>
            </Grid>

            {/* <Grid item xs={12} className={classes.subHeadingLabel}>
          Self Assessment
        </Grid> */}
            {/* <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                native
                value={clientDetails.self_assessment}
                onChange={(e) => setclientDetails({...clientDetails, self_assessment:e.target.value }) }
              >
                <option value="" style={{color:'grey'}}>Select Self Assesment</option>
                {yesNo.map(item =>
                  <option value={item.value}>{item.name}</option>
                )}
              </Select>
            </FormControl> */}

            {/* <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  // defaultValue={JSON.stringify(clientDetails.self_assessment)}
                  value={JSON.stringify(clientDetails.self_assessment)}
                  onChange={ (e) => setclientDetails({...clientDetails, self_assessment:JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
            <div className={classes.validation}>{(clientDetails.self_assessment == "" && clientDetails.self_assessment !== false  && clientDetails.self_assessment !== true)?(selfAssErr):(<div></div>)}</div> */}

            <Grid item xs={12} className={classes.createButtonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleCreateButton}
              >
                Create
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>
        {/* modal for pan card */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modalTagOpen}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          disableBackdropClick={true}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalTagOpen}>
            <div className={classes.paperModal}>
              <ConfirmMessageNewClient handleModalClose={handleModalClose} />
            </div>
          </Fade>
        </Modal>
      </div>
    )
  }
}