import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  cursorPointer: {
    cursor: "pointer"
  },
  recentCaseClientName: {
    marginTop: "2%"
  }
}));

function TaxManagementRecentClient({intl, handleChange}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={3} onClick={handleChange} className={classes.cursorPointer}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={6}>
              <Avatar src="/static/images/avatar/1.jpg" />
              </Grid>
              <Grid item xs={6} className={classes.moreVertIcon}>
                <MoreVertIcon />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.recentCaseClientName}>
                Client Name
              </Grid>
              <Grid item xs={12} className={classes.recentCaseTime}>
                Assigned 14 hours ago               
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default injectIntl(TaxManagementRecentClient);