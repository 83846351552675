import React from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  casesdisplay: {
    display: "block",
  },
  casesdisplayNone: {
    display: "none",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  alertConfgLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "20px",
    // color: "blue",
    textAlign: "left",
    textDecoration: "none",
  },
  // linkNoUnderline: {
  //   textDecoration: "none",
  // },
  root: {
    marginTop: '2%',
    display: "flex",
    // justifyContent: "flex-end",
    background: "#ffffff",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
}));

export default function TaxManagementAlerts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [Flag , setFlag] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [AletrDetailsFromApi, setAletrDetailsFromApi] = React.useState([]);

  const [alerts, setalerts] = React.useState([
    {
      alert_id: "1",
      alert_name: "Alert First",
      alert_shortcode: "AlertFirst",
      alert_body: "Alert First Alert First Alert First",
      alert_from_user: "sanyukta",
      alert_from_user_id: "1",
      alert_to_user: "vibhavari",
      alert_to_user_id: "2" ,
      on_click_action: "",
      action_t: "",
      is_read: true,
      case_id: "",
      case_current_state:"",
    },
    {
      alert_id: "1",
      alert_name: "Alert Second",
      alert_shortcode: "AlertSecond",
      alert_body: "Alert First Alert First Alert Second",
      alert_from_user: "sanyukta",
      alert_from_user_id: "1",
      alert_to_user: "vibhavari",
      alert_to_user_id: "2" ,
      on_click_action: "",
      action_t: "",
      is_read: false,
      case_id: "",
      case_current_state:"",
    },
    {
      alert_id: "1",
      alert_name: "Alert Third",
      alert_shortcode: "AlertThird",
      alert_body: "Alert First Alert First Alert Third",
      alert_from_user: "sanyukta",
      alert_from_user_id: "1",
      alert_to_user: "vibhavari",
      alert_to_user_id: "2" ,
      on_click_action: "",
      action_t: "",
      is_read: false,
      case_id: "",
      case_current_state:"",
    },
  ])

  React.useEffect(() => {
    setFlag(true);
    dispatch(userActions.checkSessionValidity())
    setShowProcedure(true);

    let user_details = JSON.parse(localStorage.getItem('users'));
    console.log("user_details in client contact",user_details,user_details.data.user_details.username);
    let client_username = user_details.data.user_details.username;
    console.log("client_username=====================",client_username)

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'username': client_username,
        'device_token': device_token, 'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_USER_ALERTS , requestOptions)
      .then((Response) => Response.json())
      .then( async(alertsDetails) => {
        setShowProcedure(false);
        console.log("alertsDetails=====",alertsDetails)
        if(alertsDetails.success == true){
          setAletrDetailsFromApi(alertsDetails.data.user_alerts)
        }
        else{
          Swal.fire({
            icon: 'error',
            text: alertsDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }

      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

  }, [Flag]);

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  console.log("role_of_user",role_of_user)
  return (
    <div>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
      <div className={classes.wrapper}>
        <Grid container>
          <Grid item xs={12} className={classes.pageHeaderLabel}>
            <FormattedMessage id="alertLink" />
          </Grid>
        </Grid>

        {
          ( role_of_user == "trpadmin")?
          (
            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',
              marginTop:'-2%', cursor: 'pointer'}}
              // onClick={() => handlealertConfigurationClick()}  
            >
              {/* <text className={classes.alertConfgLabel}>Alert Configuration</text> */}
              <Link to="/AlertConfiguration" className={classes.alertConfgLabel}>
                Alert Configuration
              </Link>
            </div>
          )
          :(<div></div>)
        }
        
        <div className={classes.root}>
          <Grid container item xs={12} style={{ padding:25}}>
            {
              // alerts.map((i,e)=>(
                AletrDetailsFromApi.map((i,e) =>(
                <Grid item xs={12} style={{marginTop:'1.5%',}}>
                  {/* <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>{i.alert_from_user}</text>
                  &nbsp; */}
                  {/* <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>{i.subject}</text>
                  &nbsp;
                  <text style={{fontWeight:500, fontSize:15, cursor:'pointer'}}>{i.body}</text>
                  {
                    ( i.is_read == false )?
                    ( <div style={{ width:'10px', height:'10px',backgroundColor: 'blue',
                      borderRadius: '50%', marginLeft:'1%', marginTop:'0.5%'}}></div>)
                    :(<div></div>)
                  }
                  <hr style={{ marginTop:'1.5%', color:'black', width: '100%',}}/> */}
                  <Grid container item xs={12}>
                    <Grid> <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>{e+1}.</text> </Grid>
                    <Grid> <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>Subject: &nbsp;</text> </Grid>
                    <Grid> <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>{i.subject}</text> </Grid>
                    
                  </Grid>
                  <Grid container item xs={12} style={{marginLeft:'1%'}}>
                    <text style={{fontWeight:500, fontSize:15, cursor:'pointer'}}>{i.body}</text>
                    {
                      ( i.is_read == false )?
                      ( <div style={{ width:'10px', height:'10px',backgroundColor: 'blue',
                        borderRadius: '50%', marginLeft:'1%', marginTop:'0.5%'}}></div>)
                      :(<div></div>)
                    }
                  </Grid>
                <hr style={{ marginTop:'1.5%', color:'black', width: '100%',}}/>
                </Grid>
              ))
            }
          </Grid>
          </div>
      </div>
    </div>
  );
}
