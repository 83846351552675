import React from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  root: {
    marginTop: '5%',
    display: "flex",
    // justifyContent: "flex-end",
    background: "#ffffff",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  alertTypeHeadings: {
    fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      padding: 20,
  },
  customRadioButton: {
    // border: "1px solid rgba(0, 0, 0, 0.32)",
    // borderRadius: "6px",
    paddingLeft: "5px",
    paddingRight: "25px",
    marginRight: "20px",
  },
}));

export default function AlertConfiguration() {
  const classes = useStyles();

  const [alerts, setalerts] = React.useState([
    {
      action_id: "1",
      action_name: "Alert First  ",
      alert_type_options: [
        {option_id: "1", option: "INFORMATION", selected: ""},
        {option_id: "2", option: "CRITICAL", selected: "" },
        {option_id: "3", option: "ANGER", selected: "" },
      ],
    },
    {
      action_id: "2",
      action_name: "Alert Two",
      alert_type_options: [
        {option_id: "1", option: "INFORMATION", selected: "" },
        {option_id: "2", option: "CRITICAL", selected: "" },
        {option_id: "3", option: "ANGER", selected: "" },
      ],
    },
    {
      action_id: "3",
      action_name: "Alert Three",
      alert_type_options: [
        {option_id: "1", option: "INFORMATION", selected: "" },
        {option_id: "2", option: "CRITICAL", selected: "" },
        {option_id: "3", option: "ANGER", selected: "" },
      ],
    },
  ])

  const handleCheckAlerConfg  = async(e, action_id, option_id) => {
    console.log("e",e.target.value)
    await setalerts(
        alerts.map((f) => {
          if (f.action_id === action_id)
          {
              for(var i = 0; i < f.alert_type_options.length; i++){
                  if(f.alert_type_options[i].option_id === option_id){
                      f.alert_type_options[i].selected = true           
                  }
                  else
                  {
                      f.alert_type_options[i].selected = false
                  }
              }
          }
          return f;
      })
      );
  }

  return (
      console.log("alerts",alerts),
    <div>
      <div className={classes.wrapper}>
        <Grid container>
          <Grid item xs={12} className={classes.pageHeaderLabel}>
            Alert Configuration
          </Grid>
        </Grid>
        
        <div className={classes.root}>
          {/* <Grid container item xs={12} style={{ padding:25}}>
            {
              alerts.map((i,e)=>(
                <Grid container item xs={12} style={{marginTop:'1.5%',}}>
                  <text style={{fontWeight:500, fontSize:15, cursor:'pointer'}}>{i.action_name}</text>
                  {
                    
                  }
                <hr style={{ marginTop:'1.5%', color:'black', width: '100%',}}/>
                </Grid>
              ))
            }
          </Grid> */}
            {/* <div style={{display: 'flex',}}>
                <div style={{ flexDirection: 'row', padding: 10,}}>
                    <div style={{marginLeft: '70%'}}>
                        <text className={classes.alertTypeHeadings}>INFORMATION</text>
                        <text className={classes.alertTypeHeadings}>CRITICAL</text>
                        <text className={classes.alertTypeHeadings}>ANGER</text>
                    </div>
                </div>
            </div> */}
            <Grid>
            <Grid container item xs={12} style={{marginLeft:'40%'}}>
                {/* <text className={classes.alertTypeHeadings}>INFORMATION</text> */}
                <Grid className={classes.alertTypeHeadings}> INFORMATION </Grid>
                <Grid style={{marginLeft:'4%'}} className={classes.alertTypeHeadings}>CRITICAL</Grid>
                <Grid style={{marginLeft:'7%'}} className={classes.alertTypeHeadings}>DANGER</Grid>
            </Grid>
            <Grid container item xs={12} >
                {
                alerts.map((i,e)=>(
                    <Grid container item xs={12} style={{marginTop:'1.5%',}}>
                        <Grid item xs={4} style={{fontWeight:500, fontSize:15, marginTop:'1%',
                            marginLeft:'3%', textAlign:'left',}}>
                            {i.action_name} 
                        </Grid>
                        {
                            i.alert_type_options.map(( o, j ) => (
                                <Grid item xs={1} style={{marginLeft:'10%'}}>
                                    <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        value={o.selected == true ? o.option : null}
                                        onChange={ (e) => handleCheckAlerConfg(e, i.action_id , o.option_id)}
                                    >   
                                        <div className={classes.customRadioButton}>
                                            <FormControlLabel
                                                value={o.option}     
                                                classes={{ label: classes.label }}
                                                control={<Radio size="small" color="primary" />}
                                                // label={o.option}     
                                            />
                                        </div>
                                    </RadioGroup>
                                </Grid>
                            ))
                        }
                        <hr style={{ marginLeft:'2%' ,marginTop:'1.5%', color:'black', width: '100%',}}/>
                    </Grid>
                ))
                }
            </Grid>
            </Grid>
        </div>
      </div>
    </div>
  );
}
