import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
// import {
//   MainContainer,
//   ChatContainer,
//   MessageList,
//   Message,
//   MessageInput,
// } from "@chatscope/chat-ui-kit-react";

import {
    MinChatUiProvider,
    MainContainer,
    MessageInput,
    MessageContainer,
    MessageList,
    MessageHeader
  } from "@minchat/react-chat-ui";

const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        // justifyContent: 'flex-end'
        flexGrow: 1,
    },
    wrapper: {
        margin: "9%",
        marginRight: "5%",
        marginTop: "7%",
        marginBottom: "3%",
    },
    casesDetailInfo: {
        textAlign: "left",
    },
    customPaperStyle: {
        borderRadius: "10px",
        marginLeft: "1.5%",
    },
    PaperClientListStyle: {
        borderRadius: "10px",
        // marginLeft: "1.5%",
        padding: 8,
        marginTop: "5%"
    },
    casesDetailSubMenu: {
        // borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
    },
    tabCustomStyle: {
        textTransform: "none",
        minWidth: '16.5%',
        // width:'10%',
        // fontSize: 10,
    },
    pageHeaderContainer: {
        marginBottom: "5%",
    },
    pageHeaderLabel: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.64)",
        textAlign: "left",
    },
    ListNameStyle: {
        fontFamily: [
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "30px",
        color: "rgba(0, 0, 0, 0.64)",
        textAlign: "left",
        cursor: "pointer",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function CommunicationInterface({ users }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [ShowProcedure, setShowProcedure] = React.useState(false);
    const [flag, setflag] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [BusinessUsersList, setBusinessUsersList] = React.useState([]);
    const [ClientsList, setClientsList] = React.useState([]);

    const [SendMessageTo, setSendMessageTo] = React.useState();

    useEffect(() => {
        setflag(true);
        setShowProcedure(true);

        const requestOptionsUsers = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'business_id': business_id,
                'device_token': device_token, 'session_id': session_id
            })
        };
        fetch(process.env.REACT_APP_GET_BUSINESS_TEAM, requestOptionsUsers)
            .then((Response) => Response.json())
            .then(async (allUsersDetails) => {
                setShowProcedure(false);
                console.log("allUsersDetails", allUsersDetails);
                if (allUsersDetails.success == true) {
                    setBusinessUsersList(allUsersDetails.data.business_team);
                }
                else if (allUsersDetails.success == false) {
                    setBusinessUsersList([]);
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            });

        const requestOptionsClients = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'business_id': business_id, 'device_token': device_token,
                'session_id': session_id
            })
        };

        fetch(process.env.REACT_APP_GET_CLIENTS, requestOptionsClients)
            .then((Response) => Response.json())
            .then(clientDetails => {
                console.log("clientDetails on same page", clientDetails.data.clients);
                if (clientDetails.data.clients) {
                    setClientsList(clientDetails.data.clients)
                }
                if (clientDetails.success == false) {
                    setClientsList([])
                    Swal.fire({
                        icon: 'error',
                        text: clientDetails.errors,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            });

    }, [flag]);


    const dispatch = useDispatch()

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

    const handleSelectForChat = (item) => {
        console.log(" handle SelectForChat is clicked", item)
        setSendMessageTo(item)
    }


    return (
        console.log("SendMessageTo return", SendMessageTo),
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
            </modal> : null}
            <div className={classes.wrapper}>
                <Grid container spacing={3}>
                    <Grid item xs={3} className={classes.casesDetailInfo}>
                        <Grid container>
                            <Grid item xs={12}>
                                {/* <Paper elevation={0} className={classes.customPaperStyle}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChangeTab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            className={classes.tabCustomStyle}
                                            label={"Business Users"}
                                        />
                                        <Tab
                                            className={classes.tabCustomStyle}
                                            label={"Clients"}
                                        />

                                    </Tabs>

                                    <TabPanel value={value} index={0}>
                                        <Paper className={classes.ListsPaperStyle}>
                                        <Grid>
                                            Business Users:
                                            <Grid>
                                                {
                                                    BusinessUsersList && BusinessUsersList.map((item, index) => (

                                                        <Grid 
                                                            style={{cursor:"pointer"}}
                                                            onClick={() => handleSelectForChat(item)}
                                                        >
                                                            {item.first_name}
                                                        </Grid>

                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                        </Paper>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Grid>
                                            Clients List:
                                            <Grid>
                                                {
                                                    ClientsList && ClientsList.map((item, index) => (
                                                        <Grid>
                                                            {item.first_name}
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </Paper> */}
                                <Paper elevation={0} className={classes.customPaperStyle}>
                                    <Grid style={{ padding: 15 }}>
                                        <Grid className={classes.pageHeaderLabel}>
                                            Clients List:
                                        </Grid>
                                        <hr />
                                        <Grid>
                                            {
                                                ClientsList && ClientsList.map((item, index) => (
                                                    <Paper className={classes.PaperClientListStyle}>
                                                        <Grid
                                                            item xs={12}
                                                            style={{ justifyContent: "center", textAlign: 'center', alignItems: 'center' }}
                                                        >
                                                            <Grid
                                                                item xs={12}
                                                                container
                                                                className={classes.ListNameStyle}
                                                                onClick={() => handleSelectForChat(item)}
                                                            >
                                                                <Grid>
                                                                    <Avatar src="/static/images/avatar/1.jpg" />
                                                                </Grid>
                                                                <Grid style={{ marginTop: "1%", marginLeft: '1%' }}>
                                                                    {item.first_name}
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Paper>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={9}>
                        {/* <Paper elevation={0} className={classes.customPaperStyle}> */}
                            <Grid >
                                {/* {
                                    (SendMessageTo !== undefined) ?
                                    ( */}
                                        {/* <Grid> */}
                                        {/* <Grid container className={classes.pageHeaderContainer} > */}
                                                                                    

                                                {/* <div style={{ position: "relative", height: "500px" }}>
                                                    <MainContainer>
                                                        <ChatContainer>
                                                        <MessageList>
                                                            <Message
                                                            model={{
                                                                message: "Hello my friend",
                                                                sentTime: "just now",
                                                                sender: "Joe",              
                                                                
                                                            }}
                                                            />
                                                        </MessageList>
                                                        <MessageInput placeholder="Type message here" />
                                                        </ChatContainer>
                                                    </MainContainer>
                                                    </div> */}
                                            
                                        {/* </Grid> */}

                                        {/* <Grid item xs={12} style={{ padding: 20 }}>
                                            Message List
                                        </Grid> */}
                                        {/* </Grid> */}
                                        
                                        {/* New Grid for second chat ui */}
                                        
                                            <MinChatUiProvider theme="#6ea9d7">
                                                <MainContainer style={{ position: "relative", height: '80vh' }}>
                                                    <MessageContainer>
                                                    <MessageHeader />
                                                    <MessageList
                                                        currentUserId='dan'
                                                        messages={[{
                                                        text: 'Hello',
                                                        user: {
                                                            id: 'mark',
                                                            name: 'Markus',
                                                        },
                                                        },
                                                        {
                                                            text: 'Hello',
                                                            user: {
                                                                id: 'mark',
                                                                name: 'Markus',
                                                            },
                                                            },
                                                            {
                                                                text: 'Hello',
                                                                user: {
                                                                    id: 'mark1',
                                                                    name: 'Markus1',
                                                                },
                                                                },
                                                                {
                                                                    text: 'Hello',
                                                                    user: {
                                                                        id: 'mark',
                                                                        name: 'Markus',
                                                                    },
                                                                    },
                                                                    {
                                                                        text: 'Hello',
                                                                        user: {
                                                                            id: 'mark',
                                                                            name: 'Markus',
                                                                        },
                                                                        },
                                                                        {
                                                                            text: 'Hello',
                                                                            user: {
                                                                                id: 'mark',
                                                                                name: 'Markus',
                                                                            },
                                                                            },
                                                                            {
                                                                                text: 'Hello',
                                                                                user: {
                                                                                    id: 'mark',
                                                                                    name: 'Markus',
                                                                                },
                                                                                },
                                                                                {
                                                                                    text: 'Hello',
                                                                                    user: {
                                                                                        id: 'mark',
                                                                                        name: 'Markus',
                                                                                    },
                                                                                    },
                                                                                    {
                                                                                        text: 'Hello',
                                                                                        user: {
                                                                                            id: 'mark',
                                                                                            name: 'Markus',
                                                                                        },
                                                                                        }]}
                                                    />
                                                    <MessageInput placeholder="Type message here" />
                                                    </MessageContainer>
                                                </MainContainer>
                                            </MinChatUiProvider>
                                        
                                    {/* ) :
                                    (
                                        <Grid>
                                           <text style={{marginTop:'15%'}}> Please Select Client </text> 
                                             
                                        </Grid>
                                    )
                                } */}
                            </Grid>
                        {/* </Paper> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    );

}




