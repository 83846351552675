import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { useSelector , useDispatch} from 'react-redux';
// import useCheckSessionApi from "../../SessionValidity";
import { userActions } from "../../actions";

import TaxManagementCasesOverView from "../../TaxManagementCasesOverView";
import TaxManagementCasesActivity from "../../TaxManagementCasesActivity";
import TaxManagementCasesMessages from "../../TaxManagementCasesMessages";
import TaxManagementCasesDocuments from "../../TaxManagementCasesDocuments";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cursorPointer: {
    cursor: "pointer"
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  customPaperStyle: {
    borderRadius: "10px",
    // marginLeft: "3%",
    marginTop: "2%",
  },
  casesDetailSubMenu: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
  },
  paperModal: {
    backgroundColor: "#FFFFFF",
    boxShadow: theme.shadows[5],
    borderRadius: "5px",
    width: "570px",
    maxHeight: "480px",
    padding: theme.spacing(2, 4, 3),
  },

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  const [flag, setflag] = React.useState(false);
  const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
    }, [flag]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ShowProcess() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };

    let selected_process_id = JSON.parse(localStorage.getItem('selected_process_id'));
    return(
      <div className={classes.root}>
      <div className={classes.wrapper}>
        <Grid container>
          <Grid item xs={8} className={classes.pageHeaderLabel} >
            <Breadcrumbs  aria-label="breadcrumb">
              <Link to="BusinessDashboard" >
                BusinessDashboard
              </Link>
              <Link to="ProcessList" >
                ProcessList
              </Link>
              <Typography color="textPrimary">Process Details for Process{selected_process_id} </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Paper elevation={0} className={classes.customPaperStyle}>
            <div className={classes.casesDetailSubMenu}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className={classes.tabCustomStyle}
                  label= "Overview"
                />
                {/* <Tab
                  className={classes.tabCustomStyle}
                  label="Activity"
                />
                <Tab
                  className={classes.tabCustomStyle}
                  label="Messages"
                />
                <Tab
                  className={classes.tabCustomStyle}
                  label="Document"
                /> */}
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <TaxManagementCasesOverView />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <TaxManagementCasesActivity />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TaxManagementCasesMessages/>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <TaxManagementCasesDocuments />
            </TabPanel> */}
          </Paper>
        </Grid>
      </div>
      </div>
    )
}