import React, {useEffect} from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import AddIcon from "@material-ui/icons/Add";
import TaxManagementClientTable from "../TaxManagementClientTable";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";
import FilterViewClient from "../FilterViewClient";
import Drawer from "@material-ui/core/Drawer";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link, Redirect} from 'react-router-dom';
import { PropagateLoader } from 'react-spinners';
import { Modal } from 'reactstrap';
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useHistory } from 'react-router-dom'

import TableSortLabel from '@material-ui/core/TableSortLabel';
import useStyles from '../ThemeStyles';


export default function TaxManagementRoles(props) {
  const [ device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [ session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [checked, setChecked] = React.useState(false);
  const [businessRoles, setBusinessRoles] = React.useState([]);
    const [roleDetails, setRoleDetails] = React.useState([]);
  const dispatch = useDispatch();
    const history = useHistory();


   const classes = useStyles();

   let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
    // console.log("user_permissions from local storage on header page::::::",user_permissions)


    const moveToPermissionsPage = (e, oldornew) => {
        console.log(e.target.dataset.roleid, "permissions page ROLE ID =====>");
        // new
        if( oldornew === "new"){
            localStorage.setItem('roleId', "new");
            history.push(`/UpdatePermissions`);
        }
        else{
            localStorage.setItem('roleId', e.target.dataset.roleid);
            history.push(`/UpdatePermissions`);
        }
        
    };

    useEffect(() => {

        dispatch(userActions.checkSessionValidity());

        localStorage.removeItem('view_clients_active_cases');

        let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
        console.log("business_id clientDetails on same page",business_id)
        let device_token = JSON.parse(localStorage.getItem('device_token'));
        let session_id = JSON.parse(localStorage.getItem('session_id'));

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'business_id': business_id,'device_token': device_token,
                'session_id': session_id })
        };

        fetch( process.env.REACT_APP_GET_BUSINESS_ROLES , requestOptions)
        .then((Response) => Response.json())
        .then( roleDetails => {
            console.log("roleDetails on same page", roleDetails.data.roles);
            if(roleDetails.data.roles)
            {
                setBusinessRoles(roleDetails.data.roles)
            }
            if(roleDetails.success == false){
              setBusinessRoles([])
                Swal.fire({
                icon: 'error',
                text: roleDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });



    }, []);

  return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Grid container  className={classes.pageHeaderContainer}>
                    {/* <Grid item xs={8} className={classes.pageHeaderLabel}>
                        <h1>Roles</h1> <br/> */}
                    {/* </Grid> */}
                    <Grid item xs={10} className={classes.pageHeaderLabel}>
                        Roles 
                    </Grid>
                    {user_permissions && user_permissions.permissions.create_roles &&
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<AddIcon />}
                                onClick={(e) => { moveToPermissionsPage(e, 'new')}}
                            >
                                New Role
                            </Button>
                        </Grid>
                    }

                </Grid>
                        <div style={{ backgroundColor: '#fff', padding: '2%', marginTop:'-2%'}}>
                            <br/>
                            {/* <Grid item xs={12}>
                                <span data-roleid="new"  onClick={(e) => { moveToPermissionsPage(e); }} style={{fontSize: 14, color: 'white', backgroundColor: 'blue', padding:'2%', cursor:'pointer' }}>+ NEW ROLE</span>
                            </Grid> */}
                            {/* <br/><br/> */}

                            <Grid container item xs={12}>
                                <Grid item xs={4}> <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>Role Name</text> </Grid>
                                <Grid item xs={4}> <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>Shortcode</text> </Grid>
                                <Grid item xs={4}> <text style={{fontWeight:'bold', fontSize:15, cursor:'pointer'}}>Actions</text> </Grid>
                            </Grid>
                            <br/>
                            <hr style={{ marginTop:'1.5%', color:'black', width: '100%',}}/>
                            {businessRoles && businessRoles.map((i, iter) => (
                                <div>
                                    <Grid iem container item xs={12} style={{marginLeft:'1%'}}>
                                        <Grid item xs={4}> <text style={{fontSize:12, color:'#000'}}>{i.name}</text> </Grid>
                                        <Grid item xs={4}> <text style={{fontSize:12, color:'#000'}}>{i.shortcode}</text> </Grid>
                                        <Grid item xs={4}>
                                            <span data-roleid={i.role_id} onClick={(e) => { moveToPermissionsPage(e, 'old'); }} style={{fontSize:12, color:'blue', textDecoration:'underline', cursor:'pointer'}}>UPDATE</span>
                                        </Grid>
                                    </Grid>
                                    <hr style={{ marginTop:'1.5%', color:'black', width: '100%',}}/>
                                </div>
                            )

                            )}
                        </div>

                    
                
            </div>
        </div>
  );
}
