import React from 'react';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { injectIntl, FormattedMessage } from "react-intl";
// import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { history } from "../helpers";
import { Router, Route, Redirect, Link} from "react-router-dom";
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(../Image/loginBanner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarLocked: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSignIn: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    marginLeft: "-20%",
    width: "140%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftAlign: {
    textAlign: "left",
  },
  errorBlock: {
    marginLeft: "-20%",
    // marginRight: "5%",
    width: "140%",
    paddingTop: "2%",
    textAlign: "left",
  },
  overFlowHidden: {
    overflow: "hidden",
  },
  dontHaveAccText: {
    cursor: "pointer",
    color: "#3f51b5",
  },
  verifyLabel: {
    width: "100%",
    marginTop: theme.spacing(5),
    // textAlign: "left",
    fontSize: 20,
  },
  verifyText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 15,
  },
  didntOtpText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 12,
  },
  resendText: {
    width: "100%",
    marginTop: "1%",
    textAlign: "left",
    fontSize: 12,
    cursor: "pointer",
    color: "#3f51b5",
  },
  leftAlignBackToHome: {
    textAlign: "left",
    marginTop: '1%',
    marginLeft: '1%',
    cursor:"pointer",
  },

}));

export default function SignUpReferalCode() {
    const classes = useStyles();
    const[referalcode, setreferalcode] = React.useState();
    const[SubmitReferalCode, setSubmitReferalCode] = React.useState(false);
    const[redirectLogin, setredirectLogin] = React.useState(false);

    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  
    const dispatch = useDispatch()

    React.useEffect(() => {
      // console.log("loggingIn in login",loggingIn)
      console.log("device_token in useeffect login",device_token)
      console.log("session_id in useeffect login",session_id)
      
      let user = JSON.parse(localStorage.getItem('user'));
      console.log("user in useeffect login",user)
  
      if( device_token !== null && session_id !== null && user !== null){
        console.log("device token , session id, user info found in useeffect of login")
        // Swal.fire({
        //   icon: 'error',
        //   text: "You are already logged in in app",
        //   confirmButtonColor: '#d33',
        //   confirmButtonText: 'OK'
        // })
        dispatch(userActions.logout());
      }
      else{
        console.log("device token , session id, user info is empty in useeffect of login")
      }
  
    }, []);

    const referalCodeFunc = () => {
      let users_fullname = JSON.parse(localStorage.getItem('users_fullname'));
      let client_password = JSON.parse(localStorage.getItem('client_password'));
      console.log("client_password",client_password)
      setSubmitReferalCode(true)
      if(referalcode){
        // setredirectLogin(true)
        // dispatch(userActions.login(users_fullname, client_password))
        let client_info = JSON.parse(localStorage.getItem('client_signup_info'))
        console.log("client_id from local storage", client_info)
        let client_id = client_info.client_id
        console.log("client_id from local storage", client_id)

        const referalCodeData = {
          'client_id': client_id,
          'trp_verification_code': referalcode,
        }
        console.log("referalCodeData",referalCodeData)

        const requestOptions = {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(referalCodeData)
        };
        fetch( process.env.REACT_APP_VERIFY_TRP_INVITE , requestOptions)
          .then((Response) => Response.json())
          .then( referalCodeData => {
              console.log("referalCodeData in submit referal code",referalCodeData);
              if(referalCodeData.success == true)
              {
                let username = referalCodeData.data.trpinvite_verification.email
                dispatch(userActions.login(username, client_password))
              }
              if(referalCodeData.success == false){
                Swal.fire({
                  icon: 'error',
                  text: referalCodeData.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error. Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          });

      }
    }

  let users_fullname = JSON.parse(localStorage.getItem('users_fullname'));
  let client_full_info = JSON.parse(localStorage.getItem('client_signup_info'))
  return (
    <div>
        <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            className={classes.overFlowHidden}
        >

        <Grid item xs className={classes.leftAlignBackToHome}  >
          <Link to="HomePage" variant="body2" style={{color:'#551A88'}}>
            <FormattedMessage id="backToHomePageLabel" />
           </Link>
        </Grid>

        <div className={classes.paper}>
            <Avatar className={classes.avatarLocked}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign Up
            </Typography>
            <div>
                    <div>
                    <div  className={classes.form}>
                      <Grid item xs={12}>
                        {
                          ( client_full_info !== null )?
                          ( 
                            <Typography className={classes.verifyLabel}>
                              Welcome {client_full_info.name}!
                            </Typography>
                            ):
                            (<Grid></Grid>)
                        }

                        <Typography className={classes.verifyText}>
                          {/* Enter Referal code */}
                          Enter Business Link Code
                        </Typography>

                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="referalcode"
                          name="referalcode"
                          autoComplete="referalcode"
                          autoFocus
                          value={referalcode}
                          onChange={(e) => setreferalcode(e.target.value.trim()) }
                        />

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={referalCodeFunc}
                        >
                          Proceed
                        </Button>
                      </Grid>
                    </div> 
                    <div>
                      <Typography className={classes.didntOtpText}>
                        {/* Don't have a referal code? */}
                        Don't have a Business Link Code?
                      </Typography>
                    </div>  
                    <Grid item xs={12}>
                      <div>
                        { SubmitReferalCode == true && !referalcode && (
                          <div className={classes.errorBlock}>
                            <Alert variant="filled" severity="error">
                              { SubmitReferalCode == true && !referalcode && (
                                <div>
                                  {/* Referal Code is required */}
                                  Business Link Code is required
                                </div>
                              )}
                            </Alert>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </div>
        </div> 
        </div>
      </Grid>
    </Grid>
    </div>       
  )

    // }
}