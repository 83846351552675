import React, { Component } from 'react';

import SignUpForm from "../TaxManagementSignUp/SignUpForm"
import ForgotPasswordForm from "./ForgotPasswordForm"

export default class TaxManagementForgotPassword extends Component {
constructor(props) {
    super(props);
    this.state = {  };
}
render() {
    
    return (
        console.log("in TaxManagementForgotPassword"),
    <div>
        <ForgotPasswordForm/>
        {/* <text>hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii</text> */}
    </div>  
    );
}
}