import React , {useEffect} from "react";
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import ReceiptIcon from "@material-ui/icons/Receipt";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  // modal: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   // overflowY:'scroll',

  //   overflow: 'hidden',
  //   // position: 'relative',
    

  // },
  // paperModal: {
  //   position: 'absolute',
  //   width: 500,
  //   // height: 830,
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[5],
  //   top:'2%',
  //   display:'block',
  //   overflow: 'scroll',
  //   // padding: '0 !important',
 
  // },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
    width:'100%',
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    // overflow: 'scroll',
  },
   validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));

function getStyles(allowedTypesList, theme) {
  return {
    fontWeight:
      allowedTypesList.indexOf(allowedTypesList) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DocumentTypes(serviceListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [documentTypeDetails, setdocumentTypeDetails] = React.useState({
        service_id: "",
        name: "",
        description: "",
        doc_type_tag: "",
        file_type: "",
        allowed_types: "",
        file_size: "",
        is_default: true,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [trueFalse, settrueFalse] = React.useState([
      { value: true , name: 'True'  },
      { value: false , name: 'False'  },
    ]);
    const [serviceIdErr, setserviceIdErr] = React.useState();
    const [nameErr, setnameErr] = React.useState();
    const [descriptionErr, setdescriptionErr] = React.useState();
    const [docTypeErr, setdocTypeErr ] = React.useState();
    const [fileTypeErr, setfileTypeErr] = React.useState();
    const [allowedTypeErr, setallowedTypeErr] = React.useState();
    const [fileSizeErr, setfileSizeErr] = React.useState();
    // const [isDefaultErr, setisDefaultErr] = React.useState();
    const [allowedTypesList, setallowedTypesList] = React.useState([
      "PDF",
      "Document",
      "Image",
    ]);
    const [storeallowedType, setstoreallowedType] = React.useState([]);
    const theme = useTheme();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
    }, [flag, open, openBulk]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelCreateDocumentTypeOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateDocumentTypeClose = () => {
      setOpen(false);
      setdocumentTypeDetails({
        //  username: "",
        service_id: "",
        name: "",
        description: "",
        doc_type_tag: "",
        file_type: "",
        allowed_types: "",
        file_size: "",
        is_default: true,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      // setusernameErr();
      setserviceIdErr();
      setnameErr();
      setdescriptionErr();
      setdocTypeErr();
      setfileTypeErr();
      setallowedTypeErr();
      setfileSizeErr();
      // setisDefaultErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateDocumentTypeClear = async() => {
      await setdocumentTypeDetails({
        //  username: "",
        service_id: "",
        name: "",
        description: "",
        doc_type_tag: "",
        file_type: "",
        allowed_types: "",
        file_size: "",
        is_default: true,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      // setusernameErr();
      setserviceIdErr();
      setnameErr();
      setdescriptionErr();
      setdocTypeErr();
      setfileTypeErr();
      setallowedTypeErr();
      setfileSizeErr();
      // setisDefaultErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateDocumentTypeMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      // let usernameErr = '';
      let serviceIdErr = '';
      let nameErr = '';
      let descriptionErr = '';
      let docTypeErr = '';
      let fileTypeErr = '';
      let allowedTypeErr = '';
      let fileSizeErr = '';
      // let isDefaultErr = '';

      // if(!documentTypeDetails.username)  {
      //     usernameErr = 'Please Enter User Name';
      // }
      if(!documentTypeDetails.service_id)  {
          serviceIdErr = 'Please Enter Service Id';
      }
      if(!documentTypeDetails.name)  {
          nameErr = 'Please Enter Name';
      }
      if(!documentTypeDetails.description)  {
          descriptionErr = 'Please Enter Description';
      }
      if(!documentTypeDetails.doc_type_tag)  {
          docTypeErr = 'Please Selecte Document Type';
      }
      if(!documentTypeDetails.file_type)  {
          fileTypeErr = 'Please Select File Type';
      }
      if(!documentTypeDetails.allowed_types)  {
          allowedTypeErr = 'Please Select Allowed Type';
      }

      if(!documentTypeDetails.file_size)  {
          fileSizeErr = 'Please Enter File Size';
      }else if (typeof documentTypeDetails.file_size !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)
        if (!pattern.test(documentTypeDetails.file_size)) {
          fileSizeErr = "Please enter only numbers.";
        }
      }

      // if( documentTypeDetails.is_default  == "" && documentTypeDetails.is_default !== false && documentTypeDetails.is_default !== true )  {
      //   isDefaultErr = 'Please Select is default';
      // }

      if(serviceIdErr || nameErr || descriptionErr || docTypeErr || fileTypeErr || allowedTypeErr 
          || fileSizeErr){
        // setusernameErr(usernameErr);
        setserviceIdErr(serviceIdErr);
        setnameErr(nameErr);
        setdescriptionErr(descriptionErr);
        setdocTypeErr(docTypeErr);
        setfileTypeErr(fileTypeErr);
        setallowedTypeErr(allowedTypeErr);
        setfileSizeErr(fileSizeErr);
        // setisDefaultErr(isDefaultErr);

        return false;
      }

      return true;

    }

    const handelSubmitCreateDocumentType = () => {
      console.log("documentTypeDetails",documentTypeDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(documentTypeDetails)
          };
          return fetch( process.env.REACT_APP_CREATE_DOC_TYPE , requestOptions)
              .then((Response) => Response.json())
              .then(documentTypeCreated => {
                  setShowProcedure(false);
                  console.log("documentTypeCreated on same page",documentTypeCreated.success);
                  console.log("documentTypeCreated on same page",documentTypeCreated);
                  if(documentTypeCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Document Type Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                 
                    setdocumentTypeDetails({
                      //  username: "",
                        service_id: "",
                        name: "",
                        description: "",
                        doc_type_tag: "",
                        file_type: "",
                        allowed_types: "",
                        file_size: "",
                        is_default: true,
                        device_token: JSON.parse(localStorage.getItem('device_token')),
                        session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    // setusernameErr();
                    setserviceIdErr();
                    setnameErr();
                    setdescriptionErr();
                    setdocTypeErr();
                    setfileTypeErr();
                    setallowedTypeErr();
                    setfileSizeErr();
                    // setisDefaultErr();
                  }
                  if(documentTypeCreated.success == false || documentTypeCreated.success == null ){
                    Swal.fire({
                      icon: 'error',
                      text: documentTypeCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
      }
    }

    const handleChangeAllowedType = (event) => {
      console.log("event.target",event.target)
      const options = event.target;
      const value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
      setdocumentTypeDetails({...documentTypeDetails, allowed_types:value })
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'30%'}}>Create Document Type</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateDocumentTypeMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateDocumentTypeClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody} style={{position:'absolute'}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={documentTypeDetails.name}
              onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(documentTypeDetails.name)?(<div></div>):(nameErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              value={documentTypeDetails.description}
              onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, description:e.target.value }) }
            />
            <div className={classes.validation}>{(documentTypeDetails.description)?(<div></div>):(descriptionErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="service_id">Service*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Service*</Grid>
              <Select
                native
                labelId="service_id"
                id="service_id"
                value={documentTypeDetails.service_id}
                onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, service_id: JSON.parse(e.target.value) }) }
              >
                {/* <MenuItem value={''}>Select Service</MenuItem>
                {serviceListDropdown.serviceListDropdown.map(item =>
                  <MenuItem value={item.category_id}>{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Service</option>
                  {serviceListDropdown.serviceListDropdown.map(item =>
                    <option value={item.category_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(documentTypeDetails.service_id)?(<div></div>):(serviceIdErr)}</div>
            
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="doc_type_tag">Document Type*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Document Type*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="doc_type_tag"
                  aria-label="doc_type_tag"
                  name="doc_type_tag"
                  defaultValue={documentTypeDetails.doc_type_tag}
                  onChange={ (e) => setdocumentTypeDetails({...documentTypeDetails, doc_type_tag: e.target.value })}
                >                  
                  <div>
                    <FormControlLabel
                      value="PERSONAL"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Personal"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="BUSINESS"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Business"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="doc_type_tag"
                id="doc_type_tag"
                value={documentTypeDetails.doc_type_tag}
                onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, doc_type_tag:e.target.value }) }
              >
                <MenuItem value={'PERSONAL'}>Personal</MenuItem>
                <MenuItem value={'BUSINESS'}>Business</MenuItem>
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(documentTypeDetails.doc_type_tag)?(<div></div>):(docTypeErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="file_type">File Type*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>File Type*</Grid>
              <Select
                native
                labelId="file_type"
                id="file_type"
                value={documentTypeDetails.file_type}
                onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, file_type:e.target.value }) }
              >
                {/* <MenuItem value={'PDF'}>PDF</MenuItem>
                <MenuItem value={'Image'}>Image</MenuItem>
                <MenuItem value={'DOC'}>DOC</MenuItem> */}
                <option value="" style={{color:'grey'}}>Select File Type</option>
                <option value="PDF" >PDF</option>
                <option value="Image" >Image</option>
                <option value="DOC" >DOC</option>
              </Select>
            </FormControl>
            <div className={classes.validation}>{(documentTypeDetails.file_type)?(<div></div>):(fileTypeErr)}</div>
            
            <FormControl className={classes.formControl}>
              <InputLabel id="allowed_types">Allowed Type*</InputLabel>
              {/* <Select
                labelId="allowed_types"
                id="allowed_types"
                value={documentTypeDetails.allowed_types}
                onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, allowed_types:e.target.value }) }
              >
                <MenuItem value={'PDF'}>PDF</MenuItem>
                <MenuItem value={'Image'}>Image</MenuItem>
                <MenuItem value={'DOC'}>DOC</MenuItem>
              </Select> */}
              {/* <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                multiple
                value={documentTypeDetails.allowed_types}
                name ={documentTypeDetails.allowed_types}
                onChange={handleChangeAllowedType}
                input={<Input />}
                MenuProps={MenuProps}
              >
                  {allowedTypesList.map((item) => (
                  <MenuItem key={item.id} value={item.id} style={getStyles(allowedTypesList, theme)}>
                    {item.allowed_type}
                  </MenuItem>
              ))}
            </Select> */}
            <div style={{marginTop:"10%"}}>
            <Multiselect
              data={allowedTypesList}
              placeholder="Select Allowed Type"
              // valueField='id'
              // textField='allowed_type'
              // onSelect={(valueField)=> console.log("valueField",valueField)}
              // onChange={valueField => setdocumentTypeDetails({...documentTypeDetails, allowed_types: valueField })  }
              onChange={ async(event)=>{
                  console.log(event);
                  // await setstoreallowedType(event)
                  // var newArray = await storeallowedType.toString()
                  // await setdocumentTypeDetails({...documentTypeDetails, allowed_types: newArray })
                  // console.log("allowed_types documentTypeDetails",documentTypeDetails)
                  var joined=[];
                  console.log("length : "+event.length);
                  if(event.length === 0)
                  {
                    await setdocumentTypeDetails({...documentTypeDetails, allowed_types: "" })
                  }
                  event.map(async(data)=>{
                    console.log("data in eve map : "+JSON.stringify(data));
                    joined = joined.concat(data);
                    console.log("data join : ",joined);
                    await setdocumentTypeDetails({...documentTypeDetails, allowed_types: joined.toString() })
                    // await setdocumentTypeDetails({...documentTypeDetails, allowed_types: joined })
                    console.log("check this documentTypeDetails",documentTypeDetails)
                  })
              }}
            />
            </div>
            </FormControl>
            <div className={classes.validation}>{(documentTypeDetails.allowed_types)?(<div></div>):(allowedTypeErr)}</div>
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="file_size"
              label="File Size(In mb)"
              name="ile_size"
              autoComplete="file_size"
              value={documentTypeDetails.file_size}
              onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, file_size:e.target.value }) }
            />
            {/* <div className={classes.validation}>{(documentTypeDetails.file_size)?(<div></div>):(fileSizeErr)}</div> */}
            <div className={classes.validation}>{fileSizeErr}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_default">Is Default*</InputLabel> */}
              {/* <Grid style={{marginTop:"2%", color:"grey"}}>Is Default*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_default"
                  aria-label="is_default"
                  name="is_default"
                  defaultValue={JSON.stringify(documentTypeDetails.is_default)}
                  onChange={ (e) => setdocumentTypeDetails({...documentTypeDetails, is_default: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid> */}
              {/* <Select
                labelId="is_default"
                id="is_default"
                value={documentTypeDetails.is_default}
                onChange={(e) => setdocumentTypeDetails({...documentTypeDetails, is_default:e.target.value }) }
              >
                {trueFalse.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            {/* <div className={classes.validation}>{(documentTypeDetails.is_default == "" && documentTypeDetails.is_default !== false && documentTypeDetails.is_default !== true)?(isDefaultErr):(<div></div>)}</div> */}

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateDocumentType}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateDocumentTypeClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelDocumentBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelDocumentBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelDocumentBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelDocumentBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitDocumentBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_DOC_TYPE , requestOptions)
            .then((Response) => Response.json())
            .then( documnetBulk => {
                setShowProcedure(false);
                console.log("documnetBulk on same page",documnetBulk.success);
                  console.log("documnetBulk on same page",documnetBulk);
                  if(documnetBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Document Type Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                   
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(documnetBulk.success == false){                    
                    Swal.fire({
                      icon: 'error',
                      text: documnetBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Document Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelDocumentBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelDocumentBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcel(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitDocumentBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelDocumentBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <ReceiptIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Document types List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="DocumentTypesList" style={{ textDecoration: 'none', color:'black' }}>
                    Document types List
                  </Link>
                </Grid>
                <Grid item xs={12} onClick={handelCreateDocumentTypeOpen}  className={classes.userAddUpdateDelete}>
                    Create Document Types
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateDocumentTypeClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelDocumentBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Document Types
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelDocumentBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
  )
}
