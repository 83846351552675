import * as logindetailsConstant from '../constants/logindetails.constants';

export function addBusinessId(businessId) {
    return { 
        type: logindetailsConstant.LOGEDIN_BUSINESS_ID, 
        businessId 
    };
}

export function addLogedInRole(logedInRole) {
    return { 
        type: logindetailsConstant.LOGEDIN_ROLE, 
        logedInRole 
    };
}

export function addDeviceToken(deviceToken) {
    return { 
        type: logindetailsConstant.DEVICE_TOKEN, 
        deviceToken 
    };
}

export function addSessionId(sessionId) {
    return { 
        type: logindetailsConstant.SESSION_ID, 
        sessionId 
    };
}

export function addLogedInUsersDetails(logedInUsersDetails) {
    return { 
        type: logindetailsConstant.LOGEDIN_USERS_DETAILS, 
        logedInUsersDetails 
    };
}

export function reset() {
    return {
      type: logindetailsConstant.RESET_ACTION,
    }
}