import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(IdProofList, comparator) {
  const stabilizedThis = IdProofList && IdProofList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'document', numeric: false, label: 'Id Proof', },
  { id: 'proof_type', numeric: false, label: 'Proof Type', },
  // { id: 'actions', numeric: false, label: 'Actions', },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            )
          
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  pageHeaderLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  buttonAddNew: {
    textAlign: "right",
    marginTop:"3%",
    marginLeft: "40%",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
  

}));

export default function UsersIdProofDetails() {
    const classes = useStyles();
    // const [IdProofList, setIdProofList] = React.useState([
    //   { id: 1, number: "1234567890"},
    //   { id: 2, number: "1122334455"},
    //   { id: 3, number: "6677889900"},
    // ]);
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [flag , setflag] = React.useState(false);
    const [IdProofList, setIdProofList] = React.useState([]);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));

    // For Adding new ID Proof
    const [NewIdProofType, setNewIdProofType] = React.useState("");
    const [NewIdProofFile, setNewIdProofFile] = React.useState("");
    const [NewIdProofName, setNewIdProofName] = React.useState("");
    const [idProofTypeErr, setidProofTypeErr] = React.useState("");
    const [idProofFileErr, setidProofFileErr] = React.useState("");

    const [openAddNewIdProof, setopenAddNewIdProof] = React.useState(false);

    const [openEditIdProof, setopenEditIdProof] = React.useState(false);

    const dispatch = useDispatch();

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, IdProofList && IdProofList.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());
      // readItemFromStorage();
      setShowProcedure(true);
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        //  headers: {
        //     'Content-Type': 'multipart/form-data',
        //   },
        body: JSON.stringify({'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_USER_ID_PROOFS , requestOptions)
        .then((Response) => Response.json())
        .then(idProofDetails => {
          setShowProcedure(false);
          console.log("idProofDetails are",idProofDetails);
          if(idProofDetails.success == true)
          {
            setIdProofList(idProofDetails.data.idproofs)
          }
          if(idProofDetails.success == false){
            setIdProofList([])
            Swal.fire({
              icon: 'error',
              text: idProofDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }, [flag]);

    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    // Add New Id Proof functions
    const handelAddNewIdProofOpen = () =>{
      setopenAddNewIdProof(true)
    }

    const handelAddNewIdProofClose = () =>{
      setopenAddNewIdProof(false);
      setidProofTypeErr("");
      setidProofFileErr("");
    }

    const onUploadIdProof = (event) => {
      console.log("event.target.files[0]",event.target.files[0])
      console.log("event.target.files[0] name",event.target.files[0].name)
      setNewIdProofName(event.target.files[0].name);
      setNewIdProofFile(event.target.files[0])
    }

    const validateAddNewIdProof = () => {
      let idProofTypeErr = '';
      let idProofFileErr = '';

      if(!NewIdProofType)  {
          idProofTypeErr = 'Please Select Id Proof Type';
      }
      if(!NewIdProofFile)  {
          idProofFileErr = 'Please Select File';
      }

      if(idProofTypeErr || idProofFileErr ){
        setidProofTypeErr(idProofTypeErr);
        setidProofFileErr(idProofFileErr);

        return false;
      }
      return true;
    }

    const handelAddIdProofSubmit = () => {
      
      console.log("Add Id Proof Clicked",NewIdProofType,NewIdProofFile)
      // setopenAddNewIdProof(false)
      const isValid = validateAddNewIdProof();
      if(isValid){
        setopenAddNewIdProof(false);
        setShowProcedure(true);

        const requestOptionsSecond = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify({
            'doc_type': 'proof', 'doc_name': NewIdProofFile.name, 'doc_extra_info': '',
            'device_token': device_token, 'session_id': session_id,
            'client_user_id': '',
          })
        }
        return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
        .then((Response) => Response.json())
        .then(checkResponseOfS3Upload => {
          console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)
          if (checkResponseOfS3Upload.success === true) {
            console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)

            const formData = new FormData();
            Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
              formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
            });
            formData.append("file", NewIdProofFile);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
            xhr.send(formData);

            xhr.onload = function () {
              if (this.status === 204) {
                console.log("-----SUCCESSFULLY UPLOADED");

                console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                
                let user = JSON.parse(localStorage.getItem('user'));
                // let formdata = new FormData();
                // formdata.append('idproof', checkResponseOfS3Upload.data.public_url.url);
                // formdata.append('proof_type', NewIdProofType);
                // formdata.append('device_token', device_token);
                // formdata.append('session_id', session_id);
                const requestOptions = {
                  method: 'POST',
                  headers: {'Authorization': 'Token ' + user.key},
                  // headers: authHeader(),
                  // body: formdata,
                  body: JSON.stringify({
                    'updated_by':"self",
                    'idproof': checkResponseOfS3Upload.data.public_url.url, 'proof_type': NewIdProofType,
                    'device_token': device_token, 'session_id': session_id,
                    'updated_by': 'self', 'client_user_id': '',
                  })
                };
                return fetch( process.env.REACT_APP_UPLOAD_ID_PROOF , requestOptions)
                  .then((Response) => Response.json())
                  .then( IdProofSaved => {
                    setShowProcedure(false);
                    console.log("IdProofSaved ",IdProofSaved.success);
                    console.log("IdProofSaved ",IdProofSaved);
                    if(IdProofSaved.success == true){
                      Swal.fire({
                      icon: 'success',
                      text: "Id Proof Saved Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setopenAddNewIdProof(false);
                    setNewIdProofType("");
                    setNewIdProofFile("");
                    setidProofTypeErr("");
                    setidProofFileErr("");
                  }
                  if(IdProofSaved.success == false){
                    setShowProcedure(false);
                    setopenAddNewIdProof(true);
                    Swal.fire({
                      icon: 'error',
                      text: IdProofSaved.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }  
                  setflag(false);    
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });

              }
              else {
                console.log("---------FAILED UPLOAD");
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Upload failed to s3",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }
          }
          else {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error (s3). Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error (s3). Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        })



        // let user = JSON.parse(localStorage.getItem('user'));
        // let formdata = new FormData();
        // formdata.append('idproof', NewIdProofFile);
        // formdata.append('proof_type', NewIdProofType);
        // formdata.append('device_token', device_token);
        // formdata.append('session_id', session_id);
        // const requestOptions = {
        //   method: 'POST',
        //   headers: {'Authorization': 'Token ' + user.key},
        //   // headers: authHeader(),
        //   body: formdata,
        // };
        // return fetch( process.env.REACT_APP_UPLOAD_ID_PROOF , requestOptions)
        //   .then((Response) => Response.json())
        //   .then( IdProofSaved => {
        //     setShowProcedure(false);
        //     console.log("IdProofSaved ",IdProofSaved.success);
        //     console.log("IdProofSaved ",IdProofSaved);
        //     if(IdProofSaved.success == true){
        //       Swal.fire({
        //       icon: 'success',
        //       text: "Id Proof Saved Succesfully",
        //       confirmButtonColor: 'primary',
        //       confirmButtonText: 'OK',
        //       timer: 5000,
        //     })
        //     setopenAddNewIdProof(false);
        //     setNewIdProofType("");
        //     setNewIdProofFile("");
        //     setidProofTypeErr("");
        //     setidProofFileErr("");
        //   }
        //   if(IdProofSaved.success == false){
        //     setopenAddNewIdProof(true);
        //     Swal.fire({
        //       icon: 'error',
        //       text: IdProofSaved.errors,
        //       confirmButtonColor: '#d33',
        //       confirmButtonText: 'OK',
        //       timer: 5000,
        //     })
        //   }  
        //   setflag(false);    
        // })
        // .catch(err => {
        //   setShowProcedure(false);
        //   Swal.fire({
        //     icon: 'error',
        //     // text: "Something went wrong",
        //     text: "Server Error. Please try again.",
        //     confirmButtonColor: '#d33',
        //     confirmButtonText: 'OK'
        //   })
        // });
      }
    }

    const bodyAddNewIdProof = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Add New Id Proof</h2>
      </div>
      <div className={classes.modalBody}>
        <FormControl className={classes.formControl}>
          <InputLabel id="NewIdProofType">Proof Type*</InputLabel>
          <Select
            labelId="NewIdProofType"
            id="NewIdProofType"
            value={NewIdProofType}
            onChange={(e) => setNewIdProofType(e.target.value) }
          >
            <MenuItem value={'AADHAR'}>Aadhaar Card</MenuItem>
            <MenuItem value={'PAN-CARD'}>PAN Card</MenuItem>
            <MenuItem value={'PASSPORT'}>Passport</MenuItem>
            <MenuItem value={'VOTER-ID'}>Voting Card</MenuItem>
            <MenuItem value={'DRIVING-LICENSE'}>Driving License</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.validationBulk}>{(NewIdProofType)?(<div></div>):(idProofTypeErr)}</div>

        <FormControl className={classes.formControl}>
          <Grid item xs={12} style={{color:'black', fontSize: 15,}}>
            Select ID Proof Image/PDF*
          </Grid>
          <div style={{marginTop:'2%'}}>
            <input type='file' 
              // accept=".xlsx"
              id="idProf"
              onChange={ (e) => onUploadIdProof(e)} 
            />
          </div>
        </FormControl>
        <div className={classes.validationBulk}>{(NewIdProofFile)?(<div></div>):(idProofFileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelAddIdProofSubmit}
          >
            Add Id Proof
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelAddNewIdProofClose}
          >
            Cancel
          </Button>
        </div>
      </div>
      </div>
    )

    // Edit Id Proof Functions
    const handleEditIdProof = (item) => {
      console.log("item",item)
      handleEditIdProofOpen();
    }

    const handleEditIdProofOpen = () => {
      setopenEditIdProof(true);
    }

    const handleEditIdProofClose = () => {
      setopenEditIdProof(false);
    }

    const handelEditIdProofSubmit = () => {
      console.log("Edit Id Proof clicked");
      setopenEditIdProof(false);
    }

    const bodyEditIdProof = (
      <div className={classes.paper}>
      <div className={classes.addNewBusinessHeaderBackgroud}> 
        <h2>Edit Id Proof</h2>
      </div>
      <div className={classes.modalBody}>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelEditIdProofSubmit}
          >
            Update Id Proof
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleEditIdProofClose}
          >
            Cancel
          </Button>
        </div>
      </div>
      </div>
    )
    
    return(
        <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
            <Grid container>
              <Grid item xs={1} className={classes.pageHeaderLabel} >
                <Button href="/UsersProfile" className={classes.backButton}>
                    <span className={classes.backArrow}>
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} className={classes.pageHeaderLabel} >
                <h2>Id Proof Details</h2>
              </Grid>
              <Grid item xs={4} className={classes.AddNewBusinessLabel}>
                {/* <h2>Add New Business</h2> */}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonAddNew}
                  startIcon={<AddIcon />}
                  // href="/newclient"
                  onClick={handelAddNewIdProofOpen}
                >
                  Add New Id Proof
                </Button>
              </Grid>
              <Modal
                open={openAddNewIdProof}
                onClose={handelAddNewIdProofClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {bodyAddNewIdProof}
              </Modal>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={IdProofList && IdProofList.length}
                    />
                    <TableBody>
                        {stableSort(IdProofList && IdProofList, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((IdProofList) => {
                          // console.log("row in table map",IdProofList)
                            return (
                            <TableRow 
                                key={IdProofList.id} 
                                hover={true} classes={{hover: classes.hover}}
                                tabIndex={-1}
                                align="center"
                            >
                              <TableCell width="10%" align="center">{IdProofList.document.substring(IdProofList.document.lastIndexOf('/')+1)}</TableCell>
                              
                              <TableCell width="10%" align="center">{IdProofList.proof_type}</TableCell>
                              {/* <TableCell width="5%" align="center" >
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => handleEditIdProof(IdProofList)}
                                  >
                                  Edit
                                </Button>
                              </TableCell> */}
                            </TableRow>
                            );
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: IdProofList && IdProofList.length }]}
                                colSpan={8}
                                count={IdProofList && IdProofList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Modal
              open={openEditIdProof}
              onClose={handleEditIdProofClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyEditIdProof}
            </Modal>
        </div>  
        </div>
    )
}