import { userConstants } from '../constants';

let usersData = JSON.parse(localStorage.getItem('users'));
const initialState = usersData ? usersData : {};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users
      };
    case userConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };

    // case userConstants.LOGINKEY_BY_NANU:
    // return {
    //   key: action.key
    // };

    default:
      return state
  }
}