import React, { Component } from 'react';

import SignUpForm from "./SignUpForm"


export default class TaxManagementSignUp extends Component {
constructor(props) {
    super(props);
    this.state = {  };
}
render() {
    return (
    <div>
        <SignUpForm/>
    </div>  
    );
}
}