import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { authHeader } from '../helpers';
import { userActions } from "../actions";
import { modal } from 'reactstrap';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from "react-intl";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
// import useCheckSessionApi from "../SessionValidity";
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import MessageIcon from '@material-ui/icons/Chat';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    topPosition: {
        top: "5px !important"
    },
    noUnderlineStyle: {
        textDecoration: "none"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
    },
    paperModalFeedback: {
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        width: 700,
        height: 620,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
    },
    addNewBusinessHeaderBackgroud: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#23c1f7',
        backgroundColor: 'white',
        height: '10%',
    },
    modalBody: {
        padding: theme.spacing(2, 4, 3),
    },
    formControl: {
        // margin: theme.spacing(1),
        // marginTop: '4%',
        width: '100%',
    },
    validation: {
        color: 'red',
        fontSize: 12,
    },
    showProcedureStyle: {
        position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
      },

}));

export default function Communication({ users }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [ShowProcedure, setShowProcedure] = React.useState(false);
    const [flag, setflag] = React.useState(false);
    const [RedirectInterface, setRedirectInterface] = React.useState(false);

    useEffect(() => {
        setflag(true);

    }, [flag]);


    const dispatch = useDispatch()

    const handleMessageClick = () => {
        console.log("handle message is clicked");
        setRedirectInterface(true)
    }


    let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));

    if (RedirectInterface == true) {
        return <Redirect to={`/CommunicationInterface`} />
    }
    else {
        return (
            <div className={classes.root}>

                {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                    <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
                </modal> : null}

                <Button
                    // ref={anchorRef}
                    // aria-controls={open ? 'menu-list-grow' : undefined}
                    // aria-haspopup="true"
                    // onClick={handleMessageClick}
                    href='/CommunicationInterface'
                >
                    <MessageIcon style={{ fontSize: 45, cursor: 'pointer', color: '#e1e5eb', }} />
                </Button>

                

            </div>
        );
    }
}
