// UserNoticeHistory

import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import { Link , Route, Redirect} from 'react-router-dom';
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(NoticeHiatory, comparator) {
  const stabilizedThis = NoticeHiatory && NoticeHiatory.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'noticeboard_id', numeric: false, label: 'ID', },
    { id: 'subject', numeric: false, label: 'Subject', },
    { id: 'notice', numeric: false, label: 'Notice Content', },
    { id: 'created', numeric: false, label: 'Created Date', }
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              ( headCell.id === "actions")?
                (
                  <TableCell
                    key={headCell.id}
                    align="center"
                    style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
                  >
                    {headCell.label}
                  </TableCell>
                )
                :(
                <TableCell
                  key={headCell.id}
                  // align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  align="center"
                  style={{fontWeight: 'bold', fontSize: 17}}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                )
              
            ))}
          </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    }
}));
function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
}
TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  //   intl: PropTypes.shape.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    wrapper: {
      margin: "2%",
    //   marginRight: "5%",
    //   marginTop: "7%",
    //   marginBottom: "3%",
    },
  
    pageHeaderLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
    },
    pageHeaderContainer: {
      marginBottom: "5%",
    },
    showProcedureStyle: {
      position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    validation: {
      color: 'red',
      fontSize: 12,
    },
    backButton: {
        padding: 0,
        lineHeight: 0,
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.64)",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
      },
      backArrow: {
        marginRight: "10%",
      },
      table: {
        minWidth: 500, 
      },
      tableWrapper:{
        boxShadow: 'none',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      hover: {
        cursor: "pointer",
      },
  }));

  export default function UserNoticeHistory(props) {
    const classes = useStyles();
    const[ShowProcedure, setShowProcedure ] = React.useState(false);
    const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
    const [Flag, setFlag] = React.useState(false);
    // const [NoticeHiatory, setNoticeHiatory] = React.useState([
    //     {id: "1",
    //     subject:"Notice Subject",
    //     notice:"Notice Content"},
    //     {id: "2",
    //     subject:"Notice Subject 2",
    //     notice:"Notice Content 2"},
    // ]);
    const [NoticeHiatory, setNoticeHiatory] = React.useState([]);

    // redux
    const dispatch = useDispatch();

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, NoticeHiatory && NoticeHiatory.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
        dispatch(userActions.checkSessionValidity());

        setShowProcedure(true);
        
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({'device_token': device_token, 
                'session_id': session_id })
        };
        fetch( process.env.REACT_APP_GET_USER_CREATED_NOTICES , requestOptions)
            .then((Response) => Response.json())
            .then(userCreatedNoticeDetails => {
                setShowProcedure(false);
                console.log("allUsersDetails on same page",userCreatedNoticeDetails.data.notices_info);
                if(userCreatedNoticeDetails.data.notices_info)
                {
                    setNoticeHiatory(userCreatedNoticeDetails.data.notices_info.notices)
                }
                if(userCreatedNoticeDetails.success == false){
                    setNoticeHiatory([])
                  Swal.fire({
                    icon: 'error',
                    text: userCreatedNoticeDetails.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
            })
            // .catch(err => {
            //   Swal.fire({
            //     icon: 'error',
            //     // text: "Something went wrong",
            //     text: "Server Error. Please try again.",
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            //   })
            // });

    }, []);

    return (
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
          <div className={classes.wrapper}>
           
            <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
                <Grid item xs={12} className={classes.pageHeaderLabel}>
                    User Notice History
                </Grid>
            </Grid>
            {/* <Paper className={classes.paper} style={{marginTop:'-2%'}}> */}
            <Grid item xs={12}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={NoticeHiatory && NoticeHiatory.length}
                    />
                    <TableBody>
                        {stableSort(NoticeHiatory && NoticeHiatory, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        // .filter(item => item.is_active == true ).map((NoticeHiatory) => {
                        .map((NoticeHiatory) => {
                          // console.log("row in table map",NoticeHiatory)
                            return (
                            <TableRow 
                                key={NoticeHiatory.client_id} 
                                hover={true} classes={{hover: classes.hover}}
                                // hover
                                tabIndex={-1}
                                align="center"
                            >
                              <TableCell width="2%" align="center">{NoticeHiatory.noticeboard_id}</TableCell>
                              <TableCell width="10%" align="center">{NoticeHiatory.subject}</TableCell>
                              <TableCell width="10%" align="center">{NoticeHiatory.notice}</TableCell> 
                              <TableCell width="10%" align="center">{NoticeHiatory.created}</TableCell> 
                            </TableRow>
                            );
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: NoticeHiatory && NoticeHiatory.length }]}
                                colSpan={8}
                                count={NoticeHiatory && NoticeHiatory.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
                </Grid>
            {/* </Paper> */}
          </div>
        </div>
    )
  }