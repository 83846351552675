import React, { useState,useEffect,useReducer,useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import { addDeviceToken, addSessionId} from "../actions/logindetails.actions";
import Swal from 'sweetalert2';
import {  reset} from "../actions/logindetails.actions";


function SessionValidity({url, options}) {
    // console.log("useDataApi CUSTOM HOOK CALLED ",url, options);
    const [data, setData] = useState("");
    const dispatch = useDispatch();
    // const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
    // const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);

    React.useEffect( () => {
        // console.log("deviceTokenFromRedux and sessionIdFromRedux in session validiti from back page",
        //     device_token,sessionIdFromRedux)
        // console.log("INSIDE useeffect check session",url,options);
        console.log("INSIDE useeffect check session body",options.body.length);
        // var resp='';
        if( options.body.length >= 105 ){
            // console.log("options.body.length >= 100")
          fetch(url,options)
          .then((Response) => Response.json())
          .then( async(checkSession) => {
            // console.log("checkSession in check session func==========",checkSession.success,checkSession);
            // console.log("checkSession in check session func .data.session_info.is_active==========",checkSession.data.session_info.is_active);
            // if(checkSession.success == undefined){
            //   Swal.fire({
            //     icon: 'error',
            //     text: "Nedd to login again",
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK',
            //   }).then(function (result) {
            //     // console.log("handleResponse of logout options",options);
            //     // localStorage.clear();
            //     // return users ;
            //     return fetch( process.env.REACT_APP_LOGOUT , options)
            //     .then((Response) => Response.json())
            //     .then( async(logout) => {
                    
            //         // console.log("handleResponse of logout",logout);
            //         await localStorage.clear();
            //         dispatch(reset());
            //         // return users ;
            //     }).catch( async(error) => {
            //         // console.log("handleResponse of logout catch error",error);
            //         await localStorage.clear();
            //         dispatch(reset());
            //     })

            //   // dispatch(userActions.logout());
            //   })
              
            // }
            if(checkSession.success == true){
                // console.log("in checkSession.success === true");
                if(checkSession.data.session_info.is_active == true && checkSession.data.session_info.session_id_refreshed == false ){
                    console.log("as it is session==1=1=1=1= in file");
                   
                }
                if(checkSession.data.session_info.is_active == true && checkSession.data.session_info.session_id_refreshed == true ){
                    console.log("in checkSession.data.session_info.is_active === true && checkSession.data.session_info.session_id_refreshed === true");
                     await localStorage.setItem('device_token', JSON.stringify(checkSession.data.session_info.device_token));
                     await localStorage.setItem('session_id', JSON.stringify(checkSession.data.session_info.session_id));
                     await dispatch(addDeviceToken(checkSession.data.session_info.device_token));
                     await dispatch(addSessionId(checkSession.data.session_info.session_id));
                    }
                else if(checkSession.data.session_info.is_active == false){
                    console.log("checkSession.data.session_info.is_active === false");
                      Swal.fire({
                        icon: 'error',
                        // text: "Session is expired.Please Login to continue!",
                        html:'Safely logged out due to inactivity.<br/>Please login to continue.',
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'Login',
                        timer: 5000,
                        // width: 500,
                        // padding: '3em',
                      }).then(function (result) {
                      //   // console.log("handleResponse of logout options",options);
                      //   // localStorage.clear();
                      //   // return users ;
                      //   return fetch( process.env.REACT_APP_LOGOUT , options)
                      //   .then((Response) => Response.json())
                      //   .then( async(logout) => {
                            
                      //       // console.log("handleResponse of logout",logout);
                      //       await localStorage.clear();
                      //       // return users ;
                      //   }).catch( async(error) => {
                      //       // console.log("handleResponse of logout catch error",error);
                      //       await localStorage.clear();
                      //   })
                      dispatch(userActions.logout());
                      })
                      

                        // const requestOptions = {
                        //     method: 'POST',
                        //     headers: authHeader(),
                        //     body: JSON.stringify({'device_token': device_token, 'session_id':session_id })
                        // };
                        // return fetch( process.env.REACT_APP_REFRESH_SESSION_TOKEN , options)
                        // .then((Response) => Response.json())
                        // .then( async(sessionRefresh) => {
                        //     await localStorage.setItem('device_token', JSON.stringify(sessionRefresh.data.session_info.device_token));
                        //     await localStorage.setItem('session_id', JSON.stringify(sessionRefresh.data.session_info.session_id));
                        //     dispatch(addDeviceToken(sessionRefresh.data.session_info.device_token));
                        //     dispatch(addSessionId(sessionRefresh.data.session_info.session_id));
                        // });
                }
            }
            else if(checkSession.success == false){
                console.log("checkSession checkSession.success === false",checkSession);
                // dispatch(userActions.logout());
                Swal.fire({
                  icon: 'error',
                  html:'Safely logged out due to inactivity.<br/>Please login to continue.',
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'Login',
                  timer: 5000,
                  // width: 500,
                  // padding: '3em',
                }).then(function (result) {
                //   // console.log("handleResponse of logout options",options);
                //   return fetch( process.env.REACT_APP_LOGOUT , options)
                //   .then((Response) => Response.json())
                //   .then( async(logout) => {
                //     // console.log("handleResponse of logout",logout);
                //     await localStorage.clear();
                //   }).catch( async(error) => {
                //     // console.log("handleResponse of logout error catch",error);
                //     await localStorage.clear();
                //   })
                dispatch(userActions.logout());
                })
                
            }

        })
        .catch(async(error) => {
          console.log(error);
          // localStorage.clear();
          await Swal.fire({
            icon: 'error',
            html:'Safely logged out due to inactivity.<br/>Please login to continue.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Login',
            timer: 5000,
            // width: 500,
            // padding: '3em',
          }).then(function (result) {
          //   // console.log("handleResponse of logout");
          //   localStorage.clear();
          //   // return users ;
          dispatch(userActions.logout());
          })
          
        });
        // });
        }

    }, [url, options]);


    
//     const fetchData = useCallback(() => {
//         console.log("INSIDE CALLBACK");
//         var resp='';
//         const fetchData = async () => {
//         try {
//             console.log("INSIDE TRY BLOCK OPTIONS",options);
//             const result = await fetch(url,options);
//             const content = await result.json();
//             setData(content);
//             console.log('status response from api : '+JSON.stringify(content))
//             if(content.success === true){
//                 if(content.data.session_info.is_active === true && content.data.session_info.session_id_refreshed === true ){
//                     await localStorage.setItem('device_token', JSON.stringify(content.data.session_info.device_token));
//                     await localStorage.setItem('session_id', JSON.stringify(content.data.session_info.session_id));
//                     }
//                 else if(content.data.session_info.is_active === false){
//                     console.log("cleared");
//                     dispatch(userActions.logout());
//                 }
//              return resp = true;
//             }
//             else{
//                 try {
//                     resp= false;
//                     console.log("cleared");
//                     dispatch(userActions.logout());
//                 } 
//                 catch (error) {
//                     console.log("ERROR:",error);
//                 }
//             }
                    
//             return resp;
//         } 
//         catch (error) {
//             console.log("INSIDE ERROR TO DISPATCH FAILURE",error);
//             console.error(error);
//         }
//     };
//     fetchData();
//   }, [url,options]);

  return [data];
};


export default SessionValidity;
