import React, {useEffect} from "react";
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
// import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',

  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
    
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3), 
    width:'100%',
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '2%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function Process(serviceListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [processDetails, setprocessDetails] = React.useState({
        service_id: "",
        business_id: JSON.parse(localStorage.getItem('selected_business_id')),
        shortcode:"",
        name: "",
        description: "",
        status: "",
        is_current: "",
        version: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [serviceIdErr, setserviceIdErr] = React.useState();
    const [shortCodeErr, setshortCodeErr] = React.useState();
    const [nameErr, setnameErr] = React.useState();
    const [descriptionErr, setdescriptionErr] = React.useState();
    const [statusErr, setstatusErr] = React.useState();
    const [currentErr, setcurrentErr] = React.useState();
    const [versionErr, setversionErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      // dispatch(userActions.checkSessionValidity());
    }, [flag, open, openBulk ]);

    const handelCreateProcessOpen = () => {
        setOpen(true);
        dispatch(userActions.checkSessionValidity());
    }

    const handelCreateProcessClose = () => {
        setOpen(false);
        setprocessDetails({
          service_id: "",
          business_id: JSON.parse(localStorage.getItem('selected_business_id')),
          shortcode: "",
          name: "",
          description: "",
          status: "",
          is_current: "",
          version: "",
          device_token: JSON.parse(localStorage.getItem('device_token')),
          session_id: JSON.parse(localStorage.getItem('session_id')), 
        })
        setserviceIdErr();
        setshortCodeErr();
        setnameErr();
        setdescriptionErr();
        setstatusErr();
        setcurrentErr();
        setversionErr();
        dispatch(userActions.checkSessionValidity());
    };

    const handelCreateProcessClear = async() => {
      await setprocessDetails({
        service_id: "",
        business_id: JSON.parse(localStorage.getItem('selected_business_id')),
        shortcode: "",
        name: "",
        description: "",
        status: "",
        is_current: "",
        version: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
      })
      setOpen(false);
      setOpen(true);
      setserviceIdErr();
      setshortCodeErr();
      setnameErr();
      setdescriptionErr();
      setstatusErr();
      setcurrentErr();
      setversionErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateProcessMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
      let serviceIdErr = '';
      let shortCodeErr = '';
      let nameErr = '';
      let descriptionErr = '';
      let statusErr = '';
      let currentErr = '';
      let versionErr = '';

      if(!processDetails.service_id)  {
          serviceIdErr = 'Please Enter Service Id';
      }
      if(!processDetails.shortcode)  {
          shortCodeErr = 'Please Enter Shortcode';
      }
      if(!processDetails.name)  {
          nameErr = 'Please Enter Name';
      }
      if(!processDetails.description)  {
          descriptionErr = 'Please Enter Descriprion';
      }
      if(processDetails.status == "" && processDetails.status !== false && processDetails.status !== true )  {
          statusErr = 'Please Enter Status';
      }
      if(processDetails.is_current == "" && processDetails.is_current !== false && processDetails.is_current !== true)  {
          currentErr = 'Please Enter Current or Not';
      }

      if(!processDetails.version)  {
          versionErr = 'Please Enter Version';
      }
      else if (typeof processDetails.version !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)

        if (!pattern.test(processDetails.version)) {
          // isValid = false;
          versionErr = "Please enter only number.";
        }
      }

      if(serviceIdErr || shortCodeErr || nameErr || descriptionErr || statusErr || currentErr || versionErr ){
        setserviceIdErr(serviceIdErr);
        setshortCodeErr(shortCodeErr);
        setnameErr(nameErr);
        setdescriptionErr(descriptionErr);
        setstatusErr(statusErr);
        setcurrentErr(currentErr);
        setversionErr(versionErr);

        return false;
      }

      return true;

    }

    const handelSubmitCreateProcess = () => {
      console.log("processDetails",processDetails);
      dispatch(userActions.checkSessionValidity());
      const isValid = validate();
      if(isValid){
        setShowProcedure(true);
        setOpen(false);
        const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(processDetails)
        };
        return fetch( process.env.REACT_APP_CREATE_PROCESS_URL , requestOptions)
            .then((Response) => Response.json())
            .then(processCreated => {
                setShowProcedure(false);
                console.log("processCreated on same page",processCreated.success);
                  console.log("processCreated on same page",processCreated);
                  if(processCreated.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Process Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      // timer: 5000,
                    })
                    setprocessDetails({
                      service_id: "",
                      business_id: JSON.parse(localStorage.getItem('selected_business_id')),
                      shortcode: "",
                      name: "",
                      description: "",
                      status: "",
                      is_current: "",
                      version: "",
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')), 
                    })
                    setserviceIdErr();
                    setshortCodeErr();
                    setnameErr();
                    setdescriptionErr();
                    setstatusErr();
                    setcurrentErr();
                    setversionErr();
                  }
                  if(processCreated.success == false || processCreated.success == null ){                  
                    Swal.fire({
                      icon: 'error',
                      text: processCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setOpen(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}>
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'42%'}}>Create Process</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateProcessMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateProcessClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody} style={{position:'absolute'}}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={processDetails.name}
              onChange={(e) => setprocessDetails({...processDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(processDetails.name)?(<div></div>):(nameErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              value={processDetails.description}
              onChange={(e) => setprocessDetails({...processDetails, description:e.target.value }) }
            />
            <div className={classes.validation}>{(processDetails.description)?(<div></div>):(descriptionErr)}</div>
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="shortcode"
              label="Short Code for Process"
              name="shortcode"
              autoComplete="shortcode"
              value={processDetails.shortcode}
              onChange={(e) => setprocessDetails({...processDetails, shortcode:e.target.value }) }
            />
            <div className={classes.validation}>{(processDetails.shortcode)?(<div></div>):(shortCodeErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="service_id">Service*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Service*</Grid>
              <Select
                native
                labelId="service_id"
                id="service_id"
                value={processDetails.service_id}
                onChange={(e) => setprocessDetails({...processDetails, service_id: JSON.parse(e.target.value ) }) }
              >
                {/* <MenuItem value={''}>Select Service</MenuItem>
                {serviceListDropdown.serviceListDropdown.map(item =>
                  <MenuItem value={item.category_id}>{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Service</option>
                  {serviceListDropdown && serviceListDropdown.serviceListDropdown.map(item =>
                    <option value={item.category_id}>{item.name}</option>
                  )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(processDetails.service_id)?(<div></div>):(serviceIdErr)}</div>
            
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="status">Status*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Status*</Grid>.
              <Grid container item xs={12} style={{marginTop:"-2%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(processDetails.status)}
                  onChange={ (e) => setprocessDetails({...processDetails, status: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="status"
                id="status"
                value={processDetails.status}
                onChange={(e) => setprocessDetails({...processDetails, status:e.target.value }) }
              >
                <MenuItem value={'True'}>True</MenuItem>
                <MenuItem value={'False'}>False</MenuItem>
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(processDetails.status == "" && processDetails.status !== false && processDetails.status !== true)?(statusErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_current">Is Current*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Current*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(processDetails.is_current)}
                  onChange={ (e) => setprocessDetails({...processDetails, is_current: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_current"
                id="is_current"
                value={processDetails.is_current}
                onChange={(e) => setprocessDetails({...processDetails, is_current:e.target.value }) }
              >
                <MenuItem value={'True'}>True</MenuItem>
                <MenuItem value={'False'}>False</MenuItem>
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(processDetails.is_current == "" && processDetails.is_current !== false && processDetails.is_current !== true)?(currentErr):(<div></div>)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="version"
              label="Version"
              name="version"
              autoComplete="version"
              value={processDetails.version}
              onChange={(e) => setprocessDetails({...processDetails, version:e.target.value }) }
            />
            {/* <div className={classes.validation}>{(processDetails.version)?(<div></div>):(versionErr)}</div> */}
            <div className={classes.validation}>{versionErr}</div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateProcess}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateProcessClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelProcessBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelProcessBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelProcessBulkClear = () =>{
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelProcessBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitProcessBulk = () => {
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_PROCESS , requestOptions)
            .then((Response) => Response.json())
            .then( processBulk => {
                setShowProcedure(false);
                console.log("processBulk on same page",processBulk.success);
                  console.log("processBulk on same page",processBulk);
                  if(processBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Process Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(processBulk.success == false){                    
                    Swal.fire({
                      icon: 'error',
                      text: processBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Process Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelProcessBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelProcessBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcel(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitProcessBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelProcessBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    Process List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="ProcessList" style={{ textDecoration: 'none', color:'black' }}>
                      Process List
                  </Link> 
                </Grid>
                <Grid item xs={12} onClick={handelCreateProcessOpen} className={classes.userAddUpdateDelete}>
                    Create Process
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateProcessClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelProcessBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Process
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelProcessBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
  )
}
