import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
// import { ClientGenericDetails } from './ClientGenericDetails.reducer';
import { caseUpdate } from './caseupdate.reducer';
import { newCase } from './newcase.reducer';
import { updateCase } from './updatecase.reducer';
import { loginDetails } from './logindetails.reducer';
import { caseDetails } from './casedetails.reducer'

const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  caseUpdate,
  newCase,
  updateCase,
  loginDetails,
  caseDetails
});

export default rootReducer;