import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { useSelector, useDispatch } from 'react-redux';
import useCheckSessionApi from "../SessionValidity";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left'
  }
}));

export default function TaxManagementCasesActivity() {
  const classes = useStyles();

  const [flag, setflag] = React.useState(false);
    const [DeviceToken, setDeviceToken] =   React.useState();
    const [SessionId, setSessionId] =   React.useState();
    const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
    const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
    let user = JSON.parse(localStorage.getItem('user'));
    const [apiURL, setAPIURL] =  React.useState("https://api.trpflow.com");
    const [data, checkSessionValidity] = useCheckSessionApi({
      url: process.env.REACT_APP_CHECK_SESSION_VALIDITY ,
      options: {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + user.key,
        },
        // body: ({'device_token':deviceTokenFromRedux, 
        //       'session_id':sessionIdFromRedux}),
        body: JSON.stringify({
          device_token: deviceTokenFromRedux,
          session_id: sessionIdFromRedux,
        }),
      },
      // props,
    });

  useEffect(() => {
    setflag(true);
    readItemFromStorage();
  }, [flag]);

  const readItemFromStorage = async() => {
    try {
      setDeviceToken(deviceTokenFromRedux)
      setSessionId(sessionIdFromRedux)
      setflag(false)    
    } catch (error) {
        console.log("ERROR:",error);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.gridContianer}>
            <Grid item xs={1}>
              <Avatar src="/static/images/avatar/1.jpg" />
            </Grid>
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={12} className={classes.leftAlign}>
                  {"TRP2 Created Client RL1123"}
                </Grid>
                <Grid item xs={12} className={classes.leftAlign}>
                  {'14 hours ago'}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
              <Divider />
        </Grid>
      </Grid>
    </div>
  );
}
