import React, { useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { PropagateLoader } from 'react-spinners';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import {deleteDocumentAPI} from '../../apis';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(caseBusinessDocuments, comparator) {
    const stabilizedThis = caseBusinessDocuments && caseBusinessDocuments.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'arrow', numeric: false, label: '', },
    { id: 'doc_type_name', numeric: false, label: 'Type', },
    { id: 'description', numeric: false, label: 'Description', },
    // { id: 'document', numeric: false, label: 'Document', },
];
function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    (headCell.id === "arrow") ?
                    (
                        <TableCell
                                key={headCell.id}
                                align="left"
                                style={{ width: "1%" }}
                            >
                                {headCell.label}
                            </TableCell>
                    ):
                    (
                        <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        align="center"
                        style={{ fontWeight: 'bold', fontSize: 17, width: "10%" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                // <span className={classes.visuallyHidden}>
                                <span style={{border: 0,
                                    clip: 'rect(0 0 0 0)',
                                    height: 1,
                                    margin: -1,
                                    overflow: 'hidden',
                                    padding: 0,
                                    position: 'absolute',
                                    top: 20,
                                    width: 1,}}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    )

                    
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        lexGrow: 1,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
    //   test:{
    //     margin: "10%",
    // marginRight: "10%",
    // marginTop: "8%",
    // marginBottom: "3%",
    //   },
}));

export default function BusinessDocumentTable() {
    const classes = useStyles();
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [flag, setflag] = React.useState(false);
    const [ShowProcedure, setShowProcedure] = React.useState(false);
    const [caseForDetailsFromAsync, setcaseForDetailsFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_details')));

    const [caseBusinessDocuments, setcaseBusinessDocuments] = React.useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, caseBusinessDocuments && caseBusinessDocuments.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {
        setflag(true);
        // setShowProcedure(true);

        let case_id = caseForDetailsFromAsync.case_id;
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                'case_id': case_id,
                'device_token': device_token, 'session_id': session_id
            })
        };
        fetch(process.env.REACT_APP_GET_CASE_UPLOADED_DOCUMENTS, requestOptions)
            .then((Response) => Response.json())
            .then(caseDocuments => {
                // setShowProcedure(false);
                console.log("caseDocuments business = ", caseDocuments.data.case_documents);
                if (caseDocuments.success == true) {
                    var joinedBusiness = [];
                    caseDocuments.data.case_documents.map((i, e) => {
                        if (i.doc_type_tag == "BUSINESS") {
                            joinedBusiness = joinedBusiness.concat(i);
                            console.log("data joinedBusiness : ", joinedBusiness);
                            setcaseBusinessDocuments(joinedBusiness);
                        }
                    })
                }
                else {
                    setcaseBusinessDocuments([])
                }
            })
            .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                    icon: 'error',
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            });

    }, [flag]);

    const handleDeletDocument = async(item2) => {
        setShowProcedure(true);
        console.log("handleDeletDocument cleicked", item2)
        let case_id = caseForDetailsFromAsync.case_id;
        const documentDeleteData =  {
            'case_id': case_id,
            'user_document_id': JSON.parse(item2.document_no),
            'device_token': device_token,
            'session_id': session_id,
        }
        const responseData = await deleteDocumentAPI(documentDeleteData).then((data) => {
            console.log("data here is",data)
            setShowProcedure(false);
            if(data.success == true){
                setflag(false);
                Swal.fire({
                    icon: 'success',
                    text: "Document deleted succesfully.",
                    confirmButtonColor: 'primary',
                    confirmButtonText: 'OK'
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: data.errors.toString(),
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            }
            return true;
        })
        .catch(err => {
            setShowProcedure(false);
            Swal.fire({
                icon: 'error',
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        });
        
    }

    function Row(props: { row: ReturnType<typeof createData> }) {
        const { row } = props;
        // const [open, setOpen] = React.useState(false);
        const [open, setOpen] = React.useState(true);

        return (
            <React.Fragment>

                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell width="1%" >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell>

                    <TableCell align="center" style={{ fontWeight: 'bold' }}>{row.doc_type_name}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    {/* <TableCell align="center">{
                        (row.user_documents.length === 0) ?
                            (
                                <Grid style={{ color: 'grey' }}>No Data</Grid>
                            ) :
                            (
                                row.user_documents.map((itemi, indexi) => (
                                    (indexi === 0) ?
                                        (
                                            // itemi.document
                                            <a
                                                href={ itemi.document}
                                                target = "_blank"
                                            >
                                                <Grid style={{color:"#551A8B",listStyle: "none",}}>{itemi.document.substring(itemi.document.lastIndexOf('/')+1)}</Grid>
                                            </a>
                                        ) :
                                        (<></>)

                                ))
                            )

                    }
                    </TableCell> */}
                </TableRow>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box >
                                <>
                                    <Table size="small" aria-label="purchases" style={{ marginLeft: '1%' }}>
                                        <TableHead>
                                            <TableRow >
                                                <TableCell width="4%" align="center" style={{ fontWeight: 'bold' }} >#</TableCell>
                                                <TableCell width="20%" align="center" style={{ fontWeight: 'bold' }} >Document Name</TableCell>
                                                <TableCell width="20%" align="center" style={{ fontWeight: 'bold' }} >Details</TableCell>
                                                <TableCell width="10%" align="center" style={{ fontWeight: 'bold' }}>Password</TableCell>
                                                <TableCell width="10%" align="center" style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        {row.user_documents.map((item2, index2) => (
                                            console.log("row for expand is in rrow.user_documents.map", item2),
                                            <TableBody>
                                                <TableRow>
                                                <TableCell width="4%" align="center" >{index2 + 1}</TableCell>
                                                    <TableCell width="20%" align="center" >
                                                        {/* {item2.document} */}
                                                        <a
                                                            href={ item2.document}
                                                            target = "_blank"
                                                        >
                                                        <Grid style={{color:"#551A8B",listStyle: "none",}}>{item2.document.substring(item2.document.lastIndexOf('/')+1)}</Grid>
                                                        </a>
                                                    </TableCell>
                                                    <TableCell width="20%" align="center" >{item2.document_no}</TableCell>
                                                    <TableCell width="10%" align="center" >{item2.password}</TableCell>
                                                    <TableCell width="10%" align="center">
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            onClick={() => handleDeletDocument(item2)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        ))}
                                    </Table>



                                    <Grid style={{ marginTop: '2%' }}></Grid>

                                </>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>

            </React.Fragment>
        );
    }


    return (
        console.log("caseBusinessDocuments on table page", caseBusinessDocuments),
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
            </modal>: null}
            <Grid>
                <TableContainer style={{ width: '95%' }} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <EnhancedTableHead
                            // classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={caseBusinessDocuments && caseBusinessDocuments.length}
                        />
                        {stableSort(caseBusinessDocuments && caseBusinessDocuments, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                                return (
                                    <TableBody>
                                        <Row key={item.doc_type_id} row={item} />
                                    </TableBody>
                                );
                            })}
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: caseBusinessDocuments && caseBusinessDocuments.length }]}
                                    colSpan={8}
                                    count={caseBusinessDocuments && caseBusinessDocuments.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>


        </div>
    );
}
