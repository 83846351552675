// PaymentPage

import React from "react";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import { PropagateLoader } from 'react-spinners';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    wrapper: {
        marginLeft: "8%",
        marginRight: "7%",
        marginTop: "8%",
        marginBottom: "3%",
    },
    // wrapper: {
    //   margin: "10%",
    //   marginRight: "5%",
    //   marginTop: "10%",
    //   marginBottom: "3%",
    // },
    paper: {
        padding: theme.spacing(2),
        height: '100%',
        textAlign: "left",
        color: theme.palette.text.secondary,
    },
    casesdisplay: {
      display: "block",
    },
    casesdisplayNone: {
      display: "none",
    },
    pageHeaderLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
    },
    alertConfgLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "20px",
      // color: "blue",
      textAlign: "left",
      textDecoration: "none",
    },
    // linkNoUnderline: {
    //   textDecoration: "none",
    // },
    // root: {
    //   marginTop: '2%',
    //   display: "flex",
    //   // justifyContent: "flex-end",
    //   background: "#ffffff",
    // },
    showProcedureStyle: {
      position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    backButton: {
        padding: 0,
        lineHeight: 0,
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.64)",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
    },
    backArrow: {
        marginRight: "10%",
    },
  }));

  export default function PaymentPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [Flag , setFlag] = React.useState(false);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    React.useEffect(() => {
        setFlag(true);
        dispatch(userActions.checkSessionValidity())
        // setShowProcedure(true);

    }, [Flag]);

    return (
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
                <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
              </modal>: null}
            <div className={classes.wrapper}>
                <Grid container>
                        <Button href="/ClientsPaymentInfo" className={classes.backButton}>
                        <span className={classes.backArrow}>
                            <ArrowBackIcon fontSize="small" />
                        </span>
                        <FormattedMessage id="backLabel" />
                        </Button>
                    <Grid item xs={12} className={classes.pageHeaderLabel}>
                        Payment Page
                    </Grid>
                </Grid>

                <Paper className={classes.paper} style={{marginTop:'1%'}}>

                </Paper>
          </div>
    </div>
  );
}