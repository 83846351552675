import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import TaxManagementCaseDetails from "../TaxManagementCaseDetails";
import TaxManagementRecentClient from "../TaxManagementRecentClient";
import TaxManagementClientDetails from "../TaxManagementClientDetails";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(usersList, comparator) {
  const stabilizedThis = usersList && usersList.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'role', numeric: false, label: 'Role', },
  { id: 'email', numeric: false, label: 'EmailID' ,},
  // { id: 'username', numeric: false, label: 'User Name' , },
  { id: 'actions', numeric: false, label: 'Actions' , },
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
            ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
              <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'center' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            )
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },

  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
    // transform: 'translate(-50%, -50%)',
    // top: '50%',
    // left: '50%',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block',
    overflowY:'scroll',
    padding: 10,
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height:'12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    marginTop: '-6%',
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '2%',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

export default function TaxManagementUsers(props) {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [business_shortcode, setbusiness_shortcode ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [Flag, setFlag] = React.useState(false);
  const [usersList, setusersList] = React.useState([]);
  const [Open, setOpen] = React.useState(false);
  const [businessRoles, setBusinessRoles] = React.useState([]);
  const [userDetails, setuserDetails] = React.useState({
    // name: "",
    first_name: "",
    middle_name: "",
    surname: "",
    username: "",
    email: "",
    password: "",
    role: "business-user",
    is_active: "",
    business_shortcode: "",
    business_role: "",
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
    client_type: "",
    org_name: "",

  });
  const [nameErr, setnameErr] = React.useState();
  const [surnameErr, setsurnameErr] = React.useState();
    // const [userNameErr, setuserNameErr] = React.useState();
    const [emailErr, setemailErr] = React.useState();
    const [passwordErr, setpasswordErr] = React.useState();
    const [roleErr, setroleErr] = React.useState();
    const [activeErr, setactiveErr] = React.useState();
    const [EditUser, setEditUser] = React.useState(false);

  // redux
  const dispatch = useDispatch();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, usersList && usersList.length - page * rowsPerPage);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

  useEffect(() => {
    setFlag(true);
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id': business_id,'device_token': device_token, 
            'session_id': session_id })
    };
    fetch( process.env.REACT_APP_GET_BUSINESS_TEAM , requestOptions)
        .then((Response) => Response.json())
        .then(allUsersDetails => {
            console.log("allUsersDetails on same page",allUsersDetails.data.business_team);
            if(allUsersDetails.data.business_team)
            {
                setusersList(allUsersDetails.data.business_team)
            }
            if(allUsersDetails.success == false){
              setusersList([])
              Swal.fire({
                icon: 'error',
                text: allUsersDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });


    fetch( process.env.REACT_APP_GET_BUSINESS_DETAIL , requestOptions)
        .then((Response) => Response.json())
        .then( async(businessDetails) => {
            console.log("businessDetails on same page",businessDetails.data.business_detail,businessDetails.data);
            if (businessDetails.data.business_detail)
            {
                await setuserDetails({...userDetails, business_shortcode: businessDetails.data.business_detail.shortcode })
                await setbusiness_shortcode(businessDetails.data.business_detail.shortcode)
            }
            if(businessDetails.success == false){
                await setuserDetails({...userDetails, business_shortcode: "" })  
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
    });

    fetch( process.env.REACT_APP_GET_BUSINESS_ROLES , requestOptions)
        .then((Response) => Response.json())
        .then( roleDetails => {
            console.log("roleDetails on same page", roleDetails.data.roles);
            if(roleDetails.data.roles)
            {
                setBusinessRoles(roleDetails.data.roles)
            }
            if(roleDetails.success == false){
              setBusinessRoles([])
                Swal.fire({
                icon: 'error',
                text: roleDetails.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
            }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    
  }, [Flag]);

    const handleClickEditUser = async(item) => {
        
        console.log("handleClickEditUser is clicked", item)
        await setuserDetails({
          ...userDetails,
          // name: "",
          user_id: item.user_id,
           first_name: item.first_name,
           middle_name: item.middle_name,
           surname: item.surname,
           username: item.username,
           email: item.email,
          //  password: "",
           role: "business-user",
           is_active: item.is_active,
           business_role: item.role,
            // business_shortcode: "",
           // device_token: JSON.parse(localStorage.getItem('device_token')),
           // session_id: JSON.parse(localStorage.getItem('session_id')),
          //  client_type: "",
          //  org_name: "",
        });
        setEditUser(true);
        // handelAddNewUserOpen();
        setOpen(true);
    }

    const handelAddNewUserOpen = async() => {
        await setuserDetails({
          ...userDetails,
          // name: "",
           first_name: "",
           middle_name: "",
           surname: "",
           username: "",
           email: "",
           password: "",
           role: "business-user",
           is_active: "",
           business_role: "",
            // business_shortcode: "",
           // device_token: JSON.parse(localStorage.getItem('device_token')),
           // session_id: JSON.parse(localStorage.getItem('session_id')),
           client_type: "",
           org_name: "",
        });
        setOpen(true);
        dispatch(userActions.checkSessionValidity());
    }   

    const handelAddNewUserClose = () => {
        setOpen(false);
        // setnameErr();
        // setuserNameErr();
        // setemailErr();
        // setpasswordErr();
        // setroleErr();
        // setactiveErr();
        // setshortCodeErr();
        dispatch(userActions.checkSessionValidity());
        setEditUser(false);
    };

    const handelAddNewUserReset = async() => {
        await setuserDetails({
          ...userDetails,
         // name: "",
          first_name: "",
          middle_name: "",
          surname: "",
          username: "",
          email: "",
          password: "",
          role: "business-user",
          is_active: "",
          business_role: "",
           // business_shortcode: "",
          // device_token: JSON.parse(localStorage.getItem('device_token')),
          // session_id: JSON.parse(localStorage.getItem('session_id')),
          client_type: "",
          org_name: "",
        })
        // this setopen false and then true is for is_active it's not clearing without that
        setOpen(false);
        setOpen(true);
        setnameErr();
        setsurnameErr();
        // setuserNameErr();
        setemailErr();
        setpasswordErr();
        setroleErr();
        setactiveErr();
        dispatch(userActions.checkSessionValidity());
        // setEditUser(false);
      }

    const handelAddNewUserCloseCross = () => {
        setOpen(false);
        setuserDetails({
          ...userDetails,
         // name: "",
          first_name: "",
          middle_name: "",
          surname: "",
          username: "",
          email: "",
          password: "",
          role: "business-user",
          is_active: "",
          business_role: "",
           // business_shortcode: "",
          // device_token: JSON.parse(localStorage.getItem('device_token')),
          // session_id: JSON.parse(localStorage.getItem('session_id')),
          client_type: "",
          org_name: "",
        })
        setnameErr();
        // setuserNameErr();
        setsurnameErr();
        setemailErr();
        setpasswordErr();
        setroleErr();
        setactiveErr();
        // setshortCodeErr();
        dispatch(userActions.checkSessionValidity());
        setEditUser(false);
    };

    const validate = () => {
        let nameErr = '';
        // let userNameErr = '';
        let surnameErr = '';
        let emailErr = '';
        let passwordErr = '';
        let roleErr = '';
        let activeErr = '';
        // let shortCodeErr = '';

        if( EditUser == false){
          

          if(!userDetails.password)  {
            passwordErr = 'Please Enter Password';
          }
          else if(userDetails.password)  {
            if(userDetails.password.length < 8){
              passwordErr = 'Password must contain atleast 8 characters';
            }
          }

          if(!userDetails.email)  {
        emailErr = 'Please Enter Email';
      }
        else if(typeof userDetails.email !== "undefined"){
            let lastAtPos = userDetails.email.lastIndexOf('@');
            let lastDotPos = userDetails.email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
                emailErr = "Email is not valid";
                if(emailErr) {
                  setemailErr(emailErr);
                }
            }
          else {
              setemailErr();    
            }
        }
        else{
          setemailErr();
        }
          

        }

      if(!userDetails.first_name)  {
        nameErr = 'Please Enter First Name';
      }
      if(!userDetails.surname)  {
        surnameErr = 'Please Enter Surname';
      }
      // if(!userDetails.username)  {
      //   userNameErr = 'Please Enter User Name';
      // }
      
        
        // if( EditUser == false){
        //     if(!userDetails.password)  {
        //         passwordErr = 'Please Enter Password';
        //     }
        // }

        if(!userDetails.business_role)  {
            roleErr = 'Please Enter Role';
        }
  
        if(userDetails.is_active == "" && userDetails.is_active !== false && userDetails.is_active !== true )  {
            activeErr = 'Please Select if user is active or not';
        }
        // if(!userDetails.business_shortcode)  {
        //     shortCodeErr = 'Please Enter Business Shortcode';
        // }
  
        if(nameErr || surnameErr || emailErr || passwordErr || roleErr || activeErr ){
          setnameErr(nameErr);
          // setuserNameErr(userNameErr);
          setsurnameErr(surnameErr);
          setemailErr(emailErr);
          setpasswordErr(passwordErr);
          setroleErr(roleErr);
          setactiveErr(activeErr);
          // setshortCodeErr(shortCodeErr);
          return false;
        }
  
        return true;
      }

    const handelSubmitAddNewUser = () => {
        console.log("user arrayy is", userDetails);
        dispatch(userActions.checkSessionValidity());
        const isValid = validate();
        if(isValid){
          setShowProcedure(true);
          setOpen(false);
          const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(userDetails)
          };
          return fetch( process.env.REACT_APP_CREATE_USER , requestOptions)
              .then((Response) => Response.json())
              .then(userAdded => {
                  setShowProcedure(false);
                  console.log("userAdded on same page",userAdded.success);
                    console.log("userAdded on same page",userAdded);
                    if(userAdded.success == true){
                      if(EditUser == true){
                        Swal.fire({
                          icon: 'success',
                          text: "User Details Updated Succesfully",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })  
                      }
                      else{
                        Swal.fire({
                          icon: 'success',
                          text: "User Added Succesfully",
                          confirmButtonColor: 'primary',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        })  
                      }
                      setuserDetails({
                        ...userDetails,
                        // name: "",
                          first_name: "",
                          middle_name: "",
                          surname: "",
                          username: "",
                          email: "",
                          password: "",
                          role: "business-user",
                          is_active: "",
                          business_role: "",
                          // business_shortcode: "",
                          // device_token: JSON.parse(localStorage.getItem('device_token')),
                          // session_id: JSON.parse(localStorage.getItem('session_id')),
                          client_type: "",
                          org_name: "",
                      })
                      setnameErr();
                      // setuserNameErr();
                      setsurnameErr();
                      setemailErr();
                      setpasswordErr();
                      setroleErr();
                      setactiveErr();
                      setFlag(false);
                      setEditUser(false);
                      // setshortCodeErr();
                    }
                    if(userAdded.success == false || userAdded.success == null ){
                      Swal.fire({
                        icon: 'error',
                        text: userAdded.errors,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      }).then(function (result) {
                        console.log("result of swal",result)
                        setOpen(true);
                      })
                  }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
          
        }
    }

    const handleChangeRoleDropdown = (e) => {
        console.log("in handleChangeRoleDropdown", e, e.target.value)
        setuserDetails({...userDetails, business_role: e.target.value })
    }

    const body = (
        <div className={classes.paperModal}>
        {/* <h2 className={classes.addNewBusinessHeader}>Add New Business</h2> */}
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
                {
                    ( EditUser == true)?
                    (
                        <h2 style={{marginLeft:'48%'}}>Edit User</h2>
                    ):
                    (
                        <h2 style={{marginLeft:'41%'}}>Add New User</h2>
                    )
                }
            
            </Grid>
            {/* {
                ( EditUser == true)?
                (
                    <div></div>
                ):
                (
                    <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
                        <MinimizeIcon onClick={()=>handelAddNewUserClose()} style={{fontSize: 20, cursor:'pointer'}}/>
                    </Grid>
                )
            } */}
            
            <Grid item xs={1} 
                // style={ EditUser == false ? { marginTop:'1%'} : {marginTop:'1%', marginLeft:'6%'}}
                style={{marginTop:'1%', marginLeft:'6%'}}
            >
              <CloseIcon onClick={()=>handelAddNewUserCloseCross()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>         
        </div>
        <div className={classes.modalBody}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                {/* {
                  (EditUser == true)?
                  (
                    <div></div>
                  ):
                  ( */}
                    <div>
                      <TextField
                      //   variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="First Name"
                        name="firstname"
                        autoComplete="firstname"
                        value={userDetails.first_name}
                        onChange={(e) => setuserDetails({...userDetails, first_name:e.target.value }) }
                      />
                      <div className={classes.validation}>{(userDetails.first_name)?(<div></div>):(nameErr)}</div>
                    </div>
                  {/* )
                } */}
              </Grid>
              <Grid item xs={6}>
                {/* {
                  (EditUser == true)?
                  (
                    <div></div>
                  ):
                  ( */}
                    <div>
                      <TextField
                      //   variant="outlined"
                        margin="normal"
                        fullWidth
                        id="middlename"
                        label="Middle Name"
                        name="middlename"
                        autoComplete="middlename"
                        value={userDetails.middle_name}
                        onChange={(e) => setuserDetails({...userDetails, middle_name:e.target.value }) }
                      />
                      {/* <div className={classes.validation}>{(userDetails.surname)?(<div></div>):(surnameErr)}</div> */}
                    </div>
                  {/* )
                } */}
              </Grid>
            </Grid>
            

            {/* {
              (EditUser == true)?
              (
                <div></div>
              ):
              ( */}
                <div>
                  <TextField
                  //   variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="surname"
                    label="Surname"
                    name="surname"
                    autoComplete="surname"
                    value={userDetails.surname}
                    onChange={(e) => setuserDetails({...userDetails, surname:e.target.value }) }
                  />
                  <div className={classes.validation}>{(userDetails.surname)?(<div></div>):(surnameErr)}</div>
                </div>
              {/* )
            } */}

            {/* {
              (EditUser == true)?
              (
                <div></div>
              ):
              ( */}
                {/* <div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    value={userDetails.username}
                    onChange={(e) => setuserDetails({...userDetails, username:e.target.value.trim() }) }
                  />
                  <div className={classes.validation}>{(userDetails.username)?(<div></div>):(userNameErr)}</div>
                </div> */}
              {/* )
            } */}
            

            {/* {
              (EditUser == true)?
              (
                <div></div>
              ):
              ( */}
                <div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="EmailID"
                    name="email"
                    autoComplete="email"
                    value={userDetails.email}
                    onChange={(e) => setuserDetails({...userDetails, email:e.target.value , username:e.target.value}) }
                  />
                  {/* <div className={classes.validation}>{(userDetails.email)?(<div></div>):(emailErr)}</div> */}
                  <div className={classes.validation}>{emailErr}</div>
                </div>
              {/* )
            } */}
            

            {
                ( EditUser == false )?
                (
                    <div>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            autoComplete="password"
                            value={userDetails.password}
                            onChange={(e) => setuserDetails({...userDetails, password:e.target.value }) }
                        />
                        {/* <div className={classes.validation}>{(userDetails.password)?(<div></div>):(passwordErr)}</div> */}
                        <div className={classes.validation}>{(passwordErr)?(passwordErr):(<div></div>)}</div>
                    </div>
                ):
                (
                    <div></div>
                )
            }
            


            <FormControl className={classes.formControl}>
              <Grid style={{marginTop:"2%", color:"grey"}}>Role*</Grid>
                <Select
                    // style={{marginTop:'5%'}}
                    native
                    labelId="role"
                    id="role"
                    value={userDetails.business_role}
                    defaultValue={userDetails.business_role}
                    // onChange={(e) => setuserDetails({...userDetails, role:e.target.value }) }
                    onChange={(e) => handleChangeRoleDropdown(e) }
                >
                    <option value="" style={{color:'grey'}}>Select Role</option>
                        {businessRoles && businessRoles.map(item =>
                        <option value={item.shortcode}>{item.name}</option>
                    )}
                </Select>
              

            </FormControl>
            <div className={classes.validation}>{(userDetails.business_role)?(<div></div>):(roleErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_active">Is Active*</InputLabel> */}
              <Grid id="is_active" style={{marginTop:"2%", color:"grey"}}>Is Active*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_active"
                  aria-label="is_active"
                  name="is_active"
                  defaultValue={JSON.stringify(userDetails.is_active)}
                  onChange={ (e) => setuserDetails({...userDetails, is_active: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_active"
                id="is_active"
                value={userDetails.is_active}
                onChange={(e) => setuserDetails({...userDetails, is_active:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(userDetails.is_active == "" && userDetails.is_active !== false && userDetails.is_active !== true)?(activeErr):(<div></div>)}</div>
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="is_active"
              label="Is Active"
              name="is_active"
              autoComplete="is_active"
              value={userDetails.is_active}
              onChange={(e) => setuserDetails({...userDetails, is_active:e.target.value }) }
            />
            <div className={classes.validation}>{(userDetails.is_active)?(<div></div>):(activeErr)}</div> */}
            {/* <TextField
              margin="normal"
              required
              fullWidth
              id="business_shortcode"
              label="Business Shortcode"
              name="business_shortcode"
              autoComplete="business_shortcode"
              value={userDetails.business_shortcode}
              onChange={(e) => setuserDetails({...userDetails, business_shortcode:e.target.value }) }
            />
            <div className={classes.validation}>{(userDetails.business_shortcode)?(<div></div>):(shortCodeErr)}</div> */}

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                {
                    ( EditUser == true )?
                    (
                        <Button
                            type="submit"
                            //   fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handelSubmitAddNewUser}
                        >
                            Update
                        </Button>
                    ):
                    (
                        <Button
                            type="submit"
                            //   fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handelSubmitAddNewUser}
                        >
                            Add
                        </Button>
                    )
                }
                

                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelAddNewUserReset}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

return (
      console.log("userdetails in returns",userDetails),
      <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
          <div className={classes.wrapper}>
            <Grid container  className={classes.pageHeaderContainer}>
                <Grid item xs={10} className={classes.pageHeaderLabel}>
                    Users List
                </Grid>
                <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={() => handelAddNewUserOpen()}
                >
                    Add New User
                </Button>
                </Grid>
            </Grid>
            <Grid style={{marginTop:'-2%'}}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={usersList && usersList.length}
                        />
                        <TableBody>
                            {stableSort(usersList && usersList, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => {
                            // console.log("row in table map",item)
                                return (
                                <TableRow 
                                    key={index}
                                    hover={true} classes={{hover: classes.hover}}
                                    tabIndex={-1}
                                    align="center"
                                >
                                    <TableCell align="center" onClick={ () => handleClickEditUser(item)}>{item.name}</TableCell>
                                    <TableCell align="center" onClick={ () => handleClickEditUser(item)}>{item.role}</TableCell>
                                    <TableCell align="center" onClick={ () => handleClickEditUser(item)}>{item.email}</TableCell>
                                    {/* <TableCell align="center" onClick={ () => handleClickEditUser(item)}>{item.username}</TableCell> */}
                                    <TableCell align="center" >
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => handleClickEditUser(item)}
                                        >
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                );
                            })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: usersList && usersList.length }]}
                                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={8}
                                    count={usersList && usersList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                                </TableRow>
                            </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
            
                    <Modal
                        open={Open}
                        onClose={handelAddNewUserClose}
                        className={classes.modal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>
          </div>
      </div>
    )
}