import React, { useEffect } from "react";
import { Link , Route, Redirect} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'15%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));


export default function TaskListsStepLink(stepListDropdown) {
    const classes = useStyles();
  
    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [openBulkSecond, setopenBulkSecond] = React.useState(false);
    const [ExcelFileSecond, setExcelFileSecond] = React.useState();
    const [ExcelNameSecond, setExcelNameSecond] = React.useState();
    const [fileErrSecond, setfileErrSecond] = React.useState();

    const [openBulkCreateTask, setopenBulkCreateTask] = React.useState(false);
    const [ExcelFileCreateTask, setExcelFileCreateTask] = React.useState();
    const [ExcelNameCreateTask, setExcelNameCreateTask] = React.useState();
    const [fileErrCreateTask, setfileErrCreateTask] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      // dispatch(userActions.checkSessionValidity());
    }, [flag, openBulk, openBulkSecond, openBulkCreateTask ]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelLinkTaskListToStepBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelLinkTaskListToStepBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelLinkTaskListToStepBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelLinkTaskListToStepBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
      let fileErr = '';

      if(!ExcelFile)  {
          fileErr = 'Please Select File';
      }

      if(fileErr ){
        setfileErr(fileErr);

        return false;
      }
      return true;
    }

    const handelSubmitLinkTaskListToStepBulk  = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_STEP_STATES , requestOptions)
            .then((Response) => Response.json())
            .then( linkTaskListToStepBulk => {
                setShowProcedure(false);
                console.log("linkTaskListToStepBulk on same page",linkTaskListToStepBulk.success);
                  console.log("linkTaskListToStepBulk on same page",linkTaskListToStepBulk);
                  if(linkTaskListToStepBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Link Task List To Step Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                   
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                  }
                  if(linkTaskListToStepBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: linkTaskListToStepBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'25%'}}>Link Task List to Step Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelLinkTaskListToStepBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelLinkTaskListToStepBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
            {/* <input type='file' 
              accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadExcel(e)} 
            /> */}
            <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
          <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitLinkTaskListToStepBulk}
            >
              Create Bulk
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handelLinkTaskListToStepBulkClear}
            >
              Clear
            </Button>
          </div>
        </div>
        </div>
    )

    const handelCreateFormBulkOpen = () => {
      setopenBulkSecond(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateFormBulkClose = () => {
      setopenBulkSecond(false);
      setExcelNameSecond();
      setExcelFileSecond();
      setfileErrSecond();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateFormBulkClear = () => {
      setExcelNameSecond();
      setExcelFileSecond();
      setfileErrSecond();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateFormBulkMinimize = () => {
      setopenBulkSecond(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcelSecond= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelNameSecond(event.target.files[0].name);
        setExcelFileSecond(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulkSecond = () => {
      let fileErrSecond = '';

      if(!ExcelFileSecond)  {
          fileErrSecond = 'Please Select File';
      }

      if(fileErrSecond ){
        setfileErrSecond(fileErrSecond);

        return false;
      }
      return true;
    }

    const handelSubmitFormBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFileSecond in category bulk",ExcelFileSecond);

      const isValid = validateBulkSecond();
      if(isValid){
        setShowProcedure(true);
        setopenBulkSecond(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFileSecond);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_QUESTION_FORMS , requestOptions)
            .then((Response) => Response.json())
            .then( formBulk => {
                setShowProcedure(false);
                console.log("formBulk on same page",formBulk.success);
                  console.log("formBulk on same page",formBulk);
                  if(formBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Form Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })    
                    setExcelNameSecond();
                    setExcelFileSecond();  
                    setfileErrSecond();        
                    setExcelFile();
                  }
                  if(formBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: formBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulkSecond(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBodySecond = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'40%'}}>Create Form Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateFormBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateFormBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
            {/* <input type='file' 
              accept=".xlsx"
              id="myBulk"
              onChange={ (e) => onUploadExcelSecond(e)} 
            /> */}
            <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcelSecond(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelNameSecond )?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelNameSecond}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
          <div className={classes.validationBulk}>{(ExcelFileSecond)?(<div></div>):(fileErrSecond)}</div>

          <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelSubmitFormBulk}
            >
              Create Bulk
            </Button>
            <div style={{marginLeft:'2%'}}></div>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={handelCreateFormBulkClear}
            >
              Clear
            </Button>
          </div>
        </div>
        </div>
    )

    // bulk create task
    const handelTaskBulkOpen = () => {
      setopenBulkCreateTask(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelTaskBulkClose = () => {
      setopenBulkCreateTask(false);
      setExcelFileCreateTask();
      setExcelNameCreateTask();
      setfileErrCreateTask();
      dispatch(userActions.checkSessionValidity());
    }

    const handelTaskBulkClear = () => {
      setExcelFileCreateTask();
      setExcelNameCreateTask();
      setfileErrCreateTask();
      dispatch(userActions.checkSessionValidity());
    }

    const handelTaskBulkMinimize = () => {
      setopenBulkCreateTask(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcelCreateTask= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelNameCreateTask(event.target.files[0].name);
        setExcelFileCreateTask(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulkCreateTask = () => {
      let fileErrCreateTask = '';

      if(!ExcelFileCreateTask)  {
          fileErrCreateTask = 'Please Select File';
      }

      if(fileErrCreateTask ){
        setfileErrCreateTask(fileErrCreateTask);

        return false;
      }
      return true;
    }

    const handelSubmitTaskBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username;
      // let username = "pavan";
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulkCreateTask();
      if(isValid){
        setShowProcedure(true);
        setopenBulkCreateTask(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFileCreateTask);
        // const requestOptions = {
        // method: 'POST',
        // headers: authHeader(),
        // body: formdata,
        // };
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_TODO_LISTS , requestOptions)
            .then((Response) => Response.json())
            .then( taskBulk => {
                setShowProcedure(false);
                console.log("taskBulk on same page",taskBulk.success);
                  console.log("taskBulk on same page",taskBulk);
                  if(taskBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "Task Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                    
                    setExcelFileCreateTask();
                    setExcelNameCreateTask();
                    setfileErrCreateTask();
                  }
                  if(taskBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: taskBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulkCreateTask(true);
                    })
                }            
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBodyCreateTask = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'48%'}}>Task Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelTaskBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelTaskBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcelCreateTask(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcelCreateTask(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelNameCreateTask )?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelNameCreateTask}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFileCreateTask)?(<div></div>):(fileErrCreateTask)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitTaskBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelTaskBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

  return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    {/* Task Lists Step Link List */}
                    Task List Bulk Uploads
                </Grid>
            </Grid>
            <hr/>
            <Grid container>

                <Grid item xs={12} onClick={handelTaskBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Task Lists
                </Grid>
                <Modal
                    open={openBulkCreateTask}
                    onClose={handelTaskBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBodyCreateTask}
                </Modal>

                <Grid item xs={12} onClick={handelLinkTaskListToStepBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Link Task lists to Step
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelLinkTaskListToStepBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                <Grid item xs={12} onClick={handelCreateFormBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk Create Form
                </Grid>
                <Modal
                    open={openBulkSecond}
                    onClose={handelCreateFormBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                  {bulkBodySecond}
                </Modal>
            </Grid>
        </Paper>
    </Grid>
  )
}
