import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "4%",
    marginRight: "4%",
  },
  filterHeader: {
    marginTop: "15px",
  },
  filterLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "21px",
    color: "rgba(0, 0, 0, 0.96)",
  },
  filterClose: {
    textAlign: "right",
    marginTop: "-5px",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.64)",
    marginTop: "25px",
    marginBottom: "10px",
  },
  dividerMargin: {
    marginTop: "25px",
    marginBottom: "10px",
  },
  marginRight: {
    marginRight: "4%",
  },
  textTransformNone: {
    textTransform: "none",
  },
  tagButtonStyle: {
    textTransform: "none",
    background: "#FDD600",
  },
  applyFilterStyle: {
    textTransform: "none",
    background: "#4D47DD",
    color: "#FFFFFF",
  },
}));

export default function FilterViewClient({ handleDrawerClose }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={classes.filterHeader}>
            <Grid item xs={6} className={classes.filterLabel}>
              <FormattedMessage id="filtersLabel" />
            </Grid>
            <Grid item xs={6} className={classes.filterClose}>
              <IconButton
                aria-label="close"
                onClick={handleDrawerClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.subHeadingLabel}>
          <FormattedMessage id="sortByLabel" />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.marginRight}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
              >
                <FormattedMessage id="recentLabel" />
              </Button>
            </Grid>
            <Grid item className={classes.marginRight}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
              >
                <FormattedMessage id="priorityLabel" />
              </Button>
            </Grid>
            <Grid item className={classes.marginRight}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
              >
                <FormattedMessage id="statusLabel" />
              </Button>
            </Grid>
            <Grid item className={classes.marginRight}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
              >
                <FormattedMessage id="dueDateLabel" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.subHeadingLabel}>
          <FormattedMessage id="caseIdPatternLabel" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className={classes.subHeadingLabel}>
          <FormattedMessage id="assignedPeopleLabel" />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.marginRight}>
              <Avatar src="/static/images/avatar/1.jpg" />
            </Grid>
            <Grid item className={classes.marginRight}>
              <Avatar src="/static/images/avatar/1.jpg" />
            </Grid>
            <Grid item className={classes.marginRight}>
              <Avatar src="/static/images/avatar/1.jpg" />
            </Grid>
            <Grid item className={classes.marginRight}>
              <Avatar src="/static/images/avatar/1.jpg" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.dividerMargin}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
              >
                <FormattedMessage id="resetLabel" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                className={classes.textTransformNone}
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleDrawerClose}
              >
                <FormattedMessage id="cancelLabel" />
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                size="small"
                variant="outlined"
                className={classes.applyFilterStyle}
              >
                <FormattedMessage id="applyFiltersLabel" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
