import React, {useEffect} from "react";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

import UserRoleBased from "./UserRoleBased";
import RolesRoleBased from "./RolesRoleBased";

const useStyles = makeStyles((theme) => ({
root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  pageHeaderLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  AddNewBusinessLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "right",
    cursor: "pointer",
    marginTop: '1.5%',
  },
   modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  customPaperStyle: {
    borderRadius: "10px",
    // marginLeft: "3%",
  },
  casesDetailSubMenu: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
  },
  tabCustomStyle: {
    textTransform: "none",
  },

}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

export default function RoleBasedAccess(props) {
    const classes = useStyles();
    const [businessDetails, setbusinessDetails] = React.useState([]);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [flag , setflag] = React.useState(false);

    const [value, setValue] = React.useState(0);

    useEffect(() => {

    }, []);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <div className={classes.root}>
        <div className={classes.wrapper}>
            <Grid>
                <Grid item xs={12} className={classes.pageHeaderLabel} >
                    <h2>Role Based Access</h2>
                </Grid>
                <Grid item xs={12}>
                <Paper elevation={0} className={classes.customPaperStyle}>
                    <div className={classes.casesDetailSubMenu}>
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            >
                            <Tab
                                className={classes.tabCustomStyle}
                                label="Role Based Access"
                            />                
                            <Tab
                                className={classes.tabCustomStyle}
                                // label={intl.formatMessage({ id: "activityLink" })}
                                label="User Based Access"
                            />
                        </Tabs>
                    </div>
                    <TabPanel value={value} index={0}>
                        <RolesRoleBased />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserRoleBased />
                    </TabPanel>
                </Paper>
              </Grid>
            </Grid>
        </div>
        </div>
    )
}
