import React, {useEffect} from "react";
import { Link , Route, Redirect} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../../actions";
import useCheckSessionApi from "../../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PersonIcon from "@material-ui/icons/Person";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DomainIcon from "@material-ui/icons//Domain";

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: "pointer"
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    marginRight:"5%",
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  moreVertIcon:{
    textAlign: "right",
  },
  personIconColor: {
      color: 'blue',
  },
  userAddUpdateDelete: {
      marginTop: '4%',
      fontSize: 11,
      color: 'black',
      cursor: "pointer",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // overflow:'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    height: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'5%',
    display:'block',
    overflowY:'scroll',
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
    width:'100%',
  },
  modalBodyBulk: {
    padding: theme.spacing(7, 4, 3,), 
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  paperModalBulk: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display:'block'
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));

export default function States(serviceListDropdown) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [stateDetails, setstateDetails] = React.useState({
        service_id: "",
        shortcode: "",
        name: "",
        is_init: "",
        is_accept: "",
        is_reject: "",
        is_hold: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const [yesNo, setyesNo] = React.useState([
      { value: true , name: 'Yes'  },
      { value: false , name: 'No'  },
    ]);
    const [trueFalse, settrueFalse] = React.useState([
      { value: true , name: 'True'  },
      { value: false , name: 'False'  },
    ]);

    const [serviceIdErr, setserviceIdErr] = React.useState();
    const [shortCodeErr, setshortCodeErr] = React.useState();
    const [nameErr, setnameErr] = React.useState();
    const [isInItErr, setisInItErr] = React.useState();
    const [isAcceptErr, setisAcceptErr] = React.useState();
    const [isRejectErr, setisRejectErr] = React.useState();
    const [isHoldErr, setisHoldErr] = React.useState();

    const [openBulk, setopenBulk] = React.useState(false);
    const [ExcelFile, setExcelFile] = React.useState();
    const [ExcelName, setExcelName] = React.useState();
    const [fileErr, setfileErr] = React.useState();

    const [ ShowProcedure, setShowProcedure ] = React.useState(false);

    const [flag, setflag] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setflag(true);
      // readItemFromStorage();
      // dispatch(userActions.checkSessionValidity());
    }, [flag, open, openBulk]);
    
    // const readItemFromStorage = async() => {
    //   try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //   } catch (error) {
    //     console.log("ERROR:",error);        }
    // };

    const handelCreateStateOpen = () => {
      setOpen(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateStateClose = () => {
      setOpen(false);
      setstateDetails({
        service_id: "",
        shortcode: "",
        name: "",
        is_init: "",
        is_accept: "",
        is_reject: "",
        is_hold: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setserviceIdErr();
      setshortCodeErr();
      setnameErr();
      setisInItErr();
      setisAcceptErr();
      setisRejectErr();
      setisHoldErr();
      dispatch(userActions.checkSessionValidity());
    };

    const handelCreateStateClear = async() => {
      await setstateDetails({
        service_id: "",
        shortcode: "",
        name: "",
        is_init: "",
        is_accept: "",
        is_reject: "",
        is_hold: "",
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')),
      })
      setOpen(false);
      setOpen(true);
      setserviceIdErr();
      setshortCodeErr();
      setnameErr();
      setisInItErr();
      setisAcceptErr();
      setisRejectErr();
      setisHoldErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelCreateStateMinimize = () => {
      setOpen(false);
      dispatch(userActions.checkSessionValidity());
    }

    const validate = () => {
        let serviceIdErr = '';
        let shortCodeErr = '';
        let nameErr = '';
        let isInItErr = '';
        let isAcceptErr = '';
        let isRejectErr = '';
        let isHoldErr = '';

        if(!stateDetails.service_id)  {
            serviceIdErr = 'Please Select Service';
        }
        if(!stateDetails.shortcode)  {
            shortCodeErr = 'Please Enter Short Code';
        }
        if(!stateDetails.name)  {
            nameErr = 'Please Enter Name';
        }
        if(stateDetails.is_init == "" && stateDetails.is_init !== false && stateDetails.is_init !== true)  {
            isInItErr = 'Please Select Is In It';
        }
        if(stateDetails.is_accept == "" && stateDetails.is_accept !== false && stateDetails.is_accept !== true )  {
            isAcceptErr = 'Please Select Is Accept';
        }
        if(stateDetails.is_reject == "" && stateDetails.is_reject !== false && stateDetails.is_reject !== true)  {
            isRejectErr = 'Please Select Is Reject';
        }
        if(stateDetails.is_hold  == "" && stateDetails.is_hold !== false && stateDetails.is_hold !== true)  {
            isHoldErr = 'Please Select Is Hold';
        }

        if(serviceIdErr || shortCodeErr || nameErr || isInItErr || isAcceptErr || isRejectErr || isHoldErr){
        setserviceIdErr(serviceIdErr);
        setshortCodeErr(shortCodeErr);
        setnameErr(nameErr);
        setisInItErr(isInItErr);
        setisAcceptErr(isAcceptErr);
        setisRejectErr(isRejectErr);
        setisHoldErr(isHoldErr);
        
        return false;
      }
      return true;
    }

    const handelSubmitCreateState = () => {
        console.log("stateDetails",stateDetails);
        dispatch(userActions.checkSessionValidity());
        const isValid = validate();
        if(isValid){
            setShowProcedure(true);
            setOpen(false);
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(stateDetails)
            };
            return fetch( process.env.REACT_APP_CREATE_STATES , requestOptions)
                .then((Response) => Response.json())
                .then(stateCreated => {
                    setShowProcedure(false);
                    console.log("stateCreated on same page",stateCreated.success);
                    console.log("stateCreated on same page",stateCreated);
                    if(stateCreated.success == true){
                        Swal.fire({
                        icon: 'success',
                        text: "State Created Succesfully",
                        confirmButtonColor: 'primary',
                        confirmButtonText: 'OK',
                        timer: 5000,
                        })                      
                        setstateDetails({
                            service_id: "",
                            shortcode: "",
                            name: "",
                            is_init: "",
                            is_accept: "",
                            is_reject: "",
                            is_hold: "",
                            device_token: JSON.parse(localStorage.getItem('device_token')),
                            session_id: JSON.parse(localStorage.getItem('session_id')),
                        })
                        setserviceIdErr();
                        setshortCodeErr();
                        setnameErr();
                        setisInItErr();
                        setisAcceptErr();
                        setisRejectErr();
                        setisHoldErr();
                    }
                    if(stateCreated.success == false || stateCreated.success == null ){
                        Swal.fire({
                          icon: 'error',
                          text: stateCreated.errors,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK',
                          timer: 5000,
                        }).then(function (result) {
                          console.log("result of swal",result)
                          setOpen(true);
                        })
                    }
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
        }
    }

    const body = (
        <div className={classes.paperModal}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'45%'}}>Create State</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelCreateStateMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelCreateStateClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBody} style={{position:'absolute'}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={stateDetails.name}
              onChange={(e) => setstateDetails({...stateDetails, name:e.target.value }) }
            />
            <div className={classes.validation}>{(stateDetails.name)?(<div></div>):(nameErr)}</div>

            <TextField
              margin="normal"
              required
              fullWidth
              id="shortcode"
              label="Short Code for State"
              name="shortcode"
              autoComplete="shortcode"
              value={stateDetails.shortcode}
              onChange={(e) => setstateDetails({...stateDetails, shortcode:e.target.value }) }
            />
            <div className={classes.validation}>{(stateDetails.shortcode)?(<div></div>):(shortCodeErr)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="service_id">Service*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Service*</Grid>
              <Select
                native
                labelId="service_id"
                id="service_id"
                value={stateDetails.service_id}
                onChange={(e) => setstateDetails({...stateDetails, service_id:e.target.value }) }
              >
                {/* <MenuItem value={''}>Select Service</MenuItem>
                {serviceListDropdown.serviceListDropdown.map(item =>
                  <MenuItem value={item.category_id}>{item.name}</MenuItem>
                )} */}
                <option value="" style={{color:'grey'}}>Select Service</option>
                  {serviceListDropdown.serviceListDropdown.map(item =>
                    <option value={item.category_id}>{item.name}</option>
                )}
              </Select>
            </FormControl>
            <div className={classes.validation}>{(stateDetails.service_id)?(<div></div>):(serviceIdErr)}</div>
            
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_init">Is In It*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is In It*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_init"
                  aria-label="is_init"
                  name="is_init"
                  defaultValue={JSON.stringify(stateDetails.is_init)}
                  onChange={ (e) => setstateDetails({...stateDetails, is_init: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_init"
                id="is_init"
                value={stateDetails.is_init}
                onChange={(e) => setstateDetails({...stateDetails, is_init:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stateDetails.is_init == "" && stateDetails.is_init !== false && stateDetails.is_init !== true)?(isInItErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_accept">Is Accept*</InputLabel>*/}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Accept*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_accept"
                  aria-label="is_accept"
                  name="is_accept"
                  defaultValue={JSON.stringify(stateDetails.is_accept)}
                  onChange={ (e) => setstateDetails({...stateDetails, is_accept: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_accept"
                id="is_accept"
                value={stateDetails.is_accept}
                onChange={(e) => setstateDetails({...stateDetails, is_accept:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stateDetails.is_accept == "" && stateDetails.is_accept !== false  && stateDetails.is_accept !== true)?(isAcceptErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_reject">Is Reject*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Reject*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_reject"
                  aria-label="is_reject"
                  name="is_reject"
                  defaultValue={JSON.stringify(stateDetails.is_reject)}
                  onChange={ (e) => setstateDetails({...stateDetails, is_reject: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_reject"
                id="is_reject"
                value={stateDetails.is_reject}
                onChange={(e) => setstateDetails({...stateDetails, is_reject:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stateDetails.is_reject == "" && stateDetails.is_reject !== false && stateDetails.is_reject !== true)?(isRejectErr):(<div></div>)}</div>

            <FormControl className={classes.formControl}>
              {/* <InputLabel id="is_hold">Is Hold*</InputLabel> */}
              <Grid style={{marginTop:"2%", color:"grey"}}>Is Hold*</Grid>
              <Grid container item xs={12} style={{marginTop:"1%"}}>
                <RadioGroup
                  row
                  id ="is_hold"
                  aria-label="is_hold"
                  name="is_hold"
                  defaultValue={JSON.stringify(stateDetails.is_hold)}
                  onChange={ (e) => setstateDetails({...stateDetails, is_hold: JSON.parse(e.target.value) })}
                >                  
                  <div>
                    <FormControlLabel
                      value="true"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "Yes"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="false"
                      classes={{ label: classes.label }}
                      control={<Radio size="small" color="primary" />}
                      label= "No"
                    />
                  </div>
                </RadioGroup>
              </Grid>
              {/* <Select
                labelId="is_hold"
                id="is_hold"
                value={stateDetails.is_hold}
                onChange={(e) => setstateDetails({...stateDetails, is_hold:e.target.value }) }
              >
                {yesNo.map(item =>
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                )}
              </Select> */}
            </FormControl>
            <div className={classes.validation}>{(stateDetails.is_hold  == "" && stateDetails.is_hold !== false && stateDetails.is_hold !== true)?(isHoldErr):(<div></div>)}</div>

            <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
                <Button
                type="submit"
                //   fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmitCreateState}
                >
                Create
                </Button>
                <div style={{marginLeft:'2%'}}></div>
                <Button
                    type="submit"
                    variant="contained"
                    // color="grey"
                    className={classes.submit}
                    onClick={handelCreateStateClear}
                >
                Clear
                </Button>
            </div>
        </div>
        </div>
    )

    const handelStateBulkOpen = () => {
      setopenBulk(true);
      dispatch(userActions.checkSessionValidity());
    }

    const handelStateBulkClose = () => {
      setopenBulk(false);
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelStateBulkClear = () => {
      setExcelFile();
      setExcelName();
      setfileErr();
      dispatch(userActions.checkSessionValidity());
    }

    const handelStateBulkMinimize = () => {
      setopenBulk(false);
      dispatch(userActions.checkSessionValidity());
    }

    const onUploadExcel= (event) => {
        console.log("event.target.files[0]",event.target.files[0])
        console.log("event.target.files[0] name",event.target.files[0].name)
        setExcelName(event.target.files[0].name);
        setExcelFile(event.target.files[0]);
        dispatch(userActions.checkSessionValidity());
    }

    const validateBulk = () => {
        let fileErr = '';

        if(!ExcelFile)  {
            fileErr = 'Please Select File';
        }

        if(fileErr ){
            setfileErr(fileErr);

            return false;
        }
        return true;
    }

    const handelSubmitStateBulk = () => {
      dispatch(userActions.checkSessionValidity());
      let users = JSON.parse(localStorage.getItem('users'));
      let username = users.data.user_details.username
      let business_id = JSON.parse(localStorage.getItem('selected_business_id'));
      // console.log("username in category bulk",username);
      // console.log("business_id in category bulk",business_id);
      // console.log("ExcelFile in category bulk",ExcelFile);

      const isValid = validateBulk();
      if(isValid){
        setShowProcedure(true);
        setopenBulk(false);
        let formdata = new FormData();
        formdata.append('business_id', business_id);
        formdata.append('username', username);
        formdata.append('excel_file', ExcelFile);
        let user = JSON.parse(localStorage.getItem('user'));
        const requestOptions = {
          method: 'POST',
          headers:  {
            Authorization: "Token " + user.key,
          },
          body: formdata,
        };
        return fetch( process.env.REACT_APP_BULK_CREATE_STATES , requestOptions)
            .then((Response) => Response.json())
            .then( stateBulk => {
                setShowProcedure(false);
                console.log("stateBulk on same page",stateBulk.success);
                console.log("stateBulk on same page",stateBulk);
                if(stateBulk.success == true){
                    Swal.fire({
                      icon: 'success',
                      text: "State Bulk Created Succesfully",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })                   
                    setExcelFile();
                    setExcelName();
                    setfileErr();
                }
                if(stateBulk.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: stateBulk.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    }).then(function (result) {
                      console.log("result of swal",result)
                      setopenBulk(true);
                    })
                }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
      }
    }

    const bulkBody = (
        <div className={classes.paperModalBulk}>
        <div className={classes.addNewBusinessHeaderBackgroud}> 
          <Grid container item xs={12}>
            <Grid item xs={10} >
            <h2 style={{marginLeft:'45%'}}>State Bulk</h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'0%',marginRight:'-1%', }}>
              <MinimizeIcon onClick={()=>handelStateBulkMinimize()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelStateBulkClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.modalBodyBulk}>
          {/* <input type='file' 
            accept=".xlsx"
            id="myBulk"
            onChange={ (e) => onUploadExcel(e)} 
          /> */}
          <Grid container>
            <Grid item xs={3}>
              <input type='file' 
                // id="files"
                // accept=".xlsx"
                id="myBulk"
                onChange={ (e) => onUploadExcel(e)}  
                hidden
              />
              <label for="myBulk" style={{backgroundColor: '#cbd4cd', height:8, width:40, padding:10, borderRadius:5,
                cursor: "pointer"}}>Select file</label>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-2%'}}>
              {
                ( ExcelName)?
                (
                  <div >
                    {/* <a 
                      href={this.state.BiographyResumeFileName} target = "_blank"> */}
                      <text>{ExcelName}</text>
                    {/* </a> */}
                  </div>
                ):
                (
                  <div>
                    No file Choosen 
                  </div>
                )
              }
            </Grid>         
          </Grid>
        <div className={classes.validationBulk}>{(ExcelFile)?(<div></div>):(fileErr)}</div>

        <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitStateBulk}
          >
            Create Bulk
          </Button>
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handelStateBulkClear}
          >
            Clear
          </Button>
        </div>
        </div>
        </div>
    )

    return(
    <Grid item xs={4}>
        <div>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        </div>
        <Paper className={classes.paper}>
            <Grid container>
                <Grid item xs={1} className={classes.moreVertIcon}>
                    <PersonIcon className={classes.personIconColor}/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'2%', color:'black'}}>
                    State List
                </Grid>
            </Grid>
            <hr/>
            <Grid container>
                <Grid item xs={12} className={classes.userAddUpdateDelete}>
                  <Link to="StateList" style={{ textDecoration: 'none', color:'black' }}>
                      State List
                  </Link> 
                </Grid>
                <Grid item xs={12} onClick={handelCreateStateOpen} className={classes.userAddUpdateDelete}>
                    Create State
                </Grid>
                <Modal
                    open={open}
                    onClose={handelCreateStateClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
                <Grid item xs={12} onClick={handelStateBulkOpen} className={classes.userAddUpdateDelete}>
                    Bulk create State
                </Grid>
                <Modal
                    open={openBulk}
                    onClose={handelStateBulkClose}
                    className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {bulkBody}
                </Modal>
                </Grid>
        </Paper>
    </Grid>
  )
}